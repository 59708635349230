// @flow
// $FlowFixMe
import React, { useState, useEffect, useRef } from 'react';
import IconLabel from './IconLabel';

import styles from './CopyButton.css';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  onClick: Function,
};

const CopyButton = ({ onClick }: OwnProps) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef();

  const onButtonClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    onClick();
    setCopied(true);

    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <button
      onClick={onButtonClick}
      className={classNames('c-btn', styles.copyButton, {
        [styles.copied]: copied,
      })}
    >
      {copied ? (
        <IconLabel name="check" />
      ) : (
        <React.Fragment>
          <IconLabel name="copy" size="default" />
          <FormattedMessage id="copy" defaultMessage="Copy" />
        </React.Fragment>
      )}
    </button>
  );
};

export default CopyButton;
