import Cookies from 'js-cookie';
import serialize from 'serialize-javascript';
import {
  selectCookiePreferences,
  selectSegmentCdnHost,
  selectSegmentWriteKey,
} from '../selectors/client';
import { selectUserIsAuthenticated } from '../selectors/user';
import { COOKIE_PREFERENCES_COOKIE } from '../constants';

const NECESSARY_EVENTS = [
  'Resumed Playback',
  'Started Playback',
  'Paused Playback',
  'Served Ad Id',
  'Stopped Playback',
  'Updated Ad Playback Duration',
  'Updated Playback Duration',
  'Ad Playback Start Timeout Error',
  'Playback Start Timeout Error',
  'Playback Start Error',
  'Measured Time to Play v1',
];

const ADVERTISING_CATEGORIES = ['Advertising', 'Tag Managers'];

const FUNCTIONAL_CATEGORIES = [
  'CRM',
  'Customer Success',
  'Deep Linking',
  'Helpdesk',
  'Livechat',
  'Performance Monitoring',
  'Personalization',
  'SMS & Push Notifications',
  'Security & Fraud',
];

const getDestinationPreferences = (destinations, preferences) => {
  const destinationPreferences = {};

  Object.values(destinations).forEach(destination => {
    const category = destination.category;

    if (destinationPreferences[destination.id] !== false) {
      if (ADVERTISING_CATEGORIES.includes(category)) {
        destinationPreferences[destination.id] = preferences.advertising;
      } else if (FUNCTIONAL_CATEGORIES.includes(category)) {
        destinationPreferences[destination.id] = preferences.functional;
      }
    }

    // Fallback to marketing
    if (!(destination.id in destinationPreferences)) {
      destinationPreferences[destination.id] = preferences.marketingAndAnalytics;
    }
  });

  return destinationPreferences;
};

async function fetchSegmentDestinations(cdnHost, writeKey) {
  const res = await fetch(`${cdnHost}/v1/projects/${writeKey}/integrations`);

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`
    );
  }

  const destinations = await res.json();

  for (const destination of destinations) {
    if (destination.name === 'Fullstory') {
      destination.id = destination.name;
    } else {
      destination.id = destination.creationName;
    }
    delete destination.creationName;
  }

  return destinations;
}

async function loadSegment(store) {
  const state = store.getState();
  const segmentWriteKey = selectSegmentWriteKey(state);
  const segmentCdnHost = selectSegmentCdnHost(state);

  const destinations = await fetchSegmentDestinations(segmentCdnHost, segmentWriteKey);

  analytics.addSourceMiddleware(({ payload, next: nextAnalytics, integrations }) => {
    const cookiePreferences = selectCookiePreferences(store.getState());
    const eventName = payload.obj.event;
    const userIsAuthenticated = selectUserIsAuthenticated(store.getState());
    const shouldOverwritePreferencesAndAllowCookies =
      userIsAuthenticated || (!userIsAuthenticated && NECESSARY_EVENTS.includes(eventName));
    const allCookiesAllowed =
      shouldOverwritePreferencesAndAllowCookies ||
      Object.values(cookiePreferences).every(value => value);

    if (!allCookiesAllowed) {
      const destinationPreferences = getDestinationPreferences(destinations, cookiePreferences);
      if (Object.values(destinationPreferences).every(value => !value)) {
        // don't send unnecessary events if all cookies are disabled
        return;
      }

      // otherwise modify integrations based on cookie preferences
      payload.obj.integrations = { ...integrations, ...destinationPreferences };
    }

    nextAnalytics(payload);
  });

  analytics.load(serialize(segmentWriteKey));
}

export default store => next => async action => {
  if (action.type === 'SAVE_COOKIES_CONSENT') {
    if (!Cookies.get(COOKIE_PREFERENCES_COOKIE)) {
      Cookies.set(COOKIE_PREFERENCES_COOKIE, action.preferences, {
        secure: process.env.ENV === 'production' && !process.env.NO_HTTPS,
        expires: 365 * 100,
        domain: process.env.TOP_DOMAIN,
      });
    }

    loadSegment(store);
  }

  next(action);
};
