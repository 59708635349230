import { track } from '../actions/analytics';
import { USER_CLICKED_NEXT } from '../constants';
import { selectAudioQuality, selectPlayerCurrentQueueItem } from '../selectors/player';
import { selectEncryptionEnabled, selectUserAgentInfo } from '../selectors/client';
import { getPlayerClass } from '../client/playback-support';
import AudioTagPlayer from '../client/players/Player';
import { getCompoundId } from '../actions/trackUrls';

function selectTrackUrl(trackId, audioQuality, trackUrls) {
  const compoundId = getCompoundId(trackId, audioQuality);
  return trackUrls[compoundId];
}

export default store => {
  const reports = {};
  const initialState = store.getState();
  const useragentInfo = selectUserAgentInfo(initialState);
  const encryptionEnabled = selectEncryptionEnabled(initialState);
  const playerClass = getPlayerClass(useragentInfo, encryptionEnabled);

  const playerEngineName = playerClass === AudioTagPlayer ? 'html5' : 'hls.js';

  return next => action => {
    next(action);
    // We do not track TTP for external playback
    if (action.source && action.source !== 'internal') {
      return;
    }

    const state = store.getState();
    const currentQueueItem = selectPlayerCurrentQueueItem(state);
    const currentTrackId = currentQueueItem && currentQueueItem.track;
    // Only exists if play was initiated by user action
    const report = reports[currentTrackId];

    if (action.type === 'PLAYER_PLAY' && action.timestamp) {
      const { track: trackId } = action.queueItem;
      reports[trackId] = {
        playStart: action.timestamp,
        trigger: 'play',
      };
      return;
    }

    if (action.type === 'PLAYER_NEXT') {
      if (action.reason === USER_CLICKED_NEXT) {
        reports[currentTrackId] = {
          playStart: action.timestamp,
          trigger: 'next',
        };
      }
      return;
    }

    if (action.type === 'PLAYER_PREVIOUS') {
      reports[currentTrackId] = {
        playStart: action.timestamp,
        trigger: 'previous',
      };
      return;
    }

    if (action.type === 'START_TIME_BUFFERING_TO_PLAY_TRACKING' && report) {
      reports[currentTrackId] = {
        ...report,
        bufferingStart: action.timestamp,
      };
    }

    if (action.type === 'END_TIME_TO_PLAY_TRACKING' && report) {
      const { playStart, bufferingStart, trigger } = report;
      const { timestamp: playEnd, wasPreloaded, trackId } = action;

      const bufferingTime = playEnd - bufferingStart;
      const audioQuality = selectAudioQuality(state);

      const { loadedAt: trackUrlLoadedAt, requestedAt: trackUrlRequestedAt } = selectTrackUrl(
        currentTrackId,
        audioQuality,
        state.maps.trackUrls
      );

      const sourcesTime =
        trackUrlLoadedAt <= playStart ? 0 : trackUrlLoadedAt - trackUrlRequestedAt;
      store.dispatch(
        track('Measured Time to Play v1', {
          total_time: playEnd - playStart,
          buffering_time: wasPreloaded ? 0 : bufferingTime,
          is_preloaded: wasPreloaded,
          track_id: trackId,
          offline: false,
          quality_level: audioQuality,
          player_engine: playerEngineName,
          sources_api_time: sourcesTime,
          trigger,
        })
      );
      delete report[currentTrackId];
      return;
    }
  };
};
