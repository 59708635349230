// @flow

import type { Notification } from '../lib/notifications';

type AddNotificationAction = {
  type: 'NOTIFICATION_ADD',
  notification: Notification,
};

type DismissNotificationAction = {
  type: 'NOTIFICATION_DISMISS',
  topic?: string,
  id?: number,
};

export type NotificationAction = AddNotificationAction | DismissNotificationAction;

export function addNotification(notification: Notification): AddNotificationAction {
  return {
    type: 'NOTIFICATION_ADD',
    notification,
  };
}

export function dismissNotification(id: string | number): DismissNotificationAction {
  if (typeof id === 'string') {
    return {
      type: 'NOTIFICATION_DISMISS',
      topic: id,
    };
  }
  return {
    type: 'NOTIFICATION_DISMISS',
    id,
  };
}
