// @flow

import { REQUEST, SUCCESS, FAILURE } from '../../middleware/api';

import type { SonosQueue } from '../../shapes/types';
import type { SetSonosQueueAction } from '../../actions/sonos';

export type State = {
  [id: string]: {|
    queue: SonosQueue,
    loading: boolean,
    loaded: boolean,
  |},
};

const initState = {};

/**
  This request phase logic looks strange but it's because of how our interaction
  with sonos works. We have to supply a fully hydrated queue (including source url)
  to create a cloud queue, which in turn will give us its id. The id is then used to
  start playback on sonos. It's basically a reverse get, send the body and receive a
  get param.
 */

export default function queues(state: State = initState, action: SetSonosQueueAction): State {
  const { type, phase, cacheId, response, tracks } = action;

  if (type === 'SET_SONOS_QUEUE') {
    if (phase === REQUEST) {
      return {
        ...state,
        [cacheId]: {
          queue: { tracks },
          loading: true,
          loaded: false,
        },
      };
    }

    if (phase === SUCCESS && response && cacheId) {
      const sonosQueue = response.normalized.entities.sonosQueues[cacheId];
      return {
        ...state,
        [cacheId]: {
          queue: {
            tracks: sonosQueue.tracks,
            cloudQueueId: sonosQueue.cloudQueueId,
          },
          loading: false,
          loaded: true,
        },
      };
    }

    if (phase === FAILURE && cacheId) {
      return {
        ...state,
        [cacheId]: {
          ...state[cacheId],
          loaded: false,
          loading: false,
        },
      };
    }
  }
  return state;
}
