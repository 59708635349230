// @flow

import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Tag.css';

type OwnProps = {
  children: React.Element<typeof FormattedMessage>,
  icon?: React.Element,
  className?: string,
};

const Tag = ({ children, className, icon }: OwnProps) => {
  return (
    <div className={classNames(styles.tag, className)}>
      {icon}
      {children}
    </div>
  );
};

export default Tag;
