// @flow
import React from 'react';
import classnames from 'classnames';
import IconLabel from './../util/IconLabel';
import styles from './DownloadButton.css';
import { defineMessages, injectIntl } from 'react-intl';
import CapacitorRipple from '../capacitor/Ripple';
import type { IntlShape } from 'react-intl';
import { CONSTANT_MESSAGES } from '../../constants';

declare var __CAPACITOR__: boolean;

const messages = defineMessages({
  download: {
    id: 'download.button.text',
    defaultMessage: 'Download',
  },
});

type OwnProps = {
  url: string,
  onClick?: Function,
  hideText?: boolean,
  className?: string,
  title?: string,
};

type Props = OwnProps & { intl: IntlShape };

const DownloadButton = ({ url, onClick, className, hideText = false, title, intl }: Props) => {
  const classNames = classnames(styles.downloadButton, className);
  const text = title || intl.formatMessage(messages.download);
  const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

  return (
    <a
      className={classNames}
      href={url}
      onClick={onClick}
      title={text}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLink}
      data-test="download-button"
    >
      <CapacitorRipple />
      <IconLabel
        name="download"
        size={hideText ? 'default' : 'small'}
        className={styles.iconDefault}
      />
      {!hideText && <span className={styles.text}>{text}</span>}
    </a>
  );
};

export default injectIntl(DownloadButton);
