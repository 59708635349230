// @flow
import React from 'react';

import LivestreamEventInfo from '../livestream-event-preview/LivestreamEventInfo';

import { getEntityUrl } from '../../lib/entities';

import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';

type OwnProps = {
  event: Object,
  onClickImageLink?: Function,
  isInverted?: boolean,
  shouldShowDetails?: boolean,
  isSliderItem?: boolean,
};

type Props = OwnProps;

const LivestreamEventPreview = ({
  event,
  onClickImageLink,
  isInverted,
  shouldShowDetails,
  isSliderItem,
}: Props) => {
  const url = getEntityUrl(ENTITY_TYPE_LIVESTREAM_EVENT, event);
  return (
    <LivestreamEventInfo
      event={event}
      url={url}
      onClickLink={onClickImageLink}
      isInverted={isInverted}
      shouldShowDetails={shouldShowDetails}
      isSliderItem={isSliderItem}
    />
  );
};

export default LivestreamEventPreview;
