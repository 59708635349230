import { selectEntityTrackIds } from '../lib/entities';
import { getQueueOrigin } from '../lib/queue';
import { selectIsLoadingAudio } from './client';
import { getItemContent, getItemType } from './discover';
import {
  selectPlayerIsPlaying,
  selectPlayerLoadingQueueOrigin,
  selectPlayerQueueOrigin,
} from './player';
import { selectQueueIsLoading } from './queue';

export function getPlayableItemPreviewQueueOrigin(playableItemPreview) {
  const item = getItemContent(playableItemPreview);
  const type = getItemType(playableItemPreview);

  return getQueueOrigin(type, item);
}

export function selectPlayableItemPreviewIsQueued(state, item) {
  const itemQueueOrigin = getPlayableItemPreviewQueueOrigin(item);
  const currentQueueOrigin = selectPlayerQueueOrigin(state);

  return (
    !!currentQueueOrigin &&
    currentQueueOrigin.id === itemQueueOrigin.id &&
    currentQueueOrigin.type === itemQueueOrigin.type
  );
}

export function selectPlayableItemPreviewIsLoading(state, item) {
  const itemQueueOrigin = getPlayableItemPreviewQueueOrigin(item);
  const loadingQueueOrigin = selectPlayerLoadingQueueOrigin(state);

  return (
    !!loadingQueueOrigin &&
    loadingQueueOrigin.id === itemQueueOrigin.id &&
    loadingQueueOrigin.type === itemQueueOrigin.type
  );
}

export function selectPlayableItemPreviewIsPlaying(state, item) {
  const isPlaying = selectPlayerIsPlaying(state);
  return isPlaying && selectPlayableItemPreviewIsQueued(state, item);
}

export function selectPlayableItemPreviewIsLoadingToPlay(state, item) {
  const isLoadingAudio = selectIsLoadingAudio(state);
  const itemIsQueued = selectPlayableItemPreviewIsQueued(state, item);
  const queueIsLoading = selectQueueIsLoading(state);

  return itemIsQueued && (isLoadingAudio || queueIsLoading);
}

export function selectPlayableItemPreviewTrackIds(state, playableItemPreview) {
  const item = getItemContent(playableItemPreview);
  const type = getItemType(playableItemPreview);

  return selectEntityTrackIds(state, type, item);
}
