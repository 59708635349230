// @flow

import React from 'react';
import styles from './ParticipantList.css';
import ParticipantInfo from './ParticipantInfo';
import FeaturedArtistsTitle from '../messages/FeaturedArtistsTitle';
import FeaturedComposersTitle from '../messages/FeaturedComposersTitle';

type ParticipantType = 'composer' | 'artist';
type Participant = {
  name: string,
  type: ParticipantType,
};
type OwnProps = {
  items: Array<Participant>,
};

const ParticipantList = ({ items }: OwnProps) => {
  if (!Array.isArray(items)) {
    return null;
  }

  const getParticipantNames = (type: ParticipantType) => {
    return items.filter(participant => participant.type === type).map(({ name }) => name);
  };

  return (
    <div className={styles.container}>
      <ParticipantInfo title={<FeaturedComposersTitle />} names={getParticipantNames('composer')} />
      <ParticipantInfo title={<FeaturedArtistsTitle />} names={getParticipantNames('artist')} />
    </div>
  );
};

export default ParticipantList;
