import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  albums: {
    id: 'modal.share.albums-link',
    defaultMessage: 'Albums Link:',
  },
  playlists: {
    id: 'modal.share.playlists-link',
    defaultMessage: 'Playlists Link:',
  },
  recordings: {
    id: 'modal.share.recordings-link',
    defaultMessage: 'Recordings Link:',
  },
  personalPlaylist: {
    id: 'modal.share.personal-playlist-link',
    defaultMessage: 'Playlist Link:',
  },
  livestreamEvent: {
    id: 'modal.share.livestream-link',
    defaultMessage: 'Live Stream Link:',
  },
  artist: {
    id: 'modal.share.artist-link',
    defaultMessage: 'Artist Link:',
  },
});

const ShareModalLinkLabel = ({ entityType, intl }) => {
  if (messages[entityType]) {
    return intl.formatMessage(messages[entityType]);
  }
  return (
    <FormattedMessage
      id="modal.share.link"
      defaultMessage="{entityType} Link:"
      values={{ entityType }}
    />
  );
};

ShareModalLinkLabel.propTypes = {
  entityType: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(ShareModalLinkLabel);
