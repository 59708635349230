import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const message = defineMessages({
  save: {
    id: 'collection.button.save',
    defaultMessage: 'Save to collection',
  },
});

export default () => <FormattedMessage {...message.save} />;
