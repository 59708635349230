// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadAllFacetsAction } from '../../actions/facet';
import type { State } from './albumFacets';

const initialState = {};

export default function recordingFacets(
  state: State = initialState,
  action: LoadAllFacetsAction
): State {
  if (action.type === 'FETCH_ALL_RECORDING_FACETS' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }
  return state;
}
