// @flow
import { Album, AlbumList } from '../schema';
import { normalizeMetaItem, normalizeMetaList } from '../schema/normalizeMeta';
import { loaded, loadedCursor } from '../middleware/apiCacheValidators';

import type { Request } from './types';
import filterAndSortParams from '../utils/filterAndSortParams';
import getSerializedFilterId from '../utils/getSerializedFilterId';
import singulariseParamObject from '../utils/singulariseParamObject';
import { VALID_ALBUM_FILTER_PARAMS } from '../constants';

export type LoadAlbumAction = { type: 'FETCH_ALBUM' } & Request;
export type LoadAlbumsAction = { type: 'FETCH_ALBUMS' } & Request;
export type LoadAlbumsByFilterAction = { type: 'FETCH_ALBUMS_BY_FILTER' } & Request;

export type AlbumAction = LoadAlbumAction | LoadAlbumsAction;

export function loadAlbum(slug: string): LoadAlbumAction {
  return {
    type: 'FETCH_ALBUM',
    cacheId: slug,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/albums/{slug}',
      params: { slug },
    },
    meta: {
      schema: Album,
      normalize: normalizeMetaItem,
    },
    cache: {
      fetch: state => state.maps.albums[slug],
      validate: loaded,
    },
  };
}

export function loadAlbums(ids: Array<string | number>, recordingId: number): LoadAlbumsAction {
  return {
    type: 'FETCH_ALBUMS',
    cacheId: recordingId.toString(),
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/metadata/albums/bulk',
      params: {
        sort: 'publishDateAsc',
      },
      body: {
        ids,
      },
    },
    meta: {
      schema: AlbumList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.maps.albumsByRecording[recordingId],
      validate: loaded,
    },
  };
}

export function loadAlbumsByFilter(
  params: Object,
  cursor?: string,
  limit?: number = 50
): LoadAlbumsByFilterAction {
  const validAndSortedParams = filterAndSortParams(params, VALID_ALBUM_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_ALBUM_FILTER_PARAMS);

  let paginatedParams = {
    ...singulariseParamObject(validAndSortedParams),
    limit,
  };

  if (cursor) {
    paginatedParams = {
      ...paginatedParams,
      cursor,
    };
  }

  return {
    type: 'FETCH_ALBUMS_BY_FILTER',
    cacheId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/albums/filter',
      params: paginatedParams,
    },
    meta: {
      schema: AlbumList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.maps.albumsByFilter[cacheId],
      validate: loadedCursor(cursor),
    },
  };
}
