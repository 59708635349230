import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './PaymentForm.css';

export default class SEPANotice extends PureComponent {
  render() {
    return (
      <p className={styles.infoSepa}>
        <FormattedMessage
          id="account.sepa-notice"
          defaultMessage="By providing your IBAN and confirming this payment, you are authorizing IDAGIO GmbH and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited."
        />
      </p>
    );
  }
}
