// @flow

import createCollectionReducer from './createCollectionReducer';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<string>,
|};

const initialState: State = {
  loading: false,
  loaded: false,
  loadedCursors: {},
  entities: [],
};

const albumCollectionActions = {
  add: 'ADD_ALBUM_TO_COLLECTION',
  remove: 'REMOVE_ALBUM_FROM_COLLECTION',
  fetch: 'FETCH_ALBUM_COLLECTION',
};

export default createCollectionReducer(initialState, albumCollectionActions);
