import { findIndex, once } from 'lodash';

import { setConnectedSonosGroup, setSonosSessionId, updateSonosGroupName } from '../actions/sonos';
import { addNotification } from '../actions/notifications';
import { setAudioQuality, setIsLoadingAudio } from '../actions/client';
import * as playerActions from '../actions/player';
import { track as analyticsTrack } from '../actions/analytics';

import { selectSonosCloudQueueId } from '../selectors/sonos';
import { selectUserId } from '../selectors/user';
import { selectQueueOriginCompoundId } from '../selectors/queue';
import {
  selectPlayerIsPlaying,
  selectPlayerQueueItems,
  selectPlayerCurrentQueueItem,
  selectProgress,
  selectPlayerMuted,
  selectPlayerVolume,
  selectPlayerControlsEnabled,
  selectAudioQuality,
} from '../selectors/player';

import { SONOS_DISCONNECTED } from '../lib/notifications';

import { SONOS_NEXT } from '../constants';

import { attachStoreToLocalPlayer } from '../client/attachStoreToLocalPlayer';
import { selectLiveCompareCurrentlyOn } from '../selectors/liveCompare';

// dynamically require these so that we can run it in web as well
let sonosConnector;
let sonosPlayback;
let sonosVolume;
let sonosGlobal;
let sonosPlaybackSession;
let sonosMetaData;
let crypto;
if (__ELECTRON__) {
  crypto = window.require('crypto');
  const SonosConnectionObjects = require('../client/players/SonosPlayer');
  sonosConnector = SonosConnectionObjects.sonosConnector;
  sonosPlayback = SonosConnectionObjects.sonosPlayback;
  sonosPlaybackSession = SonosConnectionObjects.sonosPlaybackSession;
  sonosVolume = SonosConnectionObjects.sonosVolume;
  sonosGlobal = SonosConnectionObjects.sonosGlobal;
  sonosMetaData = SonosConnectionObjects.sonosMetaData;
}

function recreateLocalPlayerState(store, player) {
  const state = store.getState();
  const progress = selectProgress(state);
  const playing = selectPlayerIsPlaying(state);
  const volume = selectPlayerVolume(state);
  const muted = selectPlayerMuted(state);
  const currentQueueItem = selectPlayerCurrentQueueItem(state);
  const queueItems = selectPlayerQueueItems(state);
  const quality = selectAudioQuality(state);

  store.dispatch(setAudioQuality(quality));
  player.seek(progress);
  player.setVolume(volume / 100);
  player.setMuted(muted);

  if (currentQueueItem) {
    const tracks = queueItems.map(({ track }) => track);
    player.setQueue(tracks, true);

    if (playing) {
      player.playQueueItem(currentQueueItem.track);
    } else {
      store.dispatch(playerActions.setCurrentQueueItem(currentQueueItem));
      store.dispatch(playerActions.seekFinish(progress));
    }
  }
}

export const createOnConnectionFail = (store, revertToLocalPlayback) => e => {
  console.warn('Something went wrong with the websocket connection'); // eslint-disable-line no-console
  console.error(e); // eslint-disable-line no-console
  revertToLocalPlayback();
};

export async function disconnectSonosGroup(store, shouldPickThisDevice) {
  try {
    await Promise.all(
      [sonosGlobal, sonosPlayback, sonosVolume, sonosPlaybackSession, sonosMetaData].map(channel =>
        channel.unsubscribe()
      )
    ).catch(e => {
      console.warn('Could not unsubscribe from sonos namespace'); // eslint-disable-line no-console
      console.error(e); // eslint-disable-line no-console
    });

    try {
      await sonosPlaybackSession.leaveSession();
    } catch (e) {
      console.warn('Could not leave session, probably there is no session to leave'); // eslint-disable-line no-console
      console.error(e); // eslint-disable-line no-console
    }

    sonosConnector.disconnect();
    store.dispatch(analyticsTrack('Disconnected Sonos'));
    if (shouldPickThisDevice) {
      store.dispatch(setConnectedSonosGroup('this-device'));
    }
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}

function onSonosPlaybackMessage(store, msg) {
  const state = store.getState();
  const currentQueueItem = selectPlayerCurrentQueueItem(state) || {};
  const queueItems = selectPlayerQueueItems(state);
  const playing = selectPlayerIsPlaying(state);
  const controlsEnabled = selectPlayerControlsEnabled(state);
  const isLiveCompare = selectLiveCompareCurrentlyOn(state);
  const {
    playbackState,
    previousItemId,
    itemId,
    positionMillis,
    previousPositionMillis,
    availablePlaybackActions,
  } = msg.body;
  switch (playbackState) {
    case 'PLAYBACK_STATE_PAUSED':
      if (playing) {
        store.dispatch(playerActions.pause('sonos'));
      }

      if (!controlsEnabled) {
        store.dispatch(playerActions.enableControls());
      }

      // seek when paused, this is otherwise taken care of by polling in the player

      if (!playing && currentQueueItem.track) {
        store.dispatch(playerActions.seekFinish(positionMillis / 1000, 'sonos'));
      }
      store.dispatch(setIsLoadingAudio(false));
      break;
    case 'PLAYBACK_STATE_PLAYING':
      if (!playing) {
        store.dispatch(playerActions.play('sonos'));
      }

      if (!controlsEnabled) {
        store.dispatch(playerActions.enableControls());
      }

      store.dispatch(setIsLoadingAudio(false));
      break;
    case 'PLAYBACK_STATE_BUFFERING':
      if (controlsEnabled) {
        store.dispatch(playerActions.disableControls());
      }
      store.dispatch(setIsLoadingAudio(true));
      break;
    case 'PLAYBACK_STATE_IDLE':
      store.dispatch(playerActions.enableControls());
      break;
    default:
  }

  // check if we changed track
  // added !!itemId check in case it's null, we don't need to change the track apparently. todo: check with someone if there's a better solution
  if (
    itemId &&
    previousItemId &&
    previousItemId !== itemId &&
    previousItemId === currentQueueItem.track
  ) {
    const currentQueueIndex = findIndex(
      queueItems,
      ({ track }) => track === currentQueueItem.track
    );
    const sonosQueueIndex = findIndex(queueItems, ({ track }) => track === itemId);
    const delta = currentQueueIndex - sonosQueueIndex;

    if (delta === 1) {
      store.dispatch(playerActions.previousThunk('sonos'));
    }

    if (delta === -1) {
      store.dispatch(playerActions.nextThunk(SONOS_NEXT, 'sonos'));
    }
  }

  const endOfTheQueue =
    availablePlaybackActions &&
    !availablePlaybackActions.canSkip &&
    positionMillis === previousPositionMillis &&
    playing &&
    playbackState === 'PLAYBACK_STATE_PAUSED' &&
    !isLiveCompare;

  if (endOfTheQueue) {
    // Counter intuitive but this needs to be source = internal, otherwise it won't propagate to
    // the sonos controller/player. Sonos doesn't internally reset the queue on end so we need to
    // do it explicitly when sonos tells us that the queue has ended.
    store.dispatch(playerActions.resetQueue(false, 'internal'));
  }
}

function subscribeToMessages(store, LocalPlayerclass, revertToLocalPlayback) {
  sonosPlayback.subscribe(msg => {
    onSonosPlaybackMessage(store, msg);
  });

  sonosVolume.subscribe(msg => {
    const state = store.getState();
    const { volume, muted } = msg.body;
    if (selectPlayerMuted(state) !== muted) {
      store.dispatch(playerActions.toggleMute('sonos'));
    }

    store.dispatch(playerActions.changeVolume(volume, 'sonos'));
  });

  sonosGlobal.subscribe(async function onSonosGlobalMessage(msg) {
    const state = store.getState();
    switch (msg.body.groupStatus) {
      case 'GROUP_STATUS_GONE':
        revertToLocalPlayback();
        store.dispatch(analyticsTrack('Sonos Group Coordinator Gone'));
        store.dispatch(addNotification(SONOS_DISCONNECTED));
        break;
      case 'GROUP_STATUS_MOVED': {
        store.dispatch(analyticsTrack('Sonos Group Coordinator Moved'));
        await disconnectSonosGroup(store, false);
        const { householdId, groupId } = msg.header;
        const { websocketUrl } = msg.body;

        const onConnected = async () => {
          store.dispatch(setConnectedSonosGroup(groupId));
          const userId = selectUserId(state);
          const hashedUserId = crypto.createHash('md5').update(userId).digest('hex');
          await sonosPlaybackSession.joinSession('com.idagio.desktop', hashedUserId);

          subscribeToMessages(store, LocalPlayerclass, revertToLocalPlayback);
        };

        const onConnectionFail = createOnConnectionFail(store, revertToLocalPlayback);

        sonosConnector.connect(
          { householdId, groupId, address: websocketUrl },
          onConnected,
          onConnectionFail
        );
        break;
      }
      case 'GROUP_STATUS_UPDATED':
        store.dispatch(updateSonosGroupName(msg.body.groupName));
        break;
      default:
    }
  });

  sonosMetaData.subscribe(
    once(function onFirstSonosMetaDataStatus(status) {
      /*
        Check what track is active on sonos when reconnecting, queue might have
        progressed. Only runs on the very first metadata message.
      */
      const localCurrentQueueItem = selectPlayerCurrentQueueItem(store.getState());
      const currentSonosTrackId = status.body.currentItem && status.body.currentItem.id;
      const trackIsDifferent =
        currentSonosTrackId && currentSonosTrackId !== localCurrentQueueItem.track;

      if (trackIsDifferent) {
        store.dispatch(playerActions.setCurrentQueueItem({ track: currentSonosTrackId }, 'sonos'));
      }
    })
  );

  sonosPlaybackSession.subscribe(msg => {
    if (msg.header.type === 'sessionError') {
      if (msg.body.errorCode === 'ERROR_SESSION_EVICTED') {
        revertToLocalPlayback();
        store.dispatch(analyticsTrack('Evicted Sonos Session'));
        store.dispatch(addNotification(SONOS_DISCONNECTED));
      }
    }
  });
}

export const createRevertToLocalPlayback =
  (store, localPlayer, LocalPlayerClass, adsPlayer) => async () => {
    await disconnectSonosGroup(store, true);
    recreateLocalPlayerState(store, localPlayer);
    attachStoreToLocalPlayer(store, localPlayer, LocalPlayerClass, adsPlayer);
  };

export const createOnConnected =
  (store, prevState, player, LocalPlayerClass, revertToLocalPlayback, groupId) => async () => {
    store.dispatch(analyticsTrack('Connected Sonos'));
    const state = store.getState();
    store.dispatch(setConnectedSonosGroup(groupId));
    const hashedUserId = crypto.createHash('md5').update(selectUserId(state)).digest('hex');

    // 1. Try to rejoin any existing session
    try {
      const playbackStatus = await sonosPlayback.getPlaybackStatus();
      const queueId = playbackStatus.body.queueVersion;

      const localQueueId = selectQueueOriginCompoundId(state);
      if (queueId !== selectSonosCloudQueueId(state, localQueueId)) {
        // In the future we should be able to recreate any queue from a queueId
        throw new Error(
          `Local queue has changed. Local queue id: ${localQueueId}. Cloud queue id: ${queueId}`
        );
      }

      await sonosPlaybackSession.joinSession('com.idagio.desktop', hashedUserId);
      console.log('Joined sonos session'); // eslint-disable-line no-console

      // on upstart, local player will always be paused but might need to be started
      if (
        playbackStatus.body.playbackState === 'PLAYBACK_STATE_PLAYING' &&
        !selectPlayerIsPlaying(state)
      ) {
        player.setupProgressPollInterval('sonos');
        store.dispatch(playerActions.play('sonos'));
      }
      subscribeToMessages(store, LocalPlayerClass, revertToLocalPlayback);
    } catch (sessionJoinError) {
      // 2. Could not join, try to create a new one instead
      try {
        console.warn('Could not rejoin session'); // eslint-disable-line no-console
        console.error(sessionJoinError); // eslint-disable-line no-console
        const sessionResponse = await sonosPlaybackSession.createSession(
          'com.idagio.desktop',
          hashedUserId
        );
        console.warn('Created sonos session'); // eslint-disable-line no-console
        const newSessionId = sessionResponse.body.sessionId;
        sonosConnector.sessionId = newSessionId;
        store.dispatch(setSonosSessionId(newSessionId));

        const queueItems = selectPlayerQueueItems(state);
        const playOnCompletion = selectPlayerIsPlaying(state);
        const currentQueueItem = selectPlayerCurrentQueueItem(state);
        if (currentQueueItem) {
          const prevPosition = selectProgress(prevState);
          const trackIds = queueItems.map(({ track }) => track);
          player.setQueue(trackIds, true, 'internal');
          player.playQueueItem(currentQueueItem.track, prevPosition, playOnCompletion, 'internal');
        }
        subscribeToMessages(store, LocalPlayerClass, revertToLocalPlayback);

        // 3. If nothing works, fall back to local playback
      } catch (sessionCreateError) {
        console.warn('Could not create new session'); // eslint-disable-line no-console
        console.error(sessionCreateError); // eslint-disable-line no-console
        revertToLocalPlayback();
      }
    }
  };
