// @flow

import update from 'immutability-helper';
import type { AuthAction } from '../actions/auth';

const initialState = {
  isAuthenticated: false,
  loading: false,
  authIsWorking: false,
  authenticationType: null,
  accessToken: null,
  sessionToken: null,
  trackingToken: null,
  fbToken: null,
};
export type State = {|
  +isAuthenticated: boolean,
  +loading: boolean,
  +authIsWorking: boolean,
  +authenticationType: ?string,
  +accessToken: ?string,
  +sessionToken: ?string,
  +trackingToken: ?string,
  +fbToken: ?string,
|};

export default function auth(state: State = initialState, action: AuthAction): State {
  switch (action.type) {
    case 'SET_TRACKING_TOKEN':
      return update(state, {
        trackingToken: { $set: action.trackingToken },
      });
    case 'SET_ACCESS_TOKEN':
      return update(state, {
        isAuthenticated: { $set: true },
        accessToken: { $set: action.accessToken },
      });
    case 'CLEAR_LOCAL_ACCESS_TOKEN':
      return update(state, {
        isAuthenticated: { $set: false },
        accessToken: { $set: null },
        sessionToken: { $set: null },
      });
    case 'SIGN_UP':
    case 'LOGIN':
    case 'LOGIN_SOCIAL':
    case 'UNLINK_SOCIAL':
      return update(state, {
        loading: { $set: action.phase === 'REQUEST' },
      });
    case 'SET_AUTH_IS_WORKING':
      return update(state, {
        authIsWorking: { $set: true },
        authenticationType: { $set: action.authenticationType },
      });
    case 'SET_AUTH_IS_DONE':
      return update(state, {
        authIsWorking: { $set: false },
        authenticationType: { $set: null },
      });
    case 'SET_SESSION_TOKEN':
      return update(state, {
        sessionToken: { $set: action.sessionToken },
      });
    case 'FACEBOOK_TOKEN_RECEIVED':
      return update(state, {
        fbToken: { $set: action.token },
      });
    default:
      return state;
  }
}
