import {
  selectPlayerCurrentQueueItem,
  selectPlayerIsPlaying,
  selectPlayerQueueOrigin,
} from './player';

export function selectQueueIsLoading(state) {
  return state.player.queueIsLoading;
}

export function selectIsQueueOriginSetAs(state, queueOrigin) {
  const currentQueueOrigin = selectPlayerQueueOrigin(state);
  const currentQueueItem = selectPlayerCurrentQueueItem(state);
  return (
    Boolean(currentQueueOrigin) &&
    Boolean(currentQueueItem) &&
    currentQueueOrigin.type === queueOrigin.type &&
    currentQueueOrigin.id === queueOrigin.id
  );
}

export function selectIsQueueOriginPlaying(state, queueOrigin) {
  const isPlaying = selectPlayerIsPlaying(state);
  return isPlaying && selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectQueueOriginCompoundId(state) {
  const { type, id } = selectPlayerQueueOrigin(state);
  return `${type}-${id}`;
}
