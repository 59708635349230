// @flow
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import classnames from 'classnames';

import styles from './PlanOptionSubscribe.css';
import PlanImage from './PlanImage';
import PlanDetails from './PlanDetails';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  'id': string,
  'isSelected': boolean,
  'onSelect': Function,
  'children'?: ChildrenArray<Element<any>>,
  'imageTitle': Element<typeof FormattedMessage>,
  'title': Element<typeof FormattedMessage>,
  'monthlyPrice': number,
  'relativeDiscount'?: number,
  'objectives': Array<Object>,
  'priceTag'?: Element<typeof FormattedMessage>,
  'className'?: string,
  'data-test'?: string,
};

type Props = OwnProps;

const PlanOptionSubscribe = ({
  isSelected,
  onSelect,
  children,
  id,
  imageTitle,
  title,
  monthlyPrice,
  relativeDiscount,
  objectives,
  className,
  priceTag,
  'data-test': dataTest,
}: Props) => {
  const containerClassname = classnames(styles.container, className, {
    [styles.isSelected]: isSelected,
  });

  return (
    <div className={containerClassname} data-test={dataTest}>
      <div className={styles.plan}>
        <button onClick={() => onSelect(id)} className="dummy-btn" />
        <PlanImage id={id} imageTitle={imageTitle} isSelected={isSelected} />
        <PlanDetails
          title={title}
          monthlyPrice={monthlyPrice}
          relativeDiscount={relativeDiscount}
          objectives={objectives}
          priceTag={priceTag}
        />
      </div>
      {children}
    </div>
  );
};

export default PlanOptionSubscribe;
