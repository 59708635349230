// @flow
import { getScrollPosition } from './scroll';

export const SCROLL_CONTEXT_KEYS = {
  DISCOVER_GROUP_LIST: 'DISCOVER_GROUP_LIST',
  RECORDING: 'RECORDING',
};

type ScrollContextKeys = $Keys<typeof SCROLL_CONTEXT_KEYS>;

function getPathKey(location: Object) {
  return `${location.basename}${location.pathname}${location.search}${location.hash}${
    location.key ? `|${location.key}` : ''
  }`;
}

export function getSessionValueFromSessionStorage(
  location: Object,
  key: ScrollContextKeys | 'scrollPosition'
) {
  const currentPathKey = getPathKey(location);
  const savedState = sessionStorage.getItem(currentPathKey);
  if (savedState) {
    const parsedState = JSON.parse(savedState);
    return parsedState[key];
  }
  return null;
}

export function saveLastStateInSessionStorage(
  location: Object,
  getScrollPositionFromStorage: boolean = true,
  key?: ScrollContextKeys,
  values?: Object
) {
  const currentPathKey = getPathKey(location);
  const savedState = sessionStorage.getItem(currentPathKey);
  const scrollPosition =
    savedState && getScrollPositionFromStorage
      ? JSON.parse(savedState).scrollPosition
      : getScrollPosition();
  sessionStorage.setItem(
    currentPathKey,
    JSON.stringify({
      scrollPosition,
      ...(key && values ? { [key]: values } : {}),
    })
  );
}

export function isReload() {
  return sessionStorage.getItem('isReload') === '1';
}
