// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PlanOptionUpgrade from './PlanOptionUpgrade';

import styles from './PlansOverviewUpgrade.css';

import { PLAN_ID_PPC_1M, PLAN_ID_PPC_12M, SUBSCRIPTION_PLANS } from '../../constants';
import PlanDetails from './PlanDetails';
import { objectivesMessages } from '../messages/PlansOverviewMessages';

type OwnProps = {
  proceedToUpgrade: Function,
};

type Props = OwnProps;

const PlansOverviewUpgrade = ({ proceedToUpgrade }: Props) => {
  const ppc12m = SUBSCRIPTION_PLANS[PLAN_ID_PPC_12M];
  const ppc1m = SUBSCRIPTION_PLANS[PLAN_ID_PPC_1M];

  return (
    <div className={styles.wrapper}>
      <PlanDetails
        objectives={[
          {
            key: 'premiumPlusFeatures',
            value: <FormattedMessage {...objectivesMessages.premiumPlusFeatures} />,
          },
          {
            key: 'concertAccess',
            value: <FormattedMessage {...objectivesMessages.concertAccess} />,
          },
        ]}
        monthlyPrice={ppc12m.monthlyPrice}
      />
      <div className={styles.btns}>
        <PlanOptionUpgrade
          id={PLAN_ID_PPC_12M}
          monthlyPrice={ppc12m.monthlyPrice}
          onSelect={proceedToUpgrade}
          className="c-btn--is-purple"
        />
        <PlanOptionUpgrade
          id={PLAN_ID_PPC_1M}
          monthlyPrice={ppc1m.monthlyPrice}
          onSelect={proceedToUpgrade}
          className="c-btn--is-grey"
        />
      </div>
    </div>
  );
};

export default PlansOverviewUpgrade;
