import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import Notifications from '../notifications/Notifications';

import styles from './PaymentForm.css';

export default class VoucherForm extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    code: PropTypes.string,
  };

  onChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { code } = this.props;
    return (
      <React.Fragment>
        <Notifications />
        <div className="c-form-field">
          <label
            className={classnames('c-form-field__label', styles.voucherLabel)}
            htmlFor="voucher"
          >
            <FormattedMessage
              defaultMessage="Enter voucher code here"
              id="account.payment.voucher-label"
            />
          </label>
          <input
            type="text"
            name="voucher"
            className="c-form-field__input"
            onChange={this.onChange}
            value={code || ''}
            data-test="voucher-form.voucher-input"
          />
        </div>
      </React.Fragment>
    );
  }
}
