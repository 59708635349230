export const ESC = 27;
export const TAB = 9;
export const DOWN = 40;
export const UP = 38;
export const SLASH = 191;
export const ENTER = 13;
export const SPACE = 32;
export const BACKSPACE = 8;
export const ARROW_RIGHT = 39;
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_DOWN = 40;
export const LETTER_F = 70;
export const LETTER_H = 72;
export const LETTER_R = 82;
export const LETTER_S = 83;
