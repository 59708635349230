import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import dataComponent from '../../hoc/dataComponent';

import * as uiActions from '../../actions/ui';

import { addNotification } from '../../actions/notifications';
import { selectOpenModalTypeIs } from '../../selectors/modals';

import {
  selectNextPersonalPlaylistCursor,
  selectPersonalPlaylistCollection,
} from '../../selectors/personalPlaylist';
import {
  addTracksToPlaylist,
  createPersonalPlaylist,
  loadPersonalPlaylists,
} from '../../actions/personalPlaylist';
import { selectPersonalPlaylistToAddState } from '../../selectors/client';

import Modal from './Modal';
import LoadingIndicator from '../common/LoadingIndicator';
import PersonalPlaylistListItem from '../common/PersonalPlaylistListItem';
import CapacitorRipple from '../capacitor/Ripple';

import { PLAYLIST_EMPTY_TITLE_ERROR, PLAYLIST_SAVE_ERROR } from '../../lib/notifications';
import stylesAddToPlaylistModal from './AddToPlaylistModal.css';
import InfiniteScroll from '../../vendor/InfiniteScroll';
import List from '../util/List';

const ADD_TO_PLAYLIST_TAB = 'ADD_TO_PLAYLIST_TAB';
const CREATE_PLAYLIST_TAB = 'CREATE_PLAYLIST_TAB';

class AddToPlaylistModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    playlists: PropTypes.array.isRequired,

    initialTab: PropTypes.oneOf([ADD_TO_PLAYLIST_TAB, CREATE_PLAYLIST_TAB]),
    shouldRenderAddToPlaylist: PropTypes.bool.isRequired,

    hideModal: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    loadPersonalPlaylists: PropTypes.func.isRequired,
    createPersonalPlaylist: PropTypes.func.isRequired,
    addTracksToPlaylist: PropTypes.func.isRequired,

    nextCursor: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    activeTab: this.props.initialTab,
    isLoading: false,
  };

  onSubmitCreatePlaylist = async event => {
    event.preventDefault();
    if (!this.playlistNameInput) {
      return;
    }

    if (this.state.isLoading) {
      return;
    }

    const title = this.playlistNameInput.value.trim();

    if (!title) {
      this.props.addNotification(PLAYLIST_EMPTY_TITLE_ERROR);
    } else {
      this.setState({ isLoading: true });
      try {
        await this.props.createPersonalPlaylist(title);
      } catch (e) {
        this.props.addNotification(PLAYLIST_SAVE_ERROR);
        this.setState({ isLoading: false });

        // rethrow
        throw e;
      }
    }
  };

  onAddToPlaylistClick = playlist => {
    try {
      this.props.addTracksToPlaylist(playlist.id);
    } catch (e) {
      this.props.addNotification(PLAYLIST_SAVE_ERROR);

      // rethrow
      throw e;
    }
  };

  renderHeader() {
    const { shouldRenderAddToPlaylist } = this.props;
    const { activeTab } = this.state;

    const { activeLink, link } = stylesAddToPlaylistModal;
    const addToPlaylistClassNames = classnames(link, {
      [activeLink]: activeTab === ADD_TO_PLAYLIST_TAB,
    });
    const createPlaylistClassNames = classnames(link, {
      [activeLink]: activeTab === CREATE_PLAYLIST_TAB,
    });

    return (
      <div className={stylesAddToPlaylistModal.headerContainer}>
        {shouldRenderAddToPlaylist && (
          <button className={addToPlaylistClassNames} onClick={this.selectAddToPlaylistTab}>
            <CapacitorRipple />
            <FormattedMessage
              id="playlist.add-modal.add-to-playlist.header"
              defaultMessage="Add to Playlist"
            />
          </button>
        )}
        <button className={createPlaylistClassNames} onClick={this.selectCreatePlaylistTab}>
          <CapacitorRipple />
          <FormattedMessage
            id="playlist.add-modal.create-playlist.header"
            defaultMessage="Create Playlist"
          />
        </button>
      </div>
    );
  }

  renderBody() {
    const { activeTab } = this.state;
    return (
      <div className={stylesAddToPlaylistModal.bodyContainer}>
        {activeTab === ADD_TO_PLAYLIST_TAB && this.renderAddToPlaylistTab()}
        {activeTab === CREATE_PLAYLIST_TAB && this.renderCreatePlaylistTab()}
      </div>
    );
  }

  renderPersonalPlaylistListItem = playlist => {
    return (
      <li key={playlist.id} data-test="add-to-playlist-modal.item">
        <PersonalPlaylistListItem playlist={playlist} onItemClick={this.onAddToPlaylistClick} />
      </li>
    );
  };

  renderAddToPlaylistTab() {
    const { playlists, nextCursor, isLoading } = this.props;
    const hasMore = !!nextCursor;
    return (
      <React.Fragment>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={hasMore}
          scrollableSelector={'.' + stylesAddToPlaylistModal.bodyContainer}
          useWindow={false}
          loader={null}
        >
          <List items={playlists} renderItem={this.renderPersonalPlaylistListItem} />
        </InfiniteScroll>
        <LoadingIndicator isLoading={isLoading} />
      </React.Fragment>
    );
  }

  renderCreatePlaylistTab() {
    const { isLoading, playlistName } = this.state;
    const buttonClassNames = classnames('c-btn', stylesAddToPlaylistModal.createPlaylistButton);

    return (
      <form
        className={stylesAddToPlaylistModal.createPlaylist}
        onSubmit={this.onSubmitCreatePlaylist}
      >
        <div className="c-form-field">
          <label htmlFor="playlistName" className="c-form-field__label">
            <FormattedMessage
              id="playlist.add-modal.create-playlist.input-title"
              defaultMessage="Playlist title"
            />
          </label>
          <input
            autoFocus
            value={playlistName}
            name="playlistName"
            className="c-form-field__input"
            ref={this.setPlaylistNameInputElement}
            data-test="add-to-playlist-modal.create-playlist-name-input"
          />
        </div>

        <button
          type="submit"
          className={buttonClassNames}
          data-test="add-to-playlist-modal.create-playlist-btn"
        >
          <CapacitorRipple />
          <LoadingIndicator isLoading={isLoading} className="hideText" />
          <FormattedMessage
            id="playlist.add-modal.create-playlist.button"
            defaultMessage="Create playlist"
          />
        </button>
      </form>
    );
  }

  render() {
    const { isOpen, hideModal } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={hideModal}
        onClose={hideModal}
        contentLabel="add-to-playlist-modal"
      >
        <section
          className={stylesAddToPlaylistModal.modalAddToPlaylist}
          data-test="add-to-playlist.modal"
        >
          {this.renderHeader()}
          {this.renderBody()}
        </section>
      </Modal>
    );
  }

  loadMore = () => {
    this.props.loadPersonalPlaylists(this.props.nextCursor);
  };

  setPlaylistNameInputElement = element => {
    this.playlistNameInput = element;
  };

  playlistNameInput = null;

  selectTab = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  selectAddToPlaylistTab = () => {
    this.selectTab(ADD_TO_PLAYLIST_TAB);
  };

  selectCreatePlaylistTab = () => {
    this.selectTab(CREATE_PLAYLIST_TAB);
  };
}

function mapStateToProps(state) {
  const personalPlaylists = selectPersonalPlaylistCollection(state);
  const personalPlaylistToAdd = selectPersonalPlaylistToAddState(state);
  const { trackIds } = personalPlaylistToAdd;

  const tracksCount = trackIds.length;
  const personalPlaylistsCount = personalPlaylists.length;

  const shouldRenderAddToPlaylist = tracksCount > 0 && personalPlaylistsCount > 0;

  const initialTab =
    tracksCount === 0 || personalPlaylistsCount === 0 ? CREATE_PLAYLIST_TAB : ADD_TO_PLAYLIST_TAB;

  return {
    isOpen: selectOpenModalTypeIs(state, 'ADD_TO_PLAYLIST_MODAL'),
    initialTab,
    shouldRenderAddToPlaylist,
    playlists: personalPlaylists,
    isLoading: state.lists.personalPlaylists.loading,
    nextCursor: selectNextPersonalPlaylistCursor(state),
  };
}

function fetchData(store) {
  return store.dispatch(loadPersonalPlaylists());
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {
    hideModal: uiActions.hidePersonalPlaylistModal,
    createPersonalPlaylist,
    loadPersonalPlaylists,
    addTracksToPlaylist,
    addNotification,
  })
)(AddToPlaylistModal);
