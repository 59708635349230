const pathMap = {
  discover: '/',
  browse: '/browse',
  gch: '/live',
  blank: '/blank',
};

const replacementMap = {
  '/search?term=': '/search?query=',
};

function translateToWeb(path) {
  for (const [from, to] of Object.entries(replacementMap)) {
    if (path.includes(from)) {
      return path.replace(from, to);
    }
  }
  return path;
}

export default ({ locationType, locationSpecificData }) => {
  if (locationType === 'pathname') {
    return translateToWeb(locationSpecificData.pathname);
  }
  if (locationType === 'profile') {
    const { profileId } = locationSpecificData;
    return `/profiles/${profileId}`;
  }
  if (locationType === 'work') {
    const { workId } = locationSpecificData;
    return `/works/${workId}`;
  }
  if (locationType === 'recording') {
    const { recordingId } = locationSpecificData;
    return `/recordings/${recordingId}`;
  }

  if (locationType === 'recordingInformation') {
    const { recordingId } = locationSpecificData;
    return `/recordings/${recordingId}`;
  }
  if (locationType === 'playlist') {
    const { playlistId } = locationSpecificData;
    return `/playlists/${playlistId}`;
  }
  if (locationType === 'album') {
    const { albumId } = locationSpecificData;
    return `/albums/${albumId}`;
  }
  if (locationType === 'mix') {
    const { mixId } = locationSpecificData;
    return `/mixes/${mixId}`;
  }
  if (locationType === 'personalPlaylist') {
    const { personalPlaylistId } = locationSpecificData;
    return `/playlists/personal/${personalPlaylistId}`;
  }
  if (locationType === 'livestreamEvents') {
    if (locationSpecificData && locationSpecificData.scrollToCategorySlug) {
      return `/live#${locationSpecificData.scrollToCategorySlug}`;
    }
    return '/live';
  }
  if (locationType === 'livestreamEventsSingleEvent') {
    const { livestreamEventSlug } = locationSpecificData;
    return `/live/event/${livestreamEventSlug}`;
  }
  if (locationType === 'livestreamEventsGroup') {
    const { groupId } = locationSpecificData;
    return `/discover/gch-events/${groupId}`;
  }
  if (locationType === 'collectionEvents') {
    return '/collection/events';
  }
  if (locationType === 'collectionArtists') {
    return '/collection/artists';
  }
  if (locationType in pathMap) {
    return pathMap[locationType];
  }
  return null;
};
