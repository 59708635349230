import {
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_MIX,
  ENTITY_TYPE_PERSONAL_PLAYLIST,
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_WORK,
  ENTITY_TYPE_RECORDING,
  QUEUE_TYPE_ALBUM,
  QUEUE_TYPE_FILTERED_ALBUM_LIST,
  QUEUE_TYPE_FILTERED_RECORDING_LIST,
  QUEUE_TYPE_MIX,
  QUEUE_TYPE_MOOD,
  QUEUE_TYPE_PERSONAL_PLAYLIST,
  QUEUE_TYPE_PLAYLIST,
  QUEUE_TYPE_RECORDING,
  QUEUE_TYPE_TRACK_COLLECTION,
  ENTITY_TYPE_MOOD,
  QUEUE_TYPE_LIVE_COMPARE,
  ENTITY_TYPE_LIVE_COMPARE,
  QUEUE_TYPE_RADIO,
  QUEUE_TYPE_RECOMMENDATIONS,
} from '../constants';

import { selectPlayerCurrentQueueItem } from '../selectors/player';

export function getQueueOriginId(type, item) {
  switch (type) {
    case ENTITY_TYPE_ALBUM:
    case ENTITY_TYPE_PLAYLIST:
    case ENTITY_TYPE_MIX:
      return item.slug;
    case ENTITY_TYPE_MOOD:
      return item.oldId;
    default:
      return item.id;
  }
}

export function getQueueType(entityType) {
  switch (entityType) {
    case ENTITY_TYPE_MIX:
      return QUEUE_TYPE_MIX;
    case ENTITY_TYPE_ALBUM:
      return QUEUE_TYPE_ALBUM;
    case ENTITY_TYPE_PLAYLIST:
      return QUEUE_TYPE_PLAYLIST;
    case ENTITY_TYPE_PERSONAL_PLAYLIST:
      return QUEUE_TYPE_PERSONAL_PLAYLIST;
    case ENTITY_TYPE_WORK:
      return QUEUE_TYPE_RECORDING;
    case ENTITY_TYPE_LIVE_COMPARE:
      return QUEUE_TYPE_LIVE_COMPARE;
    case ENTITY_TYPE_MOOD:
      return QUEUE_TYPE_MOOD;
    default:
      return QUEUE_TYPE_RECORDING;
  }
}

export function getQueueOrigin(entityType, item) {
  return {
    type: getQueueType(entityType),
    id: getQueueOriginId(entityType, item),
  };
}

export function getQueueOriginUrl({ type, id }, recording = null) {
  switch (type) {
    case QUEUE_TYPE_RECORDING:
      return `/recordings/${id}`;
    case QUEUE_TYPE_MOOD:
      return `/moods/${id}`;
    case QUEUE_TYPE_TRACK_COLLECTION:
      return '/collection/tracks';
    case QUEUE_TYPE_PLAYLIST:
      return `/playlists/${id}`;
    case QUEUE_TYPE_PERSONAL_PLAYLIST:
      return `/playlists/personal/${id}`;
    case QUEUE_TYPE_ALBUM:
      return `/albums/${id}`;
    case QUEUE_TYPE_MIX:
      return `/mixes/${id}`;
    case QUEUE_TYPE_LIVE_COMPARE:
      return `/live-compare/${id}`;
    default:
      if (recording && recording.id) {
        return `/recordings/${recording.id}`;
      }

      return null;
  }
}

export function getRecentlyPlayedTypeFromQueueType(queueType) {
  switch (queueType) {
    case QUEUE_TYPE_MIX:
      return ENTITY_TYPE_MIX;
    case QUEUE_TYPE_ALBUM:
      return ENTITY_TYPE_ALBUM;
    case QUEUE_TYPE_MOOD:
      return ENTITY_TYPE_MOOD;
    case QUEUE_TYPE_PLAYLIST:
      return ENTITY_TYPE_PLAYLIST;
    case QUEUE_TYPE_PERSONAL_PLAYLIST:
      return ENTITY_TYPE_PERSONAL_PLAYLIST;
    case QUEUE_TYPE_LIVE_COMPARE:
      return ENTITY_TYPE_LIVE_COMPARE;
    case QUEUE_TYPE_RECORDING:
    default:
      return ENTITY_TYPE_RECORDING;
  }
}

export function getEntityTypeFromQueueType(queueType) {
  switch (queueType) {
    case QUEUE_TYPE_MIX:
      return ENTITY_TYPE_MIX;
    case QUEUE_TYPE_ALBUM:
      return ENTITY_TYPE_ALBUM;
    case QUEUE_TYPE_MOOD:
    case QUEUE_TYPE_PLAYLIST:
      return ENTITY_TYPE_PLAYLIST;
    case QUEUE_TYPE_PERSONAL_PLAYLIST:
      return ENTITY_TYPE_PERSONAL_PLAYLIST;
    case QUEUE_TYPE_RECORDING:
      return ENTITY_TYPE_RECORDING;
    default:
      return ENTITY_TYPE_WORK;
  }
}

export function createGetEntitySelectorByQueueType({
  selectRecording,
  selectTrack,
  selectMix,
  selectAlbum,
  selectAlbumById,
  selectPersonalPlaylist,
  selectPlaylist,
  selectLiveCompare,
}) {
  return function getEntitySelectorByQueueType(queueType) {
    switch (queueType) {
      case QUEUE_TYPE_RECORDING:
        return selectRecording;
      case QUEUE_TYPE_TRACK_COLLECTION:
      case QUEUE_TYPE_FILTERED_RECORDING_LIST:
      case QUEUE_TYPE_RADIO:
      case QUEUE_TYPE_RECOMMENDATIONS:
        return state => {
          const id = selectPlayerCurrentQueueItem(state).track;
          return selectTrack(state, id, true).recording;
        };
      case QUEUE_TYPE_MIX:
        return selectMix;
      case QUEUE_TYPE_ALBUM:
        return selectAlbum;
      case QUEUE_TYPE_FILTERED_ALBUM_LIST:
        return selectAlbumById;
      case QUEUE_TYPE_PERSONAL_PLAYLIST:
        return selectPersonalPlaylist;
      case QUEUE_TYPE_LIVE_COMPARE:
        return selectLiveCompare;
      default:
        return selectPlaylist;
    }
  };
}
