// @flow

import type { PlayerProgressAction } from '../actions/player';
import type { FlushAnalyticsProgressBufferAction } from '../actions/analytics';
import type { AdPlaybackProgress } from '../actions/ads';
import type { LiveStreamPlaybackProgressAction } from '../actions/livestreamEvents';

const initialState = {};

export type State = {
  +[key: string]: number | { accumulatedDuration: number, latestTimestamp: number },
};

type Action =
  | PlayerProgressAction
  | AdPlaybackProgress
  | FlushAnalyticsProgressBufferAction
  | LiveStreamPlaybackProgressAction;

export const DELIMITER = '<*>';

export default function analyticsProgressBuffer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case 'PLAYER_PROGRESS': {
      if (action.sonosIsConnected) {
        /*
        Playback tracking is done via the sonos cloud queue implementation.
        This avoids any potential duplicate tracking.
      */
        return state;
      }
      const { trackId, audioQuality, source, trackPosition, repeat, shuffle } = action;
      const key = [source, audioQuality, trackId, trackPosition, repeat, shuffle].join(DELIMITER);

      if (typeof state[key] === 'number') {
        return { ...state, [key]: state[key] + 1 };
      }
      return { ...state, [key]: 1 };
    }
    case 'AD_PLAYBACK_PROGRESS': {
      const key = ['ad', action.adId].join(DELIMITER);
      if (typeof state[key] === 'number') {
        return { ...state, [key]: state[key] + 1 };
      }
      return { ...state, [key]: 1 };
    }
    case 'LIVESTREAM_PLAYBACK_PROGRESS': {
      const { eventSlug, eventType, isLive, progress, currentTime } = action;
      const key = ['livestream', eventSlug, eventType, isLive].join(DELIMITER);
      if (typeof state[key] === 'object') {
        return {
          ...state,
          [key]: {
            accumulatedDuration: state[key].accumulatedDuration + progress,
            timestamp: currentTime,
          },
        };
      }
      return {
        ...state,
        [key]: {
          accumulatedDuration: progress,
          timestamp: currentTime,
        },
      };
    }
    case 'FLUSH_ANALYTICS_PROGRESS_BUFFER':
      return initialState;
    case 'LOGOUT':
      return initialState;
    default:
      (action: empty);
      return state;
  }
}
