// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadGenreAction } from '../../actions/genre';

export type State = {
  +[key: string]: {|
    +loading: boolean,
    +loaded: boolean,
    +entities: string,
  |},
  loading: boolean,
};

const initialState = {
  loading: false,
};

export default function genres(state: State = initialState, action: LoadGenreAction): State {
  if (action.type === 'FETCH_GENRE' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
