// @flow
import type { Voucher, SetIncomingVoucherAction } from '../actions/subscription';
import { SUCCESS } from '../middleware/api';

export type State = ?Voucher;

const initState = null;

export default function incomingVoucher(
  state: State = initState,
  action: SetIncomingVoucherAction
): State {
  if (action.type === 'SET_INCOMING_VOUCHER') {
    return action.voucher;
  }

  if (action.type === 'APPLY_COUPON' && action.phase === SUCCESS) {
    return initState;
  }

  return state;
}
