// @flow

import type { AuthorGroup, Person } from '../shapes/types';

export default function shouldOmitForenameFromComposers(authors: Array<AuthorGroup>): boolean {
  const composers: Array<Person> = authors
    .filter(
      authorGroup => authorGroup.key === 'composer' || authorGroup.key === 'composer_original_work'
    )
    .reduce((accumulator, authorGroup) => {
      return [...accumulator, ...authorGroup.persons];
    }, []);
  return composers.length > 1;
}
