export const selectEntityDownloadInfo = (state, id) => {
  return state.download.entitiesDownloadInfo[id];
};

export const selectTrackIsDownloading = (state, entityId, trackId) => {
  const downloadInfo = state.download.entitiesDownloadInfo[entityId];
  if (!downloadInfo) {
    return false;
  }
  return (
    downloadInfo.downloadStatus === 'download_running' &&
    !(
      downloadInfo.downloadedTrackIds &&
      downloadInfo.downloadedTrackIds.length > 0 &&
      downloadInfo.downloadedTrackIds.includes(trackId.toString())
    )
  );
};

export const selectTrackIsDownloaded = (state, entityId, trackId) => {
  const downloadInfo = state.download.entitiesDownloadInfo[entityId];
  if (!downloadInfo) {
    return false;
  }
  return (
    downloadInfo.downloadStatus === 'downloaded' ||
    (downloadInfo.downloadedTrackIds &&
      downloadInfo.downloadedTrackIds.length > 0 &&
      downloadInfo.downloadedTrackIds.includes(trackId.toString()))
  );
};
