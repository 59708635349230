// @flow

import createCollectionReducer from './createCollectionReducer';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<string>,
|};

const initialState: State = {
  loading: false,
  loaded: false,
  loadedCursors: {},
  entities: [],
};

const artistCollectionActions = {
  add: 'ADD_ARTIST_TO_COLLECTION',
  remove: 'REMOVE_ARTIST_FROM_COLLECTION',
  fetch: 'FETCH_ARTIST_COLLECTION',
};

export default createCollectionReducer(initialState, artistCollectionActions);
