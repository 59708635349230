import BasePlayer from './BasePlayer';
import Hls from 'hls.js';

export default class HlsPlayer extends BasePlayer {
  _switchToPreloadPlayer = () => {
    this._addMainListeners(this.preloadAudio);
    this._removeMainListeners(this.mainAudio);
    this.mainHlsWrapper.detachMedia();

    this.preloadAudio.play();
    this.mainAudio.pause();

    this.preloadAudio.muted = this.mainAudio.muted;
    this.preloadAudio.volume = this.mainAudio.volume;

    this.mainHlsWrapper = this.preloadHlsWrapper;
    this.mainAudio = this.preloadAudio;

    this.preloadAudio = this.createAudioDevice();
    this.preloadHlsWrapper = new Hls();
  };

  _preloadQueueItemAtIndex = async index => {
    // Implementation is incomplete:
    // 1. Doesn't wait for the preload to load to set flags
    // 2. Doesn't call FINISHED_PRELOAD

    this.emit(BasePlayer.STARTED_PRELOAD, index);
    this.isPreloading = true;
    this.preloadingIndex = index;

    const paramsAtRequestTime = this._getEventProperties(index, true);
    this.emit(BasePlayer.RESOLVING_URL, paramsAtRequestTime);

    const nextUrl = await this._resolveItemUrlAtIndex(index);
    if (!this._isStillPreloading(index)) {
      this.emit(BasePlayer.ABORTED_PRELOAD, {
        phase: BasePlayer.RESOLVING_URL,
        oldPreloadingIndex: index,
        newPreloadingIndex: this.preloadingIndex,
      });
      return;
    }

    this.emit(BasePlayer.RESOLVED_URL, paramsAtRequestTime);

    this.preloadAudio.autoplay = false;
    this.preloadAudio.preload = 'auto';
    this.preloadHlsWrapper.attachMedia(this.preloadAudio);
    this.preloadHlsWrapper.loadSource(nextUrl);
    this.isPreloading = false;
    this.isPreloaded = true;
    this.preloadingIndex = null;
  };

  async playQueueItemAtIndex(index, autoPlay = true) {
    if (this.isPreloading) {
      this.preloadHlsWrapper.detachMedia();
    }

    return super.playQueueItemAtIndex(index, autoPlay);
  }

  _onFragBuffered = () => {
    this.mainHlsWrapper.off(Hls.Events.FRAG_BUFFERED, this._onFragBuffered);
    this.emit(BasePlayer.CONTROLS_ENABLED);
  };

  _onFragLoading = (eventName, { frag }) => {
    if (frag.loadIdx === 0) {
      this.emit(BasePlayer.STARTED_BUFFERING, Date.now());
      this.emit(BasePlayer.CONTROLS_DISABLED);
      this.mainHlsWrapper.off(Hls.Events.FRAG_BUFFERED, this._onFragLoading);
    }
  };

  _startPlayback = (url, shouldStartPlayback) => {
    // Attach main wrapper and events
    this.mainHlsWrapper.attachMedia(this.mainAudio);
    this.mainHlsWrapper.on(Hls.Events.FRAG_BUFFERED, this._onFragBuffered);
    this.mainHlsWrapper.on(Hls.Events.FRAG_LOADING, this._onFragLoading);
    this.mainHlsWrapper.loadSource(url);

    if (shouldStartPlayback) {
      return this.mainAudio.play();
    }

    return Promise.resolve();
  };

  setQueue = (queue, resumePosition = false, currentIndex) => {
    // Clear old wrappers
    if (this.mainHlsWrapper) {
      this.mainHlsWrapper.detachMedia();
    }

    if (this.preloadHlsWrapper) {
      this.preloadHlsWrapper.detachMedia();
    }

    // Handle the audio element changes
    super.setQueue(queue, resumePosition, currentIndex);

    // Make new wrappers
    this.mainHlsWrapper = new Hls();
    this.preloadHlsWrapper = new Hls();
  };

  stop() {
    super.stop();
    this.preloadHlsWrapper.detachMedia();
  }
}
