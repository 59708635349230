// @flow

import update from 'immutability-helper';
import { SUCCESS, REQUEST } from '../middleware/api';

import type { LoadPopularSearchesAction } from '../actions/search';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +results: Array<Object>,
|};

const initialState = {
  loading: false,
  loaded: false,
  results: [],
};

export default function popularSearches(
  state: State = initialState,
  action: LoadPopularSearchesAction
): State {
  if (action.type === 'FETCH_POPULAR_SEARCHES') {
    switch (action.phase) {
      case REQUEST:
        return update(state, {
          loading: { $set: true },
          loaded: { $set: false },
        });
      case SUCCESS:
        return update(state, {
          loading: { $set: false },
          loaded: { $set: true },
          results: { $set: action.response && action.response.normalized },
        });
      default:
        return state;
    }
  }

  (action: empty);
  return state;
}
