// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadTopFacetsAction } from '../../actions/facet';

export type State = {
  +[key: string]: {|
    +entities: {|
      +resultsCount: number,
      +activeFacets: Array<{|
        +facet: Object,
        +type: string,
      |}>,
      +topFacets: {
        [key: string]: {|
          +count: number,
          +results: Array<Object>,
        |},
      },
    |},
    +loaded: boolean,
    +loading: boolean,
  |},
};

const initialState = {};

export default function topAlbumFacetsByFilter(
  state: State = initialState,
  action: LoadTopFacetsAction
): State {
  if (action.type === 'FETCH_TOP_ALBUM_FACETS' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
