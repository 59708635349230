import { orderBy } from 'lodash';

function createPieceOrderMap(workparts) {
  const orderMap = {};
  workparts.forEach((workpart, workpartIndex) => {
    workpart.pieces.forEach((piece, pieceIndex) => {
      orderMap[piece.id] = { pieceIndex, workpartIndex };
    });
  });
  return orderMap;
}

function normalizeRecordingTracks(tracks, workparts) {
  const pieceOrderMap = createPieceOrderMap(workparts);

  const normalizedTracks = tracks
    .map(track => {
      if (!track.piece || !track.piece.id) {
        return null;
      }

      const orderInfo = pieceOrderMap[track.piece.id];
      if (!orderInfo) {
        return null;
      }

      const workpartIndex = orderInfo.workpartIndex;
      const workpart = workparts[workpartIndex];
      return {
        ...track,
        piece: {
          ...track.piece,
          workpart: workpart ? workpart.id : null,
        },
      };
    })
    .filter(track => track);

  return orderBy(normalizedTracks, [
    track => pieceOrderMap[track.piece.id].workpartIndex,
    track => pieceOrderMap[track.piece.id].pieceIndex,
  ]);
}

function normalizeWorkparts(workparts, work) {
  return workparts.map(workpart => {
    return {
      ...workpart,
      work: work.id,
    };
  });
}

export function normalizeRecording(recording) {
  if (!recording) {
    return {};
  }
  if (!recording.tracks || !recording.work || !recording.work.workparts) {
    return recording;
  }

  const normalizedTracks = normalizeRecordingTracks(recording.tracks, recording.work.workparts);

  return {
    ...recording,
    work: {
      ...recording.work,
      workparts: normalizeWorkparts(recording.work.workparts, recording.work),
    },
    tracks: normalizedTracks,
    trackIds: normalizedTracks.map(({ id }) => id.toString()),
  };
}

export function normalizeRecordingList(recordings) {
  return recordings.map(normalizeRecording);
}
