// @flow
declare var __ELECTRON__: boolean; // eslint-disable-line

import Cookies from 'js-cookie';
import Qs from 'qs';
import { identifyUser } from './analytics';

import { loadMe } from './me';
import { loadCollectionIds, loadLimitedCollection } from './collection';
import { applyVoucherCode, handleCouponError } from './subscription';
import { addNotification } from './notifications';
import {
  selectAutoplayPreferences,
  selectThemePreferences,
  selectUser,
  selectUserPlan,
} from '../selectors/user';
import { selectIncomingVoucher, selectShouldApplyVoucher } from '../selectors/subscription';
import { checkLanguageAndRedirect, loadFeatureFlags, setAudioQuality } from './client';
import { selectSignupUserAgent } from '../selectors/client';
import { selectShouldPlayAdsForUser } from '../selectors/ads';
import { loadAds } from './ads';
import { hideModal, setAutoplay, setUITheme } from './ui';

import { getHighestAllowedQuality } from '../client/playback-support';
import connectPusher from '../client/pusher';

import { APPLYING_COUPON, COUPON_SUCCESS } from '../lib/notifications';
import { showQueryParamModal } from '../lib/modalActions';

import {
  ANONYMOUS_ONLY_PATHS,
  DEFAULT_SIGNUP_SOURCE,
  FROM_ID_CAMPAIGNS,
  SUBSCRIPTION_PLAN_TRIAL_OPT_IN,
} from '../constants';

import type { Dispatch, GetState, Request, ThunkAction } from './types';
import type { Voucher } from './subscription';
import { loadPersonalPlaylists } from './personalPlaylist';

type SetTrackingTokenAction = {
  type: 'SET_TRACKING_TOKEN',
  trackingToken: string,
};
export type SetAccessTokenAction = {
  type: 'SET_ACCESS_TOKEN',
  accessToken: string,
};
type SetSessionTokenAction = {
  type: 'SET_SESSION_TOKEN',
  sessionToken: string,
};
export type ClearLocalAccessTokenAction = { type: 'CLEAR_LOCAL_ACCESS_TOKEN' };
type SetAuthIsWorkingAction = {
  type: 'SET_AUTH_IS_WORKING',
  authenticationType: string,
};
type SetAuthIsDoneAction = { type: 'SET_AUTH_IS_DONE' };

type FacebookTokenReceivedAction = { type: 'FACEBOOK_TOKEN_RECEIVED', token: string };

type LoginAction = { type: 'LOGIN' } & Request;
export type SignupAction = { type: 'SIGN_UP' } & Request;
type ChangeFirstNameAction = { type: 'CHANGE_FIRSTNAME' } & Request;
type ChangePasswordAction = { type: 'CHANGE_PASSWORD' } & Request;
type RequestPasswordResetAction = { type: 'REQUEST_PASSWORD_RESET' } & Request;
type SetPasswordAction = { type: 'SET_PASSWORD' } & Request;
type IdentifyAnonymousUserAction = { type: 'IDENTIFY_ANONYMOUS_USER' } & Request;
type FetchAutorizationCodeAction = { type: 'FETCH_AUTORIZATION_CODE' } & Request;

export type RevokeAccessTokenAction = { type: 'REVOKE_ACCESS_TOKEN' } & Request;
export type LogoutAction = { type: 'LOGOUT' };

export type LoginSocialAction = { type: 'LOGIN_SOCIAL' } & Request;
export type UnlinkSocialAction = { type: 'UNLINK_SOCIAL' } & Request;

export type AuthAction =
  | SetTrackingTokenAction
  | SetAccessTokenAction
  | SetSessionTokenAction
  | ClearLocalAccessTokenAction
  | LoginAction
  | SignupAction
  | ChangeFirstNameAction
  | ChangePasswordAction
  | RequestPasswordResetAction
  | SetPasswordAction
  | LoginSocialAction
  | SetAuthIsWorkingAction
  | SetAuthIsDoneAction
  | UnlinkSocialAction
  | FacebookTokenReceivedAction;

type SignupBody = {
  firstname?: string,
  email: string,
  password: string,
  plan?: string,
  from_id?: string,
  plan_ends_at?: number,
  plan_duration_days?: number,
  preferences?: {
    marketing_opt_in: boolean,
  },
  signup_ua?: string,
};

function doLoginRequest(
  email: string,
  password: string,
  type: string = 'Page',
  captchaType: string,
  captchaToken: string
): LoginAction {
  return {
    type: 'LOGIN',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/auth-v1.json',
      body: {
        username: email,
        password,
        type,
        captchaType,
        captchaToken,
      },
    },
  };
}

function doSignupRequest(
  firstName,
  email,
  password,
  plan,
  fromId,
  signupSource = DEFAULT_SIGNUP_SOURCE,
  marketingOptIn,
  promotionName,
  signupUserAgent,
  captchaToken,
  captchaType
): SignupAction {
  const body: SignupBody = {
    firstname: firstName,
    email,
    password,
    signupSource,
    preferences: {
      marketing_opt_in: marketingOptIn,
    },
    promotionName,
    captchaToken,
    captchaType,
  };

  if (plan) {
    body.plan = plan;
  }

  if (fromId) {
    body.from_id = fromId;
  }

  if (signupUserAgent) {
    body.signup_ua = signupUserAgent;
  }

  // every funnel should identify itself
  if (fromId === FROM_ID_CAMPAIGNS.optInTrial30days.fromId) {
    body.plan_duration_days = 30;
    body.plan = SUBSCRIPTION_PLAN_TRIAL_OPT_IN;
  }

  return {
    type: 'SIGN_UP',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/join-v4.json',
      body,
    },
  };
}

function doSocialLoginRequest(
  externalAccessToken: string,
  password: ?string,
  termsAccepted: boolean = true,
  marketingOptIn: boolean = false,
  fromId: ?string,
  isNewUser: boolean = false,
  provider: string = 'facebook',
  signupUserAgent: string
): LoginSocialAction {
  return {
    type: 'LOGIN_SOCIAL',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/login-social.json',
      body: {
        externalAccessToken,
        provider,
        termsAccepted,
        marketingOptIn,
        password,
        isNewUser,
        fromId,
        signupUserAgent,
      },
    },
  };
}

export function setTrackingToken(trackingToken: string): SetTrackingTokenAction {
  return {
    type: 'SET_TRACKING_TOKEN',
    trackingToken,
  };
}

export function setAccessToken(accessToken: string): SetAccessTokenAction {
  return {
    type: 'SET_ACCESS_TOKEN',
    accessToken,
  };
}

export function setSessionToken(sessionToken: string): SetSessionTokenAction {
  return {
    type: 'SET_SESSION_TOKEN',
    sessionToken,
  };
}

export function clearLocalAccessToken(): ClearLocalAccessTokenAction {
  return {
    type: 'CLEAR_LOCAL_ACCESS_TOKEN',
  };
}

export function setAuthIsWorking(authenticationType: string = 'email'): SetAuthIsWorkingAction {
  return {
    type: 'SET_AUTH_IS_WORKING',
    authenticationType,
  };
}

export function setAuthIsDone(): SetAuthIsDoneAction {
  return {
    type: 'SET_AUTH_IS_DONE',
  };
}

async function voucherApplicationThunk(dispatch: Dispatch, getState: GetState): Promise<?Voucher> {
  const state = getState();
  const incomingVoucher = selectIncomingVoucher(state);
  dispatch(addNotification(APPLYING_COUPON));
  try {
    await dispatch(applyVoucherCode(incomingVoucher.code));
    await dispatch(loadMe());
    dispatch(addNotification(COUPON_SUCCESS));
    Cookies.remove('_idagio-voucher');
    return incomingVoucher;
  } catch (e) {
    if (e.status === 400) {
      handleCouponError(e, notification => {
        dispatch(addNotification(notification));
      });
    } else {
      throw e;
    }
  }
  return null;
}

function onAuthThunk(isNewUser, loginFunction: Function, isSocialAuth) {
  return async function authThunk(dispatch: Dispatch, getState: GetState) {
    const state = getState();
    dispatch(setAuthIsWorking(isSocialAuth ? 'social' : 'email'));
    try {
      const response = await dispatch(loginFunction(dispatch, getState));
      dispatch(setAccessToken(response.normalized.accessToken));
      dispatch(setSessionToken(response.normalized.sessionToken));
      dispatch(identifyUser(response.normalized.user));

      await dispatch(loadMe());
      await dispatch(loadFeatureFlags());

      if (selectShouldPlayAdsForUser(getState())) {
        await dispatch(loadAds());
      }

      let appliedVoucher;
      if (selectShouldApplyVoucher(state)) {
        appliedVoucher = await voucherApplicationThunk(dispatch, getState);
      }

      dispatch(setUITheme(selectThemePreferences(getState())));
      dispatch(setAutoplay(selectAutoplayPreferences(getState())));

      await Promise.all([
        dispatch(loadCollectionIds()),
        dispatch(loadLimitedCollection()),
        dispatch(loadPersonalPlaylists()),
      ]);

      connectPusher({ dispatch, getState });

      dispatch(setAudioQuality(getHighestAllowedQuality(getState())));
      dispatch(setAuthIsDone());

      const postAuthState = getState();
      const queryParamModalAction = showQueryParamModal({
        state: postAuthState,
        query: postAuthState.routing.locationBeforeTransitions.query,
      });
      if (queryParamModalAction) {
        await dispatch(queryParamModalAction);
      }

      const finalState = getState();
      return {
        user: selectUser(finalState),
        plan: selectUserPlan(finalState),
        voucher: appliedVoucher,
      };
    } catch (e) {
      // On failure reset the loading state
      dispatch(setAuthIsDone());

      // Rethrow the error for the frontend to catch
      throw e;
    }
  };
}

export function authenticate(
  email: string,
  password: string,
  type: string,
  captchaType: string,
  captchaToken: string
): ThunkAction {
  return onAuthThunk(false, () => doLoginRequest(email, password, type, captchaType, captchaToken));
}

export function authenticateSocial({
  externalAccessToken,
  password,
  termsAccepted,
  marketingOptIn,
  fromId,
  isNewUser,
}: {
  externalAccessToken: string,
  password: string,
  termsAccepted: boolean,
  marketingOptIn: boolean,
  isNewUser: boolean,
  fromId: string,
}): ThunkAction {
  return onAuthThunk(
    isNewUser,
    (dispatch, getState) => {
      const signupUserAgent = selectSignupUserAgent(getState(), __ELECTRON__);
      return doSocialLoginRequest(
        externalAccessToken,
        password,
        termsAccepted,
        marketingOptIn,
        fromId,
        isNewUser,
        undefined,
        signupUserAgent
      );
    },
    true
  );
}

type signupArguments = {
  firstName?: string,
  email: string,
  password: string,
  plan: string,
  fromId: string,
  days: number,
  signupSource: string,
  marketingOptIn: boolean,
  promotionName: string,
  captchaToken: string,
  captchaType: string,
};

export function signup({
  firstName,
  email,
  password,
  plan,
  fromId,
  signupSource,
  marketingOptIn,
  promotionName,
  captchaToken,
  captchaType,
}: signupArguments): ThunkAction {
  return onAuthThunk(true, (dispatch, getState) => {
    const signupUserAgent = selectSignupUserAgent(getState(), __ELECTRON__);
    return doSignupRequest(
      firstName,
      email,
      password,
      plan,
      fromId,
      signupSource,
      marketingOptIn,
      promotionName,
      signupUserAgent,
      captchaToken,
      captchaType
    );
  });
}

export function unlinkSocial(provider: string = 'facebook'): UnlinkSocialAction {
  return {
    type: 'UNLINK_SOCIAL',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'DELETE',
      endpoint: `/v1.0/social-login/${provider}`,
      body: {},
    },
    meta: { restricted: true },
  };
}

export function logout(): LogoutAction {
  return {
    type: 'LOGOUT',
  };
}

export function requestRevokeAccessToken(): RevokeAccessTokenAction {
  return {
    type: 'REVOKE_ACCESS_TOKEN',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/logout.json',
    },
    meta: {
      authenticated: true,
    },
  };
}

export function changeFirstName(firstName: string): ChangeFirstNameAction {
  return {
    type: 'CHANGE_FIRSTNAME',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/change-firstname.json',
      body: {
        firstname: firstName,
      },
    },
  };
}

export function changePassword(
  email: string,
  password: string,
  captchaType: string,
  captchaToken: string
): ChangePasswordAction {
  return {
    type: 'CHANGE_PASSWORD',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/update-password-v1.json',
      body: {
        email,
        password,
        captchaType,
        captchaToken,
      },
    },
    meta: { restricted: true },
  };
}

export function setPassword(
  newPassword: string,
  resetpwtoken: string,
  captchaType: string,
  captchaToken: string
): SetPasswordAction {
  return {
    type: 'SET_PASSWORD',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/set-password.json',
      body: {
        newPassword,
        resetpwtoken,
        captchaType,
        captchaToken,
      },
    },
  };
}

export function setPasswordAndAuthenticate(
  newPassword: string,
  token: string,
  captchaType: string,
  captchaToken: string
): ThunkAction {
  return onAuthThunk(false, () => setPassword(newPassword, token, captchaType, captchaToken));
}

export function requestPasswordReset(
  email: string,
  captchaToken: string,
  captchaType: string
): RequestPasswordResetAction {
  return {
    type: 'REQUEST_PASSWORD_RESET',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/request-password-reset.json',
      body: {
        email,
        captchaToken,
        captchaType,
      },
    },
  };
}

export function getSonosAccessToken(
  email: string,
  password: string,
  type: string = 'Page'
): LoginAction {
  return {
    type: 'LOGIN',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'POST',
      endpoint: '/sonos-token.json',
      body: {
        username: email,
        password,
        type,
      },
    },
  };
}

export function identifyAnonymousUser(anonymousId: string): IdentifyAnonymousUserAction {
  return {
    type: 'IDENTIFY_ANONYMOUS_USER',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/identify',
      body: {
        segment_anonymous_id: anonymousId,
      },
    },
  };
}

export function fetchAuthorizationCode(): FetchAutorizationCodeAction {
  return {
    type: 'FETCH_AUTORIZATION_CODE',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/user/authorization-code.v1',
    },
    meta: {
      restricted: true,
    },
  };
}

export function onAuthCompleteFromAuthModal(location: Object): ThunkAction {
  return async dispatch => {
    // If we were on an anonymous only page and we submit signup/login modal,
    // we let the `protectedComponent` HOC handle the redirect to avoid duplicate redirection
    if (!ANONYMOUS_ONLY_PATHS.includes(location.pathname)) {
      const param = Qs.parse(location.search.slice(1));
      if (param.reloadTo) {
        document.location = param.reloadTo + location.hash;
        return;
      }
      const path =
        (param.to || `${location.basename}${location.pathname}${location.search}`) + location.hash;
      await dispatch(checkLanguageAndRedirect(path));
    }
    dispatch(hideModal({ ignore: ['WELCOME_MODAL'] }));
  };
}
