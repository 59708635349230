// @flow
import type {
  SetPlaybackTrackingContextAction,
  TrackingContext,
  TrackPlayPausePressedAction,
} from '../actions/analytics';

export type State = TrackingContext;

const initialState: State = {};

export default function analyticsPlayback(
  state: State = initialState,
  action: TrackPlayPausePressedAction | SetPlaybackTrackingContextAction
) {
  if (
    (action.type === 'TRACK_PLAY_PAUSE_PRESSED' ||
      action.type === 'SET_PLAYBACK_TRACKING_CONTEXT') &&
    action.context
  ) {
    return action.context;
  }

  return state;
}
