// @flow
import React from 'react';
import styles from './BackButton.css';
import classnames from 'classnames';
import IconLabel from '../util/IconLabel';

type OwnProps = {
  isActive?: boolean,
  isVisible: boolean,
  onClick: Function,
};

const BackButton = ({ isActive, isVisible, onClick }: OwnProps) => {
  if (!isVisible) {
    return null;
  }

  const classIcon = 'c-icon--label-false';
  const arrowLeftClasses = classnames(classIcon, {
    'is-active': isActive,
  });

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={onClick} data-test="back-button">
        <IconLabel name="chevron-left" className={arrowLeftClasses} title="Back" />
      </button>
    </div>
  );
};

export default BackButton;
