// @flow

import { handleMapRequest } from '../phasedActionHelpers';
import type { LoadLivestreamEventAction } from '../../actions/livestreamEvents';

export type State = {
  +[key: string]: {|
    +entities: string,
    +loaded: boolean,
    +loading: boolean,
  |},
};

const initialState = {};

export default function livestreamEvents(
  state: State = initialState,
  action: LoadLivestreamEventAction
): State {
  if (action.type === 'FETCH_LIVESTREAM_EVENT' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
