import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tag from './Tag';
import styles from './Tag.css';

const UpcomingTag = () => {
  return (
    <Tag className={styles.upcomingTag}>
      <FormattedMessage id="livestream-event-preview.upcoming-tag" defaultMessage="Upcoming" />
    </Tag>
  );
};

export default UpcomingTag;
