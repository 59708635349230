// @flow

import { DEFAULT_LOCALE } from '../../constants';

import type { SetLocaleAction } from '../../actions/client';

export type State = string;

export default function locale(state: State = DEFAULT_LOCALE, action: SetLocaleAction) {
  if (action.type === 'SET_LOCALE') {
    return action.locale;
  }

  (action: empty);
  return state;
}
