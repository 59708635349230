/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { selectLocale, selectModalType, selectPageTrackingContext } from '../../selectors/client';
import { CONSTANT_MESSAGES } from '../../constants';
import styles from './AcceptTerms.css';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';
import * as analyticsActions from '../../actions/analytics';
import { messages } from '../messages/Terms';

type MapStateToProps = {
  currentLocale: string,
  modalType: string,
  pageTrackingContext: Object,
};

type DispatchProps = {
  track: Function,
};

type Props = MapStateToProps & DispatchProps & { intl: IntlShape };

const AcceptTerms = ({ currentLocale, modalType, pageTrackingContext, track, intl }: Props) => {
  const trackLinkClick = linkName => {
    track(`Clicked ${linkName} Link`, {
      modalType,
      ...pageTrackingContext,
    });
  };

  const generalTermsText = intl.formatMessage(messages.generalTerms);
  const ariaGeneralTermsLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: generalTermsText,
  });

  const privacyPolicyText = intl.formatMessage(messages.privacyPolicy);
  const ariaPrivacyPolicyLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: privacyPolicyText,
  });

  const termsLink = (
    <a
      className="c-text-link--is-visible"
      href={getWebsiteUrlInLocale('/terms', currentLocale)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackLinkClick('GTC')}
      aria-label={ariaGeneralTermsLink}
    >
      {generalTermsText}
    </a>
  );

  const privacyPolicy = (
    <a
      className="c-text-link--is-visible"
      href={getWebsiteUrlInLocale('/privacy', currentLocale)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackLinkClick('Privacy Policy')}
      aria-label={ariaPrivacyPolicyLink}
    >
      {privacyPolicyText}
    </a>
  );

  return (
    <React.Fragment>
      <p className={styles.smallPrint}>
        <FormattedMessage
          id="account.signup.small-print-gtc"
          defaultMessage="By creating an account, you agree to the {termsLink}."
          values={{ termsLink }}
        />{' '}
        <FormattedMessage
          id="account.signup.small-print-pp"
          defaultMessage="Please note our {privacyPolicy}."
          values={{ privacyPolicy }}
        />
      </p>
      <p className={styles.smallPrint}>
        <FormattedMessage
          id="account.signup.small-print-email-2"
          defaultMessage="We will use your email address to send you music recommendations and occasional offers and tips about IDAGIO. You can unsubscribe from IDAGIO emails via contact@idagio.com, or click “unsubscribe” in any of our emails."
        />
      </p>
    </React.Fragment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    currentLocale: selectLocale(state),
    modalType: selectModalType(state),
    pageTrackingContext: selectPageTrackingContext(state),
  };
}

const dispatchProps: DispatchProps = {
  track: analyticsActions.track,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(AcceptTerms);
