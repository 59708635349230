import { registerPlugin } from '@capacitor/core';

const NavigationPlugin = registerPlugin('NavigationPlugin');
const MiscActionsPlugin = registerPlugin('MiscActionsPlugin');
const StatePlugin = registerPlugin('StatePlugin');
const PlayerPlugin = registerPlugin('PlayerPlugin');
const EventChannelPlugin = registerPlugin('EventChannelPlugin');
const TrackingPlugin = registerPlugin('TrackingPlugin');
const DownloadPlugin = registerPlugin('DownloadPlugin');

export {
  NavigationPlugin,
  MiscActionsPlugin,
  StatePlugin,
  PlayerPlugin,
  EventChannelPlugin,
  TrackingPlugin,
  DownloadPlugin,
};
