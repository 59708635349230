// @flow

import type { StripeAction } from '../actions/stripe';
import type { LogoutAction } from '../actions/auth';

type Action = StripeAction | LogoutAction;

export type State = {|
  +stripePaymentIntentToken: ?string,
  +revalidatedAt: ?number,
  +usedTokens: Array<string>,
|};

const initialState = {
  stripePaymentIntentToken: null,
  revalidatedAt: null,
  usedTokens: [],
};

export default function stripe(state: State = initialState, action: Action) {
  switch (action.type) {
    case 'USER_REVALIDATED_STRIPE':
      return {
        ...state,
        revalidatedAt: action.timestamp,
      };
    case 'SAVE_USED_STRIPE_TOKEN':
      return {
        ...state,
        usedTokens: [...state.usedTokens, action.token],
      };
    case 'LOGOUT':
      return initialState;
    case 'FETCH_STRIPE_PAYMENT_INTENT_TOKEN':
      return state;
    default:
      (action: empty);
      return state;
  }
}
