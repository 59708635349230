// @flow
import update from 'immutability-helper';

import { REQUEST, SUCCESS, FAILURE } from '../middleware/api';

import type { Request } from '../actions/types';

type Action = { type: any };
type RequestAction = Action & Request;

function handleResult(result, handler) {
  if (typeof handler === 'function') {
    return handler(result);
  }
  return result;
}

export function handleListRequest<S: Object>(
  state: S,
  action: RequestAction,
  resultHandler?: Function
): S {
  const { phase, response, error } = action;

  switch (phase) {
    case REQUEST:
      return update(state, {
        loading: { $set: true },
        meta: { $set: {} },
      });
    case SUCCESS:
      return update(state, {
        loading: { $set: false },
        loaded: { $set: true },
        meta: { $set: (response && response.meta) || {} },
        entities: { $set: handleResult(response && response.normalized.result, resultHandler) },
        requestedAt: { $set: action.requestedAt },
        expireAt: { $set: action.expireAt },
      });
    case FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: error },
        meta: { $set: {} },
      });
    default:
      return state;
  }
}

export function getCursorName(cursor: string = '') {
  return `cursor_${cursor}`;
}

export function handlePaginatedListRequest<S: Object>(
  state: S,
  action: RequestAction,
  resultHandler?: Function
): S {
  const { phase, response, error } = action;
  switch (phase) {
    case REQUEST:
      return update(state, {
        loading: { $set: true },
        loaded: { $set: false },
        meta: { $set: {} },
      });
    case SUCCESS:
      return update(state, {
        loading: { $set: false },
        loaded: { $set: true },
        meta: { $set: (response && response.meta) || {} },
        loadedCursors: { $merge: { [getCursorName(action.params && action.params.cursor)]: true } },
        entities: { $push: handleResult(response && response.normalized.result, resultHandler) },
      });
    case FAILURE:
      return update(state, {
        loading: { $set: false },
        loaded: { $set: false },
        error: { $set: error },
        meta: { $set: {} },
      });
    default:
      return state;
  }
}

export function handleMapRequest<S: Object>(
  state: S,
  action: RequestAction,
  id: string,
  resultHandler?: Function
): S {
  const { phase, response, error } = action;

  switch (phase) {
    case REQUEST:
      return update(state, {
        [id]: {
          $set: {
            loading: true,
            loaded: false,
            meta: {},
          },
        },
      });
    case SUCCESS:
      return update(state, {
        [id]: {
          $set: {
            loading: false,
            loaded: true,
            meta: (response && response.meta) || {},
            entities: handleResult(response && response.normalized.result, resultHandler),
            expireAt: action.expireAt,
          },
        },
      });
    case FAILURE:
      return update(state, {
        [id]: {
          $set: {
            loading: false,
            loaded: false,
            meta: {},
            error: error,
          },
        },
      });
    default:
      return state;
  }
}

const initialPaginatedMapState = {
  meta: {},
  entities: [],
  loadedCursors: {},
  loading: false,
  loaded: false,
};

export function handlePaginatedMapRequest<S: Object>(
  _state: S,
  action: RequestAction,
  id: string,
  resultHandler?: Function
): S {
  const { phase, response, error } = action;

  const state = _state[id] ? _state : update(_state, { [id]: { $set: initialPaginatedMapState } });

  switch (phase) {
    case REQUEST:
      return update(state, {
        [id]: {
          loading: { $set: true },
          loaded: { $set: false },
          meta: { $set: {} },
        },
      });
    case SUCCESS:
      return update(state, {
        [id]: {
          loading: { $set: false },
          loaded: { $set: true },
          meta: { $set: (response && response.meta) || {} },
          entities: { $push: handleResult(response && response.normalized.result, resultHandler) },
          loadedCursors: {
            $merge: { [getCursorName(action.params && action.params.cursor)]: true },
          },
        },
      });
    case FAILURE:
      return update(state, {
        [id]: {
          $set: {
            loading: false,
            loaded: false,
            meta: {},
            error: error,
          },
        },
      });
    default:
      return state;
  }
}
