// @flow

import { MAX_RECENT_SEARCH_COUNT } from '../../constants';

import type { CompleteSearchAction, CompleteQuickSearchAction } from '../../actions/search';

type CompleteSearchActions = CompleteSearchAction | CompleteQuickSearchAction;

export type State = {
  +results: Array<{ id: number | string }>,
};

const initialState = {
  results: [],
};

export default function recentSearches(
  state: State = initialState,
  action: CompleteSearchActions
): State {
  if (action.type === 'COMPLETE_SEARCH' || action.type === 'COMPLETE_QUICK_SEARCH') {
    // don't double save
    if (state.results.some(item => item.id === action.selected.id)) {
      return state;
    }

    const newResults = [action.selected, ...state.results].slice(0, MAX_RECENT_SEARCH_COUNT);

    return {
      results: newResults,
    };
  }

  (action: empty);
  return state;
}
