import { QUEUE_TYPE_RECORDING, VALID_RECORDING_FILTER_PARAMS } from '../constants';
import { createSelector } from 'reselect';
import { some, map, flatten } from 'lodash';
import * as Schema from '../schema';

import { selectNextCursor, selectPreviousCursor } from './common';
import { selectUserIsPatron } from './user';
import assembleEntity from '../schema/assembleEntity';
import {
  selectPlayerQueueOrigin,
  selectPlayerIsPlaying,
  selectPlayerLoadingQueueOrigin,
} from './player';

import getSerializedFilterId from '../utils/getSerializedFilterId';
import filterAndSortParams from '../utils/filterAndSortParams';
import { getQueueOrigin } from '../lib/queue';

export const selectRecording = createSelector(
  [
    (state, id) => id,
    state => state.entities,
    state => state.entities.recordings,
    (state, id, hydrate) => hydrate,
  ],
  (id, entities, recordings, hydrate = true) => {
    if (!recordings[id]) {
      return null;
    }

    if (hydrate) {
      return assembleEntity(Schema.Recording, entities, id);
    }

    return recordings[id];
  }
);

export const selectRecordingsByWork = createSelector(
  [(state, workId) => workId, state => state.maps],
  (workId, maps) => {
    const recordingsByWork = maps.recordingsByWork[workId];
    if (!recordingsByWork || !recordingsByWork.loaded) {
      return [];
    }
    return recordingsByWork.entities;
  }
);

export const selectRecordingFilter = createSelector(
  [state => state.entities, (state, work) => work, (state, work, composer) => composer],
  (entities, work, composer) => {
    return {
      work: work ? assembleEntity(Schema.Work, entities, work) : false,
      composer: composer ? assembleEntity(Schema.Person, entities, composer) : false,
    };
  }
);

export const selectFilteredAndSortedRecordings = createSelector(
  [
    state => state.entities,
    state => state.maps.recordingsByFilter,
    (state, filterParams) => filterParams,
  ],
  (entities, recordingsByFilter, filterParams) => {
    const validAndSortedParams = filterAndSortParams(filterParams, VALID_RECORDING_FILTER_PARAMS);
    const filterId = getSerializedFilterId(validAndSortedParams, VALID_RECORDING_FILTER_PARAMS);
    return assembleEntity(Schema.RecordingList, entities, recordingsByFilter[filterId].entities);
  }
);

export const selectFilteredAndSortedRecordingsLoading = state => {
  const loadingStates = map(state.maps.recordingsByFilter, entry => entry && entry.loading);
  return some(loadingStates, isLoading => isLoading);
};

export function selectNextFilteredRecordingsCursor(state, filterParams) {
  if (selectFilteredAndSortedRecordingsLoading(state)) {
    return '';
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_RECORDING_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_RECORDING_FILTER_PARAMS);
  return selectNextCursor(state.maps.recordingsByFilter[cacheId]);
}

export function selectPreviousFilteredRecordingsCursor(state, filterParams) {
  if (selectFilteredAndSortedRecordingsLoading(state)) {
    return '';
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_RECORDING_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_RECORDING_FILTER_PARAMS);
  return selectPreviousCursor(state.maps.recordingsByFilter[cacheId]);
}

export function selectRecordingIsPlayable(state, id) {
  const recording = selectRecording(state, id, false);
  const userIsPatron = selectUserIsPatron(state);

  if (!id || !recording) {
    return false;
  }

  if (userIsPatron) {
    return true;
  }

  return !recording.isPremium;
}

export function selectRecordingIsQueued(state, id, type = QUEUE_TYPE_RECORDING) {
  const queueOrigin = selectPlayerQueueOrigin(state);
  return (
    !!queueOrigin &&
    queueOrigin.type === type &&
    queueOrigin.id &&
    queueOrigin.id.toString() === id.toString()
  );
}

export function selectRecordingIsPlaying(state, id, type = QUEUE_TYPE_RECORDING) {
  const isQueued = selectRecordingIsQueued(state, id, type);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

export const selectTracksFromRecordings = createSelector([recordings => recordings], recordings =>
  flatten(map(recordings, recording => recording.tracks))
);

export const selectTrackIdsFromRecordings = createSelector([recordings => recordings], recordings =>
  flatten(map(recordings, ({ trackIds }) => trackIds))
);

export function selectRecordingIsLoading(state, recording) {
  const itemQueueOrigin = getQueueOrigin(QUEUE_TYPE_RECORDING, recording);
  const loadingQueueOrigin = selectPlayerLoadingQueueOrigin(state);

  return (
    !!loadingQueueOrigin &&
    loadingQueueOrigin.id === itemQueueOrigin.id &&
    loadingQueueOrigin.type === itemQueueOrigin.type
  );
}

export function selectRecordingTrackIds(state, recordingId) {
  const recording = selectRecording(state, recordingId);
  return recording.tracks.map(track => track.id);
}

export function selectRecordingIsLoadingToPlay(state, id) {
  const queueOrigin = {
    id,
    type: QUEUE_TYPE_RECORDING,
  };
  const loadingQueueOrigin = selectPlayerLoadingQueueOrigin(state);

  return (
    !!loadingQueueOrigin &&
    loadingQueueOrigin.id === queueOrigin.id &&
    loadingQueueOrigin.type === queueOrigin.type
  );
}
