// @flow

import { merge } from 'lodash';

/**
 * Updates an entity cache in response to any action with response.entities.
 */

import type {
  Ensemble,
  Category,
  Mood,
  Person,
  Piece,
  Playlist,
  PersonalPlaylist,
  Profile,
  Recording,
  RecordingType,
  Track,
  User,
  WorkPart,
  Work,
  Mix,
  Album,
  AuthorGroup,
  Ad,
  SonosTrack,
  SonosQueue,
  LivestreamEvent,
  ContentEntry,
} from '../shapes/types';

import type { Request } from '../actions/types';
import { REQUEST } from '../middleware/api';

type MergeStrategy = {|
  type: 'REPLACE_ENTITY',
  entityKey: string,
  entityId: string | number,
  optimisticUpdate: ?Object,
|};

type Action = {
  type: any,
  mergeStrategy: MergeStrategy,
} & Request;

export type State = {
  +ensembles: { [key: string]: Ensemble },
  +epochs: { [key: string]: Category },
  +genres: { [key: string]: Category },
  +instruments: { [key: string]: Category },
  +moods: { [key: string]: Mood },
  +persons: { [key: string]: Person },
  +pieces: { [key: string]: Piece },
  +playlists: { [key: string]: Playlist },
  +personalPlaylists: { [key: string]: PersonalPlaylist },
  +profiles: { [key: string]: Profile },
  +recordings: { [key: string]: Recording },
  +recordingTypes: { [key: string]: RecordingType },
  +tracks: { [key: string]: Track },
  +users: { [key: string]: User },
  +workparts: { [key: string]: WorkPart },
  +works: { [key: string]: Work },
  +mixes: { [key: string]: Mix },
  +albums: { [key: string]: Album },
  +authorGroups: { [key: string]: AuthorGroup },
  +ads: { [key: string]: Ad },
  +sonosQueues: { [key: string]: SonosQueue },
  +sonosTracks: { [key: string]: SonosTrack },
  +livestreamEvents: { [key: string]: LivestreamEvent },
  +content: { [key: string]: ContentEntry },
};

const initialEntitiesState = {
  ensembles: {},
  epochs: {},
  genres: {},
  instruments: {},
  moods: {},
  persons: {},
  pieces: {},
  playlists: {},
  personalPlaylists: {},
  profiles: {},
  recordings: {},
  recordingTypes: {},
  tracks: {},
  users: {},
  workparts: {},
  works: {},
  mixes: {},
  albums: {},
  authorGroups: {},
  ads: {},
  sonosQueues: {},
  sonosTracks: {},
  livestreamEvents: {},
  content: {},
};

function applyRequestPhaseUpdateMergeStrategy(mergeStrategy: MergeStrategy, state: State): State {
  if (
    mergeStrategy.type === 'REPLACE_ENTITY' &&
    mergeStrategy.entityKey &&
    mergeStrategy.entityId &&
    mergeStrategy.optimisticUpdate &&
    Object.keys(mergeStrategy.optimisticUpdate).length > 0
  ) {
    const { entityKey, entityId, optimisticUpdate } = mergeStrategy;
    if (state[entityKey][entityId]) {
      const entity = state[entityKey][entityId];
      const updatedEntity = {
        ...entity,
        ...optimisticUpdate,
      };

      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          [entityId]: updatedEntity,
        },
      };
    }
  }

  return state;
}

function applyMergeStrategy(mergeStrategy: MergeStrategy, state: State, normalized): State {
  const mergedState = merge({}, state, normalized.entities);
  if (
    mergeStrategy.type === 'REPLACE_ENTITY' &&
    mergeStrategy.entityKey &&
    mergeStrategy.entityId
  ) {
    const { entityKey, entityId } = mergeStrategy;
    const normalizedEntities = normalized.entities[entityKey];
    const normalizedEntity = normalizedEntities[entityId];
    return {
      ...mergedState,
      [entityKey]: {
        ...state[entityKey],
        [entityId]: normalizedEntity,
      },
    };
  }

  return mergedState;
}

export default function entities(state: State = initialEntitiesState, action: Action) {
  if (action.phase === REQUEST && action.mergeStrategy) {
    return applyRequestPhaseUpdateMergeStrategy(action.mergeStrategy, state);
  }

  if (action.response && action.response.normalized && action.response.normalized.entities) {
    if (action.mergeStrategy) {
      return applyMergeStrategy(action.mergeStrategy, state, action.response.normalized);
    }

    return merge({}, state, action.response.normalized.entities);
  }

  if (action.type === 'LOGOUT') {
    return {
      ...state,
      users: {},
    };
  }

  return state;
}
