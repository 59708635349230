import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '../util/Skeleton';
import { DataFetcherLoadingContext } from '../../lib/routing/DataFetcher';

function SkeletonIfLoading(props) {
  const isLoading = React.useContext(DataFetcherLoadingContext);

  if (isLoading && __CAPACITOR__) {
    if (props.withContainer) {
      return (
        <div className="u-page-container">
          <Skeleton />
        </div>
      );
    }
    return <Skeleton />;
  }

  return props.children;
}

SkeletonIfLoading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  withContainer: PropTypes.bool,
};

export default SkeletonIfLoading;
