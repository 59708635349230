import { createSelector } from 'reselect';
import * as Schema from '../schema';
import assembleEntity from '../schema/assembleEntity';
import { selectIsQueueOriginSetAs } from './queue';
import { selectPlayerIsPlaying } from './player';
import { QUEUE_TYPE_PERSONAL_PLAYLIST } from '../constants';
import { selectNextCursor } from './common';

const selectPersonalPlaylists = state => {
  return state.entities.personalPlaylists;
};

const selectNonAssembledPersonalPlaylistCollection = state => {
  return state.lists.personalPlaylists;
};

export const selectPersonalPlaylistCollection = createSelector(
  [state => state.entities, selectNonAssembledPersonalPlaylistCollection],
  (entities, personalPlaylistCollection) => {
    if (!personalPlaylistCollection) {
      return [];
    }

    return assembleEntity(
      Schema.PersonalPlaylistList,
      entities,
      personalPlaylistCollection.entities
    );
  }
);

export const selectPersonalPlaylist = createSelector(
  [state => state.entities, (state, id) => id, selectPersonalPlaylists],
  (entities, id, playlists) => {
    if (!id || !playlists[id]) {
      return null;
    }

    return assembleEntity(Schema.PersonalPlaylist, entities, id);
  }
);

export function getPersonalPlaylistQueueOrigin(id) {
  return {
    type: QUEUE_TYPE_PERSONAL_PLAYLIST,
    id,
  };
}

export function selectPersonalPlaylistIsQueued(state, id) {
  const queueOrigin = getPersonalPlaylistQueueOrigin(id);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectPersonalPlaylistIsPlaying(state, id) {
  const isQueued = selectPersonalPlaylistIsQueued(state, id);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

// single item
export function selectPersonalPlaylistIsLoading(state, id) {
  return state.maps.personalPlaylists[id] ? state.maps.personalPlaylists[id].loading : false;
}

export function selectPersonalPlaylistIsLoaded(state, id) {
  return state.maps.personalPlaylists[id] ? state.maps.personalPlaylists[id].loaded : false;
}

// playlists
export function selectPersonalPlaylistsLoading(state) {
  return state.lists.personalPlaylists.loading;
}

export function selectNextPersonalPlaylistCursor(state) {
  return selectNextCursor(selectNonAssembledPersonalPlaylistCollection(state));
}
