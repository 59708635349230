import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

import styles from './PaymentForm.css';

export default class PaymentTab extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className={`${styles.paymentHeading} fz--delta`}>
        <a
          className={classnames(styles.tab, { [styles.activeTab]: this.props.isActive })}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </a>
      </div>
    );
  }
}
