// @flow
declare var __ELECTRON__: boolean; // eslint-disable-line

import * as React from 'react';
import { connect } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {
  selectCaptchaSiteKey,
  selectCaptchaType,
  selectLocale,
  selectTheme,
} from '../../selectors/client';
import * as analyticsActions from '../../actions/analytics';
import { splitLocale } from '../../lib/locale-utils';
import { DESKTOP_CAPTCHA_TOKEN, ERROR_CAPTCHA_TOKEN, UI_THEME_AUTOMATIC } from '../../constants';

type OwnProps = {
  children: React.ChildrenArray<React.Element<any>>,
  captchaAction: string,
  submit: Function,
  className: ?string,
  shouldOpenCaptchaOnSubmit: ?Function,
  onSubmit: ?Function,
  onClose: ?Function,
  onError: ?Function,
  onOpen: ?Function,
  onVerify: ?Function,
  onChalExpired: ?Function,
};

type MapStateToProps = {
  captchaSiteKey: string,
  captchaType: string,
  language: string,
  theme: string,
};

type DispatchProps = {
  track: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const CaptchaForm = ({
  children,
  captchaAction,
  className,
  shouldOpenCaptchaOnSubmit,
  onSubmit,
  submit,
  onClose,
  onError,
  onOpen,
  onVerify,
  onChalExpired,

  // from the state
  captchaSiteKey,
  captchaType,
  language,
  theme,
  track,
}: Props) => {
  // $FlowFixMe
  const captchaRef = React.useRef(null);

  const trackCaptcha = (event: string, additionalProps: Object = {}) => {
    track(event, { ...additionalProps, action: captchaAction, type: captchaType });
  };

  const onSubmitForm = event => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit();
    }

    // On default, the captcha modal will be opened on submit.
    // Pass this props to define in which case the captcha modal should be opened on submit
    if (shouldOpenCaptchaOnSubmit && !shouldOpenCaptchaOnSubmit()) {
      return;
    }

    // Captcha doesn't work on Electron because Electron is using the file protocol which is being blocked by hCaptcha.
    // For the time being, we let the request through with an override captcha token.
    if (__ELECTRON__) {
      submit(DESKTOP_CAPTCHA_TOKEN);
      return;
    }

    captchaRef.current.execute();
    captchaRef.current.resetCaptcha();
  };

  const onErrorCaptcha = (err: string) => {
    console.error(err); // eslint-disable-line no-console
    if (onError) {
      onError();
    }
    submit(ERROR_CAPTCHA_TOKEN);
    trackCaptcha('Captcha Error', { message: err });
  };

  const onOpenCaptcha = () => {
    if (onOpen) {
      onOpen();
    }
    trackCaptcha('Started Captcha Challenge');
  };

  const onCloseCaptcha = () => {
    if (onClose) {
      onClose();
    }
    trackCaptcha('Aborted Captcha Challenge');
  };

  const onChalExpiredCaptcha = () => {
    if (onChalExpired) {
      onChalExpired();
    }
    trackCaptcha('Aborted Captcha Challenge');
  };

  const onVerifyCaptcha = async (captchaToken: string) => {
    if (onVerify) {
      onVerify();
    }
    submit(captchaToken);
    trackCaptcha('Passed Captcha');
  };

  return (
    <form method="POST" className={className} onSubmit={onSubmitForm} noValidate>
      {children}
      {!__ELECTRON__ && (
        <HCaptcha
          sitekey={captchaSiteKey}
          size="invisible"
          theme={theme === UI_THEME_AUTOMATIC ? 'light' : theme}
          onVerify={onVerifyCaptcha}
          onError={onErrorCaptcha}
          onOpen={onOpenCaptcha}
          onClose={onCloseCaptcha}
          onChalExpired={onChalExpiredCaptcha}
          languageOverride={language}
          ref={captchaRef}
        />
      )}
    </form>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const locale = selectLocale(state);
  return {
    captchaSiteKey: selectCaptchaSiteKey(state),
    captchaType: selectCaptchaType(state),
    theme: selectTheme(state),
    language: splitLocale(locale).language,
  };
}

const dispatchProps: DispatchProps = {
  track: analyticsActions.track,
};

export default connect(mapStateToProps, dispatchProps)(CaptchaForm);
