// @flow
import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

type OwnProps = {
  isAvailable: boolean,
  availabilityEnd: Date,
};

const AvailabilityText = ({ isAvailable, availabilityEnd }: OwnProps) => {
  const availabilityEndDate = (
    <FormattedDate
      weekday="short"
      year="numeric"
      month="short"
      day="numeric"
      value={availabilityEnd}
    />
  );

  return isAvailable ? (
    <span data-test="livestream-availability-text.available-until">
      <FormattedMessage
        id="livestream-events.event-preview.availability-info"
        defaultMessage="Available until {availabilityEndDate}"
        values={{ availabilityEndDate }}
      />
    </span>
  ) : (
    <span data-test="livestream-availability-text.not-available">
      <FormattedMessage
        id="livestream-events.event-preview.not-available"
        defaultMessage="This event is no longer available"
      />
    </span>
  );
};

export default AvailabilityText;
