// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Modal from './Modal';
import LoginComponent from '../common/Login';

import { selectOpenModalTypeIs } from '../../selectors/modals';
import * as authActions from '../../actions/auth';
import * as uiActions from '../../actions/ui';

type OwnProps = {
  location: Object,
};

type MapStateToProps = {
  isOpen: boolean,
};

type DispatchProps = {
  showModal: Function,
  hideModal: Function,
  onAuthComplete: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const LoginModal = ({ isOpen, showModal, hideModal, onAuthComplete, location }: Props) => {
  const onClickSignupLink = event => {
    event.preventDefault();
    showModal('SIGNUP_MODAL', { trigger: 'loginModal' });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={hideModal}
      onRequestClose={hideModal}
      contentLabel="login-modal"
      role="dialog"
    >
      <div data-test="login-modal">
        <LoginComponent
          onAuthComplete={() => onAuthComplete(location)}
          onClickForgotPasswordLink={hideModal}
          onClickSignupLink={onClickSignupLink}
          showFacebookLogin
        />
      </div>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isOpen: selectOpenModalTypeIs(state, 'LOGIN_MODAL'),
  };
}

const dispatchProps: DispatchProps = {
  showModal: uiActions.showModal,
  hideModal: uiActions.hideModal,
  onAuthComplete: authActions.onAuthCompleteFromAuthModal,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(LoginModal));
