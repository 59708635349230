import PropTypes from 'prop-types';
import React, { Component } from 'react';
import routeTransitionHook from '../../routeTransitionHook';
import { addNotification } from '../../actions/notifications';
import * as NOTIFICATIONS from '../notifications';
import { ApiError } from '../../middleware/api';
import RedirectInstruction from './RedirectInstruction';
import { getScrollableSelector } from '../../utils/scroll';
import {
  getSessionValueFromSessionStorage,
  isReload,
  saveLastStateInSessionStorage,
} from '../../utils/sessionStorage';

export const DataFetcherLoadingContext = React.createContext(false);
export const DataFetcherErrorContext = React.createContext(null);

let newPath;

// !! IMPORTANT !!
//
// This component is intended to be used once per app entry point,
// i.e. entry/web or entry/electron etc.
//
// It holds a mapping to location.pathname and the app visual state
// will get corrupted if two DataFetchers are present in the app
// as they will share the reference to the closure variable `newPath`
// above
export default class DataFetcher extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    components: PropTypes.array.isRequired, // eslint-disable-line
    params: PropTypes.object.isRequired, // eslint-disable-line
    router: PropTypes.object.isRequired,
  };

  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  async fetchData(props) {
    try {
      this.setState({ loading: true });
      const { location, components, params } = props;
      newPath = location.pathname;
      await routeTransitionHook(this.context.store, components, location, params);
      // Some routes, like /Discover, take longer to fetch their data.
      // When user is attempting to navigate to another route that fetches
      // its data faster, a race condition happens. The state in the following
      // code would be updated to values that user has already navigated away from.
      //
      // Since we can only view a single DataFetcher route at the time, we can
      // rely on a top level variable to record the last path user intended to visit
      // and only update state with the data from last navigation
      if (newPath !== location.pathname) {
        return;
      }
      this.setState({
        loading: false,
        error: false,
        wasError: false,
        loadedChildren: props.children,
        loadedLocation: props.location,
      });
      // Set scroll starting position for new page
      this.setScroll(location);
    } catch (reason) {
      if (reason instanceof RedirectInstruction) {
        this.props.router.replace(reason.to);
        return;
      }
      if (this.state.loadedLocation !== props.location) {
        // This is what happens when you navigate to an error page in the client
        this.onClientNavigationError(reason);
      } else {
        // This is what happens when you're coming off of a error server-side render
        this.onServerRenderingError(reason, props);
      }
    }
  }

  state = {
    loadedChildren: __ELECTRON__ || __CAPACITOR__ ? null : this.props.children,
    loadedLocation: this.props.location,
    loading: !window.__error__,
    error: window.__error__ ? window.__error__ : false,
  };

  componentWillMount() {
    if (!window.__error__) {
      this.setScroll(this.props.location);
      this.fetchData(this.props);
    }

    delete window.__error__;

    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('isReload', '1');
    });
  }

  componentWillReceiveProps(nextProps) {
    const nextLocation = nextProps.location;
    const thisLocation = this.props.location;

    saveLastStateInSessionStorage(thisLocation, false);
    sessionStorage.setItem('isReload', '0');

    const shouldFetchData =
      nextLocation.pathname !== thisLocation.pathname ||
      nextLocation.search !== thisLocation.search;
    const hashChanged = thisLocation.hash !== nextLocation.hash;
    const shouldSetScroll = nextLocation.action === 'REPLACE' || hashChanged;

    if (shouldFetchData) {
      this.fetchData(nextProps);
      return;
    }

    if (shouldSetScroll) {
      this.setScroll(nextLocation);
      return;
    }
  }

  componentDidUpdate() {
    const currentLocation = this.props.location;

    // Restore scroll position if user navigates back or forward through app without reloading the page
    if (!isReload() && currentLocation.action === 'POP') {
      const scrollPosition = getSessionValueFromSessionStorage(currentLocation, 'scrollPosition');
      const scrollableEl = getScrollableSelector();
      if (scrollableEl) {
        setTimeout(() => {
          scrollableEl.scrollTo(0, scrollPosition || 0);
        }, 0);
      }
    }
  }

  componentDidCatch(error) {
    this.setState({ error: error });
  }

  // Finish loading, show notification, return
  onClientNavigationError(error) {
    // If i'm already in a error state I don't want to navigate back
    // Navigating back would re-trigger fetchData and that causes a loop
    if (!this.state.wasError) {
      this.props.router.replace(this.state.loadedLocation);
    }

    this.setState({
      loading: false,
      error: null,
      wasError: true,
    });

    this.showErrorNotification(error);

    if (error instanceof Error) {
      throw error;
    }
  }

  // error gets passed down trough context into app
  onServerRenderingError(error, props) {
    if (error.status === 404 && __CAPACITOR__) {
      this.props.router.replace('/discover');
      this.context.store.dispatch(addNotification(NOTIFICATIONS.PAGE_NOT_FOUND));
      return;
    }
    this.setState({
      loading: false,
      error: error,
      loadedChildren: props.children,
      loadedLocation: props.location,
    });
  }

  render() {
    return (
      <DataFetcherLoadingContext.Provider value={this.state.loading}>
        <DataFetcherErrorContext.Provider value={this.state.error}>
          {this.state.loadedChildren}
        </DataFetcherErrorContext.Provider>
      </DataFetcherLoadingContext.Provider>
    );
  }

  setScroll(location) {
    const scrollEl = getScrollableSelector();

    if (scrollEl) {
      if (location.hash) {
        // this can happen before the view has fully rendered
        // so an element to which the browser should scroll might not be
        // present in the DOM yet
        setTimeout(() => {
          const target = location.hash.replace('#', '');
          const element = document.getElementById(target);
          if (element) {
            element.scrollIntoView();
          }
        });
      } else {
        scrollEl.scrollTo(0, 0);
      }
    }
  }

  showErrorNotification(error) {
    if (error instanceof ApiError) {
      let notification = NOTIFICATIONS.API_ERROR;

      const status = error.status;
      if (status === 404) {
        notification = NOTIFICATIONS.PAGE_NOT_FOUND;
      }

      if (status === 401) {
        notification = NOTIFICATIONS.UNAUTHORISED;
      }

      this.context.store.dispatch(addNotification(notification));
    }
  }
}
