import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as notificationActions from '../../actions/notifications';

import Notification from './Notification';
import AnimatedList from '../util/AnimatedList';

import styles from './Notifications.css';

class Notifications extends Component {
  static propTypes = {
    notifications: PropTypes.array,
    dismiss: PropTypes.func,
  };

  renderNotification = notification => {
    return <Notification key={notification.id} dismiss={this.props.dismiss} {...notification} />;
  };

  render() {
    const { notifications } = this.props;
    if (!notifications.length) {
      return null;
    }

    return (
      <div className={styles.component}>
        <AnimatedList items={notifications} renderItem={this.renderNotification} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.client.notifications.list,
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { notifications } = stateProps;
  return Object.assign({}, ownProps, dispatchProps, {
    notifications,
  });
}

export default connect(
  mapStateToProps,
  {
    dismiss: notificationActions.dismissNotification,
  },
  mergeProps
)(Notifications);
