// @flow

import { handleListRequest } from './phasedActionHelpers';

import type { LoadSubscriptionAction } from '../actions/subscription';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
|};

const initialState = {
  loading: false,
  loaded: false,
};

export default function subscription(state: State = initialState, action: LoadSubscriptionAction) {
  if (action.type === 'FETCH_SUBSCRIPTION') {
    return handleListRequest(state, action);
  }

  (action: empty);
  return state;
}
