import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { SEPA_PAYMENT, CARD_PAYMENT } from '../../constants';

import PaymentTab from './PaymentTab';

import styles from './PaymentForm.css';

export default class PaymentMethods extends PureComponent {
  static propTypes = {
    current: PropTypes.string,
    onMethodChange: PropTypes.func,
    sepaIsSupported: PropTypes.bool,
  };

  render() {
    const { current, sepaIsSupported } = this.props;
    return (
      <div className={styles.paymentMethodsContainer}>
        {sepaIsSupported && (
          <PaymentTab isActive={current === SEPA_PAYMENT} onClick={this.setPaymentTypeSepa}>
            <FormattedMessage id="account.payment-option.sepa" defaultMessage="Direct Debit" />
          </PaymentTab>
        )}
        <PaymentTab isActive={current === CARD_PAYMENT} onClick={this.setPaymentTypeCard}>
          <FormattedMessage id="account.payment-option.card" defaultMessage="Credit card" />
        </PaymentTab>
      </div>
    );
  }

  setPaymentTypeSepa = () => this.props.onMethodChange(SEPA_PAYMENT);
  setPaymentTypeCard = () => this.props.onMethodChange(CARD_PAYMENT);
}
