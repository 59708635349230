// @flow
import React from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import ConsentManager from '../consent-manager/ConsentManager';

import * as uiActions from '../../actions/ui';
import { selectOpenModalTypeIs } from '../../selectors/modals';

type MapStateToProps = {
  isOpen: boolean,
};

type DispatchProps = {
  hideModal: Function,
};

type Props = MapStateToProps & DispatchProps;

const ConsentManagerModal = ({ isOpen, hideModal }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => false}
      onClose={hideModal}
      contentLabel="gdpr-opt-out-modal"
      shouldCloseOnOverlayClick={false}
      hideCloseButton
    >
      <ConsentManager hideModal={hideModal} />
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isOpen: selectOpenModalTypeIs(state, 'CONSENT_MANAGER_MODAL'),
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
};

export default connect(mapStateToProps, dispatchProps)(ConsentManagerModal);
