export function selectModalOpen(state) {
  return state.client.ui.modalOpen;
}

export function selectSharedUrl(state) {
  return state.client.ui.sharedUrl;
}

export function selectSharedUrlParams(state) {
  return state.client.ui.sharedUrlParams;
}

export function selectEmbedCode(state) {
  return state.client.ui.embedCode;
}

export function selectModalType(state) {
  return state.client.ui.modalType;
}

export function selectSharedEntityType(state) {
  return state.client.ui.sharedEntityType;
}

export function selectDefaultPaymentType(state) {
  return state.client.ui.defaultPaymentType;
}

export function selectCourseResource(state) {
  return state.client.ui.courseResource;
}

export function selectBookletUrl(state) {
  return state.client.ui.bookletUrl;
}

export function selectTrackingContext(state) {
  return state.client.ui.trackingContext;
}

export function selectOpenModalTypeIs(state, type) {
  return selectModalType(state) === type;
}

export function selectFreeCollectionModal(state) {
  const { freeCollectionModal } = state.client.ui;
  return freeCollectionModal || {};
}

export function selectPremiumExperiencePreviewsModal(state) {
  return state.client.ui.premiumExperiencePreviewsModal || {};
}

export function selectSubscribeModal(state) {
  return state.client.ui.subscribeModal || {};
}

export function selectFreeExperiencePreviewsModal(state) {
  return state.client.ui.freeExperiencePreviewsModal || {};
}

export function selectFacebookPasswordConfirmationModal(state) {
  return state.client.ui.facebookPasswordConfirmationModal || {};
}
