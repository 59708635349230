import React, { Component } from 'react';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function dataComponent(fetchData, options = {}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class DataComponent extends Component {
      render() {
        return <WrappedComponent {...this.props} ref={withRef ? 'wrappedInstance' : null} />;
      }
    }

    DataComponent.displayName = `DataComponent(${getDisplayName(WrappedComponent)}`;

    DataComponent.WrappedComponent = WrappedComponent;

    DataComponent.fetchData = fetchData;

    return DataComponent;
  };
}
