import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  generalTerms: {
    id: 'account.general-terms',
    defaultMessage: 'General Terms and Conditions (GTC)',
  },
  privacyPolicy: {
    id: 'account.privacy-policy',
    defaultMessage: 'Privacy Policy',
  },
});
