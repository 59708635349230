import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { memoize } from 'lodash';

import PlayableItemPreview from '../common/PlayableItemPreview';
import LivestreamEventPreview from '../livestream-event/LivestreamEventPreview';

import stylesCollection from '../../views/Collection.css';
import stylesEventCollection from '../../views/MyEvents.css';

import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';

const toPlayableItemPreview = memoize((collectionItem, entityType) => {
  return {
    title: collectionItem.title,
    imageUrl: collectionItem.imageUrl,
    content: {
      type: entityType,
      ...collectionItem,
    },
  };
});

export default class CollectionItem extends Component {
  static propTypes = {
    collectionItem: PropTypes.object.isRequired,
    entityType: PropTypes.string.isRequired,
    onItemClick: PropTypes.func,
    beforeTogglePlay: PropTypes.func,
  };

  render() {
    const { collectionItem, entityType, onItemClick, beforeTogglePlay } = this.props;

    const classNames = classnames({
      [stylesCollection.itemDisabled]: collectionItem.isDisabled,
      [stylesEventCollection.item]: entityType === ENTITY_TYPE_LIVESTREAM_EVENT,
    });

    return (
      <li className={classNames} data-test="collection.item">
        {entityType === ENTITY_TYPE_LIVESTREAM_EVENT ? (
          <LivestreamEventPreview event={collectionItem} onClickImageLink={onItemClick} />
        ) : (
          <PlayableItemPreview
            item={toPlayableItemPreview(collectionItem, entityType)}
            onClick={onItemClick}
            beforeTogglePlay={beforeTogglePlay}
            showPlayButton={!collectionItem.isDisabled}
          />
        )}
      </li>
    );
  }
}
