// @flow
import React from 'react';
import { getEntityUrl } from '../../lib/entities';
import { Link } from 'react-router';
import stylesSearchResult from './SearchResults.css';
import stylesSearchCollectionItem from './SearchCollectionItem.css';
import { ENTITY_TYPE_LIVESTREAM_EVENT, SEARCH_IMAGE_DIMS } from '../../constants';
import Separated from '../util/Separated';
import IconLabel from '../util/IconLabel';
import ContentfulImage from '../util/ContentfulImage';

type OwnProps = {
  title: string,
  slug: string,
  imageUrl: string,
  participants: Array<{ title: string }>,
  onClick: Function,
};

const SearchEventItem = ({ title, slug, imageUrl, participants = [], onClick }: OwnProps) => {
  const path = getEntityUrl(ENTITY_TYPE_LIVESTREAM_EVENT, { slug });
  const participantTitles = participants.map(({ title: participantTitle }) => participantTitle);

  return (
    <Link to={path} className={stylesSearchResult.result} onClick={onClick}>
      {imageUrl ? (
        <ContentfulImage
          src={imageUrl}
          options={{
            fm: 'png',
            fit: 'pad',
            h: SEARCH_IMAGE_DIMS.h * 3,
            w: SEARCH_IMAGE_DIMS.w * 3,
          }}
          className={stylesSearchResult.thumb}
        />
      ) : (
        <div className={stylesSearchResult.thumb}>
          <IconLabel name="event" className={stylesSearchResult.icon} />
        </div>
      )}
      <div className={stylesSearchCollectionItem.wrapper}>
        <span className={stylesSearchCollectionItem.title}>{title}</span>
        {participantTitles.length > 0 && (
          <span className={stylesSearchCollectionItem.participants}>
            <Separated>{participantTitles}</Separated>
          </span>
        )}
      </div>
    </Link>
  );
};

export default SearchEventItem;
