import Player from './Player.js';

export default class Ads extends Player {
  constructor(urlResolver, createAudioDevice, updateInterval = 1000) {
    super(urlResolver, 50, createAudioDevice, updateInterval);
    this.DEBUG_PREFIX = 'AdsPlayer';
    this.durations = [];
    this.audioQuality = 50;
  }

  setDurations(durations) {
    if (durations.length !== this.queue.length) {
      throw new Error('Durations array length does not match queue length');
    }
    this.durations = durations;
  }

  _getTimeoutTime = () => {
    const duration = this.durations[this.currentIndex];
    if (!duration) {
      throw new Error('Duration for ad ' + this.queue[this.currentIndex] + ' not found');
    }
    return duration * 1000;
  };

  _qualityToSrc = () => 'audio/mpeg';

  _getEventProperties = (index = this.currentIndex, isPreload = false) => {
    const { queue, queueId, currentIndex, audioQuality } = this;
    const isLast = !this.hasNext();
    return {
      adId: queue[index],
      currentIndex,
      queue: queue.slice(),
      queueId,
      adPosition: currentIndex + 1,
      audioQuality,
      isPreload,
      isLast,
    };
  };
}
