// @flow
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classnames from 'classnames';

import Modal from './Modal';
import * as uiActions from '../../actions/ui';

import { selectOpenModalTypeIs } from '../../selectors/modals';
import { selectPageTrackingContext } from '../../selectors/client';
import { selectUserIsAuthenticated } from '../../selectors/user';
import { selectAlbum } from '../../selectors/album';
import { selectPlaylist } from '../../selectors/playlist';

import { ENTITY_TYPE_PLAYLIST, CONSTANT_MESSAGES } from '../../constants';

import styles from './EmbedPreviewsModal.css';
import SignupOrLogin from '../messages/SignupOrLogin';
import { getOpenInWebAppLink } from '../../lib/embed-player';
import type { IntlShape } from 'react-intl';

const messages = defineMessages({
  loginLink: {
    id: 'embed-previews-modal.login-here',
    defaultMessage: 'Log in here',
  },
  signUpLink: {
    id: 'embed-previews-modal.create-account',
    defaultMessage: 'Create account',
  },
});

type MapStateToProps = {
  isOpen: boolean,
  isAuthenticated: boolean,
  contextType: string,
  contextId: string,
  contextTitle: string,
};

type DispatchProps = {
  hideModal: Function,
};

type Props = MapStateToProps & DispatchProps & { intl: IntlShape };

const EmbedPreviewsModal = ({
  hideModal,
  isOpen,
  isAuthenticated,
  contextType,
  contextId,
  contextTitle,
  intl,
}: Props) => {
  const [referrerUrl, setReferrerUrl] = useState('');

  useEffect(() => {
    setReferrerUrl(document.referrer);
  }, []);

  const linkToEmbedContent = getOpenInWebAppLink(
    contextType,
    { id: contextId, title: contextTitle },
    referrerUrl,
    true
  );

  let signupLink = linkToEmbedContent;
  let loginLink = linkToEmbedContent;

  if (!isAuthenticated) {
    signupLink += '&showPreviewsModal=true';
    loginLink += '&showLoginModal=true';
  }

  const loginText = intl.formatMessage(messages.loginLink);
  const ariaLoginLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: loginText,
  });

  const signUpText = intl.formatMessage(messages.signUpLink);
  const ariaSignUpLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: signUpText,
  });

  const buttonStyles = classnames('c-btn', 'c-btn--is-purple', styles.button);
  return (
    <Modal
      isOpen={isOpen}
      onRequesthideModal={hideModal}
      onClose={hideModal}
      contentLabel="lossless-support-modal"
    >
      <section className={styles.modal}>
        <h1 className={`${styles.title} fz--beta`}>
          <FormattedMessage
            id="embed-previews-modal.text-2"
            defaultMessage="Create an account for free to listen to the full playlist."
          />
        </h1>
        <a
          href={signupLink}
          className={buttonStyles}
          onClick={hideModal}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaSignUpLink}
        >
          {signUpText}
        </a>
        <p className={styles.text}>
          <a
            href={loginLink}
            className="c-text-link--is-visible"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={ariaLoginLink}
          >
            <SignupOrLogin />
          </a>
        </p>
      </section>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const { contextType, contextId, contextTitle } = selectPageTrackingContext(state);

  let content;

  if (!contextTitle.length) {
    content =
      contextType === ENTITY_TYPE_PLAYLIST
        ? selectPlaylist(state, contextId)
        : selectAlbum(state, contextId);
  }

  return {
    isOpen: selectOpenModalTypeIs(state, 'EMBED_PREVIEWS_MODAL'),
    isAuthenticated: selectUserIsAuthenticated(state),
    contextType,
    contextId,
    contextTitle: (content && content.title) || contextTitle,
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(EmbedPreviewsModal);
