// @flow
// $FlowFixMe
import React, { useState, useEffect, useRef } from 'react';
import IconLabel from './IconLabel';

import styles from './EmbedCopyButton.css';
import classNames from 'classnames';

type OwnProps = {
  onClick: Function,
};

const EmbedCopyButton = ({ onClick }: OwnProps) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef();

  const onButtonClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    onClick();
    setCopied(true);

    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <button
      onClick={onButtonClick}
      className={classNames(styles.copyButton, {
        [styles.copied]: copied,
      })}
    >
      {copied ? <IconLabel name="check" /> : <IconLabel name="embed" />}
    </button>
  );
};

export default EmbedCopyButton;
