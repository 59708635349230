// @flow
// $FlowFixMe
import React, { useState } from 'react';
import type { Element } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import Modal from './Modal';
import LoadingIndicator from '../common/LoadingIndicator';
import AccountPasswordLabel from '../messages/AccountPasswordLabel';
import LoginForgot from '../messages/LoginForgot';

import styles from './FacebookPasswordConfirmationModal.css';
import PasswordInput from '../common/PasswordInput';

import * as uiActions from '../../actions/ui';
import {
  selectFacebookPasswordConfirmationModal,
  selectOpenModalTypeIs,
} from '../../selectors/modals';
import { selectAuthIsWorking } from '../../selectors/auth';

type MapStateToProps = {
  confirmPassword: Function,
  email: string,
  isWorking: boolean,
  confirmCopy: Element<typeof FormattedMessage>,
  isOpen: boolean,
};

type DispatchProps = {
  closeModal: Function,
};

type Props = MapStateToProps & DispatchProps;

const FacebookPasswordConfirmationModal = ({
  confirmPassword,
  email,
  isWorking,
  confirmCopy,
  isOpen,
  closeModal,
}: Props) => {
  const [passwordValue, setPasswordValue] = useState('');

  const onPasswordChange = (e: SyntheticEvent<HTMLFormElement>) => {
    setPasswordValue(e.currentTarget.value);
  };

  const onSubmitPassword = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    await confirmPassword(passwordValue);
    closeModal();
  };

  const emptyPassword = passwordValue === '';
  const buttonClassName = classnames(styles.btn, 'c-btn', 'c-btn--is-purple');

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onRequestClose={closeModal}
      contentLabel="confirm-with-password-modal"
      role="dialog"
    >
      <form onSubmit={onSubmitPassword}>
        <h1 className="fz--beta">
          <FormattedMessage
            id="confirm-password.validate.header"
            defaultMessage="Validate your account"
          />
        </h1>
        <p>{email}</p>
        <p>{confirmCopy}</p>
        <div className={styles.fields}>
          <PasswordInput
            id="password"
            value={passwordValue}
            autoFocus
            onChange={onPasswordChange}
            title={<AccountPasswordLabel />}
            className={styles.passwordInput}
          />
          <div className={styles.forgotPasswordLink}>
            <Link to="/forgot-password" className="c-text-link--is-visible">
              <LoginForgot />
            </Link>
          </div>
        </div>
        <button className={buttonClassName} disabled={isWorking || emptyPassword} type="submit">
          <LoadingIndicator isLoading={isWorking} className="hideText" />
          <FormattedMessage id="confirm-password.validate.button" defaultMessage="Validate" />
        </button>
      </form>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const { email, confirmPassword, confirmCopy } = selectFacebookPasswordConfirmationModal(state);
  return {
    email,
    confirmPassword,
    confirmCopy,
    isOpen: selectOpenModalTypeIs(state, 'FACEBOOK_PASSWORD_CONFIRMATION_MODAL'),
    isWorking: selectAuthIsWorking(state),
  };
}

const dispatchProps: DispatchProps = {
  closeModal: uiActions.hideModal,
};

export default connect(mapStateToProps, dispatchProps)(FacebookPasswordConfirmationModal);
