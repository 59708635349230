import {
  createGetEntitySelectorByQueueType,
  getRecentlyPlayedTypeFromQueueType,
} from '../lib/queue';
import { selectRecording } from './recording';
import { selectTrack } from './track';
import { selectMix } from './mix';
import { selectAlbum, selectAlbumById } from './album';
import { selectPersonalPlaylist } from './personalPlaylist';
import { selectPlaylist } from './playlist';
import { selectLiveCompare } from './liveCompare';

const getEntitySelectorByQueueType = createGetEntitySelectorByQueueType({
  selectRecording,
  selectTrack,
  selectMix,
  selectAlbum,
  selectAlbumById,
  selectPersonalPlaylist,
  selectPlaylist,
  selectLiveCompare,
});

export function selectURNForRecentlyPlayedServiceFromQueueOrigin(state, queueOrigin) {
  if (!queueOrigin) {
    return null;
  }

  const recentlyPlayedType = getRecentlyPlayedTypeFromQueueType(queueOrigin.type);
  const getEntity = getEntitySelectorByQueueType(queueOrigin.type);

  const recentlyPlayedEntity = getEntity(state, queueOrigin.id);

  return `${recentlyPlayedType}:${recentlyPlayedEntity.id}`;
}
