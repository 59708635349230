// @flow
type PlayerQueueLoadAction = { type: 'PLAYER_QUEUE_LOAD', origin: Object };
type PlayerQueueLoadedAction = { type: 'PLAYER_QUEUE_LOADED', origin: Object };

export type PlayerQueueAction = PlayerQueueLoadAction | PlayerQueueLoadedAction;

export function loadQueue(origin: Object): PlayerQueueLoadAction {
  return {
    type: 'PLAYER_QUEUE_LOAD',
    origin,
  };
}

export function queueIsLoaded(origin: Object): PlayerQueueLoadedAction {
  return {
    type: 'PLAYER_QUEUE_LOADED',
    origin,
  };
}
