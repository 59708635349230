import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import List from '../util/List';

import stylesCollection from '../../views/Collection.css';
import SubscribeNowButton from '../common/SubscribeNowButton';

export default class LimitedCollection extends Component {
  static propTypes = {
    listClassName: PropTypes.string.isRequired,
    limitedCollection: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    renderInfo: PropTypes.func.isRequired,
  };

  renderLimitedCollectionInfo() {
    return (
      <div className={stylesCollection.limitedCollectionInfo}>
        {this.props.renderInfo()}
        <p>
          <FormattedMessage
            id="collection.info.subscribe"
            defaultMessage="Subscribe for an unlimited collection."
          />
        </p>
        <SubscribeNowButton
          trackingContext={{ trigger: 'collection' }}
          className={classnames('c-btn c-btn--is-purple', stylesCollection.subNow)}
        />
      </div>
    );
  }

  render() {
    const { listClassName, limitedCollection, renderItem } = this.props;

    return (
      <React.Fragment>
        {this.renderLimitedCollectionInfo()}
        <List className={listClassName} items={limitedCollection} renderItem={renderItem} />
      </React.Fragment>
    );
  }
}
