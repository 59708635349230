// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './PlansOverviewSubscribe.css';

import {
  PLAN_ID_PREMIUM,
  PLAN_ID_PPC_1M,
  PLAN_ID_PPC_12M,
  PLAN_ID_PREMIUM_PLUS,
  SUBSCRIPTION_PLANS,
} from '../../constants';

import {
  objectivesMessages,
  planDescriptionMessages,
  planTitleMessages,
} from '../messages/PlansOverviewMessages';
import PlanOptionSubscribe from './PlanOptionSubscribe';
import classNames from 'classnames';

type OwnProps = {
  setSelectedPlan: Function,
  selectedPlan: string,
  relativeDiscount?: number,
};

type Props = OwnProps;

const PlansOverviewSubscribe = ({ setSelectedPlan, selectedPlan, relativeDiscount }: Props) => {
  return (
    <React.Fragment>
      <PlanOptionSubscribe
        id={PLAN_ID_PREMIUM}
        isSelected={selectedPlan === PLAN_ID_PREMIUM}
        onSelect={setSelectedPlan}
        imageTitle={<FormattedMessage {...planTitleMessages[PLAN_ID_PREMIUM]} />}
        title={<FormattedMessage {...planDescriptionMessages[PLAN_ID_PREMIUM]} />}
        monthlyPrice={SUBSCRIPTION_PLANS[PLAN_ID_PREMIUM].monthlyPrice}
        relativeDiscount={relativeDiscount}
        objectives={[
          {
            key: 'library',
            value: <FormattedMessage {...objectivesMessages.library} />,
          },
          {
            key: 'adFree',
            value: <FormattedMessage {...objectivesMessages.adFree} />,
          },
          {
            key: 'weeklyMixes',
            value: <FormattedMessage {...objectivesMessages.weeklyMixes} />,
          },
        ]}
        className={styles.pPlan}
        data-test="plan-option.premium"
      />
      <PlanOptionSubscribe
        id={PLAN_ID_PREMIUM_PLUS}
        isSelected={selectedPlan === PLAN_ID_PREMIUM_PLUS}
        onSelect={setSelectedPlan}
        imageTitle={<FormattedMessage {...planTitleMessages[PLAN_ID_PREMIUM_PLUS]} />}
        title={<FormattedMessage {...planDescriptionMessages[PLAN_ID_PREMIUM_PLUS]} />}
        monthlyPrice={SUBSCRIPTION_PLANS[PLAN_ID_PREMIUM_PLUS].monthlyPrice}
        relativeDiscount={relativeDiscount}
        objectives={[
          {
            key: 'premiumFeatures',
            value: <FormattedMessage {...objectivesMessages.premiumFeatures} />,
          },
          {
            key: 'quality',
            value: <FormattedMessage {...objectivesMessages.quality} />,
          },
        ]}
        className={styles.ppPlan}
        data-test="plan-option.premium-plus"
      />
      <PlanOptionSubscribe
        id={PLAN_ID_PPC_12M}
        isSelected={selectedPlan === PLAN_ID_PPC_1M || selectedPlan === PLAN_ID_PPC_12M}
        onSelect={setSelectedPlan}
        imageTitle={<FormattedMessage {...planTitleMessages[PLAN_ID_PPC_12M]} />}
        title={<FormattedMessage {...planDescriptionMessages[PLAN_ID_PPC_12M]} />}
        monthlyPrice={SUBSCRIPTION_PLANS[PLAN_ID_PPC_12M].monthlyPrice}
        priceTag={
          <div className={classNames('u-tag', styles.annualPlanTag)}>
            <FormattedMessage
              id="subscribe-modal.premium-plus.add-on.tag"
              defaultMessage="When billed annually"
            />
          </div>
        }
        relativeDiscount={relativeDiscount}
        objectives={[
          {
            key: 'premiumPlusFeatures',
            value: <FormattedMessage {...objectivesMessages.premiumPlusFeatures} />,
          },
          {
            key: 'concertAccess',
            value: <FormattedMessage {...objectivesMessages.concertAccess} />,
          },
        ]}
        className={styles.ppcPlan}
        data-test="plan-option.premium-plus-concerts"
      />
    </React.Fragment>
  );
};

export default PlansOverviewSubscribe;
