// @flow
import React from 'react';
import { connect } from 'react-redux';

import { selectOpenModalTypeIs, selectSubscribeModal } from '../../selectors/modals';

import ConversionForm from '../premium/ConversionForm';

import styles from '../../views/Subscribe.css';

import * as uiActions from '../../actions/ui';

import Overlay from './Overlay';

type MapStateToProps = {
  isOpen: boolean,
  plan: string,
};

type DispatchProps = {
  showModal: Function,
  closeModal: Function,
};

type Props = MapStateToProps & DispatchProps;

const SubscribeModal = ({ plan, isOpen, showModal, closeModal }: Props) => {
  const onComplete = () => {
    closeModal();
  };

  const onClose = () => {
    closeModal();
  };

  const onClickLoginLink = event => {
    event.preventDefault();
    showModal('LOGIN_MODAL', { trigger: 'subscribeModal' });
  };

  return (
    <Overlay isVisible={isOpen} hideHeader data-test="subscribe-modal">
      <ConversionForm
        className={styles.form}
        onComplete={onComplete}
        onClickLoginLink={onClickLoginLink}
        plan={plan}
        onClose={onClose}
      />
    </Overlay>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const { plan } = selectSubscribeModal(state);
  return {
    isOpen: selectOpenModalTypeIs(state, 'SUBSCRIBE_MODAL'),
    plan,
  };
}

const dispatchProps: DispatchProps = {
  showModal: uiActions.showModal,
  closeModal: uiActions.hideModal,
};

export default connect(mapStateToProps, dispatchProps)(SubscribeModal);

export { SubscribeModal as PureSubscribeModal };
