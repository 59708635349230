// @flow
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import IconLabel from '../util/IconLabel';
import styles from './Steps.css';
import classNames from 'classnames';

type OwnProps = {
  currentStep: number,
  totalSteps: number,
};

type Props = OwnProps;

const messages = defineMessages({
  [1]: {
    id: 'premium.steps.signup',
    defaultMessage: 'Create Account',
  },
  [2]: {
    id: 'premium.steps.plans-overview',
    defaultMessage: 'Select Plan',
  },
  [3]: {
    id: 'premium.steps.payment',
    defaultMessage: 'Payment',
  },
  [4]: {
    id: 'premium.steps.payment-success',
    defaultMessage: 'Enjoy IDAGIO',
  },
});

const Step = ({ step, currentStep }: { step: number, currentStep: number }) => {
  // completed step
  if (currentStep > step) {
    return (
      <div className={classNames(styles.step, styles.completed)}>
        <IconLabel name="check" size="small" />
        <FormattedMessage {...messages[step]} />
      </div>
    );
  }

  // active step
  if (currentStep === step) {
    return (
      <div className={styles.step}>
        <div className={styles.circleActive} />
        <FormattedMessage {...messages[step]} />
      </div>
    );
  }

  // pending step
  if (currentStep < step) {
    return (
      <div className={classNames(styles.step, styles.pending)}>
        <div className={styles.circle} />
        <FormattedMessage {...messages[step]} />
      </div>
    );
  }

  return null;
};

const Steps = ({ currentStep, totalSteps }: Props) => {
  return (
    <div className={styles.container}>
      {totalSteps === 4 && (
        <React.Fragment>
          <Step step={1} currentStep={currentStep} />
          <div className={styles.separator} />
        </React.Fragment>
      )}
      <Step step={2} currentStep={currentStep} />
      <div className={styles.separator} />
      <Step step={3} currentStep={currentStep} />
      {totalSteps !== 4 && (
        <React.Fragment>
          <div className={styles.separator} />
          <Step step={4} currentStep={currentStep} />
        </React.Fragment>
      )}
    </div>
  );
};

export default Steps;
