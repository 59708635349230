// @flow
import React from 'react';
import { Link, withRouter } from 'react-router';

import { LOGIN_INTENT } from '../../constants';
import AuthOptions from './AuthOptions';
import LoginForm from './LoginForm';
import LoginHeader from '../messages/LoginHeader';

import styles from './AuthOptions.css';
import LoginOrSignup from '../messages/LoginOrSignup';

type OwnProps = {
  location: Object,
  onAuthComplete?: Function,
  onClickSignupLink?: Function,
  onClickForgotPasswordLink?: Function,
  hideSocialOptions?: boolean,
  showFacebookLogin?: boolean,
};

type Props = OwnProps;

const Login = ({
  location,
  onAuthComplete,
  onClickSignupLink,
  onClickForgotPasswordLink,
  hideSocialOptions,
  showFacebookLogin,
}: Props) => {
  const SignupLink = () => {
    return (
      <Link
        to={`/join${location ? location.search : ''}`}
        onClick={onClickSignupLink}
        className="c-text-link--is-visible"
      >
        <LoginOrSignup />
      </Link>
    );
  };

  const Header = () => {
    return (
      <div className={styles.header}>
        <h1 className="fz--beta">
          <LoginHeader />
        </h1>
        <SignupLink />
      </div>
    );
  };

  const DefaultForm = () => {
    return (
      <LoginForm
        onAuthComplete={onAuthComplete}
        onClickForgotPasswordLink={onClickForgotPasswordLink}
      />
    );
  };

  return (
    <AuthOptions
      onAuthComplete={onAuthComplete}
      defaultForm={<DefaultForm />}
      header={<Header />}
      intent={LOGIN_INTENT}
      hideSocialOptions={hideSocialOptions}
      showFacebookLogin={showFacebookLogin}
    />
  );
};

export default withRouter(Login);
