import { QUEUE_TYPE_PLAYLIST, VALID_PLAYLIST_FILTER_PARAMS, QUEUE_TYPE_MOOD } from '../constants';
import { createSelector } from 'reselect';
import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

import { selectPlayerIsPlaying, selectPlayerLoadingQueueOrigin } from './player';
import { selectIsQueueOriginSetAs } from './queue';
import filterAndSortParams from '../utils/filterAndSortParams';
import getSerializedFilterId from '../utils/getSerializedFilterId';
import { map, some } from 'lodash';

export const selectPlaylistIdFromSlug = (state, playlistSlug) => {
  return state.maps.playlists[playlistSlug] ? state.maps.playlists[playlistSlug].entities : null;
};

const selectPlaylists = state => {
  return state.entities.playlists;
};

export function selectPlaylistIsLoading(state, slug) {
  return state.maps.playlists[slug] ? state.maps.playlists[slug].loading : false;
}

export function selectPlaylistIsLoaded(state, slug) {
  return state.maps.playlists[slug] ? state.maps.playlists[slug].loaded : false;
}

export function getPlaylistQueueOrigin(slug) {
  return {
    type: QUEUE_TYPE_PLAYLIST,
    id: slug,
  };
}

export function selectPlaylistIsQueued(state, slug) {
  const queueOrigin = getPlaylistQueueOrigin(slug);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectPlaylistIsPlaying(state, slug) {
  const isQueued = selectPlaylistIsQueued(state, slug);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

export const selectPlaylist = createSelector(
  [state => state.entities, selectPlaylistIdFromSlug, selectPlaylists],
  (entities, id, playlists) => {
    if (!id || !playlists[id]) {
      return null;
    }

    return assembleEntity(Schema.Playlist, entities, id);
  }
);

export const selectPlaylistImageAnnotation = (state, slug) => {
  const playlistId = selectPlaylistIdFromSlug(state, slug);
  const annotationId = `playlists-${playlistId}-main.jpg`;
  return state.client.annotations.image ? state.client.annotations.image[annotationId] : null;
};

export function selectPlaylistIsLoadingToPlay(state, slug) {
  const queueOrigin = getPlaylistQueueOrigin(slug);
  const loadingQueueOrigin = selectPlayerLoadingQueueOrigin(state);
  return (
    !!loadingQueueOrigin &&
    loadingQueueOrigin.id === queueOrigin.id &&
    loadingQueueOrigin.type === queueOrigin.type
  );
}

export const selectFilteredAndSortedPlaylists = createSelector(
  [
    state => state.entities,
    state => state.maps.playlistsByFilter,
    (state, filterParams) => filterParams,
  ],
  (entities, entityByFilter, filterParams) => {
    const validAndSortedParams = filterAndSortParams(filterParams, VALID_PLAYLIST_FILTER_PARAMS);
    const cacheId = getSerializedFilterId(validAndSortedParams, VALID_PLAYLIST_FILTER_PARAMS);

    return assembleEntity(Schema.PlaylistList, entities, entityByFilter[cacheId].entities);
  }
);

export const selectFilteredAndSortedPlaylistsLoading = createSelector(
  [state => state.maps.playlistsByFilter],
  maps => {
    const loadingStates = map(maps, entry => {
      return entry && entry.loading;
    });
    return some(loadingStates, isLoading => isLoading);
  }
);

export function getMoodQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_MOOD,
    id: originId,
  };
}

export function selectMoodIsQueued(state, moodId) {
  const queueOrigin = getMoodQueueOrigin(moodId);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectMoodIsPlaying(state, moodId) {
  const isQueued = selectMoodIsQueued(state, moodId);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}
