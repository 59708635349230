import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SearchArtistItem from './SearchArtistItem';
import SearchMusicItem from './SearchMusicItem';
import SearchCollectionItem from './SearchCollectionItem';
import SearchEventItem from './SearchEventItem';

export default class SearchResult extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.item);
  };

  render() {
    const { item } = this.props;
    const type = item.type;

    switch (type) {
      case 'person':
      case 'ensemble':
        return <SearchArtistItem {...item} path={`/profiles${item.path}`} onClick={this.onClick} />;
      case 'recording':
      case 'work':
      case 'piece':
        return <SearchMusicItem {...item} onClick={this.onClick} />;
      case 'playlist':
      case 'album':
        return <SearchCollectionItem {...item} onClick={this.onClick} />;
      case 'event':
        return <SearchEventItem {...item} onClick={this.onClick} />;
      default:
        return null;
    }
  }
}
