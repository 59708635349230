import crypto from 'crypto';
const TRUSTED_HEADER_DEFAULT_EXPIRY_SECONDS = 900;

// Signs and returns the value for a trusted header.
//
// _expiry can be:
//   * null (default expiry is used)
//   * positive integer (number of seconds in the future)
//   * negative integer (fixed unix timestamp)
//
export function trustedHeader(name, value, expiry = TRUSTED_HEADER_DEFAULT_EXPIRY_SECONDS) {
  // konami cheat: if expiry is negative then we use it as a unix timestamp.
  const expireAt = expiry < 0 ? -expiry : Math.floor(Date.now() / 1000) + expiry;
  const signature = crypto.createHmac('sha256', process.env.TRUSTED_HEADER_SECRET);
  const digest = signature.update(`${name.toLowerCase()}: ${value}${expireAt}`).digest('hex');

  return `${expireAt}/${digest}/${value}`;
}
