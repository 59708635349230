// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadDiscoverPageAction } from '../../actions/discover';
import type { DiscoverPage } from '../../shapes/types';

export type State = {
  +[key: string]: {|
    +entities: DiscoverPage,
    +loaded: boolean,
    +loading: boolean,
  |},
};

const initialState = {};

export default function discoverGroups(
  state: State = initialState,
  action: LoadDiscoverPageAction
): State {
  if (action.type === 'FETCH_DISCOVER_PAGE' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
