import { AUDIO_QUALITY_PREVIEW } from '../constants';

export function selectFeatureFlags(state) {
  return state.client.features;
}

export function selectFeatureFlag(state, featureId) {
  const flags = selectFeatureFlags(state);
  return flags[featureId];
}

// External devices
export function selectExternalDevicesAllowed(state) {
  return selectFeatureFlag(state, 'streaming_to_all_external_players');
}

export function selectSetQualityIsAllowed(state, quality) {
  const availableQualities = selectFeatureFlag(state, 'audio_quality');
  if (quality === AUDIO_QUALITY_PREVIEW) {
    return true;
  }

  return availableQualities.includes(quality);
}

export function selectShouldPlayPreviews(state) {
  const availableQualities = selectFeatureFlag(state, 'audio_quality');
  return availableQualities.length === 1 && availableQualities[0] === AUDIO_QUALITY_PREVIEW;
}
