import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  necessary: {
    id: 'cookie-banner.checkboxes.label.necessary',
    defaultMessage: 'Necessary',
  },
  marketingAndAnalytics: {
    id: 'cookie-banner.checkboxes.label.marketing-analytics',
    defaultMessage: 'Analytics',
  },
  functional: {
    id: 'cookie-banner.checkboxes.label.functional',
    defaultMessage: 'Functional',
  },
  advertising: {
    id: 'cookie-banner.checkboxes.label.advertising',
    defaultMessage: 'Advertising',
  },
});
