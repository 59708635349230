import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import SearchResult from './SearchResult';

import styles from './SearchResults.css';

const messages = defineMessages({
  top: {
    defaultMessage: 'Top Results',
    id: 'search.section.title.top',
  },
  recordings: {
    defaultMessage: 'Recordings',
    id: 'search.section.title.recordings',
  },
  compositions: {
    defaultMessage: 'Works',
    id: 'search.section.title.compositions',
  },
  artists: {
    defaultMessage: 'Artists',
    id: 'search.section.title.artists',
  },
  playlists: {
    defaultMessage: 'Playlists',
    id: 'search.section.title.playlists',
  },
  albums: {
    defaultMessage: 'Albums',
    id: 'search.section.title.albums',
  },
  recent: {
    defaultMessage: 'Recent searches',
    id: 'search.section.title.recent',
  },
  popular: {
    defaultMessage: 'Popular searches',
    id: 'search.section.title.popular',
  },
  events: {
    defaultMessage: 'Events',
    id: 'search.section.title.events',
  },
});

class SearchSection extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    section: PropTypes.array.isRequired,
    onAccessed: PropTypes.func,
    intl: intlShape,
    hideHeading: PropTypes.bool,
  };

  onAccessed = el => {
    if (this.props.onAccessed) {
      this.props.onAccessed(el, this.props.id);
    }
  };

  renderResult = item => {
    return <SearchResult key={item.id} item={item} onClick={this.onAccessed} />;
  };

  render() {
    const { id, section, intl, hideHeading } = this.props;
    if (!section || !Boolean(section.length)) {
      return null;
    }

    return (
      <div key={id} className={!hideHeading ? styles.section : ''}>
        {!hideHeading && (
          <p className={`${styles.heading} fz--delta`}>{intl.formatMessage(messages[id])}</p>
        )}
        {section.map(this.renderResult)}
      </div>
    );
  }
}

export default injectIntl(SearchSection);
