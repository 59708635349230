// @flow
import { head } from 'lodash';
import { selectAudioQuality } from '../selectors/player';
import { loadTrackUrl } from './trackUrls';
import { loadEntity } from '../lib/entities';
import type { EntityAction } from '../lib/entities';
import type { ThunkAction } from './types';
import {
  getPlayableItemPreviewQueueOrigin,
  selectPlayableItemPreviewIsLoading,
  selectPlayableItemPreviewIsPlaying,
  selectPlayableItemPreviewIsQueued,
  selectPlayableItemPreviewTrackIds,
} from '../selectors/playableItemPreview';
import { abortQueueLoad, createItem, pause, play, setShuffledQueue } from './player';
import { loadQueue, queueIsLoaded } from './queue';

declare var __CAPACITOR__: boolean;

export function loadPlayableItemPreview(playableItemPreview: {
  content: { type: string },
}): EntityAction {
  const item = playableItemPreview.content;
  const type = item.type;
  return loadEntity(type, item);
}

export function togglePlayPlayableItemPreview(item: Object, timestamp: number): ThunkAction {
  return async function togglePlayPlayableItemPreviewThunk(dispatch, getState) {
    const state = getState();
    const itemIsPlaying = selectPlayableItemPreviewIsPlaying(state, item);
    const itemIsQueued = selectPlayableItemPreviewIsQueued(state, item);

    if (itemIsPlaying) {
      dispatch(pause());
      return;
    }

    if (itemIsQueued) {
      dispatch(play('internal', { timestamp }));
      return;
    }

    const origin = getPlayableItemPreviewQueueOrigin(item);

    dispatch(loadQueue(origin));
    await dispatch(loadPlayableItemPreview(item));
    const queueHasChanged = !selectPlayableItemPreviewIsLoading(getState(), item);
    if (queueHasChanged) {
      // eslint-disable-next-line no-console
      console.warn('Queue changed during resolving playable item preview');
      return;
    }

    const trackIds = selectPlayableItemPreviewTrackIds(getState(), item);

    if (!trackIds.length) {
      dispatch(abortQueueLoad());
      return;
    }

    dispatch(setShuffledQueue(origin, trackIds.map(createItem), true, { timestamp }));
    if (__CAPACITOR__) {
      // We will set the queue to be loaded when it comes back from capacitor
      return;
    }
    dispatch(queueIsLoaded(origin));
  };
}

export function loadPlayableItemPreviewUrl(item: Object): ThunkAction {
  return async function loadPlayableItemPreviewUrlThunk(dispatch, getState) {
    await dispatch(loadPlayableItemPreview(item));
    const trackIds = selectPlayableItemPreviewTrackIds(getState(), item);
    if (!trackIds.length) {
      return null;
    }

    const quality = selectAudioQuality(getState());
    const trackId = head(trackIds);

    return dispatch(loadTrackUrl(trackId, quality));
  };
}
