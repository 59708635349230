// @flow

export type ConnectedAction = { type: 'CONNECTED' };
export type DisconnectedAction = { type: 'DISCONNECTED' };
export type NetworkErrorAction = { type: 'NETWORK_ERROR', source: string };

export type ConnectionAction = ConnectedAction | DisconnectedAction | NetworkErrorAction;

export function connected(): ConnectedAction {
  return {
    type: 'CONNECTED',
  };
}

export function disconnected(): DisconnectedAction {
  return {
    type: 'DISCONNECTED',
  };
}

export function networkError(source: string): NetworkErrorAction {
  return {
    type: 'NETWORK_ERROR',
    source,
  };
}
