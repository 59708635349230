import { findIndex, last } from 'lodash';
import { selectFeatureFlag } from './features';

export function selectPlayerState(state) {
  return state.player;
}

export function selectPlayerQueueItems(state) {
  return selectPlayerState(state).queueItems;
}

export function selectPlayerQueueOrigin(state) {
  return selectPlayerState(state).queueOrigin;
}

export function selectPlayerOriginalTracks(state) {
  return selectPlayerState(state).originalTracks;
}

export function selectPlayerLoadingQueueOrigin(state) {
  return selectPlayerState(state).loadingQueueOrigin;
}

export function selectPlayerCurrentQueueItem(state) {
  return selectPlayerState(state).currentQueueItem;
}

function selectRepeatAllFromPlayerState(playerState) {
  return playerState.repeatAll;
}

function selectRepeatOneFromPlayerState(playerState) {
  return playerState.repeatOne;
}

function selectShuffleRecordingsFromPlayerState(playerState) {
  return playerState.shuffleRecordings;
}

function selectShuffleTracksFromPlayerState(playerState) {
  return playerState.shuffleTracks;
}

export function selectRepeatAll(state) {
  return selectRepeatAllFromPlayerState(selectPlayerState(state));
}

export function selectRepeatOne(state) {
  return selectRepeatOneFromPlayerState(selectPlayerState(state));
}

export function selectShuffleRecordings(state) {
  return selectShuffleRecordingsFromPlayerState(selectPlayerState(state));
}

export function selectShuffleTracks(state) {
  return selectShuffleTracksFromPlayerState(selectPlayerState(state));
}

// NEXT / PREV Selectors
const DIRECTION_NEXT = 1;
const DIRECTION_PREVIOUS = -1;

function getNextQueueItem(queueItems, current, direction, isLooped) {
  const index = findIndex(queueItems, current);
  let nextIndex = index + direction;

  if (isLooped) {
    if (queueItems.length === index + 1 && nextIndex >= queueItems.length) {
      nextIndex = 0;
    }

    if (nextIndex < 0) {
      nextIndex = queueItems.length - 1;
    }
  }

  return queueItems[nextIndex];
}

export function selectNextQueueItemFromPlayerState(playerState) {
  const { queueItems, currentQueueItem } = playerState;
  const repeatOne = selectRepeatOneFromPlayerState(playerState);

  if (repeatOne) {
    return currentQueueItem;
  }

  const repeatAll = selectRepeatAllFromPlayerState(playerState);
  return getNextQueueItem(queueItems, currentQueueItem, DIRECTION_NEXT, repeatAll);
}

export function selectPreviousQueueItemFromPlayerState(playerState) {
  const { queueItems, currentQueueItem } = playerState;
  const repeatOne = selectRepeatOneFromPlayerState(playerState);

  if (repeatOne) {
    return currentQueueItem;
  }

  const repeatAll = selectRepeatAllFromPlayerState(playerState);

  return getNextQueueItem(queueItems, currentQueueItem, DIRECTION_PREVIOUS, repeatAll);
}

export function selectNextQueueItem(state) {
  return selectNextQueueItemFromPlayerState(selectPlayerState(state));
}

export function selectPreviousQueueItem(state) {
  return selectPreviousQueueItemFromPlayerState(selectPlayerState(state));
}

export function selectPlayerVolume(state) {
  return selectPlayerState(state).volume;
}

export function selectPlayerMuted(state) {
  return selectPlayerState(state).isMuted;
}

export function selectAudioQuality(state) {
  const allowedQualities = selectFeatureFlag(state, 'audio_quality');
  const preferredQuality = state.client.preferences.audioQuality;
  if (allowedQualities.includes(preferredQuality)) {
    return preferredQuality;
  }
  return last(allowedQualities);
}

export function selectProgress(state) {
  return selectPlayerState(state).progress;
}

export function selectDuration(state) {
  return selectPlayerState(state).duration;
}

export function selectTrackingData(state) {
  return state.analyticsPlayback;
}

export function selectPlayerControlsEnabled(state) {
  return selectPlayerState(state).controlsEnabled;
}

export function selectPlayerIsPlayingAd(state) {
  return selectPlayerState(state).isPlayingAd;
}

export function selectPlayerIsPlaying(state) {
  return selectPlayerState(state).playing && !selectPlayerIsPlayingAd(state);
}

export function selectPlayerIsPaused(state) {
  return selectPlayerState(state).paused;
}

export function selectPlayerIsPlayingForPlayerBar(state) {
  return selectPlayerState(state).playing;
}
