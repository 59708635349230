// @flow
import update from 'immutability-helper';
import type { SetUrlAction } from '../../actions/client';

export type State = {|
  +authApiBase: ?string,
  +baseUrl: ?string,
  +easyConnectSdkUrlOrigin: ?string,
  +topDomain: ?string,
  +segmentCdnHost: ?string,
|};

const initialState = {
  authApiBase: null,
  baseUrl: null,
  topDomain: null,
  segmentCdnHost: null,
  easyConnectSdkUrlOrigin: null,
};

export default function urls(state: State = initialState, action: SetUrlAction): State {
  if (action.type === 'SET_AUTH_API_BASE') {
    return update(state, {
      authApiBase: { $set: action.url },
    });
  }

  if (action.type === 'SET_BASE_URL') {
    return update(state, {
      baseUrl: { $set: action.url },
    });
  }

  if (action.type === 'SET_EASY_CONNECT_SDK_URL_ORIGIN') {
    return update(state, {
      easyConnectSdkUrlOrigin: { $set: action.url },
    });
  }

  if (action.type === 'SET_TOP_DOMAIN') {
    return update(state, {
      topDomain: { $set: action.domain },
    });
  }

  if (action.type === 'SET_SEGMENT_CDN_HOST') {
    return update(state, {
      segmentCdnHost: { $set: action.host },
    });
  }

  return state;
}
