// @flow

import update from 'immutability-helper';
import { SUCCESS } from '../middleware/api';

import type { LoadProfileAction } from '../actions/profile';

export type State = {| artistIdBySlug: { [key: string]: number } |};

const initialState = {
  artistIdBySlug: {},
};

export default function indexes(state: State = initialState, action: LoadProfileAction): State {
  if (action.type === 'FETCH_PROFILE') {
    if (action.phase === SUCCESS && action.response) {
      const profileData = action.response.normalized;
      const artistId = profileData.result;
      const profileEntities = profileData.entities.profiles;
      if (profileEntities[artistId]) {
        const { slugs } = profileEntities[artistId];
        const slugsToId = slugs
          .filter(slug => !/^\d+$/.test(slug)) // not a stringified int id
          .reduce((acc, slug) => {
            return {
              ...acc,
              [slug]: artistId,
            };
          }, {});
        return update(state, {
          artistIdBySlug: { $merge: slugsToId },
        });
      }
    }
    return state;
  }

  (action: empty);
  return state;
}
