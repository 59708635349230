import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';

import IconLabel from '../util/IconLabel';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';
import ComposerList from '../common/ComposerList';
import classnames from 'classnames';

import * as Shapes from '../../shapes';

import stylesSearchMusicItem from './SearchMusicItem.css';
import stylesSearchResults from './SearchResults.css';

import shouldOmitForenameFromComposers from '../../utils/shouldOmitForenameFromComposers';

export default class SearchMusicItem extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    work: Shapes.Work.isRequired,
    piece: Shapes.Piece,
    performers: PropTypes.string,
    recordingId: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  renderTypeSpecificIcon() {
    const { type } = this.props;
    const classesIconComb = classnames(stylesSearchResults.icon, stylesSearchResults.thumbIcon);

    switch (type) {
      case 'recording':
        return (
          <div className={stylesSearchResults.thumb}>
            <IconLabel name="recording" className={stylesSearchResults.icon} />
          </div>
        );
      case 'piece':
      case 'work':
      default:
        return (
          <div className={stylesSearchResults.thumb}>
            <IconLabel name="composition" size="30px" className={classesIconComb} />
          </div>
        );
    }
  }

  renderTypeSpecificInformation() {
    const { type, work } = this.props;

    switch (type) {
      case 'piece':
        return (
          <div className={stylesSearchMusicItem.title}>
            <div className="search-result__work">
              <TitleWithPopularTitle {...work} />
            </div>
            <div className={stylesSearchMusicItem.piece}>
              <TitleWithPopularTitle {...this.props.piece} />
            </div>
          </div>
        );
      case 'recording':
        return (
          <span>
            <div className={stylesSearchMusicItem.title}>
              <TitleWithPopularTitle {...work} />
            </div>
            <div className={stylesSearchMusicItem.performer}>{this.props.performers}</div>
          </span>
        );
      case 'work':
      default:
        return (
          <div className={stylesSearchMusicItem.title}>
            <TitleWithPopularTitle {...work} />
          </div>
        );
    }
  }

  render() {
    const { work, recordingId, onClick, type, id } = this.props;

    let link;
    if (type === 'work') {
      link = `/works/${id}`;
    } else {
      link = `/recordings/${recordingId}`;
    }

    return (
      <Link to={link} className={stylesSearchResults.result} onClick={onClick}>
        {this.renderTypeSpecificIcon()}
        <span>
          <ComposerList
            authors={work.authors}
            omitForename={shouldOmitForenameFromComposers(work.authors)}
          />
          {this.renderTypeSpecificInformation()}
        </span>
      </Link>
    );
  }
}
