import { uniq } from 'lodash';
import { SEARCH_RESULT_FILTER_ENTRY_KINDS, SEARCH_RESULT_SECTION_FILTER_MAP } from '../constants';

function searchResultSorter(a, b) {
  if (a.score > b.score) {
    return -1;
  }
  if (a.score < b.score) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

function createWork(work) {
  const title = work.title;
  const composer = work.composers[0];

  return {
    id: work.id,
    title: title.title,
    popularTitle: title.popularTitle,
    composer: {
      name: `${composer.forename} ${composer.surname}`,
      ...composer,
    },
    authors: work.authors,
  };
}

function mapWork({ score, ...item }) {
  const { id, defaultRecordingId } = item;

  return {
    id,
    type: 'work',
    work: createWork(item),
    recordingId: defaultRecordingId,
    score: score,
  };
}

function mapPerson({ score, ...item }) {
  const id = item.id;

  return {
    id,
    type: 'person',
    functions: item.functions,
    composer: item.functions.indexOf('composer'),
    name: item.forename + ' ' + item.surname,
    score: score,
    path: `/${id}`,
  };
}

function mapEnsemble({ score, ...item }) {
  const id = item.id;

  return {
    id,
    type: 'ensemble',
    name: item.name,
    score: score,
    path: `/${id}`,
  };
}

function mapPiece({ score, ...item }) {
  const { id, title, workpart } = item;
  const defaultRecordingId = workpart.work.defaultRecordingId;

  return {
    id,
    type: 'piece',
    work: createWork(workpart.work),
    piece: {
      id,
      title: title.title,
      popularTitle: title.popularTitle,
    },
    recordingId: defaultRecordingId,
    score: score,
  };
}

function mapRecording({ score, ...item }) {
  const { id, work, interpreterSummary } = item;

  return {
    id,
    type: 'recording',
    work: createWork(work),
    performers: interpreterSummary,
    recordingId: id,
    score: score,
  };
}

function mapPlaylist(item) {
  return {
    ...item,
    type: 'playlist',
  };
}

function mapAlbum(item) {
  return {
    ...item,
    type: 'album',
  };
}

function mapEvent(item) {
  return {
    ...item,
    type: 'event',
  };
}

function mapTop(item) {
  switch (item.kind) {
    case 'person':
      return mapPerson(item);
    case 'ensemble':
      return mapEnsemble(item);
    case 'work':
      return mapWork(item);
    case 'recording':
      return mapRecording(item);
    case 'piece':
      return mapPiece(item);
    case 'playlist':
      return mapPlaylist(item);
    case 'album':
      return mapAlbum(item);
    case 'event':
      return mapEvent(item);
    default:
      // eslint-disable-next-line no-console
      console.log('Problem with result type:', item.kind);
      return null;
  }
}

function reformatSearchOrder(orderArr) {
  const reformatted = uniq(
    orderArr.map(word => {
      if (word === 'pieces' || word === 'works') {
        return 'compositions';
      }

      if (word === 'ensembles' || word === 'persons') {
        return 'artists';
      }

      return word;
    })
  );

  return ['top', ...reformatted];
}

function getFilters(results) {
  return uniq(
    Object.keys(SEARCH_RESULT_SECTION_FILTER_MAP).reduce((acc, type) => {
      const filter = SEARCH_RESULT_SECTION_FILTER_MAP[type];
      const hasResults = results[type] && results[type].length;
      const hasTopResults =
        results.top &&
        results.top.some(result => SEARCH_RESULT_FILTER_ENTRY_KINDS[filter].includes(result.type));

      if (hasResults || hasTopResults) {
        acc.push(filter);
      }
      return acc;
    }, [])
  );
}

export function normalizePopularSearches(json) {
  return json.results.map(mapTop);
}

export function normalizeSearchResult(json) {
  let { persons, ensembles } = json.artists;
  let { works, recordings, pieces } = json.music;
  let { playlists, albums, events } = json;
  const topHits = json.top_hits;
  const order = reformatSearchOrder(json.order);

  /* playlist, albums */
  playlists = (playlists || []).map(mapPlaylist);
  albums = (albums || []).map(mapAlbum);

  /* artists */
  persons = persons.map(mapPerson);
  ensembles = ensembles.map(mapEnsemble);

  const artists = [...persons, ...ensembles].sort(searchResultSorter);

  recordings = recordings.map(mapRecording);

  /* music */
  works = works.map(mapWork);
  pieces = pieces.map(mapPiece);
  const compositions = [...works, ...pieces].sort(searchResultSorter);

  /* events */
  events = events.map(mapEvent);

  /* top */
  const top = topHits.map(mapTop);

  const results = { top, artists, recordings, compositions, playlists, albums, events };
  const filters = getFilters(results);

  return {
    order,
    results,
    filters,
  };
}
