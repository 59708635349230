// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadPlaylistAction } from '../../actions/playlist';

export type State = {
  +[key: string]: {|
    +entities: string,
    +loaded: boolean,
    +loading: boolean,
  |},
};

const initialState = {};

export default function playlists(state: State = initialState, action: LoadPlaylistAction): State {
  if (action.type === 'FETCH_PLAYLIST' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
