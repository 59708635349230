import FontFaceObserver from 'fontfaceobserver';
import { setFontLoaded, setFontLoading } from '../actions/client';

const fonts = [
  {
    name: 'Eesti Display',
    className: 'eesti-display--loaded',
  },
];

export default function loadFonts(store) {
  store.dispatch(setFontLoading(fonts[0].name));
  const promises = fonts.map(font => {
    return new FontFaceObserver(font.name)
      .load()
      .then(() => {
        // document.documentElement.classList.add(font.className);
        store.dispatch(setFontLoaded(font.name));
      })
      .catch(() => {
        console.log(`${font.name} failed to load`); // eslint-disable-line no-console
      });
  });
  return Promise.all(promises);
}

// export default Promise.all(promises);
