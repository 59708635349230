import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { IbanElement } from 'react-stripe-elements';

import Notifications from '../notifications/Notifications';
import { getStripeElementsStyle } from './CardForm';

const messages = defineMessages({
  nameFieldPlaceholder: {
    id: 'account.payment.iban-placeholder',
    defaultMessage: 'YOUR NAME',
  },
});

class SEPAForm extends Component {
  static propTypes = {
    isInModal: PropTypes.bool.isRequired,
    onChangeName: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    style: getStripeElementsStyle(),
  };

  componentWillMount() {
    this.setState({ style: getStripeElementsStyle() });
  }

  render() {
    const { isInModal, intl } = this.props;
    return (
      <div>
        {isInModal && <Notifications />}
        <div className="c-form-field">
          <label className="c-form-field__label">
            <FormattedMessage id="account.payment.owner-name-label" defaultMessage="Name" />
          </label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={this.props.onChangeName}
            placeholder={intl.formatMessage(messages.nameFieldPlaceholder)}
            className="c-form-field__input payment-form__owner-name"
          />
        </div>
        <div className="c-form-field">
          <label className="c-form-field__label">
            <FormattedMessage id="account.payment.iban-label" defaultMessage="IBAN" />
          </label>
          <IbanElement
            supportedCountries={SEPAForm.SUPPORTED_COUNTRIES}
            className="c-form-field__input payment-form__iban"
            style={this.state.style}
          />
        </div>
      </div>
    );
  }

  static SUPPORTED_COUNTRIES = ['SEPA'];
}

export default injectIntl(SEPAForm);
