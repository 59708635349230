import React from 'react';
import { FormattedMessage } from 'react-intl';
import { selectUserIsPatron } from '../selectors/user';
import { selectSonosIsConnected } from '../selectors/sonos';
import { selectAudioQuality } from '../selectors/player';

import { pickSonosGroup } from '../actions/sonos';
import { setAudioQuality, loadFeatureFlags } from '../actions/client';

import { thisDevice } from '../reducers/sonos/connection';
import { dismissNotification } from '../actions/notifications';
import {
  selectExternalDevicesAllowed,
  selectSetQualityIsAllowed,
  selectFeatureFlag,
} from '../selectors/features';
import { showModal } from '../actions/ui';

/**
  When downsell happens mid-session, the feature flags need to be reloaded.
  While this takes care of the ui, it doesn't prevent currently accessed
  features outside of the ui to fall back. This needs to be handled on a
  case by case basis here. Case in point for reactivity.
*/
export async function handleDownSell(store) {
  await store.dispatch(loadFeatureFlags());
  const state = store.getState();

  const sonosIsAllowed = selectFeatureFlag(state, 'streaming_to_all_external_players');
  if (selectSonosIsConnected(state) && !sonosIsAllowed) {
    store.dispatch(pickSonosGroup(thisDevice));
  }

  const allowedQualities = selectFeatureFlag(state, 'audio_quality');
  const currentQuality = selectAudioQuality(state);
  const currentQualityNotAllowed = !allowedQualities.includes(parseInt(currentQuality, 10));
  if (currentQualityNotAllowed) {
    const highestAllowedQuality = [...allowedQualities].sort()[allowedQualities.length - 1];
    store.dispatch(setAudioQuality(highestAllowedQuality));
  }
}

function actionIsAllowed(store, action) {
  const state = store.getState();
  const { type } = action;
  if (type === 'SET_AUDIO_QUALITY' && !selectSetQualityIsAllowed(state, action.quality)) {
    store.dispatch(
      showModal(
        'PREMIUM_EXPERIENCE_PREVIEWS_MODAL',
        { trigger: 'audioQuality' },
        {
          trigger: 'audioQualityPreviewsModal',
          title: (
            <FormattedMessage
              id="audio-quality-previews-modal.header"
              defaultMessage="Want to upgrade your audio quality?"
            />
          ),
          subtitle: (
            <FormattedMessage
              id="audio-quality-previews-modal.subtitle"
              defaultMessage="Subscribe for a lossless audio quality."
            />
          ),
        }
      )
    );
    return false;
  }

  if (
    type === 'PICK_SONOS_GROUP' &&
    action.group.groupId !== 'this-device' &&
    !selectExternalDevicesAllowed(state)
  ) {
    store.dispatch(
      showModal(
        'PREMIUM_EXPERIENCE_PREVIEWS_MODAL',
        { trigger: 'deviceSelector' },
        {
          trigger: 'deviceSelectorPreviewsModal',
          title: (
            <FormattedMessage
              id="device-selector-previews-modal.header"
              defaultMessage="Want to connect your external devices?"
            />
          ),
          subtitle: (
            <FormattedMessage
              id="device-selector-previews-modal.subtitle"
              defaultMessage="Subscribe to connect to Sonos and other third party devices."
            />
          ),
        }
      )
    );
    return false;
  }

  return true;
}

export default store => next => action => {
  const state = store.getState();
  if (!actionIsAllowed(store, action)) {
    // eslint-disable-next-line no-console
    console.log('ACTION IS NOT ALLOWED', action);
    store.dispatch(dismissNotification('subscription'));
    // ^ clear notifications about subscriptions
    return;
  }

  // Next middleware(s), go!
  next(action);

  // Downgrade
  const userWasPatron = selectUserIsPatron(state);
  const nextState = store.getState();
  const userIsPatron = selectUserIsPatron(nextState);

  // TODO does this also include log outs? Should it be prevented?
  // We have two definitions. One in normalizeUser that writes to isPatron to the user state.
  // That one only checks for the user plan. There's also a selector, selectUserIsPatron which
  // checks the plan, authenticated and me.loaded
  if (userWasPatron && !userIsPatron) {
    handleDownSell(store);
  }
};
