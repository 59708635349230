import { QUEUE_TYPE_RECOMMENDATIONS } from '../constants';

import { selectPlayerIsPlaying } from './player';
import { selectIsQueueOriginSetAs } from './queue';

export function getQueueOrigin(slug) {
  return {
    type: QUEUE_TYPE_RECOMMENDATIONS,
    id: slug,
  };
}

export function selectRecommendationsIsQueued(state, slug) {
  const queueOrigin = getQueueOrigin(slug);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectRecommendationsIsPlaying(state, slug) {
  const isQueued = selectRecommendationsIsQueued(state, slug);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

export const selectRecommendations = state => {
  return state.lists.recommendations.entities;
};
