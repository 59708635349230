// @flow
import { combineReducers } from 'redux';

import queues from './queues';
import connection from './connection';

import type { SonosAction } from '../../actions/sonos';
import type { State as QueuesState } from './queues';
import type { State as ConnectionState } from './connection';

export type State = {
  +connection: ConnectionState,
  +queues: QueuesState,
};

const combinedReducer: (state: State, action: SonosAction) => State = combineReducers({
  connection,
  queues,
});

export default combinedReducer;
