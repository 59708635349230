export function getAdsCountToPlayNext(secondsSinceLastAd, nextTrackDuration, adInterval) {
  if (secondsSinceLastAd === 0 || !adInterval) {
    return 0;
  }

  if (secondsSinceLastAd >= adInterval) {
    return Math.floor(secondsSinceLastAd / adInterval);
  }

  if (secondsSinceLastAd + nextTrackDuration >= adInterval) {
    return 1;
  }

  return 0;
}
