import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import urlJoin from 'url-join';
import classnames from 'classnames';
import { uniq } from 'lodash';

import {
  selectSupportedLocales,
  selectLocale,
  selectBaseUrl,
  selectTheme,
  selectFonts,
} from '../../selectors/client';

import { DEFAULT_LOCALE, UI_THEME_DARK, UI_THEME_LIGHT } from '../../constants';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { FONTS } from '../../reducers/client/fonts';

function localeToLang(locale) {
  return locale.split('-')[0];
}

function getUrl() {
  if (__SERVER__) {
    return global.CURRENT_PAGE_URL;
  }

  return window.location.href;
}

const messages = defineMessages({
  metaTitle: {
    id: 'app.meta.title',
    defaultMessage: 'The Ultimate Classical Music Streaming App | IDAGIO',
  },
  metaDescription: {
    id: 'app.meta.description',
    defaultMessage:
      'The ultimate classical music collection in one app. Discover, browse and stream now on IDAGIO.',
  },
  metaKeywords: {
    id: 'app.meta.keywords',
    defaultMessage:
      'classical music app, classical music streaming, classical music collection, classical music streaming app',
  },
});

class Head extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    baseUrl: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,

    googleMusicAction: PropTypes.shape({
      type: PropTypes.string.isRequired,
      innerHTML: PropTypes.string.isRequired,
    }),
    supportedLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentLocale: PropTypes.string.isRequired,
    defaultLang: PropTypes.string.isRequired,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    currentCursor: PropTypes.string,
    noIndex: PropTypes.bool,
    canonicalRoute: PropTypes.string,
    intl: intlShape,
    fonts: PropTypes.object.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  static defaultProps = {
    title: 'The Ultimate Classical Music Streaming App | IDAGIO',
  };

  getPaginationUrl = cursor => {
    const { location } = this.context.router;
    const { pathname, search } = location;
    const { currentCursor, currentLocale, defaultLang, baseUrl } = this.props;
    const encodedCursor = encodeURIComponent(cursor);
    let newPathname = `/${pathname}/pages/${encodedCursor}`;

    if (currentCursor) {
      const base = pathname.split(`/${encodeURIComponent(currentCursor)}`)[0];
      newPathname = `/${base}/${encodedCursor}`;
    }

    const lang = '/' + currentLocale.split('-')[0];
    return `${baseUrl}${lang === '/' + defaultLang ? '' : lang}${newPathname}${search}`;
  };

  renderDescriptionMetaTags = () => {
    const description =
      this.props.description || this.props.intl.formatMessage(messages.metaDescription);
    return [
      <meta key="description" name="description" content={description} />,
      <meta key="og:description" property="og:description" content={description} />,
      <meta key="twitter:description" name="twitter:description" content={description} />,
    ];
  };

  renderTitleMetaTags = () => {
    const formattedTitle = this.props.title
      ? `${this.props.title} | IDAGIO`
      : this.props.intl.formatMessage(messages.metaTitle);
    return [
      <title key="title">{formattedTitle}</title>,
      <meta key="og:title" property="og:title" content={formattedTitle} />,
      <meta key="twitter:title" name="twitter:title" content={formattedTitle} />,
    ];
  };

  render() {
    const {
      baseUrl,
      googleMusicAction,
      supportedLocales,
      nextCursor,
      prevCursor,
      noIndex,
      canonicalRoute,
      intl,
      keywords,
    } = this.props;

    const supportedLanguages = uniq(
      supportedLocales
        .map(localeToLang)
        .filter(locale => locale !== localeToLang(this.props.currentLocale))
    );

    const keywordsContent = keywords || intl.formatMessage(messages.metaKeywords);

    const htmlClasses = classnames({
      'eesti-display--loaded': this.props.fonts[FONTS.EESTI_DISPLAY].loaded,
      'theme-dark': this.props.theme === UI_THEME_DARK,
      'theme-light': this.props.theme === UI_THEME_LIGHT,
      'is-electron': __ELECTRON__,
    });
    return (
      <Helmet>
        <html className={htmlClasses} />
        <meta name="twitter:card" content="summary" />
        {this.renderTitleMetaTags()}
        {this.renderDescriptionMetaTags()}
        <meta property="og:url" content={getUrl()} />
        <meta property="keywords" content={keywordsContent} />
        <meta name="robots" content={noIndex ? 'noindex' : 'all'} />
        {googleMusicAction && (
          <script type={googleMusicAction.type}>{googleMusicAction.innerHTML}</script>
        )}
        {supportedLanguages.map(this.langToTag)}
        {canonicalRoute && <link href={urlJoin(baseUrl, canonicalRoute)} rel="canonical" />}
        {nextCursor && <link href={this.getPaginationUrl(nextCursor)} rel="next" />}
        {prevCursor && <link href={this.getPaginationUrl(prevCursor)} rel="prev" />}
      </Helmet>
    );
  }

  langToTag = (lang, i) => {
    const origin = this.props.baseUrl;
    const { pathname, search } = this.context.router.location;
    const localeBase = lang === this.props.defaultLang ? '' : `/${lang}`;
    const path = pathname === '/' ? pathname : `/${pathname}`;
    const href = `${origin}${localeBase}${path}${search}`;
    return <link key={i} href={href} rel="alternate" hrefLang={lang} />;
  };
}

function mapStateToProps(state, ownProps) {
  const { canonicalRoute, location } = ownProps;
  const hasCanonical = Boolean(canonicalRoute);
  const hasParams = !!location && !!location.query && !!Object.keys(location.query).length;
  const noIndex = !hasCanonical && hasParams;
  return {
    supportedLocales: selectSupportedLocales(state),
    currentLocale: selectLocale(state),
    defaultLang: DEFAULT_LOCALE.split('-')[0],
    baseUrl: selectBaseUrl(state),
    fonts: selectFonts(state),
    theme: selectTheme(state),
    noIndex,
    ...ownProps,
  };
}

export default compose(withRouter, connect(mapStateToProps, {}), injectIntl)(Head);
