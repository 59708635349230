// @flow
// $FlowFixMe
import React, { useEffect } from 'react';
import type { ChildrenArray, Element } from 'react';
import { Experiment as MarvelAppExperiment, emitter } from '@marvelapp/react-ab-test';
import { selectTrackingToken } from '../../selectors/auth';
import { connect } from 'react-redux';
import * as analyticsActions from '../../actions/analytics';

type OwnProps = {
  name: string,
  children: ChildrenArray<Element<any>>,
  preselectedVariant?: string,
};

type MapStateToProps = {
  trackingToken: string,
};

type DispatchProps = {
  track: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const Experiment = ({ name, children, trackingToken, preselectedVariant, track }: Props) => {
  useEffect(() => {
    if (preselectedVariant) {
      emitter.setActiveVariant(name, preselectedVariant);
    }

    track('Experiment Viewed', {
      experimentName: name,
      variantName: emitter.getActiveVariant(name),
    });
  }, [preselectedVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MarvelAppExperiment
      name={name}
      userIdentifier={!preselectedVariant ? trackingToken : undefined}
    >
      {children}
    </MarvelAppExperiment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    trackingToken: selectTrackingToken(state),
  };
}

const dispatchProps: DispatchProps = {
  track: analyticsActions.track,
};

export default connect(mapStateToProps, dispatchProps)(Experiment);
