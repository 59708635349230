// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SearchSections from './SearchSections';
import LoadingIndicator from '../common/LoadingIndicator';
import styles from './SearchResults.css';

type Props = {
  isValidQuery?: boolean,
  results: Array<any>,
  idleResults?: Array<any>,
  loaded: boolean,
  onAccessed: Function,
  hideHeading?: boolean,
};

const SearchResults = ({
  isValidQuery,
  results,
  idleResults = [],
  loaded,
  onAccessed,
  hideHeading,
}: Props) => {
  const Results = () => (
    <SearchSections sections={results} onAccessed={onAccessed} hideHeading={hideHeading} />
  );

  const IdleResults = () => <SearchSections sections={idleResults} onAccessed={onAccessed} />;

  const NoResults = () => (
    <div className={styles.component}>
      <FormattedMessage
        id="search.no_results"
        defaultMessage="Sorry, we couldn't find anything. Please try a different search term."
      />
    </div>
  );

  const Loader = () => (
    <div className={styles.component}>
      <LoadingIndicator isLoading />
    </div>
  );

  let ComponentToRender = IdleResults;

  if (isValidQuery) {
    if (!loaded) {
      ComponentToRender = Loader;
    } else {
      ComponentToRender = results.some(section => section.results.length > 0) ? Results : NoResults;
    }
  }

  return <ComponentToRender />;
};

export default SearchResults;
