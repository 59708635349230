import { DEFAULT_LOOKAHEAD } from '../../utils/liveCompare';

export function loadLiveCompareAction(pieceId) {
  return {
    type: 'FETCH_LIVE_COMPARE',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/live-compare/{pieceId}',
      params: {
        pieceId,
      },
    },
    meta: {
      restricted: true,
      normalize: ({ result }) => ({
        piece: result.piece,
        work: result.work,
        recordings: result.recordings,
        composer: result.work.composer,
      }),
    },
  };
}

export function fetchInterpolationAction({
  fromTrackId,
  toTrackId,
  at,
  lookAhead = DEFAULT_LOOKAHEAD,
}) {
  return {
    type: 'FETCH_INTERPOLATION',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/interpolate?from={from}&to={to}&at={at}&length={length}',
      params: {
        from: fromTrackId,
        to: toTrackId,
        at,
        length: lookAhead,
      },
    },
  };
}
