// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconLabel from '../util/IconLabel';
import styles from './PaymentSuccessStep.css';
import stylesConversionForm from './ConversionFormVisual.css';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { getEntityUrl } from '../../lib/entities';
import { selectDiscoverPageIsLoaded, selectDiscoverPageResult } from '../../selectors/discover';
import { SURVEY_LINKS } from '../../constants';
import { selectLocale } from '../../selectors/client';
import { splitLocale } from '../../lib/locale-utils';

type OwnProps = {
  onComplete: Function,
  isInModal: boolean,
};

type MapStateToProps = {
  recommendationEntities: Array<{
    type: string,
    slug: string,
    imageUrl: string,
    title: string,
    subtitle?: string,
  }>,
  locale: string,
};

type Props = OwnProps & MapStateToProps;

const PaymentSuccessStep = ({ recommendationEntities, onComplete, isInModal, locale }: Props) => {
  const onRecommendationClick = () => {
    if (isInModal) {
      onComplete();
    }
  };

  return (
    <div
      className={stylesConversionForm.paymentSuccessGrid}
      data-test="conversion-form.payment-success-step-view"
    >
      <div className={styles.summary}>
        <IconLabel name="check" className={styles.checkIcon} />
        <h1>
          <FormattedMessage
            id="premium.payment-success.title"
            defaultMessage="Welcome to IDAGIO!"
          />
        </h1>
        <p className={styles.description}>
          <FormattedMessage
            id="premium.payment-success.description"
            defaultMessage="Your subscription is active now! Dive in and explore the unlimited world of classical music at your fingertips."
          />
        </p>
        <div className={styles.btns}>
          <a
            className="c-btn c-btn--is-inverted"
            href={SURVEY_LINKS[splitLocale(locale).language]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="premium.payment-success.btn.help-us-improve"
              defaultMessage="Help Us Improve"
            />
          </a>
          <a
            className="c-btn c-btn--has-outline"
            onClick={onComplete}
            data-test="payment-success.btn.start-listening"
          >
            <FormattedMessage
              id="premium.payment-success.btn.start-listening"
              defaultMessage="Start Listening"
            />
          </a>
        </div>
      </div>
      {recommendationEntities && recommendationEntities.length > 0 && (
        <div className={styles.recommendations}>
          {recommendationEntities.map(entity => {
            return (
              <Link
                key={entity.slug}
                className={styles.recommendation}
                to={getEntityUrl(entity.type, entity)}
                onClick={onRecommendationClick}
              >
                <div className={styles.content}>
                  <img src={entity.imageUrl} alt={entity.title} />
                  <div>
                    <span className={styles.title}>{entity.title}</span>
                    {entity.subtitle && <span className={styles.subtitle}>{entity.subtitle}</span>}
                  </div>
                </div>
                <IconLabel name="caret-right" />
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  let recommendationEntities = [];

  if (selectDiscoverPageIsLoaded(state, 'home')) {
    const { heroGroup } = selectDiscoverPageResult(state, 'home', true, true);
    if (heroGroup && heroGroup.items) {
      recommendationEntities = heroGroup.items.slice(0, 3).map(item => ({
        type: item.content.type,
        slug: item.content.slug,
        title: item.title,
        imageUrl: item.imageUrl,
        subtitle: item.subtitle,
      }));
    }
  }

  return {
    recommendationEntities,
    locale: selectLocale(state),
  };
}

export default connect(mapStateToProps)(PaymentSuccessStep);
