// @flow
import { handleListRequest } from './phasedActionHelpers';
import type { LoadRecentlyPlayedAction } from '../actions/recentlyPlayed';

type Action = LoadRecentlyPlayedAction;

export type State = {|
  +loading: boolean,
  +loaded: boolean,
|};

const initialState = {
  loading: false,
  loaded: false,
};

export default function recentlyPlayed(state: State = initialState, action: Action): State {
  if (action.type === 'FETCH_RECENTLY_PLAYED') {
    return handleListRequest(state, action);
  }

  (action: empty);
  return state;
}
