import { map, filter, keyBy, sortBy, mapValues, includes } from 'lodash';

export default function filterAndSortParams(params, validList) {
  const tuples = map(params, (param, key) => {
    return {
      key,
      param,
    };
  });
  const validParams = filter(tuples, ({ key }) => includes(validList, key));
  const validAndSortedParams = mapValues(
    keyBy(sortBy(validParams, 'key'), 'key'),
    ({ param }) => param
  );

  return validAndSortedParams;
}
