// @flow
// $FlowFixMe
import React, { useState } from 'react';
import qs from 'qs';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import RedirectInstruction from '../../lib/routing/RedirectInstruction';

import LoadingIndicator from '../common/LoadingIndicator';

import * as authActions from '../../actions/auth';

import AccountEmailLabel from '../messages/AccountEmailLabel';
import AccountLogin from '../messages/AccountLogin';

import styles from './LoginForm.css';
import { selectEmailAuthIsWorking, selectSocialAuthIsWorking } from '../../selectors/auth';
import { selectCaptchaType } from '../../selectors/client';
import CaptchaForm from './CaptchaForm';
import { CAPTCHA_ACTION_LOG_IN } from '../../constants';
import LoginForgot from '../messages/LoginForgot';
import { selectOpenModalTypeIs } from '../../selectors/modals';
import PasswordInput from './PasswordInput';
import AccountPasswordLabel from '../messages/AccountPasswordLabel';

type OwnProps = {
  className?: string,
  onAuthComplete?: Function,
  onClickForgotPasswordLink?: Function,
  location: Object,
};

type MapStateToProps = {
  emailAuthIsWorking: boolean,
  socialAuthIsWorking: boolean,
  captchaType: string,
  isInModal: boolean,
};

type DispatchProps = {
  authenticate: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const LoginForm = ({
  isInModal,
  className,
  onAuthComplete,
  onClickForgotPasswordLink,

  emailAuthIsWorking,
  socialAuthIsWorking,
  captchaType,
  authenticate,
  location,
}: Props) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitLogin = async captchaToken => {
    const type = isInModal ? 'Modal' : 'Page';

    try {
      setIsLoading(true);
      await authenticate(email, password, type, captchaType, captchaToken);
      if (onAuthComplete) {
        await onAuthComplete({ email, password });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err instanceof RedirectInstruction) {
        setIsRedirecting(true);
        return;
      }
      throw err;
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
  };

  const ForgotPasswordLink = () => {
    const query = { ...location.query, email };

    return (
      <Link
        className="c-text-link--is-visible"
        to={`/forgot-password?${qs.stringify(query)}`}
        onClick={onClickForgotPasswordLink}
      >
        <LoginForgot />
      </Link>
    );
  };

  const isLoadingOrRedirecting = emailAuthIsWorking || isRedirecting || isLoading;
  const formClassName = classnames(styles.form, className);
  const buttonClassName = classnames(styles.btn, 'c-btn', 'c-btn--is-purple');

  return (
    <CaptchaForm
      captchaAction={CAPTCHA_ACTION_LOG_IN}
      className={formClassName}
      submit={submitLogin}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <div>
        <div className={styles.fields}>
          <div className="c-form-field">
            <label htmlFor="email" className="c-form-field__label">
              <AccountEmailLabel />
            </label>
            <input
              autoFocus
              type="email"
              name="email"
              className="c-form-field__input"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={isLoadingOrRedirecting}
              data-test="login-form.email-input"
            />
          </div>
          <PasswordInput
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={isLoadingOrRedirecting}
            title={<AccountPasswordLabel />}
            data-test="login-form.password"
            className={styles.passwordInput}
          />
          <div className={styles.passwordLink}>
            <ForgotPasswordLink />
          </div>
        </div>
        <button
          className={buttonClassName}
          disabled={isLoadingOrRedirecting || socialAuthIsWorking || !email || !password}
          data-test="login-form.submit-btn"
          type="submit"
        >
          <LoadingIndicator isLoading={isLoadingOrRedirecting} className="hideText" />
          <AccountLogin />
        </button>
      </div>
    </CaptchaForm>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    emailAuthIsWorking: selectEmailAuthIsWorking(state),
    socialAuthIsWorking: selectSocialAuthIsWorking(state),
    captchaType: selectCaptchaType(state),
    isInModal: selectOpenModalTypeIs(state, 'LOGIN_MODAL'),
  };
}

const dispatchProps: DispatchProps = {
  authenticate: authActions.authenticate,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(LoginForm));
