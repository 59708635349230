import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { memoize } from 'lodash';

import * as collectionActions from '../../actions/collection';
import * as analyticsActions from '../../actions/analytics';

import stylesPlaylistCollection from './PlaylistCollection.css';

import {
  selectPlaylistCollection,
  selectCollectionIds,
  selectNextPlaylistCollectionCursor,
} from '../../selectors/collection';

import { selectFeatureFlag } from '../../selectors/features';
import dataComponent from '../../hoc/dataComponent';

import EmptyCollection from './EmptyCollection';
import CollectionArchiveHeader from './CollectionArchiveHeader';
import LimitedCollection from './LimitedCollection';
import PaginatedCollection from './PaginatedCollection';
import CollectionItem from './CollectionItem';

import { ENTITY_TYPE_PLAYLIST, ENTITY_TYPE_PLAYLISTS } from '../../constants';
import protectedComponent from '../../hoc/protectedComponent';
import shareableEntity, { shareableEntityPropTypes } from '../../hoc/shareableEntity';

class PlaylistCollection extends Component {
  static propTypes = {
    playlistCollection: PropTypes.array,
    limitedCollection: PropTypes.array,
    playlistIds: PropTypes.array,
    loadPlaylistCollection: PropTypes.func.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
    trackPlayPausePressed: PropTypes.func.isRequired,
    nextCursor: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    collectionAccessLimit: PropTypes.number,
    hasDisabledCollectionItems: PropTypes.bool.isRequired,
    ...shareableEntityPropTypes,
  };

  onItemClick = ({ content }) => {
    this.props.analyticsTrack('Selected Playlist from Collection', {
      id: content.id,
    });
  };

  renderPlaylistDisplayItem = playlist => {
    return (
      <CollectionItem
        collectionItem={playlist}
        entityType={ENTITY_TYPE_PLAYLIST}
        onItemClick={this.onItemClick}
        beforeTogglePlay={this.beforeTogglePlay}
        key={playlist.id}
      />
    );
  };

  renderLimitedCollectionInfo = () => {
    return (
      <React.Fragment>
        <h1 className="fz--beta">
          <FormattedMessage
            id="collection.playlist.limited-collection-playlist"
            defaultMessage="Playlists"
          />
        </h1>
        <p className="fz--delta">
          <FormattedMessage
            id="collection.playlist.limit-text"
            defaultMessage="You can save up to { count } curated playlists for free."
            values={{ count: this.props.collectionAccessLimit }}
          />
        </p>
      </React.Fragment>
    );
  };

  renderLimitedCollection() {
    return (
      <LimitedCollection
        listClassName={stylesPlaylistCollection.list}
        limitedCollection={this.props.limitedCollection}
        renderItem={this.renderPlaylistDisplayItem}
        renderInfo={this.renderLimitedCollectionInfo}
      />
    );
  }

  renderArchiveInfo() {
    return (
      <CollectionArchiveHeader>
        <FormattedMessage
          id="collection.playlists.previously-saved"
          defaultMessage="Your Previously Saved Playlists"
        />
      </CollectionArchiveHeader>
    );
  }

  renderPaginatedCollection() {
    const { playlistCollection, isLoading } = this.props;

    return (
      <PaginatedCollection
        loadMore={this.loadMore}
        hasMore={!!this.props.nextCursor}
        items={playlistCollection}
        renderItem={this.renderPlaylistDisplayItem}
        isLoading={isLoading}
        showShareModal={this.props.showShareModal}
      />
    );
  }

  renderEmptyPlaylistCollection() {
    return (
      <EmptyCollection
        entityType={ENTITY_TYPE_PLAYLIST}
        showShareModal={this.props.showShareModal}
      />
    );
  }

  render() {
    const { playlistIds, collectionAccessLimit, hasDisabledCollectionItems } = this.props;
    const shouldRenderPaginatedCollection = hasDisabledCollectionItems || !collectionAccessLimit;
    return (
      <React.Fragment>
        {playlistIds.length === 0 && this.renderEmptyPlaylistCollection()}
        {collectionAccessLimit && this.renderLimitedCollection()}
        {hasDisabledCollectionItems && this.renderArchiveInfo()}
        {shouldRenderPaginatedCollection && this.renderPaginatedCollection()}
      </React.Fragment>
    );
  }

  loadMore = () => {
    this.props.loadPlaylistCollection(this.props.nextCursor);
  };

  beforeTogglePlay = (item, playing) => {
    this.props.trackPlayPausePressed({ contextType: 'favouritePlaylists' }, playing);
  };
}

export const createGetLimitedCollection = () =>
  memoize((collection, limit) => {
    return collection.slice(0, limit);
  });

export const createGetCollectionArchive = () =>
  memoize((collection, limit) => {
    return collection.slice(limit).map(collectionItem => ({ ...collectionItem, isDisabled: true }));
  });

const getLimitedCollection = createGetLimitedCollection();
const getCollectionArchive = createGetCollectionArchive();

function mapStateToProps(state) {
  const collectionAccessLimit = selectFeatureFlag(state, 'collections').access_limit;
  const playlistCollection = selectPlaylistCollection(state);

  const hasDisabledCollectionItems =
    collectionAccessLimit && collectionAccessLimit < playlistCollection.length;

  return {
    isLoading: state.lists.collection.playlists.loading,
    playlistIds: selectCollectionIds(state).entities.playlistIds,
    limitedCollection:
      collectionAccessLimit && getLimitedCollection(playlistCollection, collectionAccessLimit),
    playlistCollection: hasDisabledCollectionItems
      ? getCollectionArchive(playlistCollection, collectionAccessLimit)
      : playlistCollection,
    nextCursor: selectNextPlaylistCollectionCursor(state),
    hasDisabledCollectionItems,
    collectionAccessLimit,
  };
}

function fetchData(store) {
  return store.dispatch(collectionActions.loadPlaylistCollection());
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Playlist',
    sharedContentId: null,
    contextType: 'favouritePlaylists',
    contentId: null,
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    loadPlaylistCollection: collectionActions.loadPlaylistCollection,
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
    analyticsTrack: analyticsActions.track,
  }),
  shareableEntity(ENTITY_TYPE_PLAYLISTS, getShareTrackingContext)
)(PlaylistCollection);
