import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import classnames from 'classnames';

import IconLabel from '../util/IconLabel';
import { selectBaseUrl } from '../../selectors/client';
import styles from './ShareButton.css';
import CapacitorRipple from '../capacitor/Ripple';

const messages = defineMessages({
  ariaButton: {
    id: 'aria-button-share',
    defaultMessage: 'Share',
  },
});

class ShareButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    hideText: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    intl: intlShape,
  };

  static defaultProps = {
    hideText: true,
  };

  render() {
    const { hideText, className, intl, text } = this.props;

    const buttonClassNames = classnames(styles.shareButton, className);

    const ariaButton = intl.formatMessage(messages.ariaButton);

    return (
      <button
        className={buttonClassNames}
        onClick={this.props.onClick}
        aria-label={ariaButton}
        title={text}
      >
        <CapacitorRipple version="center" />
        <IconLabel
          name={__CAPACITOR__ ? 'share-android' : 'share'}
          size={hideText ? 'default' : 'small'}
        />
        {!hideText && <span className={styles.text}>{text}</span>}
      </button>
    );
  }
}

function mapStateToProps(state) {
  return {
    baseUrl: selectBaseUrl(state),
  };
}

export default compose(injectIntl, connect(mapStateToProps))(ShareButton);
