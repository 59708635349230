// @flow

import * as cacheValidators from '../middleware/apiCacheValidators';
import { Recording, RecordingList } from '../schema';
import { normalizeMetaItem, normalizeMetaList } from '../schema/normalizeMeta';
import { normalizeRecording, normalizeRecordingList } from '../schema/normalizeRecording';

import singulariseParamObject from '../utils/singulariseParamObject';
import filterAndSortParams from '../utils/filterAndSortParams';
import getSerializedFilterId from '../utils/getSerializedFilterId';

import { VALID_RECORDING_FILTER_PARAMS } from '../constants';

import { loadAlbums } from './album.js';

import { selectRecording } from '../selectors/recording';

import type { Request, ThunkAction } from './types';

type LoadRecordingAction = { type: 'FETCH_RECORDING' } & Request;
export type LoadRecordingsByFilterAction = { type: 'FETCH_RECORDINGS_BY_FILTER' } & Request;
export type LoadRecordingsByWorkAction = { type: 'FETCH_RECORDINGS_BY_WORK' } & Request;

export type RecordingAction =
  | LoadRecordingAction
  | LoadRecordingsByFilterAction
  | LoadRecordingsByWorkAction;

export function loadRecording(recordingId: number): LoadRecordingAction {
  return {
    type: 'FETCH_RECORDING',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/recordings/{recordingId}',
      params: {
        recordingId,
      },
    },
    meta: {
      schema: Recording,
      normalize: response => normalizeRecording(normalizeMetaItem(response)),
    },
    cache: {
      fetch: state => state.entities.recordings[recordingId],
      validate: cacheValidators.loaded,
    },
  };
}

export function loadRecordingWithAlbums(recordingId: number): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(loadRecording(recordingId));
    const state = getState();
    const recording = selectRecording(state, recordingId);
    const albumIds = recording.albums;

    if (albumIds.length > 0) {
      dispatch(loadAlbums(albumIds, recordingId));
    }
  };
}

// TODO type filter params
export function loadRecordingsByFilter(
  params: Object,
  cursor?: string,
  limit?: number = 50
): LoadRecordingsByFilterAction {
  const validAndSortedParams = filterAndSortParams(params, VALID_RECORDING_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_RECORDING_FILTER_PARAMS);

  let paginatedParams = {
    ...singulariseParamObject(validAndSortedParams),
    limit,
  };

  if (cursor) {
    paginatedParams = {
      ...paginatedParams,
      cursor,
    };
  }

  return {
    type: 'FETCH_RECORDINGS_BY_FILTER',
    cacheId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/recordings/filter',
      params: paginatedParams,
    },
    meta: {
      schema: RecordingList,
      normalize: response => normalizeRecordingList(normalizeMetaList(response)),
    },
    cache: {
      fetch: state => state.maps.recordingsByFilter[cacheId],
      validate: cacheValidators.loadedCursor(cursor),
    },
  };
}

export type LoadRecordingsByFilter = typeof loadRecordingsByFilter;

export function loadRecordingsByWork(workId: string): LoadRecordingsByWorkAction {
  return {
    type: 'FETCH_RECORDINGS_BY_WORK',
    cacheId: workId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/recordings/filter',
      params: {
        work: workId,
      },
    },
    meta: {
      normalize: response => ({ result: normalizeRecordingList(normalizeMetaList(response)) }),
    },
    cache: {
      fetch: state => state.maps.recordingsByWork[workId],
      validate: cacheValidators.loaded,
    },
  };
}
