import { schema as entitySchema } from 'normalizr';
const { Entity } = entitySchema;

const isCompoundSchema = schema => !schema.schema;
// There is no better way to check with the API that normalizr currently exposes
const isArraySchema = schema => Array.isArray(schema); // Uglification will change the constructor name

function assignCache(target, cache) {
  Object.keys(target).forEach(key => {
    const value = target[key];
    if (value instanceof Array) {
      target[key] = value.map(item => {
        const isInCache = cache[item];
        return isInCache ? isInCache : item;
      });
    } else {
      const isInCache = cache[value];
      if (isInCache) {
        target[key] = isInCache;
      }
    }
  });
  return target;
}

function getKey(schema) {
  if (Array.isArray(schema)) {
    return schema[0].key;
  }
  return schema.key;
}

function assembleCache(schema, entities, id, key, cacheKeys, cache) {
  if (!id) {
    return null;
  }
  let cacheKey = key + '-' + id;
  if (isCompoundSchema(schema)) {
    const idsObject = id;
    const compoundKeys = Object.keys(schema).map(
      compoundKey => compoundKey + '-' + idsObject[compoundKey]
    );
    cacheKey = compoundKeys.join('+');
  }
  if (cacheKeys.indexOf(cacheKey) === -1) {
    cacheKeys.push(cacheKey);
    cache[cacheKey] = assembleEntityInner(schema, entities, id, cache, cacheKeys); // eslint-disable-line no-use-before-define
  }
  return cacheKey;
}

function assembleEntityInner(schema, entities, id, cache, cacheKeys) {
  let result = {};
  if (isCompoundSchema(schema)) {
    // Handle compound objects with no id (like soloist)
    const schemas = schema;
    const idsObject = id;
    Object.keys(schemas).forEach(key => {
      let foreignSchema = schemas[key];
      const foreignId = idsObject[key];
      if (Array.isArray(foreignId)) {
        foreignSchema = schema[key][0];
        result[key] = foreignId
          .filter(fid => fid) // eslint-disable-line no-shadow
          .map(fid => {
            // eslint-disable-line no-shadow
            return assembleCache(foreignSchema, entities, fid, key, cacheKeys, cache);
          });
      } else {
        result[key] = assembleCache(foreignSchema, entities, foreignId, key, cacheKeys, cache);
      }
    });
  } else {
    // Handle defined dependencies
    result = { ...entities[getKey(schema)][id] };
    Object.keys(schema.schema)
      .filter(key => key.substr(0, 1) !== '_') // remove normalizr specific keys
      .forEach(key => {
        let foreignSchema = schema.schema[key];
        if (foreignSchema instanceof Entity) {
          // Handle direct dependency
          const foreignId = result[key];
          result[key] = assembleCache(foreignSchema, entities, foreignId, key, cacheKeys, cache);
        } else {
          // Handle arrayOf dependency
          const foreignIds = result[key];
          foreignSchema = schema.schema[key][0];
          result[key] = []; // default value of empty array
          if (Array.isArray(foreignIds)) {
            result[key] = foreignIds
              .filter(fid => fid)
              .map(fid => {
                return assembleCache(foreignSchema, entities, fid, key, cacheKeys, cache);
              });
          }
        }
      });
  }

  return result;
}

export default function assembleEntity(schema, entities, id, cache = {}, cacheKeys = []) {
  if (isArraySchema(schema)) {
    if (!Array.isArray(id)) {
      return [];
    }
    const foreignSchema = schema[0];
    return id.map(foreignId => assembleEntity(foreignSchema, entities, foreignId));
  }

  const result = assembleEntityInner(schema, entities, id, cache, cacheKeys);
  // Traverse the cache, replace key values with other cache items
  Object.keys(cache).forEach(cacheKey => {
    cache[cacheKey] = assignCache(cache[cacheKey], cache);
  });

  // Traverse ret and replace key values with cache items
  return assignCache(result, cache);
}
