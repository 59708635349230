// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import type { IntlShape } from 'react-intl';
import classnames from 'classnames';
import ConsentManagerSelection from './ConsentManagerSelection';
import ConsentManagerSelectionTable from './ConsentManagerSelectionTable';
import { selectLocale } from '../../selectors/client';
import styles from './ConsentManager.css';
import { CONSTANT_MESSAGES } from '../../constants';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';
import * as clientActions from '../../actions/client';

const messages = defineMessages({
  privacyPolicyLink: {
    id: 'gdpr.opt-out-modal.privacy-policy-link',
    defaultMessage: 'privacy policy',
  },
  imprintLink: {
    id: 'gdpr.opt-out-modal.imprint-link',
    defaultMessage: 'imprint',
  },
});

type OwnProps = {
  hideModal?: Function,
  showSelection?: boolean,
};

type MapStateToProps = {
  currentLocale: string,
};

type DispatchProps = {
  saveConsent: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const ConsentManager = ({ hideModal, currentLocale, saveConsent, intl, ...props }: Props) => {
  const [showSelection, setShowSelection] = useState(props.showSelection || false);
  const [showDetails, setShowDetails] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    marketingAndAnalytics: false,
    functional: false,
    advertising: false,
  });

  const onPreferencesSaved = () => {
    if (hideModal) {
      hideModal();
    } else {
      window.location = '/';
    }
  };

  const onClickSettings = () => {
    setShowSelection(true);
  };

  const onAllowAllCookies = () => {
    saveConsent({
      necessary: true,
      marketingAndAnalytics: true,
      functional: true,
      advertising: true,
    });
    onPreferencesSaved();
  };

  const onAllowSelection = () => {
    saveConsent(preferences);
    onPreferencesSaved();
  };

  const onSelectionChange = selection => {
    setPreferences(selection);
  };

  const onToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const buttonClassName = classnames('c-btn');
  const purpleButtonClassName = classnames('c-btn', 'c-btn--is-purple');
  const linkButtonClassName = classnames('c-text-link--is-visible');

  const privacyPolicyText = intl.formatMessage(messages.privacyPolicyLink);
  const ariaPrivacyPolicyLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: privacyPolicyText,
  });

  const imprintText = intl.formatMessage(messages.imprintLink);
  const ariaImprintLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: imprintText,
  });

  return (
    <section className={styles.container} data-test="cookie-banner.modal">
      <div className={styles.header}>
        <h2>
          <FormattedMessage
            id="gdpr.opt-out-modal.cookie-settings"
            defaultMessage="Cookie Settings"
          />
        </h2>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.content}>
          <p>
            <FormattedMessage
              defaultMessage="We use cookies to recognise your repeat visits, enable product functionality, and improve our marketing. This includes cookies that are necessary for the operation of the site, as well as cookies that are used only for anonymous statistical purposes, for storing your personal settings or to display personalized content. You can decide which categories you want to allow. Please note that based on your settings, not all functionalities of the site may be available."
              id="gdpr.opt-out-modal.info-1"
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="Further information can be found in our {privacyPolicyLink} and {imprintLink}."
              id="gdpr.opt-out-modal.info-2"
              values={{
                privacyPolicyLink: (
                  <a
                    href={getWebsiteUrlInLocale('/privacy#cookies', currentLocale)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-text-link--is-visible"
                    aria-label={ariaPrivacyPolicyLink}
                  >
                    {privacyPolicyText}
                  </a>
                ),
                imprintLink: (
                  <a
                    href={getWebsiteUrlInLocale('/imprint', currentLocale)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-text-link--is-visible"
                    aria-label={ariaImprintLink}
                  >
                    {imprintText}
                  </a>
                ),
              }}
            />
          </p>
        </div>

        {showSelection && (
          <div className={styles.selectionWrapper}>
            {!showDetails ? (
              <ConsentManagerSelection selection={preferences} onChange={onSelectionChange} />
            ) : (
              <ConsentManagerSelectionTable selection={preferences} onChange={onSelectionChange} />
            )}
          </div>
        )}

        <div className={styles.footer}>
          {showSelection ? (
            <button className={buttonClassName} onClick={onAllowSelection}>
              <FormattedMessage
                defaultMessage="Allow Selection"
                id="gdpr.opt-out-modal.allow-selection"
              />
            </button>
          ) : (
            <button className={buttonClassName} onClick={onClickSettings}>
              <FormattedMessage
                defaultMessage="Cookie Settings"
                id="gdpr.opt-out-modal.cookie-settings"
              />
            </button>
          )}
          <button
            className={purpleButtonClassName}
            onClick={onAllowAllCookies}
            data-test="cookie-banner.allow-all-btn"
          >
            <FormattedMessage
              defaultMessage="Allow All Cookies"
              id="gdpr.opt-out-modal.allow-all-cookies"
            />
          </button>
          {showSelection && (
            <button className={linkButtonClassName} onClick={onToggleDetails}>
              {!showDetails ? (
                <FormattedMessage id="gdpr.opt-out-modal.details" defaultMessage="Details" />
              ) : (
                <FormattedMessage id="gdpr.opt-out-modal.cancel" defaultMessage="Cancel" />
              )}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    currentLocale: selectLocale(state),
  };
}

const dispatchProps: DispatchProps = {
  saveConsent: clientActions.saveCookiesConsent,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(ConsentManager);
