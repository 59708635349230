// @flow
import React from 'react';

import styles from './SocialLoginButtons.css';
import { FormattedMessage } from 'react-intl';

type Props = {
  onClick: Function,
  disabled?: boolean,
};

const AppleButton = ({ onClick, disabled }: Props) => {
  return (
    <button className={styles.AppleButton} onClick={onClick} disabled={disabled}>
      <svg viewBox="0 0 24 30" className={styles.icon}>
        <path
          d="M20.0440682,15.6591509 C20.0854875,20.1196543 23.9571077,21.6039974 24,21.6229432 C23.9672636,21.7276272 23.3813797,23.7382775 21.9602466,25.8151356 C20.7317395,27.6106846 19.4567112,29.3996562 17.4481649,29.4367073 C15.4745673,29.4730727 14.8399455,28.2663589 12.5835647,28.2663589 C10.3278654,28.2663589 9.62277178,29.3996532 7.75454181,29.4730727 C5.81578507,29.5464384 4.33944217,27.5314242 3.10072574,25.7424527 C0.569558735,22.083051 -1.36477658,15.4018432 1.23254681,10.8919191 C2.52284167,8.65227237 4.8286961,7.23403459 7.33149492,7.19766652 C9.23530448,7.16135115 11.0322744,8.4784861 12.1960973,8.4784861 C13.3591823,8.4784861 15.5428833,6.8945159 17.8385275,7.12714194 C18.7995626,7.16714114 21.497204,7.51534573 23.2294336,10.0508802 C23.0898586,10.1374062 20.0105953,11.9300067 20.0440682,15.6591551 M16.3349196,4.70612843 C17.3642185,3.46020299 18.0569961,1.72575955 17.8679989,0 C16.3843417,0.0596297086 14.5902669,0.988667897 13.5260727,2.23390787 C12.5723503,3.33662586 11.7371039,5.10159633 11.9624678,6.79319936 C13.6161779,6.92114468 15.30557,5.95284621 16.3349196,4.70613046"
          fill="#ffffff"
        />
      </svg>
      <span className={styles.text}>
        <FormattedMessage
          id="login.options.continue-with-apple"
          defaultMessage="Continue with Apple"
        />
      </span>
    </button>
  );
};

export default AppleButton;
