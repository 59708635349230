import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Modal from './Modal';
import * as uiActions from '../../actions/ui';

import Ok from '../messages/Ok';
import { selectOpenModalTypeIs } from '../../selectors/modals';
import stylesPlaybackSupportModal from './PlaybackSupportModal.css';

class PlaybackSupportModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  render() {
    const { hideModal, isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequesthideModal={hideModal}
        onClose={hideModal}
        contentLabel="lossless-support-modal"
      >
        <section className={stylesPlaybackSupportModal.modal}>
          <p>
            <FormattedMessage
              id="playback-not-supported-modal.text"
              defaultMessage="Playback is not supported in your current browser. We recommend you download {chromeLink}."
              values={{
                chromeLink: (
                  <a
                    className="c-text-link--is-visible"
                    href="https://www.google.com/chrome/browser/desktop/"
                    target="_black"
                  >
                    Chrome
                  </a>
                ),
              }}
            />
          </p>
          <button className="c-text-link--is-visible" onClick={hideModal}>
            <Ok />
          </button>
        </section>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'PLAYBACK_SUPPORT_MODAL'),
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
})(PlaybackSupportModal);
