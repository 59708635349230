// @flow
import update from 'immutability-helper';
import type { MeAction } from '../actions/me';
import type { LoadEventJWTAction } from '../actions/livestreamEvents';

type Action = LoadEventJWTAction | MeAction;
export type State = {|
  tickets: [],
|};

const initialState = {
  tickets: [],
};

export default function livestreamEventsTickets(
  state: State = initialState,
  action: Action
): State {
  const { phase, type } = action;
  if (phase !== 'SUCCESS' || !action.response) {
    return state;
  }

  switch (type) {
    case 'LOAD_EVENT_JWT': {
      const normalized = action.response.normalized;
      return update(state, {
        tickets: {
          $set: normalized.user.tickets,
        },
      });
    }

    case 'FETCH_ME': {
      const normalized = action.response.normalized;
      const userId = normalized.result;
      const user = normalized.entities.users[userId];
      return update(state, {
        tickets: {
          $set: user.tickets,
        },
      });
    }
    default:
      return state;
  }
}
