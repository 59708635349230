import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import IconLabel from '../util/IconLabel';
import CapacitorRipple from '../capacitor/Ripple';
import Notifications from '../notifications/Notifications';

import styles from './Modal.css';

class Modal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    shouldCloseOnOverlayClick: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
  };

  /*
  Optional context passed down from the `ModalHarness` component which is used
  inside of vladbook.
  See: http://reactcommunity.org/react-modal/#custom-parent
   */
  static contextTypes = {
    parentSelector: PropTypes.func,
  };

  render() {
    const { onClose, hideCloseButton } = this.props;

    return (
      <ReactModal
        className={classnames(styles.modal, { 'is-capacitor': __CAPACITOR__ })}
        portalClassName={styles.portal}
        bodyOpenClassName={styles.bodyOpen}
        overlayClassName={styles.overlay}
        onRequestClose={onClose}
        parentSelector={this.context.parentSelector}
        {...this.props}
      >
        <Notifications />
        {!hideCloseButton && (
          <div className={styles.mobileHeaderBar}>
            <button onClick={onClose} className={styles.close} data-test="modal.close">
              <CapacitorRipple version="center" />
              <IconLabel name="close" title="Close" />
            </button>
          </div>
        )}

        <section className={styles.modalContentWrapper}>
          <div className={styles.modalContentInner}>{this.props.children}</div>
        </section>
      </ReactModal>
    );
  }
}

export default Modal;
