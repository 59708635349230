import createFilter, { createBlacklistFilter } from 'redux-persist-transform-filter';
import { createTransform } from 'redux-persist';
import { identity } from 'lodash';

const persistClientStateFilter = createFilter('client', [
  'preferences',
  'annotations',
  'recentSearches',
  'hasInteractedWithSlider',
]);

// this migrates persisted recent searches to use the authors field
export const appendAuthorsToRecentSearches = createTransform(
  identity,
  state => {
    return {
      ...state,
      recentSearches: {
        results: state.recentSearches.results.map(res => {
          if (!res.work || res.work.authors) {
            return res;
          }
          return {
            ...res,
            work: {
              ...res.work,
              authors: [],
            },
          };
        }),
      },
    };
  },
  { whitelist: ['client'] }
);

const removeInterpretationQueueTransform = createTransform(
  identity,
  state => ({
    ...state,
    interpretationQueue: {},
  }),
  { whitelist: ['player'] }
);

const sonosQueueMigrationTransform = createTransform(
  identity,
  state => {
    if (!state.queues && !state.connection) {
      return {
        connection: {
          sessionId: state.sessionId,
          groups: state.groups,
          connectedGroup: state.connectedGroup,
          isConnected: false,
        },
        queues: {},
      };
    }
    return state;
  },
  { whitelist: ['sonos'] }
);

const persistPlayerStateBlacklistFilter = createBlacklistFilter('player', [
  'playing',
  'paused',
  'interpretationQueue',
  'controlsEnabled',
  'wasPlayingOnNetworkError',
  'seeking',
  'queueIsLoading',
  'queueIsLoaded',
  'loadingQueueOrigin',
]);

const sonosStateFilter = createFilter('sonos', [
  'connection.sessionId',
  'connection.groups',
  'connection.connectedGroup',
  'queues',
]);

const adsStateFilter = createFilter('ads', [
  'secondsSinceLastAd',
  'currentlyPlayingAdId',
  'currentlyPlayingAd',
  'currentAdQueue',
]);

const stripeStateFilter = createFilter('stripe', ['revalidatedAt', 'usedTokens']);

const liveCompareFilter = createFilter('liveCompare', ['inactivityTooltipDismissed']);

export default {
  whitelist: [
    'client',
    'player',
    'analyticsProgressBuffer',
    'recentlyPlayed',
    'sonos',
    'stripe',
    'ads',
    'liveCompare',
  ],
  transforms: [
    persistClientStateFilter,
    persistPlayerStateBlacklistFilter,
    appendAuthorsToRecentSearches,
    sonosStateFilter,
    removeInterpretationQueueTransform,
    stripeStateFilter,
    adsStateFilter,
    sonosQueueMigrationTransform,
    liveCompareFilter,
  ],
};

export const embedConfig = {
  keyPrefix: 'embed',
  whitelist: ['analyticsProgressBuffer'],
};
