import { schema } from 'normalizr';
const { Entity } = schema;

// schemas
export const Profile = new Entity('profiles');
export const Ensemble = new Entity('ensembles');
export const Epoch = new Entity('epochs');
export const Genre = new Entity('genres');
export const Instrument = new Entity('instruments');
export const Mood = new Entity('moods');
export const Person = new Entity('persons');
export const Piece = new Entity('pieces');
export const Playlist = new Entity('playlists');
export const Mix = new Entity('mixes');
export const Album = new Entity('albums');
export const Recording = new Entity('recordings');
export const RecordingType = new Entity('recordingTypes');
export const Track = new Entity('tracks');
export const User = new Entity('users');
export const Work = new Entity('works');
export const Workpart = new Entity('workparts');
export const PersonalPlaylist = new Entity('personalPlaylists');
export const Ad = new Entity('ads');
export const SonosTrack = new Entity('sonosTracks');
export const SonosQueue = new Entity('sonosQueues');
export const ContentEntry = new Entity('content');

export const getAuthorGroupId = (authorGroup, work) =>
  authorGroup.authorType.toLowerCase() + work.id;
export const AuthorGroup = new Entity('authorGroups', {}, { idAttribute: getAuthorGroupId });

export const LivestreamEvent = new Entity('livestreamEvents');
export const MyEvent = new Entity('myEvents');

// ArraySchemas
export const EnsembleList = [Ensemble];
export const EpochList = [Epoch];
export const GenreList = [Genre];
export const InstrumentList = [Instrument];
export const MoodList = [Mood];
export const PersonList = [Person];
export const RecordingList = [Recording];
export const TrackList = [Track];
export const PlaylistList = [Playlist];
export const WorkList = [Work];
export const AlbumList = [Album];
export const AdsList = [Ad];
export const ArtistList = [Profile];
export const LivestreamEventList = [LivestreamEvent];
export const MyEventsList = [MyEvent];

// Compound Schema
export const Soloist = {
  person: Person,
  instrument: Instrument,
};

AuthorGroup.define({ persons: [Person] });

// Schema definitions
Work.define({
  authors: [AuthorGroup],
  composer: Person,
  workparts: [Workpart],
  genre: Genre,
  epoch: Epoch,
});

Workpart.define({
  work: Work,
  pieces: [Piece],
});

Piece.define({
  workpart: Workpart,
});

Recording.define({
  work: Work,
  conductor: Person,
  recordingType: RecordingType,
  soloists: [Soloist],
  ensembles: [Ensemble],
  tracks: [Track],
});

Track.define({
  piece: Piece,
  recording: Recording,
});

Playlist.define({
  tracks: TrackList,
});

PersonalPlaylist.define({
  tracks: TrackList,
});

export const PersonalPlaylistList = [new Entity('personalPlaylists')];

Mix.define({
  tracks: TrackList,
});

Album.define({
  tracks: TrackList,
});

SonosQueue.define({
  tracks: [SonosTrack],
});

export const paramKeyToSchema = {
  works: [Work],
  composers: [Person],
  ensembles: [Ensemble],
  conductors: [Person],
  soloists: [Person],
  recordingTypes: [RecordingType],
  instruments: [Instrument],
  genres: [Genre],
  epochs: [Epoch],
  artists: [Person],
  myEvents: [MyEvent],
};
