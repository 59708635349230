/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Shapes from '../shapes';

import {
  pause,
  play,
  rewind,
  setQueue,
  setQueueAndPlay,
  setCurrentQueueItem,
  createItem,
  getItemById,
} from '../actions/player';
import { selectPlayerCurrentQueueItem, selectPlayerIsPlayingAd } from '../selectors/player';
import { trackPlayPausePressed } from '../actions/analytics';
import { flatten, map } from 'lodash';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

// Component prop types as defined on the connected HOC
const propTypes = {
  originId: PropTypes.string.isRequired,
  tracks: PropTypes.arrayOf(Shapes.Track).isRequired,
  trackIds: Shapes.TrackIds.isRequired,

  isPlaying: PropTypes.bool.isRequired,
  isQueued: PropTypes.bool.isRequired,
  queueItem: PropTypes.object,

  setQueueAndPlay: PropTypes.func.isRequired,
  setQueue: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  rewind: PropTypes.func.isRequired,
  trackPlayPausePressed: PropTypes.func.isRequired,

  queues: PropTypes.array, // optional for nested queues
};

// Exported component prop types for the encapsulated component
export const queueOriginComponentPropTypes = {
  ...propTypes,

  isQueuedTrack: PropTypes.func.isRequired,
  isPlayingTrack: PropTypes.func.isRequired,
  rewindTrack: PropTypes.func.isRequired,
  playFromTrack: PropTypes.func.isRequired,
  togglePlayAll: PropTypes.func.isRequired,
};

function constructNestedQueueOrigin(getQueueOrigin, originId, queues) {
  return {
    ...getQueueOrigin(originId),
    queues: queues.map(({ originId, trackIds }) => ({
      originId,
      length: trackIds.length,
    })),
  };
}

// HOC Definition
export default function queueOriginComponent(
  getQueueOrigin,
  mapQueueOriginStateToProps,
  getPlaybackContext = () => ({}),
  options = {}
) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class QueueOriginComponent extends Component {
      static propTypes = propTypes;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            ref={withRef ? 'wrappedInstance' : null}
            rewindTrack={this.rewindTrack}
            playFromTrack={this.playFromTrack}
            togglePlayAll={this.togglePlayAll}
            isQueuedTrack={this.isQueuedTrack}
            isPlayingTrack={this.isPlayingTrack}
          />
        );
      }

      isQueuedTrack = (trackId, index) => {
        const { isQueued, queueItem } = this.props;
        return (
          isQueued &&
          queueItem &&
          queueItem.track.toString() === trackId.toString() &&
          queueItem.index === index
        );
      };

      isPlayingTrack = (trackId, index) => {
        return this.isQueuedTrack(trackId, index) && this.props.isPlaying;
      };

      playNested = (trackId, isQueued) => {
        const timestamp = Date.now();
        const { originId, isPlaying, play, pause, setQueueAndPlay, trackPlayPausePressed, queues } =
          this.props;

        // flatten the tracks from the queues
        const trackIds = flatten(map(queues, ({ trackIds }) => trackIds));

        trackPlayPausePressed(getPlaybackContext(this.props), isPlaying);

        if (isQueued) {
          if (isPlaying) {
            pause();
          } else {
            play('internal', { timestamp });
          }
        } else {
          const nested = constructNestedQueueOrigin(getQueueOrigin, originId, queues);
          const tracks = trackIds.map(createItem);
          setQueueAndPlay(nested, tracks, getItemById(trackId, tracks), true, 'internal', {
            timestamp,
          });
        }
      };

      play = (trackId, isQueued, index) => {
        const timestamp = Date.now();
        const {
          trackIds,
          originId,
          isPlaying,
          play,
          pause,
          setQueueAndPlay,
          trackPlayPausePressed,
          queues,
          collectionType,
        } = this.props;

        if (queues) {
          this.playNested(trackId, isQueued);
          return;
        }

        trackPlayPausePressed(getPlaybackContext(this.props), isPlaying);

        if (isQueued) {
          if (isPlaying) {
            pause();
          } else {
            play('internal', {
              timestamp,
              selectedIndividualTrack: typeof trackId !== 'undefined',
            });
          }
        } else {
          setQueueAndPlay(
            getQueueOrigin(originId, collectionType),
            trackIds.map(createItem),
            trackId ? createItem(trackId, index) : undefined,
            true,
            'internal',
            { timestamp, selectedIndividualTrack: typeof trackId !== 'undefined' }
          );
        }
      };

      playFromTrack = (trackId, index) => {
        const trackIsQueued = this.isQueuedTrack(trackId, index);
        this.play(trackId, trackIsQueued, index);
      };

      rewindTrack = () => {
        this.props.rewind();
      };

      togglePlayAll = () => {
        const { isQueued } = this.props;
        this.play(undefined, isQueued);
      };
    }

    QueueOriginComponent.displayName = `QueueOriginComponent(${getDisplayName(WrappedComponent)}`;

    QueueOriginComponent.WrappedComponent = WrappedComponent;

    function mapStateToProps(state, ownProps) {
      return {
        queueItem: selectPlayerCurrentQueueItem(state),
        isPlayingAd: selectPlayerIsPlayingAd(state),

        ...mapQueueOriginStateToProps(state, ownProps),
        ...ownProps,
      };
    }

    return connect(mapStateToProps, {
      setQueue,
      setCurrentQueueItem,
      setQueueAndPlay,
      pause,
      play,
      rewind,
      trackPlayPausePressed,
    })(QueueOriginComponent);
  };
}
