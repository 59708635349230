import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import Modal from './Modal';
import * as uiActions from '../../actions/ui';
import styles from './VoucherSuccessModal.css';
import { selectOpenModalTypeIs } from '../../selectors/modals';
import IconLabel from '../util/IconLabel';

class VoucherSuccessModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  render() {
    const { hideModal, isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal
        isOpen={isOpen}
        onClose={hideModal}
        onRequestClose={hideModal}
        contentLabel="voucher-success-modal"
      >
        <section className={styles.modal} data-test="voucher-success.modal">
          <h1 className={styles.headline}>
            <FormattedMessage id="voucher.success-modal" defaultMessage="Success" />
          </h1>
          <p className={styles.subline}>
            <FormattedMessage
              id="voucher.success-modal.paragraph"
              defaultMessage="You've successfully activated your voucher."
            />
            <br />
            <FormattedMessage
              id="voucher.success-modal-paragraph.enjoy"
              defaultMessage="Enjoy listening!"
            />
          </p>
          <div className={styles.icon}>
            <div className={styles.iconControls}>
              <IconLabel className={styles.iconSkip} name="skip-back" />
              <div className={styles.iconCircle}>
                <IconLabel className={styles.iconCheck} name="check" size="36px" />
              </div>
              <IconLabel className={styles.iconSkip} name="skip-forward" />
            </div>
          </div>
          <button
            className={classnames('c-btn', 'c-btn--is-purple', styles.button)}
            onClick={hideModal}
          >
            <FormattedMessage id="voucher.success-modal-cta" defaultMessage="Get Started" />
          </button>
        </section>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'VOUCHER_SUCCESS_MODAL'),
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
})(VoucherSuccessModal);
