// @flow

import type { SetClientIPAction } from '../../actions/client';

export type State = ?string;

export default function ip(state: State = null, action: SetClientIPAction) {
  if (action.type === 'SET_CLIENT_IP') {
    return action.ip;
  }

  (action: empty);
  return state;
}
