// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PlanPrice from './PlanPrice';
import styles from './PlanDetails.css';

type OwnProps = {
  title?: React$Element<typeof FormattedMessage> | string,
  monthlyPrice: number,
  relativeDiscount?: number,
  objectives: Array<{
    key: string,
    value: React$Element<typeof FormattedMessage> | string,
  }>,
  priceTag?: React$Element<any>,
};

type Props = OwnProps;

const PlanDetails = ({ title, objectives, monthlyPrice, relativeDiscount, priceTag }: Props) => {
  return (
    <div className={styles.container}>
      {title && <h2 className="fz--gamma">{title}</h2>}
      <PlanPrice
        price={monthlyPrice}
        relativeDiscount={relativeDiscount}
        interval="monthly"
        className={styles.price}
        priceTag={priceTag}
      />
      <ul className={styles.objectives}>
        {objectives.map(({ key, value }) => (
          <li key={key}>{value}</li>
        ))}
      </ul>
    </div>
  );
};

export default PlanDetails;
