import {
  selectUserActiveCreditEffectOfType,
  selectUserHasEligibleCredits,
  selectUserIsAllowedOptOutTrial,
  selectUserIsAuthenticated,
} from './user';

export function selectOfferDisplayName(state) {
  return state.subscriptionOffer.displayNName;
}

export function selectOfferTrackingName(state) {
  return state.subscriptionOffer.trackingName;
}

export function selectSubscriptionPrice(state) {
  return state.subscriptionOffer.price;
}

export function selectSubscriptionFromId(state) {
  return state.subscriptionOffer.fromId;
}

export function selectSubscriptionRelativeDiscount(state) {
  if (selectUserHasEligibleCredits(state)) {
    // Check StripeCoupon credits
    const creditCouponEffect = selectUserActiveCreditEffectOfType(state, 'StripeCoupon');
    if (creditCouponEffect) {
      return creditCouponEffect.relativeDiscount;
    }
  }

  if (state.subscriptionOffer.relativeDiscount) {
    return state.subscriptionOffer.relativeDiscount;
  }

  return null;
}

export function selectSubscriptionReducedPriceDuration(state) {
  if (selectUserHasEligibleCredits(state)) {
    // Check StripeCoupon credits
    const couponDealEffect = selectUserActiveCreditEffectOfType(state, 'StripeCoupon');
    if (couponDealEffect) {
      return couponDealEffect.duration;
    }
  }

  return state.subscriptionOffer.reducedPriceDuration;
}

export function selectSubscriptionTrialDurationDays(state) {
  if (selectUserHasEligibleCredits(state)) {
    const durationEffect = selectUserActiveCreditEffectOfType(state, 'StripeTrialDuration');

    if (durationEffect && typeof durationEffect.days === 'number') {
      return Number(durationEffect.days);
    }
  }

  if (selectUserIsAuthenticated(state) && !selectUserIsAllowedOptOutTrial(state)) {
    return 0;
  }

  return state.subscriptionOffer.trialDurationInDays;
}
