// @flow

import { SUCCESS } from '../../middleware/api';

import type { LoadImageAnnotationsAction } from '../../actions/client';

type ImageAnnotations = { [key: string]: { v: string } };

export type State = {
  +lastLoaded: ?string,
  +image: ?ImageAnnotations,
};

const initialState = {
  lastLoaded: null,
  image: null,
};

export default function annotations(
  state: State = initialState,
  action: LoadImageAnnotationsAction
): State {
  if (action.type === 'LOAD_IMAGE_ANNOTATIONS') {
    if (action.phase === SUCCESS && action.response) {
      const imageAnnotations = action.response.normalized.result.annotations.image;
      return {
        ...state,
        lastLoaded: action.requestedAt,
        image: imageAnnotations,
      };
    }
    return state;
  }

  (action: empty);
  return state;
}
