// @flow
import update from 'immutability-helper';
import type { SetCaptchaAction } from '../../actions/client';

export type State = {|
  +captchaType: ?string,
  +captchaSiteKey: ?string,
|};

const initialState = {
  captchaType: null,
  captchaSiteKey: null,
};

export default function captchas(state: State = initialState, action: SetCaptchaAction): State {
  if (action.type === 'SET_CAPTCHA_TYPE') {
    return update(state, {
      captchaType: { $set: action.captchaType },
    });
  }

  if (action.type === 'SET_CAPTCHA_SITE_KEY') {
    return update(state, {
      captchaSiteKey: { $set: action.captchaSiteKey },
    });
  }

  return state;
}
