import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { filter } from 'lodash';

import styles from '../facet/Facet.css';

class Separated extends PureComponent {
  static propTypes = {
    children: PropTypes.array,
    separator: PropTypes.node,
  };

  static defaultProps = {
    separator: ', ',
  };

  renderChildren(children) {
    let nodeKey = 0;
    const renderNode = node => (
      <span className={styles.facetPart} key={nodeKey++}>
        {node}
      </span>
    );

    const nonEmptyChildren = filter(children, child => child);

    const elements = [];

    nonEmptyChildren.forEach((child, index) => {
      if (index > 0 && index < children.length) {
        elements.push(renderNode(this.props.separator));
      }

      elements.push(renderNode(child));
    });

    return elements;
  }

  render() {
    return this.renderChildren(this.props.children);
  }
}

export default Separated;
