// @flow
import React from 'react';
import styles from './LivestreamEventImage.css';
import { LIVESTREAM_EVENT_IMAGE_DIMS } from '../../constants';
import BookedTag from '../livestream-event-preview/BookedTag';
import UpcomingTag from '../livestream-event-preview/UpcomingTag';
import livestreamEventComponent from '../../hoc/livestreamEventComponent';
import classNames from 'classnames';
import ContentfulImage from '../util/ContentfulImage';
import Image from '../util/Image';

type OwnProps = {
  event: Object,
  userHasTicket: boolean,
  children?: React$Element<any>,
  isHeroItem?: boolean,
  isSliderItem?: boolean,
};

const LivestreamEventImage = ({
  event,
  userHasTicket,
  children,
  isHeroItem,
  isSliderItem,
}: OwnProps) => {
  const { imageUrl, isUpcoming } = event;

  const className = classNames(styles.figure, {
    [styles.heroImage]: isHeroItem,
    [styles.sliderImage]: isSliderItem,
  });

  return (
    <figure role="none" className={className}>
      {imageUrl ? (
        <ContentfulImage
          src={imageUrl}
          options={{
            fm: 'jpg',
            fl: 'progressive',
            fit: 'thumb',
            f: 'top_left',
            h: LIVESTREAM_EVENT_IMAGE_DIMS.h,
            w: LIVESTREAM_EVENT_IMAGE_DIMS.w,
          }}
        />
      ) : (
        <Image
          src="/assets/web/live-fallback.jpg"
          width={LIVESTREAM_EVENT_IMAGE_DIMS.w}
          height={LIVESTREAM_EVENT_IMAGE_DIMS.h}
        />
      )}
      {userHasTicket && <BookedTag />}
      {isUpcoming && <UpcomingTag />}
      {children}
    </figure>
  );
};

export default livestreamEventComponent()(LivestreamEventImage);
