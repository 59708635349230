import PropTypes from 'prop-types';

export const Person = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  forename: PropTypes.string,
  surname: PropTypes.string,
});

export const Category = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
});

export const Soloist = PropTypes.shape({
  person: Person,
  instrument: Category,
});

export const RecordingDate = PropTypes.shape({
  fromKeyword: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
});

export const Location = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const RecordingVenue = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  location: Location,
});

export const Ensemble = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  alternativeNames: PropTypes.arrayOf(PropTypes.string),
});

export const Profile = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  alternativeNames: PropTypes.arrayOf(PropTypes.string),
});

export const Piece = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  popularTitle: PropTypes.string,
  subtitle: PropTypes.string,
  structuralSubtitle: PropTypes.string,
});

export const Track = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  duration: PropTypes.number.isRequired,
  piece: Piece.isRequired,
  recording: Recording, // eslint-disable-line no-use-before-define
});

export const TrackIds = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

export const RecordingTag = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
});

export const Recording = PropTypes.shape({
  summary: PropTypes.string,
  conductor: Person,
  soloists: PropTypes.arrayOf(Soloist),
  ensembles: PropTypes.arrayOf(Ensemble),
  tracks: PropTypes.arrayOf(Track),
  recordingDate: RecordingDate,
  venue: RecordingVenue,
  location: Location,
  isPremium: PropTypes.bool,
  tags: PropTypes.arrayOf(RecordingTag),
});

export const Workpart = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  isOverture: PropTypes.bool,
  pieces: PropTypes.arrayOf(Piece),
});

export const Work = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  popularTitle: PropTypes.string,
  subtitle: PropTypes.string,
  structuralSubtitle: PropTypes.string,
  epoch: Category,
  genre: Category,
  composer: Person,
  workparts: PropTypes.arrayOf(Workpart),
});

export const WorkFacet = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  popularTitle: PropTypes.string, // TODO: Ask why this keeps coming back null
});

export const Facet = PropTypes.shape({
  composer: Person,
  works: PropTypes.arrayOf(WorkFacet),
});

export const Playlist = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  trackIds: TrackIds.isRequired,
  tracks: PropTypes.arrayOf(Track).isRequired,
});

export const PersonalPlaylist = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tracks: PropTypes.arrayOf(Track).isRequired,
});

export const LinkShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
});

export const CollectionIds = PropTypes.shape({
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  trackIds: PropTypes.array,
  recordingIds: PropTypes.array,
  playlistIds: PropTypes.array,
  albumIds: PropTypes.array,
  artistIds: PropTypes.array,
  livestreamEventIds: PropTypes.array,
});

export const LivestreamEventVideo = PropTypes.shape({
  name: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  showControls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  hideYoutubeLogo: PropTypes.bool,
});

export const LivestreamEvent = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  pusherChannel: PropTypes.string,
  video: LivestreamEventVideo,

  // decorated
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  isLive: PropTypes.bool,
  isUpcoming: PropTypes.bool,
  hasEnded: PropTypes.bool,
});
