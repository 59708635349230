import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { ConsentManagerSelectionOption } from './ConsentManagerSelectionOption';
import { messages } from './data/translations';

class ConsentManagerSelection extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    selection: PropTypes.shape({
      necessary: PropTypes.bool.isRequired,
      marketingAndAnalytics: PropTypes.bool.isRequired,
      functional: PropTypes.bool.isRequired,
      advertising: PropTypes.bool.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    checkboxes: {
      necessary: {
        key: 'necessary',
        disabled: true,
        checked: this.props.selection.necessary,
        label: messages.necessary,
      },
      marketingAndAnalytics: {
        key: 'marketingAndAnalytics',
        disabled: false,
        checked: this.props.selection.marketingAndAnalytics,
        label: messages.marketingAndAnalytics,
      },
      functional: {
        key: 'functional',
        disabled: false,
        checked: this.props.selection.functional,
        label: messages.functional,
      },
      advertising: {
        key: 'advertising',
        disabled: false,
        checked: this.props.selection.advertising,
        label: messages.advertising,
      },
    },
  };

  onStateUpdate = () => {
    this.props.onChange(this.getSelection(this.state.checkboxes));
  };

  onCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(
      prevState => ({
        checkboxes: {
          ...prevState.checkboxes,
          [name]: {
            ...prevState.checkboxes[name],
            checked: !prevState.checkboxes[name].checked,
          },
        },
      }),
      this.onStateUpdate
    );
  };

  getSelection = checkboxes => {
    return Object.keys(checkboxes)
      .map(key => checkboxes[key]) // turn to array
      .reduce((acc, { key, checked }) => {
        return {
          ...acc,
          [key]: checked,
        };
      }, {});
  };

  renderCheckbox = key => {
    const { disabled, checked, label } = this.state.checkboxes[key];
    return (
      <ConsentManagerSelectionOption
        key={key}
        name={key}
        disabled={disabled}
        checked={checked}
        onChange={this.onCheckboxChange}
        label={this.props.intl.formatMessage(label)}
      />
    );
  };

  render() {
    return Object.keys(this.state.checkboxes).map(this.renderCheckbox);
  }
}

export default injectIntl(ConsentManagerSelection);
