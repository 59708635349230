// @flow

import update from 'immutability-helper';

import type { LogoutAction, SetAccessTokenAction } from '../../actions/auth';
import type { InvalidateTrackUrlAction, FetchTrackUrlAction } from '../../actions/trackUrls';

type Action = LogoutAction | SetAccessTokenAction | InvalidateTrackUrlAction | FetchTrackUrlAction;

export type State = {
  +[key: string]: {|
    +expireAt: number,
    +loaded: boolean,
    +loadedAt: number,
    +requestedAt: number,
    +loading: boolean,
    +url: string,
  |},
};

const initialState = {};

export default function trackUrls(state: State = initialState, action: Action) {
  if (action.type === 'LOGOUT') {
    return initialState;
  }

  if (action.type === 'INVALIDATE_TRACK_URL') {
    return update(state, {
      [action.compoundId]: { $set: null },
    });
  }

  if (action.type === 'FETCH_TRACK_URL') {
    const { compoundId } = action;
    switch (action.phase) {
      case 'REQUEST':
        return update(state, {
          [compoundId]: {
            $set: {
              loading: true,
              loaded: false,
              requestedAt: action.requestedAt,
            },
          },
        });
      case 'SUCCESS':
        return update(state, {
          [compoundId]: {
            $set: {
              loading: false,
              loaded: true,
              url: action.trackUrl,
              loadedAt: action.loadedAt,
              expireAt: action.expireAt,
              requestedAt: state[action.compoundId].requestedAt,
            },
          },
        });
      case 'FAILURE':
        return update(state, {
          [compoundId]: {
            $set: {
              loading: false,
              loaded: false,
            },
          },
        });
      default:
        return state;
    }
  }

  if (action.type === 'SET_ACCESS_TOKEN') {
    return initialState;
  }

  (action: empty);
  return state;
}
