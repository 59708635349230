// @flow

import { handleListRequest } from '../phasedActionHelpers';

import type { LoadRadioAction } from '../../actions/radio';
import type { Radio } from '../../shapes/types';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +entities: Array<Radio>,
|};

const initialState = {
  loading: false,
  loaded: false,
  entities: [],
};

export default function radios(state: State = initialState, action: LoadRadioAction): State {
  if (action.type === 'FETCH_RADIO') {
    return handleListRequest(state, action);
  }

  (action: empty);
  return state;
}
