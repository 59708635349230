// @flow
import update from 'immutability-helper';
import type { IdentifyUserAgentAction } from '../../actions/client';

export type State = {
  +isMobile: boolean,
  +isDesktop: boolean,
  +isiPhone: boolean,
  +isMac: boolean,
  +isWindows: boolean,
  +isAndroid: boolean,
  +isChrome: boolean,
  +isSafari: boolean,
  +isFirefox: boolean,
};

const initialState = {
  isDesktop: false,
  isMobile: false,
  isiPhone: false,
  isMac: false,
  isWindows: false,
  isAndroid: false,
  isChrome: false,
  isSafari: false,
  isFirefox: false,
};

export default function userAgentInfo(
  state: State = initialState,
  action: IdentifyUserAgentAction
): State {
  if (action.type === 'IDENTIFY_USER_AGENT') {
    const {
      isMobile,
      isDesktop,
      isMac,
      isWindows,
      isAndroid,
      isiPhone,
      isChrome,
      isSafari,
      isFirefox,
    } = action.userAgentInfo;
    return update(state, {
      isMobile: { $set: isMobile },
      isiPhone: { $set: isiPhone },
      isMac: { $set: isMac },
      isWindows: { $set: isWindows },
      isAndroid: { $set: isAndroid },
      isDesktop: { $set: isDesktop },
      isChrome: { $set: isChrome },
      isSafari: { $set: isSafari },
      isFirefox: { $set: isFirefox },
    });
  }

  return state;
}
