// @flow
import { findIndex } from 'lodash';

import type { SonosAction } from '../../actions/sonos';
import type { SonosGroup } from '../../components/player/SonosGroup';
import type { SonosGroupsArray } from '../../components/player/SonosGroups';
import type { PlayerSetQueueAction } from '../../actions/player';

export type State = {
  +groups: SonosGroupsArray,
  +connectedGroup: string,
  +isConnected: boolean,
  +sessionId: ?string,
};

export const thisDevice: SonosGroup = {
  address: '',
  groupId: 'this-device',
  groupName: '',
  householdId: '',
};

const initState = {
  groups: [thisDevice],
  connectedGroup: 'this-device',
  isConnected: false,
  sessionId: null,
};

export default function connection(
  state: State = initState,
  action: SonosAction | PlayerSetQueueAction
): State {
  switch (action.type) {
    case 'REPLACE_SONOS_GROUPS':
      return {
        ...state,
        groups: [thisDevice, ...action.groups],
      };
    case 'SET_CONNECTED_SONOS_GROUP':
      if (action.groupId === 'this-device') {
        return {
          ...state,
          connectedGroup: action.groupId,
          isConnected: false,
        };
      }
      return {
        ...state,
        connectedGroup: action.groupId,
        isConnected: true,
      };
    // Avoids being able to rejoin a session with non matching queues
    case 'PLAYER_QUEUE_SET':
      if (state.isConnected) {
        return state;
      }
      return { ...state, queueId: null };
    case 'SET_SONOS_SESSION_ID':
      return { ...state, sessionId: action.sessionId };
    case 'UPDATE_SONOS_GROUP_NAME': {
      const connectedGroupId = state.connectedGroup;
      const connectedGroupIndex = findIndex(
        state.groups,
        group => group.groupId === connectedGroupId
      );
      /*
      Protect from corner case where user tries to change name of group
      that is not in the list of discovered groups yet.
    */
      if (connectedGroupIndex >= 0) {
        const newGroups = [...state.groups];
        newGroups[connectedGroupIndex].groupName = action.groupName;
        return {
          ...state,
          groups: newGroups,
        };
      }
      return state;
    }
    default:
      return state;
  }
}
