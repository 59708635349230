// @flow

import { handleListRequest } from '../phasedActionHelpers';

import type { LoadPeriodsAction } from '../../actions/period';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
|};

const initialState = {
  loading: false,
  loaded: false,
};

export default function epochs(state: State = initialState, action: LoadPeriodsAction) {
  if (action.type === 'FETCH_EPOCHS') {
    return handleListRequest(state, action, result => {
      return result;
    });
  }

  (action: empty);
  return state;
}
