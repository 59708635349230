import { checkStripeValidationValidity } from '../actions/stripe';

import { selectStripeStatus } from '../selectors/user';

import { SUCCESS } from './api';

function getInterval(dispatch) {
  return window.setInterval(() => {
    dispatch(checkStripeValidationValidity());
  }, 60 * 1000);
}

export default store => {
  let interval;
  const initialState = store.getState();
  const initialStripeStatus = selectStripeStatus(initialState);

  // User was loaded from server with an active stripe status
  if (initialStripeStatus) {
    interval = getInterval(store.dispatch);
  }

  return next => action => {
    if (action.type === 'FETCH_ME' && action.phase === SUCCESS) {
      const prevState = store.getState();
      next(action);
      const state = store.getState();
      const prevStripeStatus = selectStripeStatus(prevState);
      const stripeStatus = selectStripeStatus(state);
      // User stripe status set during session
      if (!interval && stripeStatus) {
        interval = getInterval(store.dispatch);
      }

      // Revalidation succeeded
      if (prevStripeStatus && !stripeStatus) {
        clearInterval(interval);
        interval = undefined;
      }
    } else {
      next(action);
    }
  };
};
