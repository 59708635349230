// @flow
import { normalizeSearchResult, normalizePopularSearches } from '../schema/normalizeSearch';
import * as cacheValidators from '../middleware/apiCacheValidators';

import type { Request } from './types';

export type LoadQuickSearchAction = {
  type: 'FETCH_QUICK_SEARCH_RESULTS',
} & Request;
export type LoadSearchResultsAction = {
  type: 'FETCH_SEARCH_RESULTS',
  query: string,
} & Request;
type LoadSearchAction = LoadQuickSearchAction | LoadSearchResultsAction;
export type LoadPopularSearchesAction = {
  type: 'FETCH_POPULAR_SEARCHES',
} & Request;
export type SearchRequestAction = LoadSearchAction | LoadPopularSearchesAction;

export type ResetQuickSearchAction = { type: 'RESET_QUICK_SEARCH' };
export type ResetSearchAction = { type: 'RESET_SEARCH' };
export type CompleteSearchAction = {
  type: 'COMPLETE_SEARCH',
  selected: { id: number | string },
  section: string,
};
export type CompleteQuickSearchAction = {
  type: 'COMPLETE_QUICK_SEARCH',
  selected: { id: number | string },
};
export type SetQuickSearchQueryAction = { type: 'SET_QUICK_SEARCH_QUERY', query: string };
export type SetQuickSearchActiveFilterAction = {
  type: 'SET_QUICK_SEARCH_ACTIVE_FILTER',
  filter: string,
};
export type SetSearchActiveFilterAction = {
  type: 'SET_SEARCH_ACTIVE_FILTER',
  filter: string,
};

export type SearchAction =
  | ResetQuickSearchAction
  | ResetSearchAction
  | CompleteSearchAction
  | CompleteQuickSearchAction
  | SetQuickSearchQueryAction
  | SetQuickSearchActiveFilterAction
  | SetSearchActiveFilterAction;

export function loadSearch(
  query: string,
  full: boolean = true
): LoadSearchAction | ResetSearchAction | ResetQuickSearchAction {
  if (!query) {
    if (full) {
      return { type: 'RESET_SEARCH' };
    }
    return { type: 'RESET_QUICK_SEARCH' };
  }

  const fullParam = full ? { full: 'true' } : {};

  if (full) {
    return {
      type: 'FETCH_SEARCH_RESULTS',
      query: query,
      IDAGIO_REQUEST: {
        type: 'API_REQUEST',
        method: 'GET',
        endpoint: '/search.v1',
        params: {
          q: query,
          ...fullParam,
        },
      },
      meta: {
        normalize: normalizeSearchResult,
      },
    };
  }

  return {
    type: 'FETCH_QUICK_SEARCH_RESULTS',
    query: query,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/search.v1',
      params: {
        q: query,
        ...fullParam,
      },
    },
    meta: {
      normalize: normalizeSearchResult,
    },
  };
}

export function loadQuickSearch(
  query: string
): LoadSearchAction | ResetSearchAction | ResetQuickSearchAction {
  return loadSearch(query, false);
}

export function loadPopularSearches(): LoadPopularSearchesAction {
  return {
    type: 'FETCH_POPULAR_SEARCHES',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/popular.v1',
    },
    meta: {
      normalize: normalizePopularSearches,
    },
    cache: {
      fetch: state => state.popularSearches,
      validate: cacheValidators.loaded,
    },
  };
}

export function completeSearch(
  selected: { id: number | string },
  section: string
): CompleteSearchAction {
  return {
    type: 'COMPLETE_SEARCH',
    selected,
    section,
  };
}

export function completeQuickSearch(
  selected: { id: number | string },
  section: string
): CompleteQuickSearchAction {
  return {
    type: 'COMPLETE_QUICK_SEARCH',
    selected,
    section,
  };
}

export function setQuickSearchQuery(query: string): SetQuickSearchQueryAction {
  return {
    type: 'SET_QUICK_SEARCH_QUERY',
    query,
  };
}

export function setQuickSearchActiveFilter(filter: string): SetQuickSearchActiveFilterAction {
  return {
    type: 'SET_QUICK_SEARCH_ACTIVE_FILTER',
    filter,
  };
}

export function setSearchActiveFilter(filter: string): SetSearchActiveFilterAction {
  return {
    type: 'SET_SEARCH_ACTIVE_FILTER',
    filter,
  };
}
