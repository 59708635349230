import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';

import { selectUrlToFonts, selectLocale } from '../../selectors/client';

import PaymentForm from './PaymentForm';

import { splitLocale } from '../../lib/locale-utils';

class StripePaymentForm extends PureComponent {
  static propTypes = {
    language: PropTypes.string.isRequired,
    urlToFonts: PropTypes.string.isRequired,
  };

  render() {
    const { language, urlToFonts, ...rest } = this.props;
    return (
      <Elements
        locale={language}
        fonts={[
          {
            family: 'Eesti Display',
            src: urlToFonts,
          },
        ]}
      >
        <PaymentForm {...rest} />
      </Elements>
    );
  }
}

function mapStateToProps(state) {
  return {
    urlToFonts: selectUrlToFonts(state),
    language: splitLocale(selectLocale(state)).language,
  };
}

export default connect(mapStateToProps, {})(StripePaymentForm);
