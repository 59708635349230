import React from 'react';
import { FormattedMessage } from 'react-intl';

import LiveIndicator from '../livestream-event/LiveIndicator';

const LiveText = () => {
  return (
    <div>
      <LiveIndicator />
      <FormattedMessage id="availability-info.live-now" defaultMessage="Online now" />
    </div>
  );
};

export default LiveText;
