// @flow
// $FlowFixMe
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import LoadingIndicator from './LoadingIndicator';
import styles from './BookletViewer.css';
import { selectAdobeApiKey } from '../../selectors/client';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  bookletUrl: string,
};

type MapStateToProps = {
  adobeApiKey: string,
};

type Props = OwnProps & MapStateToProps;

const BookletViewer = ({ bookletUrl, adobeApiKey }: Props) => {
  const randomId = useRef(Math.random().toString(36).substring(7));
  const [isLoading, setIsLoading] = useState(true);

  const loadPdfViewer = useCallback(async () => {
    setIsLoading(false);
    const adobeDCView = new window.AdobeDC.View({
      clientId: adobeApiKey,
      divId: randomId.current,
    });

    adobeDCView.previewFile(
      {
        content: {
          location: {
            url: bookletUrl,
          },
        },
        metaData: { fileName: 'Booklet.pdf' },
      },
      { showAnnotationTools: false, showDownloadPDF: !__CAPACITOR__, showPrintPDF: false }
    );
  }, [bookletUrl, adobeApiKey]);

  useEffect(() => {
    // We want to load the adobe script dynamically since it's pretty heavy (~50kb)
    // (we can't use helmet since we might be in capacitor mode)
    if ('AdobeDC' in window) {
      loadPdfViewer();
    } else {
      const script = document.createElement('script');
      script.onload = () => {
        document.addEventListener('adobe_dc_view_sdk.ready', loadPdfViewer);
      };
      script.src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';
      if (document.head) {
        document.head.appendChild(script);
      }
    }
  }, [loadPdfViewer]);

  return (
    <div className={styles.viewer}>
      {isLoading && (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator isLoading />
        </div>
      )}
      <div id={randomId.current} style={{ display: isLoading ? 'none' : 'block' }} />
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    adobeApiKey: selectAdobeApiKey(state),
  };
}

export default connect(mapStateToProps)(BookletViewer);
