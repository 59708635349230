import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { filter, uniqBy } from 'lodash';
import * as Shapes from '../../shapes';
import { Link } from 'react-router';
import Separated from '../util/Separated';

export default class SoloistList extends Component {
  static propTypes = {
    soloists: PropTypes.arrayOf(Shapes.Soloist).isRequired,
    hyperlinks: PropTypes.bool,
    linkClassNames: PropTypes.string,
  };

  static defaultProps = {
    hyperlinks: false,
  };

  renderPerformers(soloists, hyperlinks, linkClassNames) {
    // only handle soloists that have a person and instrument defined
    const completeSoloists = filter(soloists, ({ person, instrument }) => {
      return person !== null && instrument !== null;
    });
    return uniqBy(completeSoloists, ({ person, instrument }) => `${instrument.id}${person.id}`) // Removes soloist duplicates if soloist plays multiple roles
      .map(({ person, instrument }) => {
        const caption = this.fullCaption(person.name, instrument);

        if (hyperlinks) {
          return (
            <Link key={person.id} to={`profiles/${person.id}`} className={linkClassNames}>
              {caption}
            </Link>
          );
        }

        return caption;
      });
  }

  render() {
    const { soloists, hyperlinks, linkClassNames } = this.props;

    if (!soloists.length) return null;

    return <Separated>{this.renderPerformers(soloists, hyperlinks, linkClassNames)}</Separated>;
  }

  fullCaption(name, instrument) {
    const parts = [name];
    instrument && parts.push(`(${instrument.title})`);

    return parts.join(' ');
  }
}
