// @flow

import { handleListRequest } from '../phasedActionHelpers';

import type { LoadRecommendationsAction } from '../../actions/recommendations';
import type { Recommendations } from '../../shapes/types';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +entities: Array<Recommendations>,
|};

const initialState = {
  loading: false,
  loaded: false,
  entities: [],
};

export default function recommendations(
  state: State = initialState,
  action: LoadRecommendationsAction
): State {
  if (action.type === 'FETCH_RECOMMENDATIONS') {
    return handleListRequest(state, action);
  }

  (action: empty);
  return state;
}
