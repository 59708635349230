import { selectRecording } from './recording';
import { createSelector } from 'reselect';
import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

export const selectTrack = createSelector(
  [(state, id) => id, state => state.entities, (state, id, hydrate) => hydrate],
  (id, entities, hydrate) => {
    if (!entities.tracks[id]) {
      return null;
    }

    if (hydrate) {
      return assembleEntity(Schema.Track, entities, id);
    }

    return entities.tracks[id];
  }
);

export function selectTrackIsLoaded(state, trackId) {
  if (selectTrack(state, trackId)) {
    return true;
  }

  return false;
}

export function selectTrackIsPremium(state, trackId) {
  const track = selectTrack(state, trackId);
  if (!track) {
    return false;
  }

  const recording = selectRecording(state, track.recording, false);
  if (!recording) {
    return false;
  }

  return recording.isPremium;
}
