import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { selectClientDownloadUrl, selectClientName } from '../../selectors/client';
import * as analyticsActions from '../../actions/analytics';
import styles from './DownloadAppButton.css';
import { CONSTANT_MESSAGES } from '../../constants';

const messages = defineMessages({
  downloadApp: {
    id: 'download-page.cta',
    defaultMessage: 'Download app',
  },
});

class DownloadAppButton extends React.PureComponent {
  static propTypes = {
    track: PropTypes.func.isRequired,
    page: PropTypes.string.isRequired,

    downloadUrl: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,

    className: PropTypes.string,

    intl: intlShape,
  };

  onDownloadClick = () => {
    const { track, page, clientName } = this.props;
    const eventProps = {
      client: clientName,
      source: page,
    };
    track('Clicked on Download Client', eventProps);
  };

  static renderIcon(clientName) {
    if (clientName === 'Windows' || clientName === 'macOS') {
      return (
        <svg
          className={styles.ctaSvg}
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
        >
          <g>
            <polygon points="15,18 16,20 8,20 9,18" />
            <path d="M20,14V6H4v8H20z M22,6v8c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h16C21.1,4,22,4.9,22,6z" />
          </g>
        </svg>
      );
    }

    return null;
  }

  static renderText(clientName) {
    if (!clientName) {
      return <FormattedMessage id="download-page.cta" defaultMessage="Download app" />;
    }
    return (
      <FormattedMessage
        id="download-page.cta-with-client"
        defaultMessage="Download { client } app"
        values={{ client: clientName }}
      />
    );
  }

  render() {
    const { clientName, className, downloadUrl, intl } = this.props;

    const text = intl.formatMessage(messages.downloadApp);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    const classesBtn = classnames('c-btn', styles.btn, className);
    return (
      <a
        className={classesBtn}
        href={downloadUrl}
        onClick={this.onDownloadClick}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLink}
      >
        {DownloadAppButton.renderIcon(clientName)}
        {DownloadAppButton.renderText(clientName)}
      </a>
    );
  }
}

function mapStateToProps(state) {
  return {
    clientName: selectClientName(state),
    downloadUrl: selectClientDownloadUrl(state),
  };
}

export default compose(
  connect(mapStateToProps, { track: analyticsActions.track }),
  injectIntl
)(DownloadAppButton);
