export function selectSessionToken(state) {
  return state.auth && state.auth.sessionToken;
}

export function selectAccessToken(state) {
  return state.auth && state.auth.accessToken;
}

export function selectTrackingToken(state) {
  return state.auth && state.auth.trackingToken;
}

export function selectTrackingIsEnabled(state) {
  return !!selectTrackingToken(state);
}

export function selectFbToken(state) {
  return state.auth && state.auth.fbToken;
}

export function selectAuthLoading(state) {
  return state.auth && state.auth.loading;
}

export function selectAuthIsWorking(state) {
  return state.auth && state.auth.authIsWorking;
}

export function selectAuthenticationType(state) {
  return state.auth && state.auth.authenticationType;
}

export function selectEmailAuthIsWorking(state) {
  return selectAuthIsWorking(state) && selectAuthenticationType(state) === 'email';
}

export function selectSocialAuthIsWorking(state) {
  return selectAuthIsWorking(state) && selectAuthenticationType(state) === 'social';
}
