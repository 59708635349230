import {
  CREATING_ACCOUNT,
  PASSWORD_CHANGE_SUCCESS,
  REQUEST_PASSWORD_RESET_SUCCESS,
  SIGN_UP_SUCCESS,
  SUBSCRIPTION_SUCCESS,
  PLEASE_WHILE_REDIRECTING,
  AUTH_SUCCESS,
  SET_PASSWORD_SUCCESS_AND_AUTHENTICATED,
  FIRSTNAME_CHANGE_SUCCESS,
} from '../lib/notifications';
import { FAILURE, REQUEST, SUCCESS } from './api';
import { addNotification, dismissNotification } from '../actions/notifications';
import { clearLocalAccessToken, requestRevokeAccessToken, setAccessToken } from '../actions/auth';
import { pause, resetQueue } from '../actions/player';
import { updateAudioQualityPreference } from '../actions/client';
import { selectAudioQualityPreference, selectUserIsAuthenticated } from '../selectors/user';
import { loadMe } from '../actions/me';

export default store => next => async action => {
  next(action);
  const { dispatch } = store;

  const { type, phase } = action;
  switch (type) {
    case 'SET_AUDIO_QUALITY':
      if (selectUserIsAuthenticated(store.getState())) {
        if (action.quality !== selectAudioQualityPreference(store.getState())) {
          dispatch(updateAudioQualityPreference(action.quality));
        }
      }
      return;
    case 'LOGIN':
      if (phase === REQUEST) {
        dispatch(dismissNotification('auth'));
      }

      if (phase === FAILURE) {
        dispatch(addNotification(action.error.body.data.notification));
      }

      if (phase === SUCCESS) {
        dispatch(addNotification(AUTH_SUCCESS));
      }
      break;
    case 'LOGIN_SOCIAL':
      if (phase === SUCCESS && action.response.normalized.isNewUser) {
        dispatch(addNotification(SIGN_UP_SUCCESS));
      }
      break;
    case 'SIGN_UP':
      if (phase === REQUEST) {
        dispatch(addNotification(CREATING_ACCOUNT));
      }

      if (phase === FAILURE) {
        dispatch(addNotification(action.error.body.data.notification));
      }

      if (phase === SUCCESS) {
        dispatch(
          addNotification(action.response.normalized.isNewUser ? SIGN_UP_SUCCESS : AUTH_SUCCESS)
        );
      }
      break;
    case 'ADD_SUBSCRIPTION':
      if (phase === SUCCESS) {
        dispatch(addNotification(SUBSCRIPTION_SUCCESS));
      }
      break;
    case 'LOGOUT':
      dispatch(addNotification(PLEASE_WHILE_REDIRECTING));
      await dispatch(pause());
      dispatch(resetQueue());
      await dispatch(requestRevokeAccessToken());
      dispatch(clearLocalAccessToken());
      setTimeout(() => {
        if (__ELECTRON__) {
          document.location.reload();
          return;
        }
        document.location = '/';
      }, 1000);

      break;
    case 'CHANGE_FIRSTNAME':
      if (action.phase === SUCCESS) {
        await dispatch(loadMe());
        dispatch(addNotification(FIRSTNAME_CHANGE_SUCCESS));
      }

      if (action.phase === FAILURE) {
        dispatch(addNotification(action.error.body.data.notification));
      }
      break;
    case 'CHANGE_PASSWORD':
      if (action.phase === SUCCESS) {
        dispatch(addNotification(PASSWORD_CHANGE_SUCCESS));
        dispatch(setAccessToken(action.response.normalized.accessToken));
        // react to setting new password by enabling "disconnect from FB" button
        dispatch(loadMe());
      }

      if (action.phase === FAILURE) {
        dispatch(addNotification(action.error.body.data.notification));
      }
      break;

    case 'SET_PASSWORD':
      if (action.phase === SUCCESS) {
        dispatch(addNotification(SET_PASSWORD_SUCCESS_AND_AUTHENTICATED));
      }

      if (action.phase === FAILURE) {
        dispatch(addNotification(action.error.body.data.notification));
      }
      break;

    case 'REQUEST_PASSWORD_RESET':
      if (action.phase === SUCCESS) {
        dispatch(addNotification(REQUEST_PASSWORD_RESET_SUCCESS));
      }

      if (action.phase === FAILURE) {
        dispatch(addNotification(action.error.body.data.notification));
      }
      break;
    default:
  }
};
