// @flow
import { combineReducers } from 'redux';

import recordingsByFilter from './recordingsByFilter';
import recordingsByWork from './recordingsByWork';
import workFacets from './workFacets';
import recordingFacets from './recordingFacets';
import topRecordingFacetsByFilter from './topRecordingFacetsByFilter';
import topWorkFacetsByFilter from './topWorkFacetsByFilter';
import playlists from './playlists';
import personalPlaylists from './personalPlaylists';
import mixes from './mixes';
import albums from './albums';
import albumFacets from './albumFacets';
import albumsByFilter from './albumsByFilter';
import albumsByRecording from './albumsByRecording';
import topAlbumFacetsByFilter from './topAlbumFacetsByFilter';
import worksByFilter from './worksByFilter';
import trackUrls from './trackUrls';
import playlistsByFilter from './playlistsByFilter';
import livestreamEvents from './livestreamEvents';
import discoverGroups from './discoverGroups';
import discoverPages from './discoverPages';
import instruments from './instruments';
import epochs from './epochs';
import genres from './genres';
import works from './works';

import type { State as RecordingsByFilterState } from './recordingsByFilter';
import type { State as RecordingsByWorkState } from './recordingsByWork';
import type { State as TopAlbumFacetsByFilterState } from './topAlbumFacetsByFilter';
import type { State as PlaylistsState } from './playlists';
import type { State as PersonalPlaylistsState } from './personalPlaylists';
import type { State as MixesState } from './mixes';
import type { State as AlbumsState } from './albums';
import type { State as AlbumFacetsState } from './albumFacets';
import type { State as AlbumsByFilterState } from './albumsByFilter';
import type { State as AlbumsByRecordingState } from './albumsByRecording';
import type { State as WorksByFilterState } from './worksByFilter';
import type { State as TrackUrlsState } from './trackUrls';
import type { State as PlaylistsByFilterState } from './playlistsByFilter';
import type { State as LivestreamEventsState } from './livestreamEvents';
import type { State as DiscoverGroupsState } from './discoverGroups';
import type { State as DiscoverPagesState } from './discoverPages';
import type { State as InstrumentsState } from './instruments';
import type { State as EpochsState } from './epochs';
import type { State as GenresState } from './genres';
import type { State as WorksState } from './works';

type TopRecordingFacetsByFilterState = TopAlbumFacetsByFilterState;
type TopWorkFacetsByFilterState = TopAlbumFacetsByFilterState;

type RecordingFacetsState = AlbumFacetsState;
type WorkFacetsState = AlbumFacetsState;

export type State = {|
  +recordingsByFilter: RecordingsByFilterState,
  +recordingsByWork: RecordingsByWorkState,
  +workFacets: WorkFacetsState,
  +recordingFacets: RecordingFacetsState,
  +topRecordingFacetsByFilter: TopRecordingFacetsByFilterState,
  +topWorkFacetsByFilter: TopWorkFacetsByFilterState,
  +playlists: PlaylistsState,
  +personalPlaylists: PersonalPlaylistsState,
  +mixes: MixesState,
  +albums: AlbumsState,
  +albumFacets: AlbumFacetsState,
  +albumsByFilter: AlbumsByFilterState,
  +albumsByRecording: AlbumsByRecordingState,
  +topAlbumFacetsByFilter: TopAlbumFacetsByFilterState,
  +worksByFilter: WorksByFilterState,
  +trackUrls: TrackUrlsState,
  +playlistsByFilter: PlaylistsByFilterState,
  +livestreamEvents: LivestreamEventsState,
  +discoverGroups: DiscoverGroupsState,
  +discoverPages: DiscoverPagesState,
  +instruments: InstrumentsState,
  +epochs: EpochsState,
  +genres: GenresState,
  +works: WorksState,
|};

type Action = { type: any };

const combinedReducer: (state: State, action: Action) => State = combineReducers({
  recordingsByFilter,
  recordingsByWork,
  workFacets,
  recordingFacets,
  topRecordingFacetsByFilter,
  topWorkFacetsByFilter,
  playlists,
  personalPlaylists,
  mixes,
  albums,
  albumFacets,
  albumsByFilter,
  albumsByRecording,
  topAlbumFacetsByFilter,
  worksByFilter,
  trackUrls,
  playlistsByFilter,
  livestreamEvents,
  discoverGroups,
  discoverPages,
  instruments,
  epochs,
  genres,
  works,
});

export default combinedReducer;
