// @flow
import { combineReducers } from 'redux';

import tracks from './tracks';
import recordings from './recordings';
import playlists from './playlists';
import albums from './albums';
import artists from './artists';
import livestreamEvents from './livestreamEvents';
import ids from './ids';

import type { State as TracksState } from './tracks';
import type { State as RecordingsState } from './recordings';
import type { State as PlaylistsState } from './playlists';
import type { State as AlbumsState } from './albums';
import type { State as ArtistsState } from './artists';
import type { State as LivestreamEventsState } from './livestreamEvents';
import type { State as IdsState } from './ids';
import type { CollectionAction } from '../../../actions/collection';

export type State = {|
  +tracks: TracksState,
  +recordings: RecordingsState,
  +playlists: PlaylistsState,
  +albums: AlbumsState,
  +artists: ArtistsState,
  +livestreamEvents: LivestreamEventsState,
  +ids: IdsState,
|};

const combinedReducer: (state: State, action: CollectionAction) => State = combineReducers({
  tracks,
  recordings,
  playlists,
  albums,
  artists,
  livestreamEvents,
  ids,
});

export default combinedReducer;
