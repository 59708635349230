// @flow
import update from 'immutability-helper';

import { REQUEST, SUCCESS, FAILURE } from '../../../middleware/api';

import type { SignupAction, LogoutAction } from '../../../actions/auth';
import type { LoadCollectionIdsAction, ModifyCollectionAction } from '../../../actions/collection';

type Action = SignupAction | LogoutAction | LoadCollectionIdsAction | ModifyCollectionAction;

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +error: ?Object,
  +entities: {
    +trackIds: Array<string>,
    +recordingIds: Array<string>,
    +playlistIds: Array<string>,
    +albumIds: Array<string>,
    +artistIds: Array<string>,
    +livestreamEventIds: Array<string>,
  },
|};

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  entities: {
    trackIds: [],
    recordingIds: [],
    playlistIds: [],
    albumIds: [],
    artistIds: [],
    livestreamEventIds: [],
  },
};

function updateIds(state, updateAction, collectionType) {
  return update(state, {
    entities: {
      trackIds: collectionType === 'track' ? updateAction : { $set: state.entities.trackIds },
      recordingIds:
        collectionType === 'recording' ? updateAction : { $set: state.entities.recordingIds },
      playlistIds:
        collectionType === 'playlist' ? updateAction : { $set: state.entities.playlistIds },
      albumIds: collectionType === 'album' ? updateAction : { $set: state.entities.albumIds },
      artistIds: collectionType === 'artist' ? updateAction : { $set: state.entities.artistIds },
      livestreamEventIds:
        collectionType === 'livestreamEvent'
          ? updateAction
          : { $set: state.entities.livestreamEventIds },
    },
  });
}

export default function collectionIds(state: State = initialState, action: Action) {
  switch (action.type) {
    case 'SIGN_UP':
      if (action.phase === REQUEST) {
        return update(state, {
          loaded: { $set: true },
        });
      }
      return state;
    case 'FETCH_COLLECTION_IDS':
      if (action.phase === REQUEST) {
        return update(state, {
          loading: { $set: true },
        });
      }
      if (action.phase === SUCCESS) {
        return update(state, {
          loading: { $set: false },
          loaded: { $set: true },
          entities: { $set: action.response && action.response.normalized },
        });
      }
      if (action.phase === FAILURE) {
        return update(state, {
          error: { $set: action.error },
        });
      }
      return state;
    case 'ADD_TRACK_TO_COLLECTION':
    case 'ADD_RECORDING_TO_COLLECTION':
    case 'ADD_PLAYLIST_TO_COLLECTION':
    case 'ADD_ALBUM_TO_COLLECTION':
    case 'ADD_ARTIST_TO_COLLECTION':
    case 'ADD_LIVESTREAM_EVENT_TO_COLLECTION':
      if (action.phase === REQUEST) {
        return updateIds(state, { $unshift: action.ids }, action.collectionType);
      }
      return state;
    case 'REMOVE_TRACK_FROM_COLLECTION':
    case 'REMOVE_RECORDING_FROM_COLLECTION':
    case 'REMOVE_PLAYLIST_FROM_COLLECTION':
    case 'REMOVE_ALBUM_FROM_COLLECTION':
    case 'REMOVE_ARTIST_FROM_COLLECTION':
    case 'REMOVE_LIVESTREAM_EVENT_FROM_COLLECTION':
      if (action.phase === REQUEST) {
        const collectionType = action.collectionType;
        const spliceActions = action.ids.reduce((result, trackIdToRemove) => {
          const indexToRemove = state.entities[`${collectionType}Ids`].indexOf(trackIdToRemove);
          if (indexToRemove !== -1) {
            result.push([indexToRemove, 1]);
          }
          return result;
        }, []);

        return updateIds(state, { $splice: spliceActions }, collectionType);
      }
      return state;
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
