// @flow

import { partition, flatten, shuffle, isEqual, findIndex } from 'lodash';

import { selectShuffleRecordings, selectShuffleTracks } from '../selectors/player';
import { selectTrack } from '../selectors/track';

import type { GetState } from './types';
import type { QueueItem } from './player';

type QueueItemWithRecording = QueueItem & {
  recording: any,
};

const groupByRecordingConsecutive = (acc, value) => {
  // compare the current value with the last item in the collected array
  if (acc.length && acc[acc.length - 1][0].recording === value.recording) {
    // append the current value to it if it is matching
    acc[acc.length - 1].push(value);
  } else {
    // append the new value at the end of the collected array
    acc.push([value]);
  }

  return acc;
};

function shuffleRecordings(
  tracksWithRecording: Array<QueueItemWithRecording>,
  currentQueueItem: ?QueueItem
): Array<Array<QueueItemWithRecording>> {
  const groupedTracks = tracksWithRecording.reduce(groupByRecordingConsecutive, []);

  if (currentQueueItem) {
    const [currentRecordingItems, itemsToShuffle] = partition(groupedTracks, track => {
      return findIndex(track, currentQueueItem) >= 0;
    });

    return currentRecordingItems.concat(shuffle(itemsToShuffle));
  }

  return shuffle(groupedTracks);
}

function shuffleTracks(tracks: Array<QueueItem>, currentQueueItem: ?QueueItem): Array<QueueItem> {
  if (currentQueueItem) {
    const itemsToShuffle = tracks.filter(item => !isEqual(item, currentQueueItem));
    return [currentQueueItem].concat(shuffle(itemsToShuffle));
  }

  return shuffle(tracks);
}

export function getShuffledTracks(
  getState: GetState,
  tracks: Array<QueueItem>,
  currentQueueItem: ?QueueItem
): Array<QueueItem> {
  const state = getState();

  if (selectShuffleRecordings(state)) {
    const tracksWithRecording = tracks.map(item => {
      return {
        ...item,
        recording: selectTrack(state, item.track).recording,
      };
    });

    return flatten(shuffleRecordings(tracksWithRecording, currentQueueItem)).map(
      ({ track, index }) => ({
        track,
        index,
      })
    );
  }

  if (selectShuffleTracks(state)) {
    return shuffleTracks(tracks, currentQueueItem);
  }

  return tracks;
}
