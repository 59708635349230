// @flow

import update from 'immutability-helper';
import { SUCCESS, FAILURE, REQUEST } from '../middleware/api';

import type { Work, Piece, Person } from '../shapes/types';
import type { LiveCompareAction } from '../actions/liveCompare';

type Composer = Person;

export type Recording = {|
  albumSlug: string,
  imageUrl: string,
  performerSummary: string,
  recordingId: number,
  // currently, we only compare single piece works, thus it's trackId, not trackIds.
  trackId: number,
|};

export type PlaybackState = {|
  active: ?number,
  playingRecordingIndex: ?number,
  hasPlayedIndices: { [key: number]: boolean },
  isSwitching: boolean,
  hasSwitched: boolean,
|};

export type State = {|
  loaded: boolean,
  available: ?boolean,
  currentlyOn: boolean,
  inactivityTooltipDismissed: boolean,
  work: ?Work,
  piece: ?Piece,
  composer: ?Composer,
  recordings: Recording[],
  playback: PlaybackState,
|};

export type FetchLiveCompareAction = {
  type: 'FETCH_LIVE_COMPARE',
  phase: SUCCESS | FAILURE | REQUEST,
  response?: {
    normalized: {
      work: Work,
      piece: Piece,
      recordings: Recording[],
      composer: Composer,
    },
  },
};

const initialState: State = {
  // is the page state loaded
  loaded: false,
  // null for unknown, false for unavailable, true for available
  available: null,
  // whether we are facing this feature at the moment
  currentlyOn: false,
  work: null,
  piece: null,
  recordings: [],
  composer: null,
  playback: {
    active: null,
    playingRecordingIndex: -1,
    hasPlayedIndices: {},
    isSwitching: false,
    hasSwitched: false,
  },
  inactivityTooltipDismissed: false,
};

export default function liveCompare(state: State = initialState, action: LiveCompareAction) {
  switch (action.type) {
    case 'FETCH_LIVE_COMPARE':
      switch (action.phase) {
        case REQUEST:
          return update(state, {
            loaded: { $set: false },
          });
        case FAILURE:
          return update(state, {
            loaded: { $set: true },
            available: { $set: false },
          });
        case SUCCESS: {
          const response = action.response || {};
          const { work, recordings, composer, piece } = response.normalized;
          return update(state, {
            loaded: { $set: true },
            available: { $set: true },
            piece: { $set: piece },
            work: { $set: work },
            recordings: { $set: recordings },
            composer: { $set: composer },
            playback: {
              active: {
                // set the carousel in the middle
                $set: Math.floor(recordings.length / 2),
              },
            },
          });
        }
        default:
          return state;
      }
    case 'SET_ACTIVE_RECORDING_INDEX':
      return update(state, {
        playback: {
          active: {
            $set: action.payload,
          },
        },
      });
    case 'SET_LIVE_COMPARE_CURRENTLY_ON':
      return update(state, {
        currentlyOn: { $set: action.payload },
      });
    case 'SET_LIVE_COMPARE_IS_SWITCHING':
      return update(state, {
        playback: {
          isSwitching: {
            $set: action.payload,
          },
          hasSwitched: {
            $set: true,
          },
        },
      });
    case 'SET_LIVE_COMPARE_PLAYING_RECORDING_INDEX':
      return update(state, {
        playback: {
          playingRecordingIndex: {
            $set: action.payload,
          },
          hasPlayedIndices: {
            $merge: { [action.payload]: true },
          },
        },
      });
    case 'SET_LIVE_COMPARE_DISMISSED_INACTIVITY_TOOLTIP':
      return update(state, {
        inactivityTooltipDismissed: {
          $set: true,
        },
      });
    default:
      return state;
  }
}
