// @flow
import {
  selectRecordingIsPlaying,
  selectRecordingIsQueued,
  selectRecordingTrackIds,
} from '../selectors/recording';
import { filter, head, includes, isEqual, findIndex } from 'lodash';
import { selectTrackIsLoaded } from '../selectors/track';
import { loadTracks } from './tracks';
import {
  selectPlayerCurrentQueueItem,
  selectPlayerIsPlaying,
  selectPlayerIsPaused,
  selectPlayerIsPlayingAd,
  selectPlayerLoadingQueueOrigin,
  selectPlayerQueueOrigin,
  selectPlayerOriginalTracks,
  selectPlayerState,
  selectPlayerQueueItems,
} from '../selectors/player';
import { selectSupportsPlayback } from '../selectors/client';
import { selectUserIsPatron } from '../selectors/user';
import { loadQueue, queueIsLoaded } from './queue';
import { showUpsellView } from './capacitor';

import type { Dispatch, GetState, Request, ThunkAction } from './types';
import type { QueueOrigin } from '../reducers/player';
import type { SetLiveCompareActiveRecordingAction } from './liveCompare';
import { setLiveComparePlayingRecordingIndex } from './liveCompare';

import { addNotification } from './notifications';
import { TRACK_WILL_PLAY_AFTER_THIS_AD } from '../lib/notifications';
import { selectNumberOfAdsToPlayNow, selectShouldPlayAdsForUser } from '../selectors/ads';
import { playAdsNext } from './ads';
import { showModal } from './ui';
import {
  selectLiveCompareActiveRecording,
  selectLiveCompareActiveRecordingIndex,
  selectLiveCompareCurrentlyOn,
  selectLiveCompareHasPlayedIndices,
  selectLiveCompareNextRecordingIndex,
  selectLiveComparePiece,
  selectLiveComparePreviousRecordingIndex,
  selectLiveCompareRecordings,
} from '../selectors/liveCompare';
import { getQueueOrigin as getLiveCompareQueueOrigin } from '../utils/liveCompare';
import { removeAtIndex } from '../utils/array';

import {
  QUEUE_TYPE_LIVE_COMPARE,
  QUEUE_TYPE_RECORDING,
  FINISHED_TRACK,
  USER_CLICKED_NEXT,
  SONOS_NEXT,
} from '../constants.js';
import { getQueueOrigin } from '../lib/queue';
import { loadRecording } from './recording';
import { getShuffledTracks } from './shuffle';

import type { TrackMoveOperation, TrackRemoveOperation } from './personalPlaylist';

declare var __CAPACITOR__: boolean;

export type QueueItem = { track: string, index: number };

/*
  The Source type is used to differentiate betweenOrEqual redux actions instigated by user
  action and sonos ws messages. If the action comes from a user we propagate it to sonos.
  If it comes from sonos itself, propagating it would cause an infinite loop.
*/
type Source = 'internal' | 'sonos';

type PlayerPauseAction = { type: 'PLAYER_PAUSE', source: Source };
export type PlayerPlayAction = {
  type: 'PLAYER_PLAY',
  queueItem: Object,
  source: Source,
  timestamp?: number,
  progress?: number,
  selectedIndividualTrack?: boolean,
};
export type PlayerResumeAction = { type: 'PLAYER_RESUME', source: Source };

type PlayerSeekBeginAction = { type: 'PLAYER_SEEK_BEGIN' };
type PlayerSeekFinishAction = {
  type: 'PLAYER_SEEK_FINISH',
  newPosition: number,
  source: Source,
};
type PlayerNextReason = FINISHED_TRACK | USER_CLICKED_NEXT | SONOS_NEXT;
type PlayerNextAction = {
  type: 'PLAYER_NEXT',
  reason: PlayerNextReason,
  source: Source,
  timestamp?: number,
};
type PlayerPreviousAction = { type: 'PLAYER_PREVIOUS', source: Source };
type PlayerRewindAction = { type: 'PLAYER_REWIND' };
export type PlayerSetQueueAction = {
  type: 'PLAYER_QUEUE_SET',
  origin: Object,
  tracks: Array<QueueItem>,
  restartPlayback: boolean,
  source: Source,
  originalTracks: Array<QueueItem>,
};
type PlayerAbortQueueLoadAction = { type: 'PLAYER_QUEUE_LOAD_ABORT' };
type PlayerResetQueueAction = {|
  type: 'PLAYER_QUEUE_RESET',
  restartPlayback: boolean,
  source: Source,
|};
export type PlayerProgressAction = {
  type: 'PLAYER_PROGRESS',
  value: number,
  duration: number,
  trackId: string,
  audioQuality: number,
  source: 'Embedded Player' | 'Webapp',
  trackPosition: number,
  repeat: string,
  shuffle: string,
  sonosIsConnected: boolean,
};
type PlayerChangeVolumeAction = {
  type: 'PLAYER_VOLUME',
  value: number,
  source: Source,
};
type PlayerToggleMuteAction = { type: 'PLAYER_TOGGLE_MUTE', source: Source };
type PlayerEnableControlsAction = { type: 'PLAYER_ENABLE_CONTROLS' };
type PlayerDisableControlsAction = { type: 'PLAYER_DISABLE_CONTROLS' };
type PlayerToggleRepeatAction = { type: 'PLAYER_TOGGLE_REPEAT' };
type PlayerToggleShuffleAction = { type: 'PLAYER_TOGGLE_SHUFFLE' };
type PlayerNotifyPlayAction = { type: 'NOTIFY_PLAY' } & Request;
export type SetCurrentQueueItemAction = {
  type: 'PLAYER_SET_CURRENT_QUEUE_ITEM',
  queueItem: QueueItem,
  source: Source,
};

export type RadioOrigin =
  | 'recordingRadio'
  | 'playlistRadio'
  | 'profileRadio'
  | 'composerRadio'
  | 'artistRadio'
  | 'epochRadio'
  | 'genreRadio'
  | 'instrumentRadio';

type CapacitorSetQueueAndPlayAction = {
  type: 'CAPACITOR_SET_QUEUE_AND_PLAY',
  queueOrigin: QueueOrigin,
  radioOrigin?: RadioOrigin,
  entityName?: string,
};

export type SetCurrentQueueItemIndexAction = {
  type: 'PLAYER_SET_CURRENT_QUEUE_ITEM_INDEX',
  index: number,
};

type ReconstructLocalPlayerQueueAction = {
  type: 'PLAYER_RECONSTRUCT_LOCAL_QUEUE',
};

type LiveComparePlayerSwitchAction = {
  type: 'LIVE_COMPARE_PLAYER_SWITCH',
  source: Source,
  queueOrigin: QueueOrigin,
};

type SetlastPlayedTrackIdAction = {
  type: 'SET_LAST_PLAYED_TRACK_ID',
  payload: string,
};

export type PlayerAction =
  | PlayerPauseAction
  | PlayerPlayAction
  | PlayerSeekBeginAction
  | PlayerSeekFinishAction
  | PlayerNextAction
  | PlayerPreviousAction
  | PlayerRewindAction
  | PlayerSetQueueAction
  | PlayerAbortQueueLoadAction
  | PlayerResetQueueAction
  | PlayerProgressAction
  | PlayerChangeVolumeAction
  | PlayerToggleMuteAction
  | PlayerEnableControlsAction
  | PlayerDisableControlsAction
  | PlayerToggleRepeatAction
  | PlayerToggleShuffleAction
  | PlayerNotifyPlayAction
  | ReconstructLocalPlayerQueueAction
  | SetCurrentQueueItemAction
  | SetCurrentQueueItemIndexAction
  | PlayerResumeAction
  | SetLiveCompareActiveRecordingAction
  | LiveComparePlayerSwitchAction
  | SetlastPlayedTrackIdAction
  | CapacitorSetQueueAndPlayAction;

type PlayActionMeta = {
  progress?: number,
  timestamp?: number,
  startPlayback?: boolean,
  selectedIndividualTrack?: boolean,
};

type StartLiveComparePlaybackActionParameters = {
  recordingIndex: number,
  tryToMatchProgress?: boolean,
  source?: Source,
};

export function abortQueueLoad(): PlayerAbortQueueLoadAction {
  return {
    type: 'PLAYER_QUEUE_LOAD_ABORT',
  };
}

// An individual track can appear in the queue multiple times, therefore we
// wrap the track in an object such that rather than having a list of track ids
// which are not unique we instead we have a list of object in the queue which
// are unique
export function createItem(track: any, index: number) {
  return { track: track.toString(), index };
}

export function getItemById(trackId: any, tracks: Array<QueueItem>) {
  return createItem(
    trackId,
    tracks.findIndex(item => item.track === trackId.toString())
  );
}

export function setQueue(
  origin: Object,
  tracks: Array<QueueItem>,
  restartPlayback: boolean = true,
  source: Source = 'internal',
  affectPlayback: boolean = true,
  originalTracks: Array<QueueItem> = tracks
): PlayerSetQueueAction {
  return {
    type: 'PLAYER_QUEUE_SET',
    origin,
    tracks,
    originalTracks,
    restartPlayback,
    source,
    affectPlayback,
  };
}

export function setCurrentQueueItemIndex(index: number): SetCurrentQueueItemIndexAction {
  return {
    type: 'PLAYER_SET_CURRENT_QUEUE_ITEM_INDEX',
    index,
  };
}

export function setCurrentQueueItem(
  queueItem: QueueItem,
  source: Source = 'internal',
  startPlayback: boolean = true
): SetCurrentQueueItemAction {
  return {
    type: 'PLAYER_SET_CURRENT_QUEUE_ITEM',
    queueItem,
    source,
    startPlayback,
  };
}

const findTrack = (track: QueueItem) => player => {
  const { queueItems } = player;
  const items = filter(queueItems, item => isEqual(item, track));
  if (items.length === 1) {
    // Most common case, found one track
    return items[0];
  }

  // Default case, first item
  return queueItems[0];
};

export function resume(source: Source = 'internal'): PlayerResumeAction {
  return {
    type: 'PLAYER_RESUME',
    source,
  };
}

export function seekFinish(
  newPosition: number,
  source: Source = 'internal'
): PlayerSeekFinishAction {
  return {
    type: 'PLAYER_SEEK_FINISH',
    newPosition,
    source,
  };
}

export function pause(source: Source = 'internal'): PlayerPauseAction {
  return {
    type: 'PLAYER_PAUSE',
    source,
  };
}

export function seekBegin(): PlayerSeekBeginAction {
  return {
    type: 'PLAYER_SEEK_BEGIN',
  };
}

function genericPlayAction(
  findQueueItem,
  source: Source = 'internal',
  meta: PlayActionMeta = {},
  restartPlayback: boolean = false
): ThunkAction {
  return (dispatch, getState) => {
    let state = getState();
    const player = selectPlayerState(state);
    const queueItem = findQueueItem(player);
    const isPlayingAd = selectPlayerIsPlayingAd(state);

    // Licensing agreements prevent free users from selecting individual track on iOS & Android
    if (__CAPACITOR__ && meta.selectedIndividualTrack && !selectUserIsPatron(getState())) {
      dispatch(showUpsellView('fromTrackSelection'));
      return;
    }

    if (isPlayingAd) {
      dispatch(addNotification(TRACK_WILL_PLAY_AFTER_THIS_AD));
      dispatch(setCurrentQueueItem(queueItem, source));
      return;
    }

    if (!restartPlayback && isEqual(selectPlayerCurrentQueueItem(state), queueItem)) {
      if (selectPlayerIsPaused(state)) {
        dispatch(resume(source));
      } else {
        dispatch({
          type: 'PLAYER_PLAY',
          queueItem,
          source,
        });
      }
      return;
    }

    const previousQueueItem = selectPlayerCurrentQueueItem(state);
    dispatch(setCurrentQueueItem(queueItem, source));
    state = getState();

    if (selectSupportsPlayback(state)) {
      // don't trigger PLAYER_PLAY when an ad is playing
      // 1. player middleware doesn't protect against it
      // 2. player reducer will cause the play to transform into a pause, which we don't want
      if (!selectPlayerIsPlayingAd(state)) {
        if (selectShouldPlayAdsForUser(state)) {
          const adCount = selectNumberOfAdsToPlayNow(state);
          if (adCount) {
            dispatch(playAdsNext(adCount));
            return;
          }
        }

        if (meta.startPlayback !== false) {
          dispatch({
            type: 'PLAYER_PLAY',
            queueItem,
            previousQueueItem,
            source,
            progress: meta.progress,
            timestamp: meta.timestamp,
            selectedIndividualTrack: meta.selectedIndividualTrack,
          });
        } else {
          if (typeof meta.progress === 'number') {
            dispatch(seekFinish(meta.progress, 'internal'));
          }
        }
      }
    }
  };
}

export function playTrack(
  track: QueueItem,
  meta: PlayActionMeta,
  restartPlayback?: boolean
): ThunkAction {
  return genericPlayAction(findTrack(track), 'internal', meta, restartPlayback);
}

export function setShuffledQueue(
  origin: Object,
  originalTracks: Array<QueueItem>,
  startPlaying: boolean = false,
  meta: PlayActionMeta = {},
  restartPlayback: boolean = true,
  source: Source = 'internal',
  affectPlayback: boolean = true,
  trackToPlay: ?QueueItem
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const currentQueueItem = selectPlayerCurrentQueueItem(state);

    // shouldn't be shuffled and should be put in the front of the shuffled queue if it's defined
    let newCurrentQueueItem = currentQueueItem;
    if (restartPlayback) {
      newCurrentQueueItem = trackToPlay ? trackToPlay : undefined;
    }

    let newTracks = originalTracks;

    if (originalTracks.length) {
      newTracks = getShuffledTracks(getState, originalTracks, newCurrentQueueItem);
    }

    dispatch(setQueue(origin, newTracks, restartPlayback, source, affectPlayback, originalTracks));

    if (!newCurrentQueueItem) {
      newCurrentQueueItem = head(newTracks);
    }

    if (startPlaying) {
      dispatch(playTrack(newCurrentQueueItem, meta, restartPlayback));
    } else if (newCurrentQueueItem !== currentQueueItem) {
      dispatch(setCurrentQueueItem(newCurrentQueueItem));
    }
  };
}

export function shuffleRecordingsAndTracks(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const tracks = selectPlayerOriginalTracks(state);
    const origin = selectPlayerQueueOrigin(state);

    if (!tracks.length || !origin) {
      return;
    }

    dispatch(setShuffledQueue(origin, tracks, false, undefined, false, undefined, false));
  };
}

async function loadAndFilterTracks(
  getState: GetState,
  dispatch: Dispatch,
  tracks: Array<QueueItem>
): Promise<Array<QueueItem>> {
  const notLoadedTracks = tracks.filter(item => !selectTrackIsLoaded(getState(), item.track));

  if (notLoadedTracks.length) {
    await dispatch(loadTracks(notLoadedTracks.map(item => item.track)));
  }

  return tracks.filter(item => selectTrackIsLoaded(getState(), item.track));
}

function queueHasChanged(origin, getState) {
  return !isEqual(origin, selectPlayerLoadingQueueOrigin(getState()));
}

export function setQueueAndPlay(
  origin: Object,
  rawTracks: Array<QueueItem>,
  trackToPlay: ?QueueItem, // undefined if should play the head of the (shuffled) tracks
  restartPlayback: boolean = true,
  source: Source = 'internal',
  meta: PlayActionMeta = {},
  shuffle: boolean = true
): ThunkAction {
  return async function setQueueAndPlayThunk(dispatch, getState) {
    if (!rawTracks.length) {
      return;
    }

    // Licensing agreements prevent free users from selecting individual track on iOS & Android
    if (__CAPACITOR__ && meta.selectedIndividualTrack && !selectUserIsPatron(getState())) {
      dispatch(showUpsellView('fromTrackSelection'));
      return;
    }

    dispatch(loadQueue(origin));

    const tracks = await loadAndFilterTracks(getState, dispatch, rawTracks);

    if (queueHasChanged(origin, getState)) {
      // eslint-disable-next-line no-console
      console.warn('Queue changed during setQueueAndPlay');
      return;
    }

    if (!tracks.length) {
      dispatch(abortQueueLoad());
      return;
    }

    if (typeof trackToPlay !== 'undefined') {
      // Useful in capacitor to only allow playing radio
      meta.selectedIndividualTrack = true;
    }

    if (shuffle) {
      dispatch(
        setShuffledQueue(
          origin,
          tracks,
          true,
          meta,
          restartPlayback,
          source,
          undefined,
          trackToPlay
        )
      );
    } else {
      dispatch(setQueue(origin, tracks, restartPlayback, source));

      const trackInList = includes(tracks, trackToPlay);
      const track = trackToPlay && trackInList ? trackToPlay : head(tracks);
      dispatch(playTrack(track, meta, restartPlayback));
    }

    dispatch(queueIsLoaded(origin));
  };
}

export function setLiveCompareActiveRecording(
  newRecordingIndex: number
): SetLiveCompareActiveRecordingAction {
  return {
    type: 'SET_ACTIVE_RECORDING_INDEX',
    payload: newRecordingIndex,
  };
}

export function startLiveComparePlaybackForRecording({
  recordingIndex,
  tryToMatchProgress = true,
  source = 'internal',
}: StartLiveComparePlaybackActionParameters): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const hasPlayedTracksCount = Object.keys(selectLiveCompareHasPlayedIndices(state)).length;
    const currentPiece = selectLiveComparePiece(state);

    const recordingToBePlayed = selectLiveCompareRecordings(state)[recordingIndex];

    const queueOrigin = getLiveCompareQueueOrigin(currentPiece.id, recordingToBePlayed.trackId);
    if (hasPlayedTracksCount && tryToMatchProgress) {
      dispatch({
        type: 'LIVE_COMPARE_PLAYER_SWITCH',
        source,
        queueOrigin,
      });
    } else {
      const tracks = [recordingToBePlayed.trackId].map(createItem);
      await dispatch(
        setQueueAndPlay(
          queueOrigin,
          tracks,
          getItemById(recordingToBePlayed.trackId, tracks),
          true,
          source
        )
      );
      await dispatch(setLiveComparePlayingRecordingIndex(recordingIndex));
    }
    dispatch({
      type: 'SET_LAST_PLAYED_TRACK_ID',
      payload: recordingToBePlayed.trackId,
    });
  };
}

export function changeLiveCompareRecording(
  recordingIndex: number,
  source: Source = 'internal',
  forcePlayback: boolean = false
): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(setLiveCompareActiveRecording(recordingIndex));
    const isPlaying = selectPlayerIsPlaying(getState());
    if (isPlaying || forcePlayback) {
      dispatch(startLiveComparePlaybackForRecording({ recordingIndex, source }));
    }
  };
}

export function resumeThunk(source: Source = 'internal'): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    if (selectLiveCompareCurrentlyOn(state)) {
      const queueItem = selectPlayerCurrentQueueItem(state);
      const queueOrigin = selectPlayerQueueOrigin(state);

      const recording = selectLiveCompareActiveRecording(state);
      const recordingIndex = selectLiveCompareActiveRecordingIndex(state);

      // the queue is of different type, restart playback
      if (!queueOrigin || queueOrigin.type !== QUEUE_TYPE_LIVE_COMPARE) {
        const tracks = [recording.trackId].map(createItem);
        dispatch(
          setQueueAndPlay(queueOrigin, tracks, getItemById(recording.trackId, tracks), true)
        );
        dispatch(setLiveComparePlayingRecordingIndex(recordingIndex));
        return;
      }

      // the queue origin is the same but the track is different
      if (queueItem.track !== recording.trackId.toString()) {
        dispatch(startLiveComparePlaybackForRecording({ recordingIndex, source }));
        return;
      }

      // otherwise, resume;
      dispatch(resume(source));
      dispatch(setLiveComparePlayingRecordingIndex(recordingIndex));
    } else {
      dispatch(resume(source));
    }
  };
}

export function play(
  source: Source = 'internal',
  meta?: PlayActionMeta,
  restartPlayback?: boolean
): ThunkAction {
  return genericPlayAction(
    player => {
      // play the current queueItem if it is defined, otherwise pick the first one
      return player.currentQueueItem || player.queueItems[0];
    },
    source,
    meta,
    restartPlayback
  );
}

function next(reason: string, source: Source = 'internal', timestamp?: number): PlayerNextAction {
  let action = {
    type: 'PLAYER_NEXT',
    reason,
    source,
  };

  if (timestamp) {
    action = {
      ...action,
      timestamp,
    };
  }

  return action;
}

export function nextThunk(
  reason: string,
  source: Source = 'internal',
  timestamp?: number
): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    const isLiveCompare = selectLiveCompareCurrentlyOn(state);

    if (isLiveCompare) {
      dispatch(changeLiveCompareRecording(selectLiveCompareNextRecordingIndex(state)));
    } else {
      dispatch(next(reason, source, timestamp));
    }
  };
}

function previous(source: Source = 'internal', timestamp?: number): PlayerPreviousAction {
  return timestamp
    ? {
        type: 'PLAYER_PREVIOUS',
        source,
        timestamp,
      }
    : {
        type: 'PLAYER_PREVIOUS',
        source,
      };
}

export function previousThunk(source: Source = 'internal', timestamp?: number): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    const isLiveCompare = selectLiveCompareCurrentlyOn(state);

    if (isLiveCompare) {
      dispatch(changeLiveCompareRecording(selectLiveComparePreviousRecordingIndex(state)));
    } else {
      dispatch(previous(source, timestamp));
    }
  };
}

export function rewind(source: Source = 'internal'): PlayerRewindAction {
  return {
    type: 'PLAYER_REWIND',
    source,
  };
}

export function resetQueue(
  restartPlayback: boolean = false,
  source: Source = 'internal'
): PlayerResetQueueAction {
  return {
    type: 'PLAYER_QUEUE_RESET',
    restartPlayback,
    source,
  };
}

export function progress(
  value: number,
  duration: number,
  trackId: string,
  audioQuality: number,
  source: 'Embedded Player' | 'Webapp',
  trackPosition: number,
  repeat: string,
  shuffle: string,
  sonosIsConnected: boolean = false
): PlayerProgressAction {
  return {
    type: 'PLAYER_PROGRESS',
    value,
    duration,
    trackId,
    audioQuality,
    source,
    trackPosition,
    repeat,
    shuffle,
    sonosIsConnected,
  };
}

export function changeVolume(value: number, source: Source = 'internal'): PlayerChangeVolumeAction {
  return {
    type: 'PLAYER_VOLUME',
    value,
    source,
  };
}

export function toggleMute(source: Source = 'internal'): PlayerToggleMuteAction {
  return {
    type: 'PLAYER_TOGGLE_MUTE',
    source,
  };
}

export function enableControls(): PlayerEnableControlsAction {
  return {
    type: 'PLAYER_ENABLE_CONTROLS',
  };
}

export function disableControls(): PlayerDisableControlsAction {
  return {
    type: 'PLAYER_DISABLE_CONTROLS',
  };
}

export function toggleRepeat(): PlayerToggleRepeatAction {
  return {
    type: 'PLAYER_TOGGLE_REPEAT',
  };
}

export function toggleShuffle(): PlayerToggleShuffleAction {
  return {
    type: 'PLAYER_TOGGLE_SHUFFLE',
  };
}

export function notifyPlay(deviceId: string, userId: string): PlayerNotifyPlayAction {
  return {
    type: 'NOTIFY_PLAY',
    meta: {
      restricted: true,
    },
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/events',
      body: {
        _IEP: 1,
        _type: 'UserRequestPlayV1',
        device_id: deviceId,
        user_id: userId,
      },
    },
  };
}

export function reconstructLocalPlayerQueue(): ReconstructLocalPlayerQueueAction {
  return { type: 'PLAYER_RECONSTRUCT_LOCAL_QUEUE' };
}

export function showPreviewsModal(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const modalType = state.client.isEmbed ? 'EMBED_PREVIEWS_MODAL' : 'PREVIEWS_MODAL';
    dispatch(showModal(modalType, { trigger: 'player' }));
  };
}

// Queue change operations triggered by personal playlist changes
export function playerAppendItemsToCurrentQueueAndShuffle(trackIds: Array<string>): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const origin = selectPlayerQueueOrigin(state);
    dispatch(
      setShuffledQueue(origin, trackIds.map(createItem), false, undefined, false, undefined, false)
    );
  };
}

const betweenOrEqual = (a: number, b: number, value: number) => {
  const min = Math.min(a, b);
  const max = Math.max(a, b);
  return value >= min && value <= max;
};

function getNewIndexForCurrentItemAfterMove(currentIndex, from, to) {
  const currentItemIndexNeedsUpdate = betweenOrEqual(from, to, currentIndex);
  if (currentItemIndexNeedsUpdate) {
    const indexOffset = from < to ? -1 : 1;
    return currentIndex === from ? to : currentIndex + indexOffset;
  }

  return currentIndex;
}

function getNewIndexForCurrentItemAfterRemove(currentIndex, deletedIndex) {
  if (deletedIndex > currentIndex) {
    return currentIndex;
  }

  return currentIndex - 1;
}

function getItemsWithNewIndexAfterMove(
  from: number,
  to: number,
  items: Array<QueueItem>
): Array<QueueItem> {
  return items.map(item => {
    return {
      ...item,
      index: getNewIndexForCurrentItemAfterMove(item.index, from, to),
    };
  });
}

export function playerMoveItemInCurrentQueue(action: TrackMoveOperation): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const currentQueueOrigin = selectPlayerQueueOrigin(state);
    const currentQueueItem = selectPlayerCurrentQueueItem(state);

    const { from, to } = action;

    const queueItems = selectPlayerQueueItems(state);
    const updatedQueueItems = getItemsWithNewIndexAfterMove(from, to, queueItems);

    // Check if the current queued item needs an index update
    const currentIndex = currentQueueItem.index;
    const newIndex = getNewIndexForCurrentItemAfterMove(currentIndex, from, to);
    dispatch(setCurrentQueueItemIndex(newIndex));

    dispatch(setQueue(currentQueueOrigin, updatedQueueItems, false, undefined, false));
  };
}

export function playerMoveItemInCurrentQueueAndShuffle(action: TrackMoveOperation): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const currentQueueOrigin = selectPlayerQueueOrigin(state);
    const currentQueueItem = selectPlayerCurrentQueueItem(state);

    const { from, to, trackIds } = action;

    // Check if the current queued item needs an index update
    const currentIndex = currentQueueItem.index;
    const newIndex = getNewIndexForCurrentItemAfterMove(currentIndex, from, to);
    dispatch(setCurrentQueueItemIndex(newIndex));

    dispatch(
      setShuffledQueue(
        currentQueueOrigin,
        trackIds.map(createItem),
        false,
        undefined,
        false,
        undefined,
        false
      )
    );
  };
}

function getItemsWithNewIndexAfterRemove(
  deletedIndex: number,
  items: Array<QueueItem>
): Array<QueueItem> {
  return items.map(item => {
    return {
      ...item,
      index: getNewIndexForCurrentItemAfterRemove(item.index, deletedIndex),
    };
  });
}

export function playerRemoveItemFromCurrentQueue(action: TrackRemoveOperation): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { trackId, index } = action;
    const currentQueueItem = selectPlayerCurrentQueueItem(state);
    const queueItems = selectPlayerQueueItems(state);
    const indexInQueueItems = findIndex(queueItems, createItem(trackId, index));
    const newQueueItems = removeAtIndex(queueItems, indexInQueueItems);
    const updatedQueueItems = getItemsWithNewIndexAfterRemove(index, newQueueItems);

    const removedItemIsQueuedItem =
      currentQueueItem.track === trackId && currentQueueItem.index === index;

    const currentQueueOrigin = selectPlayerQueueOrigin(state);

    if (removedItemIsQueuedItem) {
      const isLastTrack = indexInQueueItems === queueItems.length - 1;
      const targetIndex = isLastTrack ? 0 : indexInQueueItems;
      const targetTrack = updatedQueueItems[targetIndex];
      dispatch(
        setQueueAndPlay(
          currentQueueOrigin,
          updatedQueueItems,
          targetTrack,
          true,
          undefined,
          { progress: 0, timestamp: Date.now() },
          false
        )
      );
    } else {
      // Check if the current queued item needs an index update
      const currentIndex = currentQueueItem.index;
      const newIndex = getNewIndexForCurrentItemAfterRemove(currentIndex, index);
      dispatch(setCurrentQueueItemIndex(newIndex));

      dispatch(setQueue(currentQueueOrigin, updatedQueueItems, false, undefined, false));
    }
  };
}

type CapacitorSetQueueAndPlayActionArgs = $Diff<CapacitorSetQueueAndPlayAction, { type: string }>;

export function capacitorSetQueueAndPlay({
  queueOrigin,
  radioOrigin,
  entityName,
}: CapacitorSetQueueAndPlayActionArgs): CapacitorSetQueueAndPlayAction {
  return {
    type: 'CAPACITOR_SET_QUEUE_AND_PLAY',
    queueOrigin,
    radioOrigin,
    entityName,
  };
}

export function togglePlayRecording(recording: Object, timestamp: number): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const itemIsPlaying = selectRecordingIsPlaying(state, recording.id);
    const itemIsQueued = selectRecordingIsQueued(state, recording.id);

    if (itemIsPlaying) {
      dispatch(pause());
      return;
    }

    if (itemIsQueued) {
      dispatch(play('internal', { timestamp }));
      return;
    }

    const origin = getQueueOrigin(QUEUE_TYPE_RECORDING, recording);
    dispatch(loadQueue(origin));

    // For Capacitor we hijack this function here
    if (__CAPACITOR__) {
      const payload = { queueOrigin: origin };
      const action = capacitorSetQueueAndPlay(payload);
      dispatch(action);
      // we'll set the queue to be loaded when it comes back from capacitor
      return;
    }

    await dispatch(loadRecording(recording.id));

    if (queueHasChanged(origin, getState)) {
      // eslint-disable-next-line no-console
      console.warn('Queue changed during resolving playable item preview');
      return;
    }

    const trackIds = selectRecordingTrackIds(getState(), recording.id);
    if (!trackIds.length) {
      dispatch(abortQueueLoad());
      return;
    }

    dispatch(setShuffledQueue(origin, trackIds.map(createItem), true, { timestamp }));
    dispatch(queueIsLoaded(origin));
  };
}
