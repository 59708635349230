// @flow

import { handleListRequest } from './phasedActionHelpers';

import type { MeAction } from '../actions/me';
import type { ClearLocalAccessTokenAction } from '../actions/auth';

type Action = MeAction | ClearLocalAccessTokenAction;

export type State = {|
  loading: boolean,
  loaded: boolean,
  requestedAt: ?string,
|};

const initialState = {
  loading: false,
  loaded: false,
  requestedAt: null,
};

export default function me(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_ME':
    case 'UPDATE_PREFERENCES':
      return handleListRequest(state, action);
    case 'CLEAR_LOCAL_ACCESS_TOKEN':
      return initialState;
    default:
      (action: empty);
      return state;
  }
}
