// @flow
import React from 'react';
import styles from './PlanImage.css';
import classNames from 'classnames';
import IconLabel from '../util/IconLabel';
import { FormattedMessage } from 'react-intl';
import {
  PLAN_ID_PPC_12M,
  PLAN_ID_PPC_1M,
  PLAN_ID_PREMIUM,
  PLAN_ID_PREMIUM_PLUS,
} from '../../constants';

const PLAN_ID_TO_IMAGE = {
  [PLAN_ID_PREMIUM]: 'p-1m',
  [PLAN_ID_PREMIUM_PLUS]: 'pp-1m',
  [PLAN_ID_PPC_1M]: 'ppc-1m',
  [PLAN_ID_PPC_12M]: 'ppc-12m',
};

type OwnProps = {
  id: string,
  isSelected: boolean,
  imageTitle: React$Element<typeof FormattedMessage>,
};

type Props = OwnProps;

const PlanImage = ({ id, isSelected, imageTitle }: Props) => {
  return (
    <div className={classNames(styles.container, styles[`container--${PLAN_ID_TO_IMAGE[id]}`])}>
      {isSelected ? (
        <IconLabel className={styles.icon} name="check" size="medium" />
      ) : (
        <div className={classNames(styles.icon, styles.circle)} />
      )}
      <span className={styles.title}>{imageTitle}</span>
    </div>
  );
};

export default PlanImage;
