// @flow
import createCollectionReducer from './createCollectionReducer';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<number>,
|};

const initialState: State = {
  loading: false,
  loaded: false,
  loadedCursors: {},
  entities: [],
};

const recordingCollectionActions = {
  add: 'ADD_RECORDING_TO_COLLECTION',
  remove: 'REMOVE_RECORDING_FROM_COLLECTION',
  fetch: 'FETCH_RECORDING_COLLECTION',
};

export default createCollectionReducer(initialState, recordingCollectionActions);
