// @flow
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import quickSearch from './quickSearch';
import search from './search';
import popularSearches from './popularSearches';
import entities from './entities';
import lists from './lists';
import maps from './maps';
import indexes from './indexes';
import auth from './auth';
import me from './me';
import subscription from './subscription';
import client from './client';
import analyticsProgressBuffer from './analyticsProgressBuffer';
import player from './player';
import analyticsPlayback from './analyticsPlayback';
import recentlyPlayed from './recentlyPlayed';
import lastActions from './lastActions';
import sonos from './sonos';
import incomingVoucher from './incomingVoucher';
import subscriptionOffer from './subscriptionOffer';
import stripe from './stripe';
import ads from './ads';
import liveCompare from './liveCompare';
import livestreamEventsTickets from './livestreamEventsTickets';
import download from './download';
import localListSearch from './localListSearch';

import type { State as QuickSearchState } from './quickSearch';
import type { State as SearchState } from './search';
import type { State as PopularSearchesState } from './popularSearches';
import type { State as EntitiesState } from './entities';
import type { State as ListsState } from './lists';
import type { State as MapsState } from './maps/index';
import type { State as IndexesState } from './indexes';
import type { State as AuthState } from './auth';
import type { State as MeState } from './me';
import type { State as SubscriptionState } from './subscription';
import type { State as ClientState } from './client';
import type { State as AnalyticsProgressBufferState } from './analyticsProgressBuffer';
import type { State as PlayerState } from './player';
import type { State as AnalyticsPlaybackState } from './analyticsPlayback';
import type { State as RecentlyPlayedState } from './recentlyPlayed';
import type { State as LastActionsState } from './lastActions';
import type { State as SonosState } from './sonos';
import type { State as StripeState } from './stripe';
import type { State as AdsState } from './ads';
import type { State as LiveCompareState } from './liveCompare';
import type { State as LivestreamEventsTicketsState } from './livestreamEventsTickets';
import type { State as DownloadState } from './download';
import type { State as LocalListSearchState } from './localListSearch';

import type { Action } from '../actions/types';

// This is THE state type
export type State = {|
  +lastActions: LastActionsState,
  +client: ClientState,
  +player: PlayerState,
  +quickSearch: QuickSearchState,
  +search: SearchState,
  +popularSearches: PopularSearchesState,
  +entities: EntitiesState,
  +lists: ListsState,
  +maps: MapsState,
  +indexes: IndexesState,
  +auth: AuthState,
  +me: MeState,
  +subscription: SubscriptionState,
  +routing: {
    +locationBeforeTransitions: {
      pathname: string,
      query: Object,
      state: Object,
    },
  },
  +analyticsProgressBuffer: AnalyticsProgressBufferState,
  +analyticsPlayback: AnalyticsPlaybackState,
  +recentlyPlayed: RecentlyPlayedState,
  +sonos: SonosState,
  +stripe: StripeState,
  +ads: AdsState,
  +liveCompare: LiveCompareState,
  +livestreamEventsTickets: LivestreamEventsTicketsState,
  +download: DownloadState,
  +localListSearch: LocalListSearchState,
|};

const combinedReducer: (state: State, action: Action) => State = combineReducers({
  lastActions,
  client,
  player,
  quickSearch,
  search,
  popularSearches,
  entities,
  lists,
  maps,
  indexes,
  auth,
  me,
  subscription,
  routing: routerReducer,
  analyticsProgressBuffer,
  analyticsPlayback,
  recentlyPlayed,
  sonos,
  incomingVoucher,
  subscriptionOffer,
  stripe,
  ads,
  liveCompare,
  livestreamEventsTickets,
  download,
  localListSearch,
});

export default combinedReducer;
