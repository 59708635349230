// @flow
import React from 'react';
import styles from './ProceedToPayment.css';
import { FormattedMessage } from 'react-intl';
import PlanPrice from './PlanPrice';
import ToggleSwitch from '../util/ToggleSwitch';
import { PLAN_ID_PPC_12M, PLAN_ID_PPC_1M } from '../../constants';
import classNames from 'classnames';
import { planTitleMessages } from '../messages/PlansOverviewMessages';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';
import { selectLocale } from '../../selectors/client';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

type OwnProps = {
  proceedToPayment: Function,
  selectedPlan: string,
  setSelectedPlan: Function,
  relativeDiscount?: number,
  price: number,
  isStudentStripeCoupon: boolean,
};

type MapStateToProps = {
  locale: string,
};

type Props = OwnProps & MapStateToProps;

const ProceedToPayment = ({
  proceedToPayment,
  selectedPlan,
  setSelectedPlan,
  relativeDiscount,
  price,
  isStudentStripeCoupon,
  locale,
}: Props) => {
  const onToggleSwitchChange = e => {
    const targetPlan = e.target.checked ? PLAN_ID_PPC_12M : PLAN_ID_PPC_1M;
    setSelectedPlan(targetPlan);
  };

  return (
    <div className={styles.container} data-test="proceed-to-payment">
      {selectedPlan && (
        <React.Fragment>
          <div className={styles.title}>
            <FormattedMessage
              id="premium.plan-summary.selected-plan.title"
              defaultMessage="Your chosen plan"
            />
            {(selectedPlan === PLAN_ID_PPC_1M || selectedPlan === PLAN_ID_PPC_12M) && (
              <ToggleSwitch
                checked={selectedPlan === PLAN_ID_PPC_12M}
                id="ppc-12m"
                name="ppc-12m"
                onChange={onToggleSwitchChange}
                descriptionLeft={
                  <FormattedMessage
                    id="premium.plan-summary.selected-plan.pay-annually"
                    defaultMessage="Pay annually?"
                  />
                }
              />
            )}
          </div>
          <div className={styles.plan}>
            <span data-test="plan-summary.selected-plan.title" data-test-value={selectedPlan}>
              IDAGIO&nbsp;
              <FormattedMessage {...planTitleMessages[selectedPlan]} />
            </span>
            <PlanPrice
              price={price}
              relativeDiscount={relativeDiscount}
              interval={selectedPlan === PLAN_ID_PPC_12M ? 'yearly' : 'monthly'}
            />
          </div>
        </React.Fragment>
      )}
      <button
        className={classNames('c-btn c-btn--is-inverted', styles.btn)}
        onClick={proceedToPayment}
        disabled={!selectedPlan}
        data-tip
        data-for="proceed-to-payment"
        data-test="proceed-to-payment.btn"
      >
        <FormattedMessage id="premium.plan-summary.btn.continue" defaultMessage="Continue" />
      </button>
      {!selectedPlan && (
        <ReactTooltip className={styles.tooltip} id="proceed-to-payment" effect="solid" place="top">
          <FormattedMessage
            id="premium.plan-summary.tooltip.continue"
            defaultMessage="Please select a plan to continue"
          />
        </ReactTooltip>
      )}
      {!isStudentStripeCoupon && (
        <span className={styles.student}>
          <FormattedMessage
            id="premium.plan-summary.student"
            defaultMessage="You are a student? Get 50% off any plan! {clickHere}"
            values={{
              clickHere: (
                <a
                  href={getWebsiteUrlInLocale('/students', locale)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-text-link--is-visible"
                >
                  <FormattedMessage
                    id="premium.plan-summary.student.click-here"
                    defaultMessage="Click here"
                  />
                </a>
              ),
            }}
          />
        </span>
      )}
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    locale: selectLocale(state),
  };
}

export default connect(mapStateToProps)(ProceedToPayment);
