import { reduce, includes } from 'lodash';
import { BOOLEAN_FACET_PARAMS } from '../constants';

const ignoredKeys = ['sort', 'artist_id', 'searchTerm', ...BOOLEAN_FACET_PARAMS];

export default function singulariseParamObject(object) {
  return reduce(
    object,
    (acc, item, key) => {
      let singularKey = key;
      if (!includes(ignoredKeys, key)) {
        singularKey = key.slice(0, -1);
      }

      return {
        ...acc,
        [singularKey]: item,
      };
    },
    {}
  );
}
