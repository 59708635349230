// @flow
import React from 'react';
import styles from './PlansOverviewSummary.css';
import { FormattedMessage } from 'react-intl';
import PlanBenefit from './PlanBenefit';

type OwnProps = {
  isTrialAvailable: boolean,
  trialDurationDays: number,
};

type Props = OwnProps;

const PlansOverviewSummary = ({ isTrialAvailable, trialDurationDays }: Props) => {
  return (
    <div className={styles.container}>
      <h1>
        <FormattedMessage id="premium.plan-summary.title" defaultMessage="Pick Your Subscription" />
      </h1>
      <p className={styles.description}>
        <FormattedMessage
          id="premium.plan-summary.description"
          defaultMessage="Choose the plan that suits you best to dive into the unlimited world of classical music."
        />
      </p>
      <div className={styles.benefits}>
        {isTrialAvailable && (
          <PlanBenefit
            title={
              <FormattedMessage
                id="premium.plan-summary.trial.title"
                defaultMessage="{trialDurationDays}-day Free Trial Included"
                values={{ trialDurationDays }}
              />
            }
            description={
              <FormattedMessage
                id="premium.plan-summary.trial"
                defaultMessage="All Plans come with a {trialDurationDays}-day free trial period! No commitment. Cancel at any time."
                values={{ trialDurationDays }}
              />
            }
            data-test="plans-overview-summary.trial"
            data-test-value={trialDurationDays}
          />
        )}
      </div>
    </div>
  );
};

export default PlansOverviewSummary;
