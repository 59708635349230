import { createSelector } from 'reselect';
import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

import {
  LIVESTREAM_TYPE_CONCERT,
  LIVESTREAM_TYPE_STORY,
  LIVESTREAM_TYPE_COURSE,
  MEETING_EARLY_ACCESS_PERIOD,
  COURSE_RESOURCE_TYPE,
  CONTENT_TYPE,
} from '../constants';
import { selectUserIsConcertTier, selectUserIsAuthenticated } from './user';
import { selectTicketPurchaseIsDisabled } from './client';

export const hasValidTicketForEvent = (tickets = [], eventId) => {
  if (tickets && tickets.length) {
    return tickets
      .filter(
        ({ product_type: productType }) =>
          productType.toLowerCase() === 'cf.LivestreamEvent'.toLowerCase()
      )
      .some(({ product_id: ticketEventId, expires_at: expiresAt }) => {
        const hasTicket = eventId === ticketEventId;
        let notExpired = true;
        if (expiresAt) {
          const now = new Date();
          const expiryDate = new Date(expiresAt * 1000);
          notExpired = now < expiryDate;
        }

        return hasTicket && notExpired;
      });
  }
  return false;
};

export const selectUserHasValidTicketForEvent = (state, event) => {
  const eventId = event.id;
  const tickets = state.livestreamEventsTickets.tickets;
  return hasValidTicketForEvent(tickets, eventId);
};

export const selectUserCanPlaybackEvent = (state, event) => {
  return selectUserIsConcertTier(state) && event.isIncludedInConcertTier;
};

export const selectUserHasAccessToEvent = (state, event) => {
  if (!selectUserIsAuthenticated(state)) {
    return false;
  }

  if (!event.isBookable) {
    return true;
  }

  if (selectUserCanPlaybackEvent(state, event)) {
    return true;
  }

  return selectUserHasValidTicketForEvent(state, event);
};

export const selectDisableTicketPurchase = (state, event) => {
  return event.disableTicketPurchase || selectTicketPurchaseIsDisabled(state);
};

export const selectUserCanPurchaseEvent = (state, event) => {
  if (!event.isBookable) {
    return false;
  }

  if (selectUserCanPlaybackEvent(state, event)) {
    return false;
  }

  if (selectDisableTicketPurchase(state, event)) {
    return false;
  }

  return true;
};

export const selectShouldShowVideo = (state, event) => {
  if (!event.video) {
    return false;
  }

  // always play video on courses for course trailer regardless of the time
  if (event.isCourse) {
    return true;
  }

  if (event.isUpcoming || !event.isAvailable) {
    return false;
  }

  return selectUserHasAccessToEvent(state, event);
};

export const selectShouldShowPrice = (state, event) => {
  if (!event.hasPriceSet) {
    return false;
  }

  // always shows "Free of charge" on free events
  if (event.price.isFree) {
    return true;
  }

  if (selectDisableTicketPurchase(state, event)) {
    return false;
  }

  if (!selectUserIsAuthenticated(state)) {
    return true;
  }

  // otherwise shows the price only if user has no access to the event yet
  return !selectUserHasAccessToEvent(state, event);
};

const minuteInMilliseconds = 60 * 1000;
const hourInMilliseconds = 60 * minuteInMilliseconds;

export const livestreamEventStatusDecorator = item => {
  const present = new Date();
  const start = new Date(item.start);
  const end = new Date(item.end);
  const hasStarted = present > start;
  const hasEnded = present > end;
  const isLive = hasStarted && !hasEnded;
  const isUpcoming = !hasStarted;

  // Price
  const { ticketPriceEuro, ticketPriceGbp, ticketPriceUsd, priceTemplate } = item;

  const hasOldPriceSet = Boolean(
    ticketPriceEuro !== undefined || ticketPriceGbp !== undefined || ticketPriceUsd !== undefined
  );
  const hasPriceTemplateSet = Boolean(priceTemplate && priceTemplate.name);
  const hasPriceSet = hasOldPriceSet || hasPriceTemplateSet;

  const isFreeEvent =
    (hasOldPriceSet && ticketPriceEuro === 0) ||
    (hasPriceTemplateSet && priceTemplate.minEur === 0 && !priceTemplate.maxEur);
  const isFixedPrice =
    (hasOldPriceSet && !hasPriceTemplateSet) ||
    (hasPriceTemplateSet && Boolean(priceTemplate.isFixedPrice));

  // Maintain backwards compatibility with old ticketPrice* keys
  let fixedValues = {
    EUR: ticketPriceEuro ? ticketPriceEuro : null,
    USD: ticketPriceUsd ? ticketPriceUsd : null,
    GBP: ticketPriceGbp ? ticketPriceGbp : null,
  };
  let variableValues = null;

  // Handle price templates
  if (hasPriceTemplateSet) {
    if (isFixedPrice) {
      // Overwrite fixed price
      fixedValues = {
        EUR: priceTemplate.minEur,
        GBP: priceTemplate.minGbp,
        USD: priceTemplate.minUsd,
      };
    } else {
      variableValues = {};
      variableValues.min = {
        EUR: priceTemplate.minEur,
        GBP: priceTemplate.minGbp,
        USD: priceTemplate.minUsd,
      };
      variableValues.max = {
        EUR: priceTemplate.maxEur,
        GBP: priceTemplate.maxGbp,
        USD: priceTemplate.maxUsd,
      };
    }
  }

  const price = {
    isFree: isFreeEvent,
    isFixed: isFixedPrice,
    fixedValues,
    variableValues,
  };

  const type = item.type ? item.type : LIVESTREAM_TYPE_STORY;
  const isConcert = type === LIVESTREAM_TYPE_CONCERT;
  const isCourse = type === LIVESTREAM_TYPE_COURSE;

  const isBookable = (isConcert || isCourse) && !isFreeEvent;

  const defaultAvailabilityEnd = new Date(end.getTime() + 24 * hourInMilliseconds);
  const availabilityEnd = new Date(item.availabilityEnd || defaultAvailabilityEnd);
  const isAvailable = present < availabilityEnd;

  const ticketSaleEnd = new Date(availabilityEnd.getTime() - 2 * hourInMilliseconds);
  const areTicketsAvailable = isBookable ? present < ticketSaleEnd : false;

  return {
    ...item,

    type,
    startDate: start,
    hasStarted,
    hasEnded,
    endDate: end,
    isLive,
    isUpcoming,
    isConcert,
    isCourse,
    isBookable,
    isIncludedInConcertTier: !isCourse,
    areTicketsAvailable,
    isAvailable,
    availabilityEnd,

    // Price
    hasPriceSet,
    price,
  };
};

export const courseSessionDecorator = (session, index) => {
  const present = new Date();
  const start = new Date(session.start);
  const end = new Date(session.end);
  const hasStarted = present > start;
  const hasEnded = present > end;
  const isWithinAccessPeriod =
    present > new Date(start - MEETING_EARLY_ACCESS_PERIOD * minuteInMilliseconds);
  const durationInMins = Math.round((end - start) / minuteInMilliseconds);

  return {
    ...session,
    sessionNo: index + 1,
    isWithinAccessPeriod,
    hasStarted,
    hasEnded,
    durationInMins,
  };
};

const RESOURCE_TYPE = {
  [COURSE_RESOURCE_TYPE.IMAGE]: [CONTENT_TYPE.IMAGE],
  [COURSE_RESOURCE_TYPE.AUDIO]: [CONTENT_TYPE.AUDIO],
  [COURSE_RESOURCE_TYPE.VIDEO]: [CONTENT_TYPE.VIDEO],
  [COURSE_RESOURCE_TYPE.DOCUMENT]: [CONTENT_TYPE.PDF, CONTENT_TYPE.PRESENTATION],
};

const getContentType = (resourceType, contentType) => {
  const validContentTypes = RESOURCE_TYPE[resourceType];

  for (const validContentType of validContentTypes) {
    if (contentType.toLowerCase().includes(validContentType.toLowerCase())) {
      return validContentType;
    }
  }

  return null;
};

export const courseResourceDecorator = (resourceType, resource) => ({
  ...resource,
  contentType: getContentType(resourceType, resource.asset.file.contentType),
});

const selectLivestreamEventIdFromSlug = (state, slug) =>
  state.maps.livestreamEvents[slug] ? state.maps.livestreamEvents[slug].entities : null;

export const selectLivestreamEvent = createSelector(
  [
    state => state.entities,
    selectLivestreamEventIdFromSlug,
    state => state.entities.livestreamEvents,
  ],
  (entities, id, list) => {
    if (!id || !list[id]) {
      return null;
    }

    const event = assembleEntity(Schema.LivestreamEvent, entities, id);
    return livestreamEventStatusDecorator(event);
  }
);
