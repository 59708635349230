// @flow

import { AUDIO_QUALITY_LOW } from '../../constants';

import type { SetAudioQuality, AudioQuality } from '../../actions/client';

export type State = {| +audioQuality: AudioQuality |};

const initialState = {
  audioQuality: AUDIO_QUALITY_LOW,
};

export default function preferences(state: State = initialState, action: SetAudioQuality) {
  if (action.type === 'SET_AUDIO_QUALITY') {
    return {
      ...state,
      audioQuality: action.quality,
    };
  }

  return state;
}
