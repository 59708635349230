// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './PlansOverviewSubscribe.css';

import {
  PLAN_ID_PREMIUM,
  PLAN_ID_PPC_1M,
  PLAN_ID_PPC_12M,
  PLAN_ID_PREMIUM_PLUS,
  SUBSCRIPTION_PLANS,
} from '../../constants';

import {
  objectivesMessages,
  planDescriptionMessages,
  planTitleMessages,
} from '../messages/PlansOverviewMessages';
import PlanOptionSubscribe from './PlanOptionSubscribe';
import ToggleSwitch from '../util/ToggleSwitch';
import IconLabel from '../util/IconLabel';
import PlanPrice from './PlanPrice';
import classNames from 'classnames';

type OwnProps = {
  setSelectedPlan: Function,
  setPremiumPlusPlan: Function,
  premiumPlusPlan: string,
  selectedPlan: string,
  relativeDiscount?: number,
};

type Props = OwnProps;

const PlansOverviewSubscribe = ({
  setSelectedPlan,
  setPremiumPlusPlan,
  premiumPlusPlan,
  selectedPlan,
  relativeDiscount,
}: Props) => {
  const onToggleSwitchChange = e => {
    const targetPlan = e.target.checked ? PLAN_ID_PPC_12M : PLAN_ID_PREMIUM_PLUS;
    setPremiumPlusPlan(targetPlan);
    setSelectedPlan(targetPlan);
  };

  return (
    <React.Fragment>
      <PlanOptionSubscribe
        id={PLAN_ID_PREMIUM}
        isSelected={selectedPlan === PLAN_ID_PREMIUM}
        onSelect={setSelectedPlan}
        imageTitle={<FormattedMessage {...planTitleMessages[PLAN_ID_PREMIUM]} />}
        title={<FormattedMessage {...planDescriptionMessages[PLAN_ID_PREMIUM]} />}
        monthlyPrice={SUBSCRIPTION_PLANS[PLAN_ID_PREMIUM].monthlyPrice}
        relativeDiscount={relativeDiscount}
        objectives={[
          {
            key: 'library',
            value: <FormattedMessage {...objectivesMessages.library} />,
          },
          {
            key: 'adFree',
            value: <FormattedMessage {...objectivesMessages.adFree} />,
          },
          {
            key: 'weeklyMixes',
            value: <FormattedMessage {...objectivesMessages.weeklyMixes} />,
          },
        ]}
        className={styles.pPlan}
        data-test="plan-option.premium"
      />
      <PlanOptionSubscribe
        id={PLAN_ID_PREMIUM_PLUS}
        isSelected={
          selectedPlan === PLAN_ID_PREMIUM_PLUS ||
          selectedPlan === PLAN_ID_PPC_12M ||
          selectedPlan === PLAN_ID_PPC_1M
        }
        onSelect={() => {
          setSelectedPlan(premiumPlusPlan);
        }}
        imageTitle={<FormattedMessage {...planTitleMessages[PLAN_ID_PREMIUM_PLUS]} />}
        title={<FormattedMessage {...planDescriptionMessages[PLAN_ID_PREMIUM_PLUS]} />}
        monthlyPrice={SUBSCRIPTION_PLANS[PLAN_ID_PREMIUM_PLUS].monthlyPrice}
        relativeDiscount={relativeDiscount}
        objectives={[
          {
            key: 'premiumFeatures',
            value: <FormattedMessage {...objectivesMessages.premiumFeatures} />,
          },
          {
            key: 'quality',
            value: <FormattedMessage {...objectivesMessages.quality} />,
          },
        ]}
        className={styles.ppPlan}
        data-test="plan-option.premium-plus"
      >
        <div className={styles.addOn} data-test="plan-option.premium-plus.add-on">
          <div className={styles.addOnPlan}>
            <div className={styles.info}>
              <IconLabel name="events" className={styles.addOnIcon} />
              <div className={styles.addOnInfo}>
                <span className={styles.addOnTitle}>
                  <FormattedMessage
                    id="subscribe-modal.premium-plus.add-on.title"
                    defaultMessage="Add-on: Video"
                  />
                </span>
                <div className={styles.addOnPrice}>
                  <PlanPrice
                    price={
                      SUBSCRIPTION_PLANS[PLAN_ID_PPC_12M].monthlyPrice -
                      SUBSCRIPTION_PLANS[PLAN_ID_PREMIUM_PLUS].monthlyPrice
                    }
                    relativeDiscount={relativeDiscount}
                    interval="monthly"
                  />
                  <span className={classNames('u-tag', styles.annualPlanTag)}>
                    <FormattedMessage
                      id="subscribe-modal.premium-plus.add-on.tag"
                      defaultMessage="When billed annually"
                    />
                  </span>
                </div>
              </div>
            </div>
            <ToggleSwitch
              checked={
                premiumPlusPlan === PLAN_ID_PPC_12M ||
                selectedPlan === PLAN_ID_PPC_12M ||
                selectedPlan === PLAN_ID_PPC_1M
              }
              id="ppc-plan"
              name="ppc-plan"
              onChange={onToggleSwitchChange}
              className={styles.addOnToggle}
            />
          </div>
          <div className={styles.addOnDescription}>
            <FormattedMessage
              id="subscribe-modal.premium-plus.add-on.description"
              defaultMessage="Enjoy premium concerts, operas, and dance, plus a dedicated tvOS app for the ultimate classical experience."
            />
          </div>
        </div>
      </PlanOptionSubscribe>
    </React.Fragment>
  );
};

export default PlansOverviewSubscribe;
