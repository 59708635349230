import { omit } from 'lodash';

/**
 * Explicitly different structure between the list and the item.
 * The tracks structure is different between the item and the list.
 * So don't try to use `normalizePersonalPlaylist` inside
 * `normalizePersonalPlaylistList`
 */
export function normalizePersonalPlaylistList(response) {
  return response.results.map(
    ({
      id,
      title,
      track_ids: trackIds,
      image_url: imageUrl,
      created_at: createdAt,
      updated_at: updatedAt,
    }) => ({
      id,
      title,
      imageUrl,
      trackIds,
      createdAt,
      updatedAt,
    })
  );
}

export function normalizePersonalPlaylist(response) {
  const playlist = response.result;
  const { image_url: imageUrl, tracks } = playlist;
  return {
    ...omit(playlist, ['image_url']),
    imageUrl,
    trackIds: tracks.map(track => track.id.toString()),
  };
}
