import { defineMessages } from 'react-intl';
import {
  PLAN_ID_PPC_12M,
  PLAN_ID_PPC_1M,
  PLAN_ID_PREMIUM,
  PLAN_ID_PREMIUM_PLUS,
} from '../../constants';

export const objectivesMessages = defineMessages({
  library: {
    id: 'index.plans-overview.premium.library',
    defaultMessage: 'Enjoy a library of 2+ million tracks and exclusive releases',
  },
  adFree: {
    id: 'index.plans-overview.premium.on-demand-ad-free',
    defaultMessage: 'Listen uninterrupted by ads, anywhere, anytime',
  },
  weeklyMixes: {
    id: 'index.plans-overview.premium.weekly-mixes',
    defaultMessage: 'Discover curated recommendations & weekly mixes',
  },
  premiumFeatures: {
    id: 'index.plans-overview.premium-plus.premium-features',
    defaultMessage: 'ALL Basic Audio Features',
  },
  quality: {
    id: 'index.plans-overview.premium-plus.lossless',
    defaultMessage:
      'Experience classical music in the best quality possible (FLAC 16-bit, 44.1kHz)',
  },
  premiumPlusFeatures: {
    id: 'index.plans-overview.premium-plus-concerts.premium-plus-features',
    defaultMessage: 'ALL Premium Audio Features',
  },
  concertAccess: {
    id: 'index.plans-overview.premium-plus-concerts.access',
    defaultMessage:
      'Watch exclusive video content, including concerts, operas, and dance performances',
  },
});

export const planTitleMessages = defineMessages({
  [PLAN_ID_PREMIUM]: {
    id: 'index.plans-overview.premium.title',
    defaultMessage: 'Basic Audio',
  },
  [PLAN_ID_PREMIUM_PLUS]: {
    id: 'index.plans-overview.premium-plus.title',
    defaultMessage: 'Premium Audio',
  },
  [PLAN_ID_PPC_1M]: {
    id: 'index.plans-overview.ppc-1m.title',
    defaultMessage: 'Premium Audio + Video',
  },
  [PLAN_ID_PPC_12M]: {
    id: 'index.plans-overview.ppc-12m.title',
    defaultMessage: 'Premium Audio + Video',
  },
});

export const planDescriptionMessages = defineMessages({
  [PLAN_ID_PREMIUM]: {
    id: 'index.plans-overview.premium.description',
    defaultMessage: 'Our Entry Level Plan',
  },
  [PLAN_ID_PREMIUM_PLUS]: {
    id: 'index.plans-overview.premium-plus.description',
    defaultMessage: 'Our Most Popular Plan',
  },
  [PLAN_ID_PPC_1M]: {
    id: 'index.plans-overview.ppc-1m.description',
    defaultMessage: 'Our Ultimate Experience',
  },
  [PLAN_ID_PPC_12M]: {
    id: 'index.plans-overview.ppc-12m.description',
    defaultMessage: 'Our Ultimate Experience',
  },
});
