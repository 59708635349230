// @flow

import * as React from 'react';
import styles from './ParticipantList.css';
import { FormattedMessage } from 'react-intl';
import Separated from '../util/Separated';

type OwnProps = {
  title: React.Element<typeof FormattedMessage>,
  names: Array<string>,
};

const ParticipantInfo = ({ title, names }: OwnProps) => {
  if (!names.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}:&nbsp;</span>
      <Separated>{names}</Separated>
    </div>
  );
};

export default ParticipantInfo;
