// @flow

import update from 'immutability-helper';

import {
  selectNextQueueItemFromPlayerState,
  selectPreviousQueueItemFromPlayerState,
} from '../selectors/player';

import {
  QUEUE_TYPE_RECORDING,
  QUEUE_TYPE_PLAYLIST,
  QUEUE_TYPE_PERSONAL_PLAYLIST,
  QUEUE_TYPE_MIX,
  QUEUE_TYPE_ALBUM,
} from '../constants';

import type {
  QueueItem,
  PlayerAction,
  SetCurrentQueueItemAction,
  SetCurrentQueueItemIndexAction,
} from '../actions/player';
import type { PlayerQueueAction } from '../actions/queue';
import type { NetworkErrorAction, ConnectedAction } from '../actions/connection';
import type { SetConnectedSonosGroupAction } from '../actions/sonos';
import type {
  AdPlaybackProgress,
  EndedAdPlayback,
  ResetAdQueueAction,
  SetAdQueueAndPlayAction,
  StartedAdPlayback,
} from '../actions/ads';
import type { LogoutAction } from '../actions/auth';

type Action =
  | PlayerAction
  | SetCurrentQueueItemAction
  | SetCurrentQueueItemIndexAction
  | PlayerQueueAction
  | ConnectedAction
  | NetworkErrorAction
  | SetConnectedSonosGroupAction
  | StartedAdPlayback
  | EndedAdPlayback
  | AdPlaybackProgress
  | LogoutAction
  | SetAdQueueAndPlayAction
  | ResetAdQueueAction;

type QueueOriginType =
  | typeof QUEUE_TYPE_RECORDING
  | typeof QUEUE_TYPE_PLAYLIST
  | typeof QUEUE_TYPE_PERSONAL_PLAYLIST
  | typeof QUEUE_TYPE_MIX
  | typeof QUEUE_TYPE_ALBUM;

export type QueueOrigin = {|
  type: QueueOriginType,
  id: string,
|};

type QueueItems = Array<QueueItem>;

export type State = {|
  +queueItems: QueueItems,
  +originalTracks: QueueItems,
  +currentQueueItem: ?QueueItem,
  +queueOrigin: ?QueueOrigin,
  +loadingQueueOrigin: ?QueueOrigin,
  +queueIsLoading: boolean,
  +queueIsLoaded: boolean,
  +playing: boolean,
  +paused: boolean,
  +seeking: boolean,
  +progress: number,
  +duration: number,
  +volume: number,
  +isMuted: boolean,
  +repeatAll: boolean,
  +repeatOne: boolean,
  +shuffleRecordings: boolean,
  +shuffleTracks: boolean,
  +wasPlayingOnNetworkError: boolean,
  +interpretationQueue:
    | {}
    | {|
        origin: QueueOrigin,
        queue: QueueItems,
      |},
  +controlsEnabled: boolean,
  +isPlayingAd: boolean,
|};

const initialState = {
  queueItems: [],
  originalTracks: [],
  currentQueueItem: null,
  queueOrigin: null,
  loadingQueueOrigin: null,
  queueIsLoading: false,
  queueIsLoaded: false,
  playing: false,
  paused: false,
  seeking: false,
  progress: 0,
  duration: 0,
  volume: 100,
  isMuted: false,
  repeatAll: false,
  repeatOne: false,
  shuffleRecordings: false,
  shuffleTracks: false,
  wasPlayingOnNetworkError: false,
  interpretationQueue: {},
  controlsEnabled: true,
  isPlayingAd: false,
};

export default function player(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'PLAYER_ENABLE_CONTROLS':
      return update(state, {
        controlsEnabled: { $set: true },
      });
    case 'PLAYER_DISABLE_CONTROLS':
      return update(state, {
        controlsEnabled: { $set: false },
      });
    case 'PLAYER_PROGRESS':
      // Handle player progress first, as it happens most frequently
      // (every 1 second when playing)

      /*
      If a scheduled update-callback is fired after the track in question has been changed,
      don't do anything. Otherwise, this sometimes causes playback to start in the middle
      of tracks.
    */
      if (!state.currentQueueItem || action.trackId !== state.currentQueueItem.track) {
        // eslint-disable-next-line no-console
        console.warn(
          'action.trackId',
          action.trackId,
          'vs state.currentQueueItem.track',
          state.currentQueueItem && state.currentQueueItem.track,
          'action',
          action
        );
        return state;
      }

      return update(state, {
        progress: { $set: action.value },
        duration: { $set: action.duration },
      });
    case 'AD_PLAYBACK_PROGRESS':
      return update(state, {
        progress: { $set: action.value },
        duration: { $set: action.duration },
      });
    case 'PLAYER_RESUME':
      return update(state, {
        playing: { $set: true },
        paused: { $set: false },
      });
    case 'PLAYER_PLAY': {
      const newState: { progress?: { $set: number } } = {
        playing: { $set: true },
        paused: { $set: false },
        currentQueueItem: { $set: action.queueItem },
      };

      if (
        state.currentQueueItem &&
        action.queueItem &&
        state.currentQueueItem.track !== action.queueItem.track
      ) {
        newState.progress = { $set: 0 };
      }
      return update(state, newState);
    }
    case 'PLAYER_SET_CURRENT_QUEUE_ITEM':
      if (state.isPlayingAd) {
        // don't affect shared parts of the state
        return update(state, {
          // todo: think whether removing progress: 0, playing: false is really necessary
          currentQueueItem: { $set: action.queueItem },
        });
      }
      return update(state, {
        currentQueueItem: { $set: action.queueItem },
      });
    case 'PLAYER_SET_CURRENT_QUEUE_ITEM_INDEX':
      return update(state, {
        currentQueueItem: {
          index: { $set: action.index },
        },
      });
    case 'PLAYER_PAUSE':
      return update(state, {
        playing: { $set: false },
        paused: { $set: true },
      });
    case 'PLAYER_SEEK_BEGIN':
      return update(state, {
        seeking: { $set: true },
      });
    case 'PLAYER_SEEK_FINISH':
      return update(state, {
        seeking: { $set: false },
        progress: { $set: action.newPosition },
      });
    case 'PLAYER_NEXT':
      return update(state, {
        currentQueueItem: { $set: selectNextQueueItemFromPlayerState(state) },
        progress: { $set: 0 },
      });
    case 'PLAYER_PREVIOUS':
      if (!selectPreviousQueueItemFromPlayerState(state)) {
        return state;
      }

      return update(state, {
        currentQueueItem: { $set: selectPreviousQueueItemFromPlayerState(state) },
        progress: { $set: 0 },
      });
    case 'PLAYER_REWIND':
      return update(state, {
        progress: { $set: 0 },
      });
    case 'PLAYER_QUEUE_LOAD':
      return update(state, {
        loadingQueueOrigin: { $set: action.origin },
        queueIsLoading: { $set: true },
        queueIsLoaded: { $set: false },
      });
    case 'PLAYER_QUEUE_LOADED':
      return update(state, {
        loadingQueueOrigin: { $set: null },
        queueIsLoading: { $set: false },
        queueIsLoaded: { $set: true },
      });
    case 'PLAYER_QUEUE_SET': {
      return update(state, {
        queueItems: { $set: action.tracks },
        queueOrigin: { $set: action.origin },
        originalTracks: { $set: action.originalTracks },
      });
    }
    case 'PLAYER_QUEUE_LOAD_ABORT':
      return update(state, {
        loadingQueueOrigin: { $set: null },
        queueIsLoading: { $set: false },
        queueIsLoaded: { $set: false },
      });
    case 'PLAYER_QUEUE_RESET':
      return update(state, {
        currentQueueItem: { $set: state.queueItems[0] },
        playing: { $set: false },
        paused: { $set: false },
        progress: { $set: 0 },
        queueIsLoading: { $set: false },
      });
    case 'PLAYER_VOLUME':
      return update(state, {
        volume: { $set: action.value },
      });
    case 'PLAYER_TOGGLE_MUTE':
      return update(state, {
        isMuted: { $set: !state.isMuted },
      });
    case 'PLAYER_TOGGLE_REPEAT':
      if (!state.repeatAll && !state.repeatOne) {
        return update(state, {
          repeatAll: { $set: true },
          repeatOne: { $set: false },
        });
      }

      if (!state.repeatOne) {
        return update(state, {
          repeatAll: { $set: false },
          repeatOne: { $set: true },
        });
      }

      return update(state, {
        repeatAll: { $set: false },
        repeatOne: { $set: false },
      });
    case 'PLAYER_TOGGLE_SHUFFLE':
      if (!state.shuffleRecordings && !state.shuffleTracks) {
        return update(state, {
          shuffleRecordings: { $set: true },
          shuffleTracks: { $set: false },
        });
      }

      if (!state.shuffleTracks) {
        return update(state, {
          shuffleRecordings: { $set: false },
          shuffleTracks: { $set: true },
        });
      }

      return update(state, {
        shuffleRecordings: { $set: false },
        shuffleTracks: { $set: false },
      });
    case 'NETWORK_ERROR':
      if (action.source === 'playback') {
        return update(state, {
          playing: { $set: false },
          wasPlayingOnNetworkError: { $set: state.playing },
        });
      }
      return state;
    case 'CONNECTED': {
      const nextPlaying = state.wasPlayingOnNetworkError ? true : state.playing;
      return update(state, {
        playing: { $set: nextPlaying },
        paused: { $set: false },
      });
    }
    case 'SET_CONNECTED_SONOS_GROUP':
      /*
      Sonos does not support looping at the moment. Not resetting here can
      make the player and sonos queues diverge when skipping tracks. It's an
      acceptable inconvenience that this state is lost on return to local playback.
    */
      return update(state, {
        repeatAll: { $set: false },
        repeatOne: { $set: false },
      });

    case 'SET_AD_QUEUE_AND_PLAY':
      return update(state, {
        isPlayingAd: { $set: true },
      });

    case 'STARTED_AD_PLAYBACK':
      return update(state, {
        isPlayingAd: { $set: true },
        playing: { $set: true },
      });

    case 'ENDED_AD_PLAYBACK':
      return update(state, {
        isPlayingAd: { $set: false },
        progress: { $set: 0 },
        duration: { $set: 0 },
      });
    case 'RESET_AD_QUEUE':
      return update(state, {
        isPlayingAd: { $set: false },
        progress: { $set: 0 },
        duration: { $set: 0 },
      });
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}

export function hasNextQueueItem(playerState: State) {
  return selectNextQueueItemFromPlayerState(playerState) !== undefined;
}

export function hasPreviousQueueItem(playerState: State) {
  return selectPreviousQueueItemFromPlayerState(playerState) !== undefined;
}

export function isQueueEmpty(playerState: State) {
  return playerState.queueItems.length === 0;
}
