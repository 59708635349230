import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import { Link } from 'react-router';

import Modal from './Modal';
import classnames from 'classnames';
import * as uiActions from '../../actions/ui';
import stylesWelcomeModal from './WelcomeModal.css';
import { ITUNES_APP_LINK, PLAY_STORE_APP_LINK, CONSTANT_MESSAGES } from '../../constants';
import * as analyticsActions from '../../actions/analytics';
import { selectOpenModalTypeIs } from '../../selectors/modals';
import { selectIsAndroid, selectIsDesktop, selectIsIOS } from '../../selectors/client';

const classesDownloadButton = classnames('c-btn', 'c-btn--is-purple', stylesWelcomeModal.button);

const messages = defineMessages({
  downloadLink: {
    id: 'landing.welcome-modal-download',
    defaultMessage: 'Download',
  },
});

class WelcomeModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    clientIsDesktop: PropTypes.bool.isRequired,
    clientIsAndroid: PropTypes.bool.isRequired,
    clientIsIOS: PropTypes.bool.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
    intl: intlShape,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  onClickDownloadDesktop = () => {
    this.props.hideModal();
    this.props.analyticsTrack('Clicked on Download Desktop Client', {
      source: 'WelcomeModal',
    });
  };

  onClickDownloadAndroid = () => {
    this.closeAndRedirectToDiscover();
    this.props.analyticsTrack('Clicked on Download Android Client', {
      source: 'WelcomeModal',
    });
  };

  onClickDownloadIOS = () => {
    this.closeAndRedirectToDiscover();
    this.props.analyticsTrack('Clicked on Download iOS Client', {
      source: 'WelcomeModal',
    });
  };

  onClickDownloadOther = () => {
    this.props.hideModal();
    this.props.analyticsTrack('Clicked on Download Other Client', {
      source: 'WelcomeModal',
    });
  };

  renderDownloadLink() {
    const { clientIsDesktop, clientIsAndroid, clientIsIOS, intl } = this.props;

    const text = intl.formatMessage(messages.downloadLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    if (clientIsDesktop) {
      return (
        <Link className={classesDownloadButton} to="/install" onClick={this.onClickDownloadDesktop}>
          {text}
        </Link>
      );
    }

    if (clientIsAndroid) {
      return (
        <a
          href={PLAY_STORE_APP_LINK}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.onClickDownloadAndroid}
          className={classesDownloadButton}
          aria-label={ariaLink}
        >
          {text}
        </a>
      );
    }

    if (clientIsIOS) {
      return (
        <a
          href={ITUNES_APP_LINK}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.onClickDownloadIOS}
          className={classesDownloadButton}
          aria-label={ariaLink}
        >
          {text}
        </a>
      );
    }

    // Default to download page
    return (
      <Link className={classesDownloadButton} to="/download" onClick={this.onClickDownloadOther}>
        <FormattedMessage
          id="landing.welcome-modal-download.cta"
          defaultMessage="Download desktop app"
        />
      </Link>
    );
  }

  render() {
    const { hideModal, isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} hideCloseButton contentLabel="welcome-modal">
        <section className={stylesWelcomeModal.modal}>
          <h1 className={stylesWelcomeModal.headline}>
            <FormattedMessage id="landing.welcome-modal" defaultMessage="Welcome to IDAGIO" />
          </h1>
          <p className={stylesWelcomeModal.subline}>
            <FormattedMessage
              id="landing.welcome-modal-paragraph"
              defaultMessage="Download the app for the best streaming experience."
            />
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 223.3 131"
            className={stylesWelcomeModal.svg}
          >
            <path d="M206.6,122.5c0.2-0.6,0.3-1.2,0.3-1.9V6c0-3.3-2.7-6-6-6H22.4c-3.3,0-6,2.7-6,6v114.6c0,0.7,0.1,1.3,0.3,1.9H0v4.3c0,1.4,1.3,2.5,3.9,3.2c2.1,0.6,5,1,8,1h199.6c5.7,0,11.8-1.3,11.8-4.2v-4.3H206.6z M18.4,120.6V6c0-2.2,1.8-4,4-4h178.6c2.2,0,4,1.8,4,4v114.6c0,0.7-0.2,1.3-0.5,1.9h-80.8v1c0,0.5-0.3,0.9-0.8,1.1c-0.1,0-0.3,0.1-0.4,0.1h-21.7c-0.2,0-0.3,0-0.4-0.1c-0.4-0.2-0.8-0.6-0.8-1.1v-1H18.8C18.5,121.9,18.4,121.3,18.4,120.6z M221.3,126.8c-0.1,0.7-3.6,2.2-9.8,2.2H11.8c-2.8,0-5.4-0.3-7.4-0.9c-2-0.6-2.4-1.2-2.5-1.3v-2.3h15.8h3.7h76.3c0,0,0,0.1,0,0.1c0.4,1,1.2,1.8,2.3,2c0.2,0,0.5,0.1,0.7,0.1h21.7c0.2,0,0.5,0,0.7-0.1c1.1-0.2,1.9-1,2.3-2c0,0,0-0.1,0-0.1h76.3h3.7h15.8V126.8z M101,70.5V52l14.8,9.2L101,70.5z M110.2,76v-5.5h11.1V76H110.2z" />
          </svg>
          <div>{this.renderDownloadLink()}</div>
          <Link className="c-text-link--is-visible" to="/discover" onClick={hideModal}>
            <FormattedMessage id="landing.welcome-modal-link" defaultMessage="Use the web app" />
          </Link>
        </section>
      </Modal>
    );
  }

  closeAndRedirectToDiscover = () => {
    this.props.hideModal();
    this.context.router.push('/discover');
  };
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'WELCOME_MODAL'),
    clientIsDesktop: selectIsDesktop(state),
    clientIsAndroid: selectIsAndroid(state),
    clientIsIOS: selectIsIOS(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    hideModal: uiActions.hideModal,
    analyticsTrack: analyticsActions.track,
  }),
  injectIntl
)(WelcomeModal);
