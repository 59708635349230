// @flow
import { PLEASE_WHILE_REDIRECTING } from '../lib/notifications';

declare var __ELECTRON__: boolean;

import { selectEncryptionEnabled, selectLocale, selectUserAgentInfo } from '../selectors/client';
import {
  clientSupportsAAC,
  clientSupportsLossless,
  clientSupportsMP3,
  clientSupportsPlayback,
  getPlaybackNotSupportedNotification,
} from '../client/playback-support';
import { addNotification } from './notifications';
import { updatePreferences } from './me';
import { selectLocalePreference, selectUserLanguageIsClientLanguage } from '../selectors/user';
import {
  AUDIO_QUALITY_LOSSLESS,
  AUDIO_QUALITY_HIGH,
  AUDIO_QUALITY_LOW,
  AUDIO_QUALITY_PREVIEW,
} from '../constants';

import type { ThunkAction, Request } from './types';
import type { UpdatePreferencesAction } from './me';
import { activeElementCapturesKeyEvents, activeElementIsTextField } from '../utils/dom';
import { splitLocale, switchUrlLanguage } from '../lib/locale-utils';
import { replace } from 'react-router-redux';

export type Currency = 'EUR' | 'USD' | 'CAD';

export type AudioQuality =
  | typeof AUDIO_QUALITY_LOSSLESS
  | typeof AUDIO_QUALITY_HIGH
  | typeof AUDIO_QUALITY_LOW
  | typeof AUDIO_QUALITY_PREVIEW;

export type CookiePreferences = {
  necessary: boolean,
  functional: boolean,
  advertising: boolean,
  marketingAndAnalytics: boolean,
};

export type SetPageVisibilityAction = {
  type: 'SET_PAGE_VISIBILITY',
  visible: boolean,
};
type IdentifyUseragentAction = {
  type: 'IDENTIFY_USER_AGENT',
  userAgentInfo: Object,
};
type KeyDownAction = { type: 'KEY_DOWN', event: KeyboardEvent };
export type SetLocaleAction = { type: 'SET_LOCALE', locale: string };
export type SetSupportedLocales = {
  type: 'SET_SUPPORTED_LOCALES',
  locales: Array<string>,
};
export type LoadImageAnnotationsAction = {
  type: 'LOAD_IMAGE_ANNOTATIONS',
} & Request;
export type LoadFeatureFlagsAction = { type: 'LOAD_FEATURE_FLAGS' } & Request;
export type SetAudioQuality = { type: 'SET_AUDIO_QUALITY', quality: AudioQuality };
export type SetPlaybackIsSupportedAction = { type: 'SET_PLAYBACK_IS_SUPPORTED' };
export type SetFLACPlaybackIsSupportedAction = { type: 'SET_FLAC_PLAYBACK_IS_SUPPORTED' };
export type SetPlaybackUsingAAC = { type: 'SET_PLAYBACK_USING_AAC' };
export type SetClientIPAction = { type: 'SET_CLIENT_IP', ip: string };
export type SetEncryptionEnabledAction = { type: 'SET_ENCRYPTION_ENABLED' };
export type SetIsLoadingAudioAction = { type: 'SET_IS_LOADING_AUDIO', flag: boolean };
export type SetClientVersionsAction = {
  type: 'SET_CLIENT_VERSIONS',
  electronVersion: string,
  webVersion: string,
};
export type DisableRecentlyPlayed = { type: 'DISABLE_RECENTLY_PLAYED' };
export type SetDeviceIdAction = { type: 'SET_DEVICE_ID', deviceId: string };
export type SaveCookiesConsentAction = {
  type: 'SAVE_COOKIES_CONSENT',
  preferences: CookiePreferences,
};
export type SetCountryAction = { type: 'SET_COUNTRY', country: string };
export type SetShouldBlockAction = { type: 'SET_SHOULD_BLOCK', shouldBlock: boolean };
export type SetCurrencyAction = { type: 'SET_CURRENCY', currency: Currency };
type SetAuthApiAction = { type: 'SET_AUTH_API_BASE', url: string };
type SetBaseUrlAction = { type: 'SET_BASE_URL', url: string };
type SetTopDomainAction = { type: 'SET_TOP_DOMAIN', domain: string };
type SetSegmentCdnHostAction = { type: 'SET_SEGMENT_CDN_HOST', host: string };
type SetEasyConnectSdkUrlOriginAction = {
  type: 'SET_EASY_CONNECT_SDK_URL_ORIGIN',
  url: string,
};
export type SetCaptchaType = { type: 'SET_CAPTCHA_TYPE', captchaType: string };
export type SetCaptchaSiteKey = { type: 'SET_CAPTCHA_SITE_KEY', captchaSiteKey: string };
export type SetFontLoadingAction = { type: 'SET_FONT_LOADING', font: string };
export type SetFontLoadedAction = { type: 'SET_FONT_LOADED', font: string };
export type SetUrlToFontsAction = { type: 'SET_URL_TO_FONTS', url: string };
export type SetStripeIntentClientSecretAction = {
  type: 'SET_STRIPE_INTENT_CLIENT_SECRET',
  secret: string,
};
export type SetIsEmbedAction = { type: 'SET_IS_EMBED' };
export type SetIsInAppAction = { type: 'SET_IS_IN_APP' };
export type SetDisableTicketPurchase = { type: 'SET_DISABLE_TICKET_PURCHASE' };
export type SetEasyConnectPartnerIdAction = {
  type: 'SET_EASY_CONNECT_PARTNER_ID',
  partnerId: number,
};
export type SetAdobeApiKeyAction = { type: 'SET_ADOBE_API_KEY', key: string };
export type SetSegmentWriteKeyAction = { type: 'SET_SEGMENT_WRITE_KEY', key: string };
export type SetHttpRequestPostDataAction = { type: 'SET_HTTP_REQUEST_POST_DATA', data: Object };
export type InteractWithSliderAction = { type: 'INTERACT_WITH_SLIDER' };

export type UserAgentInfo = {|
  +isMobile: boolean,
  +isMac: boolean,
  +isWindows: boolean,
  +isAndroid: boolean,
  +isiPhone: boolean,
  +isDesktop: boolean,
  +isChrome: boolean,
  +isSafari: boolean,
  +isFirefox: boolean,
  +source: string,
|};

export type IdentifyUserAgentAction = {|
  type: 'IDENTIFY_USER_AGENT',
  userAgentInfo: UserAgentInfo,
|};

export type SetCaptchaAction = SetCaptchaType | SetCaptchaSiteKey;

export type SetUrlAction =
  | SetAuthApiAction
  | SetBaseUrlAction
  | SetEasyConnectSdkUrlOriginAction
  | SetTopDomainAction
  | SetSegmentCdnHostAction;

export type ClientAction =
  | SetPageVisibilityAction
  | IdentifyUseragentAction
  | KeyDownAction
  | SetLocaleAction
  | SetSupportedLocales
  | LoadImageAnnotationsAction
  | SetAudioQuality
  | SetPlaybackIsSupportedAction
  | SetFLACPlaybackIsSupportedAction
  | SetPlaybackUsingAAC
  | SetClientIPAction
  | SetEncryptionEnabledAction
  | SetIsLoadingAudioAction
  | SetClientVersionsAction
  | SetDeviceIdAction
  | DisableRecentlyPlayed
  | SetCountryAction
  | SetCurrencyAction
  | SetUrlAction
  | SetFontLoadingAction
  | SetFontLoadedAction
  | SetUrlToFontsAction
  | SetStripeIntentClientSecretAction
  | LoadFeatureFlagsAction
  | SetIsEmbedAction
  | SetIsInAppAction
  | SetDisableTicketPurchase
  | SetEasyConnectPartnerIdAction
  | SetAdobeApiKeyAction
  | SetSegmentWriteKeyAction
  | InteractWithSliderAction
  | SetCaptchaAction;

export function setPageVisibility(visible: boolean): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    // Don't dispatch if visibility hasn't changed:
    if (state.client.pageVisible === visible) {
      return;
    }

    dispatch({
      type: 'SET_PAGE_VISIBILITY',
      visible,
    });
  };
}

export function identifyUserAgent(userAgentInfo: UserAgentInfo): IdentifyUseragentAction {
  return {
    type: 'IDENTIFY_USER_AGENT',
    userAgentInfo,
  };
}

function keyDown(event: KeyboardEvent): KeyDownAction {
  return {
    type: 'KEY_DOWN',
    event,
  };
}

export function onKeyDown(event: KeyboardEvent): ThunkAction {
  return dispatch => {
    if (activeElementIsTextField()) {
      return;
    }

    if (activeElementCapturesKeyEvents(event)) {
      return;
    }

    dispatch(keyDown(event));
  };
}

export function setLocale(locale: string): SetLocaleAction {
  return {
    type: 'SET_LOCALE',
    locale,
  };
}

export function setSupportedLocals(locales: Array<string>): SetSupportedLocales {
  return {
    type: 'SET_SUPPORTED_LOCALES',
    locales,
  };
}

export function loadImageAnnotations(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const annotationsLastLoaded = state.client.annotations.lastLoaded;
    const now = new Date().getTime();
    const timeDelta = annotationsLastLoaded ? now - parseInt(annotationsLastLoaded, 10) : null;

    if (!annotationsLastLoaded || (timeDelta && timeDelta > 3.6e6)) {
      return dispatch({
        type: 'LOAD_IMAGE_ANNOTATIONS',
        IDAGIO_REQUEST: {
          type: 'API_REQUEST',
          method: 'GET',
          endpoint: '/v2.0/annotations/image',
        },
        meta: {
          requestedAt: now.toString(),
        },
      });
    }
    return null;
  };
}

export function loadFeatureFlags(): LoadFeatureFlagsAction {
  return {
    type: 'LOAD_FEATURE_FLAGS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/features.v1',
    },
  };
}

export function loadGeo() {
  return {
    type: 'LOAD_GEO',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'GET',
      endpoint: '/geo.json',
    },
  };
}

export function updateAudioQualityPreference(quality: number): UpdatePreferencesAction {
  return updatePreferences({ web_audio_quality: quality });
}

export function updateAutoplayPreference(autoplay: boolean): UpdatePreferencesAction {
  return updatePreferences({ web_autoplay: autoplay });
}

export function setFontLoading(font: string): SetFontLoadingAction {
  return { type: 'SET_FONT_LOADING', font };
}

export function setFontLoaded(font: string): SetFontLoadedAction {
  return { type: 'SET_FONT_LOADED', font };
}

export function setUrlToFonts(commonsUrl: string): SetUrlToFontsAction {
  return { type: 'SET_URL_TO_FONTS', url: commonsUrl };
}

export function setAudioQuality(quality: number): SetAudioQuality {
  return {
    type: 'SET_AUDIO_QUALITY',
    quality,
  };
}

export function setAudioPlaybackSupport(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const userAgentInfo = selectUserAgentInfo(state);
    const encryptionEnabled = selectEncryptionEnabled(state);

    if (!clientSupportsPlayback(userAgentInfo, encryptionEnabled)) {
      dispatch(addNotification(getPlaybackNotSupportedNotification(dispatch)));
      return;
    }

    dispatch({ type: 'SET_PLAYBACK_IS_SUPPORTED' });

    if (clientSupportsLossless(userAgentInfo, encryptionEnabled)) {
      dispatch({ type: 'SET_FLAC_PLAYBACK_IS_SUPPORTED' });
    }

    if (!clientSupportsMP3(userAgentInfo, encryptionEnabled) && clientSupportsAAC()) {
      dispatch({ type: 'SET_PLAYBACK_USING_AAC' });
    }
  };
}

export function setClientIp(ip: string): SetClientIPAction {
  return {
    type: 'SET_CLIENT_IP',
    ip,
  };
}

export function setEncryptionEnabled(): SetEncryptionEnabledAction {
  return { type: 'SET_ENCRYPTION_ENABLED' };
}

export function setIsLoadingAudio(flag: boolean): SetIsLoadingAudioAction {
  return {
    type: 'SET_IS_LOADING_AUDIO',
    flag,
  };
}

export function setClientVersions(
  electronVersion: string,
  webVersion: string
): SetClientVersionsAction {
  return {
    type: 'SET_CLIENT_VERSIONS',
    electronVersion,
    webVersion,
  };
}

export function setDeviceId(deviceId: string): SetDeviceIdAction {
  return {
    type: 'SET_DEVICE_ID',
    deviceId,
  };
}

export function saveCookiesConsent(preferences: CookiePreferences): SaveCookiesConsentAction {
  return {
    type: 'SAVE_COOKIES_CONSENT',
    preferences,
  };
}

export function disableRecentlyPlayed(): DisableRecentlyPlayed {
  return { type: 'DISABLE_RECENTLY_PLAYED' };
}

export function setShouldBlock(shouldBlock: boolean): SetShouldBlockAction {
  return {
    type: 'SET_SHOULD_BLOCK',
    shouldBlock,
  };
}

export function setCountry(country: string): SetCountryAction {
  return {
    type: 'SET_COUNTRY',
    country,
  };
}

export function setCurrency(currency: Currency): SetCurrencyAction {
  return {
    type: 'SET_CURRENCY',
    currency,
  };
}

export function checkLanguageAndRedirect(path: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    if (!selectUserLanguageIsClientLanguage(state)) {
      dispatch(addNotification(PLEASE_WHILE_REDIRECTING));

      if (!__ELECTRON__) {
        const toLocale = selectLocalePreference(state);
        const fromLocale = selectLocale(state);
        const fromLanguage = splitLocale(fromLocale).language;
        const toLanguage = splitLocale(toLocale).language;
        document.location = switchUrlLanguage(path, fromLanguage, toLanguage);
      } else {
        await dispatch(replace(path));
        document.location.reload();
      }

      return;
    }

    // user is in the right language -- redirect through router
    await dispatch(replace(path));
  };
}

export function setAuthApiBase(url: string): SetAuthApiAction {
  return {
    type: 'SET_AUTH_API_BASE',
    url,
  };
}

export function setBaseUrl(url: string): SetBaseUrlAction {
  return {
    type: 'SET_BASE_URL',
    url,
  };
}

export function setTopDomain(domain: string): SetTopDomainAction {
  return {
    type: 'SET_TOP_DOMAIN',
    domain,
  };
}

export function setSegmentCdnHost(host: string): SetSegmentCdnHostAction {
  return {
    type: 'SET_SEGMENT_CDN_HOST',
    host,
  };
}

export function setStripeIntentClientSecret(secret: string): SetStripeIntentClientSecretAction {
  return {
    type: 'SET_STRIPE_INTENT_CLIENT_SECRET',
    secret,
  };
}

export function setIsEmbed(): SetIsEmbedAction {
  return {
    type: 'SET_IS_EMBED',
  };
}

export function setHttpRequestPostData(data: Object): SetHttpRequestPostDataAction {
  return {
    type: 'SET_HTTP_REQUEST_POST_DATA',
    data,
  };
}

export function setIsInApp(): SetIsInAppAction {
  return {
    type: 'SET_IS_IN_APP',
  };
}

export function setDisableTicketPurchase(): SetDisableTicketPurchase {
  return {
    type: 'SET_DISABLE_TICKET_PURCHASE',
  };
}

export function setEasyConnectSdkUrlOrigin(url: string): SetEasyConnectSdkUrlOriginAction {
  return {
    type: 'SET_EASY_CONNECT_SDK_URL_ORIGIN',
    url,
  };
}

export function setEasyConnectPartnerId(partnerId: number): SetEasyConnectPartnerIdAction {
  return {
    type: 'SET_EASY_CONNECT_PARTNER_ID',
    partnerId,
  };
}

export function setAdobeApiKey(key: string): SetAdobeApiKeyAction {
  return {
    type: 'SET_ADOBE_API_KEY',
    key,
  };
}

export function setSegmentWriteKey(key: string): SetSegmentWriteKeyAction {
  return {
    type: 'SET_SEGMENT_WRITE_KEY',
    key,
  };
}

export function interactWithSlider(): InteractWithSliderAction {
  return {
    type: 'INTERACT_WITH_SLIDER',
  };
}

export function setCaptchaType(captchaType: string): SetCaptchaType {
  return {
    type: 'SET_CAPTCHA_TYPE',
    captchaType,
  };
}

export function setCaptchaSiteKey(captchaSiteKey: string): SetCaptchaSiteKey {
  return {
    type: 'SET_CAPTCHA_SITE_KEY',
    captchaSiteKey,
  };
}
