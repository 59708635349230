import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from './Modal';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';

import * as uiActions from '../../actions/ui';
import { updatePlaylist } from '../../actions/personalPlaylist';
import { addNotification } from '../../actions/notifications';

import { selectOpenModalTypeIs } from '../../selectors/modals';
import { selectPersonalPlaylistToEdit } from '../../selectors/client';
import LoadingIndicator from '../common/LoadingIndicator';

import CancelButton from '../messages/CancelButton';
import SaveButton from '../messages/SaveButton';
import PlaylistTitle from '../messages/PlaylistTitle';
import CapacitorRipple from '../capacitor/Ripple';

import * as Shapes from '../../shapes';

import { PLAYLIST_EMPTY_TITLE_ERROR, PLAYLIST_SAVE_ERROR } from '../../lib/notifications';
import stylesAddToPlaylistModal from './AddToPlaylistModal.css';

class EditPlaylistModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    playlist: Shapes.PersonalPlaylist,

    hideModal: PropTypes.func.isRequired,
    updatePlaylist: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
  };

  onSubmitSavePlaylist = async event => {
    event.preventDefault();
    if (!this.playlistNameInput) {
      return;
    }

    if (this.state.isLoading) {
      return;
    }

    const { playlist } = this.props;
    const title = this.playlistNameInput.value.trim();

    if (!title) {
      this.props.addNotification(PLAYLIST_EMPTY_TITLE_ERROR);
    } else {
      this.setState({ isLoading: true });
      try {
        const update = { title };
        await this.props.updatePlaylist(playlist.id, update);
      } catch (e) {
        this.props.addNotification(PLAYLIST_SAVE_ERROR);
        this.setState({ isLoading: false });

        // rethrow
        throw e;
      }
    }
  };

  render() {
    const { isOpen, hideModal, playlist } = this.props;
    const { isLoading } = this.state;
    const { createPlaylist, modalAddToPlaylist, saveButton, cancelButton, body } =
      stylesAddToPlaylistModal;

    const classesModal = classnames(modalAddToPlaylist);
    const saveButtonClassNames = classnames('c-btn', saveButton);
    const cancelButtonClassNames = classnames('c-btn', 'c-btn--has-outline', cancelButton);

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={hideModal}
        onClose={hideModal}
        contentLabel="add-to-playlist-modal"
      >
        <section className={classesModal}>
          <form onSubmit={this.onSubmitSavePlaylist} className={createPlaylist}>
            <div className={body}>
              <h1 className="fz--beta">
                <FormattedMessage
                  id="playlist.edit-modal.edit-playlist.header"
                  defaultMessage="Edit Playlist"
                />
              </h1>
              <div className="c-form-field">
                <label htmlFor="playlistName" className="c-form-field__label">
                  <PlaylistTitle />
                </label>
                <input
                  defaultValue={playlist.title}
                  type="text"
                  name="playlistName"
                  ref={this.setPlaylistNameInputElement}
                  className="c-form-field__input"
                  data-test="edit-playlist-modal.name-field"
                />
              </div>
            </div>
            <div className={stylesAddToPlaylistModal.buttonWrapper}>
              <button className={cancelButtonClassNames} onClick={hideModal} type="button">
                <CapacitorRipple />
                <CancelButton />
              </button>
              <button
                className={saveButtonClassNames}
                type="submit"
                data-test="edit-playlist-modal.save-btn"
              >
                <CapacitorRipple />
                <LoadingIndicator isLoading={isLoading} className="hideText" />
                <SaveButton />
              </button>
            </div>
          </form>
        </section>
      </Modal>
    );
  }

  setPlaylistNameInputElement = element => {
    this.playlistNameInput = element;
    if (element) {
      // focus on the end of the element
      const length = element.value.length;
      element.focus();
      element.setSelectionRange(0, length);
    }
  };

  playlistNameInput = null;
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'EDIT_PLAYLIST_MODAL'),
    playlist: selectPersonalPlaylistToEdit(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    hideModal: uiActions.hidePersonalPlaylistModal,
    updatePlaylist: updatePlaylist,
    addNotification,
  })
)(EditPlaylistModal);
