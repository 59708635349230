import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class FormattedTime extends PureComponent {
  static propTypes = {
    children: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    return <span className={className}>{this.format(this.props.children)}</span>;
  }

  leadingZero(t) {
    return t < 10 ? `0${t}` : t;
  }

  format(time) {
    if (isNaN(time)) {
      return '';
    }

    let formattedDuration;
    // 1. Extract hours
    const hours = parseInt(time / 3600, 10);
    formattedDuration = hours < 1 ? '' : `${hours}:`;
    // 2. Extract minutes from time without the hours
    const timeWithoutHours = time - hours * 3600;
    const minutes = this.leadingZero(parseInt(timeWithoutHours / 60, 10));
    // 3. Extract seconds from time without the hours
    const seconds = this.leadingZero(parseInt(timeWithoutHours % 60, 10));
    formattedDuration += `${minutes}:${seconds}`;

    return formattedDuration;
  }
}
