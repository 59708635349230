// @flow

import update from 'immutability-helper';
import type { DownloadUpdateAction, DownloadStatus } from '../actions/download';

type Action = DownloadUpdateAction;

type EntityDownloadInfoMap = {
  [string]: {
    downloadStatus: DownloadStatus,
    downloadedTrackIds: string[],
  },
};

export type State = {|
  +entitiesDownloadInfo: EntityDownloadInfoMap,
|};

const initialState = {
  entitiesDownloadInfo: {},
};

export default function download(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'DOWNLOAD_UPDATE':
      return update(state, {
        entitiesDownloadInfo: {
          $merge: {
            [action.entityId]: {
              downloadStatus: action.downloadStatus,
              downloadedTrackIds: action.downloadedTrackIds,
            },
          },
        },
      });
    default:
      return state;
  }
}
