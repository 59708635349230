// @flow
import React from 'react';
import classnames from 'classnames';

import PlanPrice from './PlanPrice';
import styles from './PlanOptionUpgrade.css';

type OwnProps = {
  id: string,
  monthlyPrice: number,
  onSelect: Function,
  className?: string,
};

type Props = OwnProps;

const PlanOptionUpgrade = ({ monthlyPrice, onSelect, id, className }: Props) => {
  const containerClassname = classnames(styles.container, className);

  return (
    <div className={containerClassname}>
      <button
        onClick={() => onSelect(id)}
        className="dummy-btn"
        data-test="plan-option.upgrade.btn"
      />
      <PlanPrice price={monthlyPrice} interval="monthly" />
    </div>
  );
};

export default PlanOptionUpgrade;
