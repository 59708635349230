// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadMixAction } from '../../actions/mix';

export type State = {
  +[key: string]: {|
    +entities: string,
    +loaded: boolean,
    +loading: boolean,
  |},
};

const initialState = {};

export default function mixes(state: State = initialState, action: LoadMixAction): State {
  if (action.type === 'FETCH_MIX' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
