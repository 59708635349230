import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConsentManagerSelectionOption.css';

export function ConsentManagerSelectionOption(props) {
  return (
    <label className={styles.label}>
      <input
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
        name={props.name}
        id={`cookie-banner-${props.name}`}
        className={styles.input}
      />
      <span className={styles.text}>{props.label}</span>
    </label>
  );
}

ConsentManagerSelectionOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
};
