import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Image from '../util/Image';

import styles from './Curator.css';

import { IMGIX_PARAMS } from '../../constants';

class Curator extends PureComponent {
  static propTypes = {
    curator: PropTypes.object.isRequired,
  };

  render() {
    const { curator } = this.props;

    return (
      <div className={styles.container}>
        <Image
          noBase
          src={curator.imageUrl}
          auto={IMGIX_PARAMS}
          className={styles.avatar}
          width={40}
          height={40}
          alt=""
        />
        <span className={styles.details}>
          <span className={styles.name}>{curator.name}</span>
          <div className={styles.summary}>{curator.summary}</div>
        </span>
      </div>
    );
  }
}

export default Curator;
