import { selectUserIsFree } from './user';
import { getAdsCountToPlayNext } from '../utils/ads';
import { selectTrack } from './track';
import { selectPlayerCurrentQueueItem } from './player';
import { selectFeatureFlag, selectShouldPlayPreviews } from './features';

function selectAdIds(state) {
  return state.lists.ads.entities;
}

export function selectAdById(state, adId) {
  return state.entities.ads[adId];
}

function selectAllAdsInOrder(state) {
  const ids = selectAdIds(state);
  return ids.map(id => selectAdById(state, id));
}

function selectIntervalsBetweenIntermissionListeningLength(state) {
  return selectFeatureFlag(state, 'interval_between_intermission');
}

export function selectSecondsSinceLastAd(state) {
  return state.ads.secondsSinceLastAd;
}

export function selectNumberOfAdsToPlayNow(state) {
  if (!selectAdIds(state).length) {
    return 0;
  }

  const secondsSinceLastAd = selectSecondsSinceLastAd(state);
  const currentTrack = selectTrack(state, selectPlayerCurrentQueueItem(state).track, true);
  const currentTrackDuration = currentTrack.duration;
  const adInterval = selectIntervalsBetweenIntermissionListeningLength(state);
  return getAdsCountToPlayNext(secondsSinceLastAd, currentTrackDuration, adInterval);
}

export function selectNumberOfAdsPlayedInSession(state) {
  return state.ads.adsPlayedInSession || 0;
}

export function selectNAdsToPlayQueue(state, count) {
  const allAds = selectAllAdsInOrder(state);
  if (!allAds.length) {
    return [];
  }
  const adsPlayedInSession = selectNumberOfAdsPlayedInSession(state);
  let indexInAdsArray = adsPlayedInSession % allAds.length;
  const adsToPlay = [];
  for (let i = 0; i < count; i++) {
    adsToPlay.push(allAds[indexInAdsArray]);
    indexInAdsArray++;
    if (indexInAdsArray === allAds.length) {
      indexInAdsArray = 0;
    }
  }

  return adsToPlay;
}

export function selectCurrentlyPlayingAd(state) {
  return state.ads.currentlyPlayingAd;
}

export function selectShouldPlayAdsForUser(state) {
  return selectUserIsFree(state) && !selectShouldPlayPreviews(state);
}

const empty = [];
export function selectCurrentlyPlayingAdQueue(state) {
  return state.ads.currentAdQueue || empty;
}

export function selectCurrentlyPlayingAdIndex(state) {
  const currentlyPlayingAd = selectCurrentlyPlayingAd(state);
  const queue = selectCurrentlyPlayingAdQueue(state);

  return queue.findIndex(ad => currentlyPlayingAd.id === ad.id);
}

export function selectAdUrlFromAdId(state, adId) {
  const ad = selectAdById(state, adId);
  return ad.audio_url;
}
