import { selectPlayerIsPlaying } from './player';

export function selectLiveCompare(state) {
  return state.liveCompare || {};
}

export function selectLiveCompareRecordings(state) {
  return selectLiveCompare(state).recordings || [];
}

export function selectLiveComparePiece(state) {
  return selectLiveCompare(state).piece;
}

export function selectLiveCompareWork(state) {
  return selectLiveCompare(state).work;
}

export function selectLiveCompareComposer(state) {
  return selectLiveCompare(state).composer;
}

export function selectLiveCompareLoaded(state, pieceId) {
  const liveCompare = selectLiveCompare(state);
  if (Boolean(liveCompare.loaded)) {
    return liveCompare.piece.id.toString() === pieceId.toString();
  }
  return false;
}

export function selectLiveCompareAvailable(state) {
  return selectLiveCompare(state).available;
}

export function selectLiveComparePlaybackState(state) {
  return selectLiveCompare(state).playback || {};
}

export function selectLiveCompareActiveRecordingIndex(state) {
  return selectLiveComparePlaybackState(state).active;
}

export function selectLiveCompareNextRecordingIndex(state) {
  const recordingsLength = selectLiveCompareRecordings(state).length;
  const activeIndex = selectLiveCompareActiveRecordingIndex(state);

  return (activeIndex + 1) % recordingsLength;
}

export function selectLiveComparePreviousRecordingIndex(state) {
  const recordingsLength = selectLiveCompareRecordings(state).length;
  const activeIndex = selectLiveCompareActiveRecordingIndex(state);

  return (activeIndex - 1 + recordingsLength) % recordingsLength;
}

export function selectLiveCompareActiveRecording(state) {
  return selectLiveCompareRecordings(state)[selectLiveCompareActiveRecordingIndex(state)];
}

export function selectLiveCompareCurrentlyOn(state) {
  return selectLiveCompare(state).currentlyOn;
}

export function selectLiveCompareCurrentTrackId(state) {
  return selectLiveCompareRecordings(state)[selectLiveCompareActiveRecordingIndex(state)].trackId;
}

export function selectLiveCompareIsSwitching(state) {
  return selectLiveComparePlaybackState(state).isSwitching;
}

export function selectLiveComparePlayingRecordingIndex(state) {
  if (selectPlayerIsPlaying(state)) {
    return selectLiveComparePlaybackState(state).playingRecordingIndex;
  }

  return -1;
}

export function selectLiveCompareHasPlayedIndices(state) {
  return selectLiveComparePlaybackState(state).hasPlayedIndices || {};
}

export function selectLiveCompareHasSwitched(state) {
  return selectLiveComparePlaybackState(state).hasSwitched;
}

export function selectLiveCompareInactivityTooltipDismissed(state) {
  return selectLiveCompare(state).inactivityTooltipDismissed;
}
