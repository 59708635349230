// @flow

import { User } from '../schema';
import normalizeUser from '../schema/normalizeUser';

import type { Request, ThunkAction } from './types';
import type { SetOfferFromIdAction } from './subscriptionOffer';
import { selectSubscriptionFromId } from '../selectors/subscriptionOffer';
import { FROM_ID_CAMPAIGNS } from '../constants';

type LoadMeAction = { type: 'FETCH_ME' } & Request;
export type UpdatePreferencesAction = { type: 'UPDATE_PREFERENCES' } & Request;

export type MeAction = LoadMeAction | UpdatePreferencesAction;

export function loadMe(): LoadMeAction {
  return {
    type: 'FETCH_ME',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.1/user',
    },
    meta: {
      restricted: true,
      normalize: normalizeUser,
      schema: User,
      requestedAt: Date.now().toString(),
    },
  };
}

type Preferences = { web_audio_quality?: number, locale?: string, web_autoplay?: boolean };

export function updatePreferences(preferences: Preferences): UpdatePreferencesAction {
  return {
    type: 'UPDATE_PREFERENCES',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'PATCH',
      endpoint: '/v2.1/user/preferences',
      body: preferences,
    },
    meta: {
      restricted: true,
      normalize: normalizeUser,
      schema: User,
    },
  };
}

export function setFromId(fromId: string): SetOfferFromIdAction {
  return {
    type: 'SET_OFFER_FROM_ID',
    fromId,
  };
}

export function setFromIdIfNoOffer(fromId: string): ThunkAction {
  return (dispatch, getState) => {
    const currentFromId = selectSubscriptionFromId(getState());
    if (currentFromId === FROM_ID_CAMPAIGNS.default.fromId) {
      dispatch(setFromId(fromId));
    }
  };
}
