import React from 'react';
import { FormattedMessage } from 'react-intl';

import IconLabel from './../util/IconLabel';
import Tag from './Tag';
import styles from './Tag.css';

const BookedTag = () => {
  return (
    <Tag
      className={styles.bookedTag}
      icon={
        <div className={styles.icon}>
          <IconLabel name="tag" size="small" />
        </div>
      }
    >
      <FormattedMessage id="livestream-event-preview.booked-tag" defaultMessage="Booked" />
    </Tag>
  );
};

export default BookedTag;
