/* @flow */

import * as React from 'react';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FormattedMessage } from 'react-intl';

import type { FBButton } from './FacebookButton';
import FacebookButton from './FacebookButton';

import {
  CONNECTED_FACEBOOK_CANCEL,
  FACEBOOK_LOGIN_UNAVAILABLE,
  LOGIN_INCORRECT_CREDENTIALS,
} from '../../lib/notifications';

import { ERROR_MESSAGE_FACEBOOK_LOGIN_NOT_ALLOWED, FACEBOOK_CLIENT_ID } from '../../constants';

import { ApiError } from '../../middleware/api';

import {
  selectEmailAuthIsWorking,
  selectFbToken,
  selectSocialAuthIsWorking,
} from '../../selectors/auth';

import * as uiActions from '../../actions/ui';
import * as authActions from '../../actions/auth';
import * as notificationActions from '../../actions/notifications';
import { loadMe } from '../../actions/me';
import { selectSubscriptionFromId } from '../../selectors/subscriptionOffer';

declare var __ELECTRON__: boolean;

type FBButtonRenderProps = { onClick: Function };

type FBResponse = {
  accessToken: string,
  name?: string,
  userID?: string,
  status?: string,
};

type OwnProps = {
  onAuthComplete?: Function,
  canDisconnect?: boolean,
  onDisconnect?: Function,
  passwordConfirmCopy: React.Element<typeof FormattedMessage>,
  disabled?: boolean,
  fromId?: string,
  trackSelectedFacebook?: Function,
};

type MapStateToProps = {
  isLoading: boolean,
  isEmailAuthLoading: boolean,
  fbToken: string,
  newFromId: string,
};

type DispatchProps = {
  loadMe: Function,
  unlinkSocial: Function,
  authenticateSocial: Function,
  addNotification: Function,
  showModal: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

type State = {
  fbAccessToken: string,
  repromptFacebook: boolean,
};

type LoginSocial = {
  externalAccessToken: string,
  password: ?string,
};

export class FacebookLoginPure extends React.PureComponent<Props, State> {
  state = {
    fbAccessToken: '',
    repromptFacebook: false,
  };

  // The code below is specific to Electron/Desktop app:
  // This component has a state selector for fbToken, which will get set once
  // the Electron IPC request token cycle is complete
  componentDidUpdate(prevProps: Props) {
    if (this.props.fbToken !== prevProps.fbToken) {
      this.onFacebookLogin({ accessToken: this.props.fbToken });
    }
  }

  onFacebookLogin = (fbResponse: FBResponse) => {
    // `status` field is omitted by FacebookLogin package when there is correct
    // response
    //
    // it will be present on fbResponse but set to `undefined` if user simply
    // closes facebook dialog window
    if (!fbResponse.hasOwnProperty('status')) {
      this.setState({ fbAccessToken: fbResponse.accessToken }, () => {
        this.loginSocial({
          externalAccessToken: fbResponse.accessToken,
          password: null,
        });
      });
    } else if (!fbResponse.status === 'unknown') {
      this.props.addNotification(CONNECTED_FACEBOOK_CANCEL);
    }
  };

  onFacebookButtonClickElectron = (e: SyntheticMouseEvent<*>) => {
    window.require('electron').ipcRenderer.send('facebook-sign-on', this.state.repromptFacebook);
    e.preventDefault();
  };

  onFacebookButtonClick = (e: SyntheticMouseEvent<*>, renderProps: { onClick: Function }) => {
    renderProps.onClick();
    if (this.props.trackSelectedFacebook) {
      this.props.trackSelectedFacebook();
    }
  };

  renderLoginFBButton = (renderProps: FBButtonRenderProps): FBButton => {
    const { disabled, isLoading, isEmailAuthLoading } = this.props;

    return (
      // We shadow the onClick coming from renderProps, which are provided
      // by react-facebook-login. We override entire FB dialog + token receival
      // with Electron specific flow
      <FacebookButton
        {...renderProps}
        onClick={e =>
          __ELECTRON__
            ? this.onFacebookButtonClickElectron(e)
            : this.onFacebookButtonClick(e, renderProps)
        }
        isWorking={isLoading}
        disabled={disabled || isEmailAuthLoading}
      >
        <FormattedMessage
          id="login.options.continue-with-facebook"
          defaultMessage="Continue with facebook"
        />
      </FacebookButton>
    );
  };

  render() {
    if (this.props.canDisconnect) {
      return (
        <FacebookButton
          onClick={this.unlinkSocial}
          isWorking={this.props.isLoading}
          disabled={this.props.disabled || this.props.isEmailAuthLoading}
        >
          <FormattedMessage
            id="settings.account.linked.disconnect-facebook"
            defaultMessage="Disconnect Facebook"
          />
        </FacebookButton>
      );
    }

    return (
      <FacebookLogin
        appId={FACEBOOK_CLIENT_ID}
        callback={this.onFacebookLogin}
        render={this.renderLoginFBButton}
        fields="email"
        authType={this.state.repromptFacebook ? 'rerequest' : ''}
        version="3.2"
      />
    );
  }

  loginSocial = async ({ externalAccessToken, password }: LoginSocial) => {
    const { newFromId, onAuthComplete } = this.props;

    try {
      await this.props.authenticateSocial({
        externalAccessToken,
        password,
        newFromId,
      });
      if (onAuthComplete) {
        await onAuthComplete();
      }
    } catch (err) {
      if (err instanceof ApiError) {
        if (err.status === 400) {
          // We submit facebook token to IDAGIO’s API, our backend uses the
          // token to query Facebook API and gets email out of that.
          //
          // Since we are disabling new Facebook signups, our backend will return 400 with
          // {email: null} and a message in that regard in response body.
          //
          // 400 is also a response we get when we got email (initially or from
          // reprompting) but it’s a new email/user so they need to accept terms
          if (!err.body.email) {
            this.setState({ repromptFacebook: true });
            this.props.addNotification(
              err.body.message === ERROR_MESSAGE_FACEBOOK_LOGIN_NOT_ALLOWED
                ? FACEBOOK_LOGIN_UNAVAILABLE
                : CONNECTED_FACEBOOK_CANCEL
            );
          }
        }

        if (err.status === 401) {
          this.props.addNotification(LOGIN_INCORRECT_CREDENTIALS);
        }

        if (err.status === 403) {
          this.props.showModal(
            'FACEBOOK_PASSWORD_CONFIRMATION_MODAL',
            {},
            {
              email: err.body.email,
              confirmPassword: this.confirmPassword,
              confirmCopy: this.props.passwordConfirmCopy,
            }
          );
        }
      } else {
        throw err;
      }
    }
  };

  unlinkSocial = async () => {
    await this.props.unlinkSocial();
    await this.props.loadMe();

    if (this.props.onDisconnect) {
      this.props.onDisconnect();
    }
  };

  confirmPassword = async (password: string) => {
    return this.loginSocial({
      externalAccessToken: this.state.fbAccessToken,
      password,
    });
  };
}

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  // We select token from state only when running in Electron,
  // in normal case it comes from FacebookLogin component
  const fbToken = selectFbToken(state);
  return {
    isLoading: selectSocialAuthIsWorking(state),
    isEmailAuthLoading: selectEmailAuthIsWorking(state),
    fbToken,
    newFromId: ownProps.fromId || selectSubscriptionFromId(state),
  };
}

const dispatchProps: DispatchProps = {
  loadMe,
  unlinkSocial: authActions.unlinkSocial,
  authenticateSocial: authActions.authenticateSocial,
  addNotification: notificationActions.addNotification,
  showModal: uiActions.showModal,
};

export default connect(mapStateToProps, dispatchProps)(FacebookLoginPure);
