// @flow
import { loadMe } from './me';
import { loadFeatureFlags, setAudioQuality } from './client';
import { getHighestAllowedQuality } from '../client/playback-support';

import { ApiError } from '../middleware/api';

import {
  COUPON_ERROR_NO_SUBSCRIBE,
  COUPON_ERROR,
  COUPON_ERROR_HAS_AN_ACTIVE_COUPON,
} from '../lib/notifications';

import type { ThunkAction, Request } from './types';

type SubscribeAction = { type: 'ADD_SUBSCRIPTION' } & Request;
type OpenStripeCustomerPortalAction = {
  type: 'OPEN_STRIPE_CUSTOMER_PORTAL',
} & Request;
type OpenStripeCustomerChangePlanAction = {
  type: 'OPEN_STRIPE_CUSTOMER_CHANGE_PLAN',
} & Request;
export type LoadSubscriptionAction = { type: 'FETCH_SUBSCRIPTION' } & Request;
type ApplyVoucherCodeAction = { type: 'APPLY_COUPON' } & Request;
type CancelSubscriptionAction = { type: 'CANCEL_SUBSCRIPTION' } & Request;
type ReactiveSubscriptionAction = { type: 'REACTIVATE_SUBSCRIPTION' } & Request;
type SendSubscriptionCancellationFeedbackAction = {
  type: 'SEND_SUBSCRIPTION_CANCEL_FEEDBACK',
};

export type UserRevalidatedStripeAction = {
  type: 'USER_REVALIDATED_STRIPE',
  timestamp: string,
};

export type SubscriptionAction =
  | SubscribeAction
  | LoadSubscriptionAction
  | ApplyVoucherCodeAction
  | CancelSubscriptionAction
  | ReactiveSubscriptionAction
  | SendSubscriptionCancellationFeedbackAction
  | UserRevalidatedStripeAction
  | OpenStripeCustomerPortalAction;

export type Voucher = {
  code: string,
  status: 'applied' | 'pending' | 'invalid' | 'not-eligible',
};
export type SetIncomingVoucherAction = { type: 'SET_INCOMING_VOUCHER', voucher: Voucher };

function formatTrialDuration(trialDuration) {
  return Math.ceil(Date.now() / 1000) + 86400 * trialDuration;
}

type SubscribeReqBody = {
  trial_ends_at?: number,
  stripe_plan_id: string,
};
type SubscribeWithCCReqBody = SubscribeReqBody & { stripe_pm_id: string };
type SubscribeWithSepaReqBody = SubscribeReqBody & { stripe_token: string };

function subscribeWithCreditCard(
  paymentMethodId: string,
  trialDuration: number | null,
  stripePlanId: string
): SubscribeAction {
  const body: SubscribeWithCCReqBody = {
    stripe_pm_id: paymentMethodId,
    stripe_plan_id: stripePlanId,
  };

  if (trialDuration) {
    body.trial_ends_at = formatTrialDuration(trialDuration);
  }

  return {
    type: 'ADD_SUBSCRIPTION',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/subscription/stripe.v3',
      body,
    },
    meta: {
      restricted: true,
      requestedAt: Date.now().toString(),
    },
  };
}

function subscribeWithSepa(
  stripeToken: string,
  trialDuration: number | null,
  stripePlanId: string
): SubscribeAction {
  const body: SubscribeWithSepaReqBody = {
    stripe_token: stripeToken,
    stripe_plan_id: stripePlanId,
  };

  if (trialDuration) {
    body.trial_ends_at = formatTrialDuration(trialDuration);
  }

  return {
    type: 'ADD_SUBSCRIPTION',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/subscription',
      body,
    },
    meta: {
      restricted: true,
      requestedAt: Date.now().toString(),
    },
  };
}

export function subscribeToPlan(
  stripeRef: string, // stripe token for cc, payment method id for sepa
  trialDuration: number | null = null,
  isSEPAPayment: boolean,
  planId: string,
  isInModal: boolean
): ThunkAction {
  return async function subscribeThunk(dispatch, getState) {
    const subscribe = isSEPAPayment ? subscribeWithSepa : subscribeWithCreditCard;
    await dispatch(subscribe(stripeRef, trialDuration, planId));
    const actions = [dispatch(loadFeatureFlags())];
    if (isInModal) {
      actions.push(dispatch(loadMe()));
    }
    await Promise.all(actions);
    dispatch(setAudioQuality(getHighestAllowedQuality(getState())));
  };
}

export function loadSubscription(): LoadSubscriptionAction {
  return {
    type: 'FETCH_SUBSCRIPTION',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/subscription',
    },
    meta: {
      restricted: true,
    },
  };
}

const COUPON_START_SUBSCRIPTION_ERROR = 'Coupon can not be used to start subscription';
const COUPON_HAS_AN_ACTIVE_COUPON_ERROR = 'User already has an active coupon';
export function handleCouponError(error: ApiError, dispatchAddNotification: Function) {
  if (error.body.message === COUPON_START_SUBSCRIPTION_ERROR) {
    return dispatchAddNotification(COUPON_ERROR_NO_SUBSCRIBE);
  } else if (error.body.message === COUPON_HAS_AN_ACTIVE_COUPON_ERROR) {
    return dispatchAddNotification(COUPON_ERROR_HAS_AN_ACTIVE_COUPON);
  }

  return dispatchAddNotification(COUPON_ERROR);
}

export function applyVoucherCode(code: string): ApplyVoucherCodeAction {
  return {
    type: 'APPLY_COUPON',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/subscription/discount',
      body: {
        discount_code: code,
      },
    },
    meta: {
      restricted: true,
    },
  };
}

export function cancelSubscription(): CancelSubscriptionAction {
  return {
    type: 'CANCEL_SUBSCRIPTION',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'DELETE',
      endpoint: '/v2.0/subscription',
    },
    meta: {
      restricted: true,
    },
  };
}

export function reactivateSubscription(): ReactiveSubscriptionAction {
  return {
    type: 'REACTIVATE_SUBSCRIPTION',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'PUT',
      endpoint: '/v2.0/subscription',
    },
    meta: {
      restricted: true,
    },
  };
}

type FeedbackData = {
  reason: string,
  comment: string,
};

export function sendSubscriptionCancellationFeedback(
  data: FeedbackData
): SendSubscriptionCancellationFeedbackAction {
  return {
    type: 'SEND_SUBSCRIPTION_CANCEL_FEEDBACK',
    data,
  };
}

export function setIncomingVoucher(voucher: Voucher): SetIncomingVoucherAction {
  return {
    type: 'SET_INCOMING_VOUCHER',
    voucher,
  };
}

export function openStripeCustomerPortal(returnUrl: string): OpenStripeCustomerPortalAction {
  const body = {
    return_url: returnUrl,
  };

  return {
    type: 'OPEN_STRIPE_CUSTOMER_PORTAL',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/subscription/stripe/portal.v1',
      body,
    },
    meta: {
      normalize: data => data.portal_url,
    },
  };
}

export function openStripeCustomerChangePlan(
  returnUrl: string,
  targetPlan: string
): OpenStripeCustomerChangePlanAction {
  const body = {
    success_url: returnUrl,
    abort_url: returnUrl,
    target_plan: targetPlan,
  };

  return {
    type: 'OPEN_STRIPE_CUSTOMER_CHANGE_PLAN',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/subscription/stripe/change-plan.v1',
      body,
    },
    meta: {
      normalize: data => data.redirect_url,
    },
  };
}
