// @flow
import React from 'react';
import styles from './PaymentSummary.css';
import { FormattedDate, FormattedMessage } from 'react-intl';
import PlanPrice from './PlanPrice';
import { A_MONTH_IN_DAYS, PLAN_ID_PPC_12M } from '../../constants';
import { planTitleMessages } from '../messages/PlansOverviewMessages';
import PlanBenefit from './PlanBenefit';
import IconLabel from '../util/IconLabel';

type OwnProps = {
  isTrialAvailable: boolean,
  trialDurationDays: number,
  selectedPlan: string | null,
  relativeDiscount?: number,
  price: number,
  goBack: Function,
  reducedPriceDuration?: number,
};

type Props = OwnProps;

const PaymentSummary = ({
  isTrialAvailable,
  trialDurationDays,
  selectedPlan,
  relativeDiscount,
  price,
  goBack,
  reducedPriceDuration,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.plan}>
        <span data-test="payment-summary.selected-plan.title" data-test-value={selectedPlan}>
          IDAGIO&nbsp;
          <FormattedMessage {...planTitleMessages[selectedPlan]} />
        </span>
        <button
          className="c-btn c-btn--is-inverted"
          onClick={goBack}
          data-test="payment-summary.btn.change"
        >
          <FormattedMessage id="premium.payment-summary.btn.change" defaultMessage="Change" />
        </button>
      </div>
      <div className={styles.summary}>
        <span className={styles.description}>
          <FormattedMessage
            id="premium.payment-summary.charge"
            defaultMessage="You will be charged"
          />
        </span>
        <PlanPrice
          price={price}
          relativeDiscount={relativeDiscount}
          interval={selectedPlan === PLAN_ID_PPC_12M ? 'yearly' : 'monthly'}
          className={styles.price}
        />
        <div className={styles.reducedPriceDuration}>
          {reducedPriceDuration && (
            <span
              data-test="payment-summary.reduced-price-duration"
              data-test-value={Math.floor(reducedPriceDuration / A_MONTH_IN_DAYS)}
            >
              <FormattedMessage
                id="premium.payment-summary.reduced-price-duration"
                defaultMessage="for {months} months"
                values={{ months: Math.floor(reducedPriceDuration / A_MONTH_IN_DAYS) }}
              />
            </span>
          )}
        </div>
        {isTrialAvailable && (
          <PlanBenefit
            title={
              <FormattedMessage
                id="premium.payment-summary.trial.title"
                defaultMessage="{trialDurationDays}-day Free Trial Included"
                values={{ trialDurationDays }}
              />
            }
            description={
              <FormattedMessage
                id="premium.payment-summary.trial"
                defaultMessage="You will not be charged before the end of your trial period which ends on the {trialEndDate}. No commitment. Cancel at any time."
                values={{
                  trialEndDate: (
                    <FormattedDate
                      value={new Date(Date.now() + trialDurationDays * 24 * 60 * 60 * 1000)}
                    />
                  ),
                }}
              />
            }
            data-test="payment-summary.trial"
            data-test-value={trialDurationDays}
          />
        )}
        <div className={styles.stripeInfo}>
          <div className={styles.checkout}>
            <IconLabel name="lock" className={styles.lock} />
            <span className={styles.text}>
              <FormattedMessage
                id="premium.payment-summary.stripe-info.checkout"
                defaultMessage="Guaranteed safe & secure checkout"
              />
            </span>
            <IconLabel name="powered-by-stripe" className={styles.poweredByStripe} />
          </div>
          <div className={styles.iconCards} />
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
