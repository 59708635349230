import React from 'react';
import PropTypes from 'prop-types';
import IconLabel from './../util/IconLabel';
import styles from './CollectionButton.css';
import CapacitorRipple from './Ripple';

const CollectionButton = props => {
  return (
    <button className={styles.button} onClick={props.onClick}>
      <CapacitorRipple version="center" />
      <div>
        {props.active ? (
          <IconLabel name="heart-block" size="default" />
        ) : (
          <IconLabel name="heart" size="default" />
        )}
      </div>
    </button>
  );
};

CollectionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default CollectionButton;
