export const LIVE_COMPARE_METHOD_CROSSFADE = 'crossfade';
export const LIVE_COMPARE_METHOD_PLAIN = 'plain';

export const LIVE_COMPARE_PHASE_BEGIN = 'begin';
export const LIVE_COMPARE_PHASE_END = 'end';

export function liveCompareError(reason, message, fromTrackId, toTrackId) {
  return {
    type: 'LIVE_COMPARE_ERROR',
    reason,
    message,
    fromTrackId: !!fromTrackId ? fromTrackId.toString() : null,
    toTrackId: !!toTrackId ? toTrackId.toString() : null,
  };
}

export function trackLiveCompareSwitch(method, phase, fromTrackId, toTrackId) {
  return {
    type: 'TRACK_LIVE_COMPARE_SWITCH',
    method,
    phase,
    fromTrackId: fromTrackId.toString(),
    toTrackId: toTrackId.toString(),
  };
}
