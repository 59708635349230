import 'isomorphic-fetch';
import serialize from 'serialize-javascript';
import Qs from 'qs';
import { isEmpty } from 'lodash';

export function getFetchOptions({ method, credentials, headers, formData, body }, deviceId) {
  const fetchOptions = { method };

  if (headers || deviceId) {
    fetchOptions.headers = new Headers();
  }

  if (headers) {
    Object.keys(headers)
      .filter(key => key && headers[key])
      .forEach(key => {
        const value = headers[key];
        fetchOptions.headers.append(key, value);
      });
  }

  /*
    Device id gets passed in explicitly for requests made
    elsewhere than the redux api middleware.
  */
  if (deviceId) {
    fetchOptions.headers.append('X-Device-ID', deviceId);
  }

  if (credentials) {
    fetchOptions.credentials = credentials;
  }

  if (body && !isEmpty(body)) {
    fetchOptions.body = serialize(body);
  }

  if (formData) {
    if (body) {
      throw new Error('Both formData and body were supplied to fetch');
    }
    fetchOptions.body = Qs.stringify(formData);
  }

  return fetchOptions;
}

export default function request(requestData, deviceId) {
  const { url, query } = requestData;
  const queryString = Qs.stringify(query);
  const urlWithQuery = queryString ? `${url}?${queryString}` : url;

  const fetchOptions = getFetchOptions(requestData, deviceId);
  return fetch(urlWithQuery, fetchOptions);
}

export function checkStatus(response) {
  if (response.ok) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}
