// @flow
import { handlePaginatedListRequest } from '../phasedActionHelpers';

import type { LoadMyEventsAction } from '../../actions/livestreamEvents';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<number>,
|};

const initialState = {
  loading: false,
  loaded: false,
  loadedCursors: {},
  entities: [],
};

export default function myEvents(state: State = initialState, action: LoadMyEventsAction) {
  if (action.type === 'FETCH_MY_EVENTS') {
    return handlePaginatedListRequest(state, action, result => {
      return result;
    });
  }

  (action: empty);
  return state;
}
