// @flow

import { handleListRequest } from '../phasedActionHelpers';

import type { LoadMoodsAction } from '../../actions/mood';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
|};

const initialState = {
  loading: false,
  loaded: false,
};

export default function moods(state: State = initialState, action: LoadMoodsAction) {
  if (action.type === 'FETCH_MOODS') {
    return handleListRequest(state, action);
  }

  (action: empty);
  return state;
}
