// @flow

export type State = boolean;
import type { ConnectionAction } from '../../actions/connection';

export default function connected(state: State = true, action: ConnectionAction) {
  if (action.type === 'CONNECTED') {
    return true;
  }

  if (action.type === 'DISCONNECTED') {
    return false;
  }

  if (action.type === 'NETWORK_ERROR') {
    return state;
  }

  (action: empty);
  return state;
}
