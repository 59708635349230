import BasePlayer from './BasePlayer';
import { createTimeoutError } from '../../utils/liveCompare';

export default class Player extends BasePlayer {
  static _getSource = () => document.createElement('source');

  _switchToPreloadPlayer = (autoPlay = true) => {
    this._addMainListeners(this.preloadAudio);
    this._removeMainListeners(this.mainAudio);

    this.mainAudio.pause();

    this.preloadAudio.muted = this.mainAudio.muted;
    this.preloadAudio.volume = this.mainAudio.volume;

    this.mainAudio = this.preloadAudio;
    this.mainAudio.currentTime = 0;
    if (autoPlay) {
      this.mainAudio.play();
    }
    this.preloadAudio = this.createAudioDevice();
  };

  /**
   * tries to start make sure that the audioDevice starts playing by subscribing to its events and to the play promise
   * which is returned by .play() call. If playback couldn't start successfully before the timeout, an TimeoutError will be thrown.
   * @param audioDevice HTMLAudioElement
   * @param getCurrentTimestamp () => Promise<number>
   * @param playPromise Promise<void>
   * @param timeout number
   * @returns {Promise<void>}
   * @throws TimeoutError
   * @private intended to be used by live compare
   */
  _waitWhileAudioStartsPlaying = (
    audioDevice,
    getCurrentTimestamp,
    playPromise,
    timeout = 5000
  ) => {
    const interval = setInterval(async () => {
      audioDevice.currentTime = await getCurrentTimestamp();
    }, 200);

    return new Promise((resolve, reject) => {
      const failureTimeout = setTimeout(() => {
        clearInterval(interval);
        clearTimeout(failureTimeout);
        audioDevice.pause();
        reject(createTimeoutError());
      }, timeout);

      const listener = () => {
        if (!audioDevice.paused && audioDevice.readyState === 4) {
          clearInterval(interval);
          clearTimeout(failureTimeout);
          audioDevice.removeEventListener('playing', listener);
          audioDevice.removeEventListener('play', listener);
          resolve();
        }
      };

      audioDevice.addEventListener('playing', listener);
      audioDevice.addEventListener('play', listener);
      if (playPromise) {
        playPromise.then(listener);
      }
    });
  };

  /**
   * tries to crossfade between this.mainAudio and withAudioDevice by recurrently
   * setting the currentTime with the returned value of playFromTimestampFn, awaiting for the playback to start.
   * if the playback on the withAudioDevice starts successfully, then the crossfade is happening by changing the volumes of two audios
   * linearly in a loop. if the playback doesn't start before certain timeout, an exception is thrown by _waitWhileAudioStartsPlaying
   * which (when caught outside) does the hard switch instead.
   * @param playFromTimestampFn () => Promise<number>
   * @param withAudioDevice HTMLAudioElement
   * @returns {Promise<void>}
   * @throws TimeoutError
   */
  _liveCompareSwitch = async (playFromTimestampFn, withAudioDevice) => {
    const startVolume = this.mainAudio.volume;

    this._removeMainListeners(this.mainAudio);

    withAudioDevice.volume = 0;
    withAudioDevice.currentTime = await playFromTimestampFn();

    const playPromise = withAudioDevice.play();
    // can throw
    await this._waitWhileAudioStartsPlaying(withAudioDevice, playFromTimestampFn, playPromise);

    // sometimes, safari resets the currentTime to zero for some reason and this is used to prevent this. alternatively, this can be moved to _waitWhileAudioStartsPlaying
    const expectedActualTimestampDifference = Math.abs(
      (await playFromTimestampFn()) - withAudioDevice.currentTime
    );
    if (expectedActualTimestampDifference > 0.4) {
      withAudioDevice.currentTime = await playFromTimestampFn();
    }

    if (!this.mainAudio.muted) {
      // todo: can be perhaps replaced by an animation library like https://github.com/juliangarnier/anime if we can justify this?
      for (let idx = 0; idx < 30; idx++) {
        await new Promise(r => setTimeout(r, 50));

        this.mainAudio.volume = startVolume - (startVolume / 30) * (idx + 1);
        withAudioDevice.volume = (startVolume / 30) * (idx + 1);
      }
    } else {
      console.log('skipping fade'); // eslint-disable-line
    }

    this.mainAudio.pause();
    withAudioDevice.muted = this.mainAudio.muted;
    withAudioDevice.volume = startVolume;
    this.mainAudio = withAudioDevice;
    this._addMainListeners(this.mainAudio);
  };

  _onPreloadPlaying = index => {
    this.preloadAudio.removeEventListener('playing', this._onPreloadPlaying);

    if (!this._isStillPreloading(index)) {
      this.emit(BasePlayer.ABORTED_PRELOAD, {
        phase: BasePlayer.FINISHED_PRELOAD,
        oldPreloadingIndex: index,
        newPreloadingIndex: this.preloadingIndex,
      });
      return;
    }

    this.emit(BasePlayer.FINISHED_PRELOAD, index);
    this.isPreloading = false;
    this.isPreloaded = true;
    this.preloadingIndex = null;
    this.preloadAudio.pause();
  };

  _preloadQueueItemAtIndex = async index => {
    this.emit(BasePlayer.STARTED_PRELOAD, index);
    this.isPreloading = true;
    this.preloadingIndex = index;

    const paramsAtRequestTime = this._getEventProperties(index, true);
    this.emit(BasePlayer.RESOLVING_URL, paramsAtRequestTime);

    const nextUrl = await this._resolveItemUrlAtIndex(index);
    if (!this._isStillPreloading(index)) {
      this.emit(BasePlayer.ABORTED_PRELOAD, {
        phase: BasePlayer.RESOLVING_URL,
        oldPreloadingIndex: index,
        newPreloadingIndex: this.preloadingIndex,
      });
      return;
    }

    this.emit(BasePlayer.RESOLVED_URL, paramsAtRequestTime);

    this.preloadAudio.wasPreload = true;
    this._setSrc(this.preloadAudio, nextUrl);
    this.preloadAudio.volume = 0;
    this.preloadAudio.addEventListener('playing', () => this._onPreloadPlaying(index));

    this.preloadAudio.play();
  };

  _startPlayback = (url, shouldStartPlayback, audioElement) => {
    const localAudio = audioElement || this.mainAudio;

    this._setSrc(localAudio, url);

    localAudio.addEventListener('loadstart', () => {
      this.emit(BasePlayer.STARTED_BUFFERING, Date.now());
    });

    const loadPromise = new Promise(resolve => {
      const callback = () => {
        localAudio.removeEventListener('loadedmetadata', callback);
        localAudio.removeEventListener('loadeddata', callback);
        localAudio.removeEventListener('canplay', callback);
        resolve();
      };
      localAudio.addEventListener('loadedmetadata', callback);
      localAudio.addEventListener('loadeddata', callback);
      localAudio.addEventListener('canplay', callback);
    });

    // loading needs to happen when setting the queue also
    localAudio.load();
    if (shouldStartPlayback) {
      return localAudio.play();
    }

    return loadPromise;
  };

  _setSrc = (target, url) => {
    // Cleanup previous source elements, if present
    if (target.firstChild) {
      target.removeChild(target.firstChild);
    }

    const sourceTag = Player._getSource();
    sourceTag.type = this._qualityToSrc();
    sourceTag.src = url;

    target.appendChild(sourceTag);
  };

  _getSrc = target => (target.firstChild ? target.firstChild.src : null);

  _qualityToSrc = () => (this.quality === 90 ? 'audio/flac' : 'audio/mpeg');
}
