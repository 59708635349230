import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import IconLabel from './../util/IconLabel';
import styles from './CollectionButton.css';
import stylesShareButton from './ShareButton.css';
import CapacitorRipple from '../capacitor/Ripple';
import SaveToCollectionButton, {
  message as saveToCollectionButtonMessage,
} from '../messages/SaveToCollectionButton';

const messages = defineMessages({
  remove: {
    id: 'collection.button.in-collection',
    defaultMessage: 'In collection',
  },
});

class CollectionButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    hideText: PropTypes.bool,

    intl: intlShape,
  };

  static defaultProps = {
    disabled: false,
    hideText: true,
  };

  onClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.props.onClick();
  };

  render() {
    const { className, active, hideText, intl, disabled } = this.props;

    const buttonClassNames = classnames(stylesShareButton.shareButton, className, {
      [styles.isDisabled]: disabled,
      [styles.isActive]: active,
    });

    const title = !active
      ? intl.formatMessage(saveToCollectionButtonMessage.save)
      : intl.formatMessage(messages.remove);

    const size = hideText ? 'default' : 'small';

    return (
      <button
        className={buttonClassNames}
        onClick={this.props.onClick}
        title={title}
        data-test="add-to-collection-button"
      >
        <CapacitorRipple version="center" />
        <div>
          <IconLabel name="heart" size={size} className={styles.iconDefault} />
          <IconLabel name="heart-block" size={size} className={styles.iconActive} />
        </div>
        {!hideText && (
          <span className={stylesShareButton.text}>
            {active ? (
              <FormattedMessage
                id="collection.button.in-my-collection"
                defaultMessage="In My Collection"
              />
            ) : (
              <SaveToCollectionButton />
            )}
          </span>
        )}
      </button>
    );
  }
}

export default injectIntl(CollectionButton);
