export function selectNextCursor(entity) {
  if (entity && entity.meta && entity.meta.cursor) {
    return entity.meta.cursor.next;
  }
  return '';
}

export function selectPreviousCursor(entity) {
  if (entity && entity.meta && entity.meta.cursor) {
    return entity.meta.cursor.prev;
  }
  return '';
}
