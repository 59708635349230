import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

function selectSonosQueues(state) {
  return state.sonos.queues;
}

/**
  WARNING this selector is not memoized and only used for
  sonos cloud queue request logic. Do not use in react
  land unless first memoizing it.
*/
export function selectSonosQueue(state, id) {
  return assembleEntity(Schema.SonosQueue, state.entities, id);
}

export function selectSonosCloudQueueId(state, id) {
  const queues = selectSonosQueues(state);
  return queues[id] && queues[id].queue.cloudQueueId;
}

export function selectSonosIsConnected(state) {
  return !!state.sonos.connection.isConnected; // might be undefined after store rehydrates
}

export function selectSonosSessionId(state) {
  return state.sonos.connection.sessionId;
}

export function selectSonosGroups(state) {
  return state.sonos.connection.groups;
}
export function selectConnectedSonosGroup(state) {
  return state.sonos.connection.connectedGroup;
}
