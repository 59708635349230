import { setPageVisibility } from '../actions/client';

export default {
  connectToStore: function connectToStore(store) {
    let visibilityProp;
    let visibilityChangeEvent;

    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      visibilityProp = 'hidden';
      visibilityChangeEvent = 'visibilitychange';
    } else if (typeof document.mozHidden !== 'undefined') {
      visibilityProp = 'mozHidden';
      visibilityChangeEvent = 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityProp = 'msHidden';
      visibilityChangeEvent = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityProp = 'webkitHidden';
      visibilityChangeEvent = 'webkitvisibilitychange';
    }

    // Bail out if the browser doesn't support visibiliy change events:
    if (
      !visibilityProp ||
      !visibilityChangeEvent ||
      typeof document.addEventListener === 'undefined' ||
      document[visibilityProp] === 'undefined'
    ) {
      return;
    }

    document.addEventListener(
      visibilityChangeEvent,
      () => {
        store.dispatch(setPageVisibility(!document[visibilityProp]));
      },
      false
    );
  },
};
