// @flow
import * as cacheValidators from '../middleware/apiCacheValidators';
import { Playlist, PlaylistList } from '../schema';
import { normalizePlaylist } from '../schema/normalizePlaylist';

import type { Request } from './types';
import filterAndSortParams from '../utils/filterAndSortParams';
import { VALID_PLAYLIST_FILTER_PARAMS } from '../constants';
import getSerializedFilterId from '../utils/getSerializedFilterId';
import singulariseParamObject from '../utils/singulariseParamObject';
import { normalizeMetaList } from '../schema/normalizeMeta';

export type LoadPlaylistAction = { type: 'FETCH_PLAYLIST' } & Request;
export type LoadPlaylistsByFilterAction = {
  type: 'FETCH_PLAYLISTS_BY_FILTER',
} & Request;

export type PlaylistAction = LoadPlaylistAction | LoadPlaylistsByFilterAction;

export function loadPlaylist(slug: string): LoadPlaylistAction {
  return {
    type: 'FETCH_PLAYLIST',
    cacheId: slug,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/playlists/{slug}',
      params: {
        slug,
      },
    },
    meta: {
      schema: Playlist,
      normalize: normalizePlaylist,
    },
    cache: {
      fetch: state => state.maps.playlists[slug],
      validate: cacheValidators.loaded,
    },
  };
}

export function loadPlaylistsByFilter(
  params: Object,
  cursor?: string,
  limit?: number = 50
): LoadPlaylistsByFilterAction {
  const validAndSortedParams = filterAndSortParams(params, VALID_PLAYLIST_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_PLAYLIST_FILTER_PARAMS);

  let paginatedParams = {
    ...singulariseParamObject(validAndSortedParams),
    limit,
    artist_function: 'any',
  };

  if (cursor) {
    paginatedParams = {
      ...paginatedParams,
      cursor,
    };
  }

  return {
    type: 'FETCH_PLAYLISTS_BY_FILTER',
    cacheId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/playlists.v1',
      params: paginatedParams,
    },
    meta: {
      schema: PlaylistList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.maps.playlistsByFilter[cacheId],
      validate: cacheValidators.loadedCursor(cursor),
    },
  };
}
