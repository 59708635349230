import {
  SPACE,
  BACKSPACE,
  ARROW_RIGHT,
  ARROW_LEFT,
  ARROW_UP,
  ARROW_DOWN,
  LETTER_F,
  LETTER_H,
  LETTER_R,
  LETTER_S,
} from '../lib/key-codes';

import { USER_CLICKED_NEXT } from '../constants';

import { getQueueOriginUrl } from '../lib/queue';

import {
  pause,
  nextThunk,
  previousThunk,
  rewind,
  changeVolume,
  seekBegin,
  seekFinish,
  toggleRepeat,
  toggleShuffle,
  resumeThunk,
} from '../actions/player';

import { showVolume, hideVolume, hideModal, showModal } from '../actions/ui';
import { toggleTrackInCollection } from '../actions/collection';
import { push } from 'react-router-redux';
import { includes, findIndex } from 'lodash';

import { selectPlayerIsPlayingAd, selectPlayerState, selectRepeatAll } from '../selectors/player';
import { selectOpenModalTypeIs } from '../selectors/modals';
import { selectLiveCompareCurrentlyOn } from '../selectors/liveCompare';

let history = [];

function flashVolume(dispatch) {
  dispatch(showVolume());
  const timer = setTimeout(() => {
    clearTimeout(timer);
    dispatch(hideVolume());
  }, 1500);
}

async function handleMediaControlEvent(store, action) {
  const state = store.getState();
  const dispatch = store.dispatch;
  const event = action.event;
  const { playing, currentQueueItem, progress, volume, queueOrigin, queueItems } =
    selectPlayerState(state);
  const repeatAll = selectRepeatAll(state);
  const shortcutsHelpIsVisible = selectOpenModalTypeIs(state, 'SHORTCUTS_HELP_MODAL');
  const { keyCode, shiftKey, metaKey, ctrlKey } = event;
  const isPlayingAd = selectPlayerIsPlayingAd(state);
  const isLiveCompare = selectLiveCompareCurrentlyOn(state);

  // Easter egg
  history.push(keyCode);
  if (includes(history.join(''), '38384040373937396665')) {
    history = [];
    dispatch(push('/recordings/10932902#t=10932914'));
  }

  // toggle play/pause
  if (keyCode === SPACE && currentQueueItem) {
    event.preventDefault();
    dispatch(playing ? pause() : resumeThunk());
    return;
  }

  // Skipping and scrubbing
  if (keyCode === ARROW_RIGHT && currentQueueItem) {
    if (isPlayingAd || isLiveCompare) {
      return;
    }

    event.preventDefault();
    const isEndOfQueue = findIndex(queueItems, currentQueueItem) === queueItems.length - 1;
    if (shiftKey && (!isEndOfQueue || repeatAll)) {
      dispatch(nextThunk(USER_CLICKED_NEXT, 'internal', Date.now()));
      return;
    }
    dispatch(seekBegin());
    dispatch(seekFinish(progress + 5));
    return;
  }

  if (keyCode === ARROW_LEFT && currentQueueItem) {
    if (isPlayingAd || isLiveCompare) {
      return;
    }

    event.preventDefault();
    if (shiftKey) {
      const isStartOfQueue = findIndex(queueItems, currentQueueItem) === 0;
      if (progress === 0 && (!isStartOfQueue || repeatAll)) {
        dispatch(previousThunk('internal', Date.now()));
      } else {
        dispatch(rewind());
      }
      return;
    }
    dispatch(seekBegin());
    let newProgress = progress - 5;
    if (newProgress < 0) {
      newProgress = 0;
    }
    dispatch(seekFinish(newProgress));
    return;
  }

  // Change volume
  if (keyCode === ARROW_UP && shiftKey) {
    event.preventDefault();
    let newVolume = volume + 10;
    if (newVolume > 100) {
      newVolume = 100;
    }
    dispatch(changeVolume(newVolume));
    flashVolume(dispatch);
    return;
  }

  if (keyCode === ARROW_DOWN && shiftKey) {
    event.preventDefault();
    let newVolume = volume - 10;
    if (newVolume < 0) {
      newVolume = 0;
    }
    dispatch(changeVolume(newVolume));
    flashVolume(dispatch);
    return;
  }

  // Toggle track in collection
  if (keyCode === LETTER_F && currentQueueItem && !metaKey && !ctrlKey) {
    // don't break browser find
    if (isPlayingAd) {
      return;
    }

    event.preventDefault();
    dispatch(toggleTrackInCollection(currentQueueItem.track, 'Keyboard'));
    return;
  }

  // Toggle Repeat
  // don't break browser refresh
  if (keyCode === LETTER_R && currentQueueItem && !metaKey && !ctrlKey) {
    if (isPlayingAd) {
      return;
    }

    event.preventDefault();
    dispatch(toggleRepeat());
    return;
  }

  // Toggle Shuffle
  // don't break browser refresh
  if (keyCode === LETTER_S && !metaKey && !ctrlKey) {
    if (isPlayingAd) {
      return;
    }

    event.preventDefault();
    dispatch(toggleShuffle());
    return;
  }

  // Go to player queue origin
  if (keyCode === BACKSPACE && shiftKey && queueOrigin) {
    event.preventDefault();
    const queueOriginLink = getQueueOriginUrl(queueOrigin);
    queueOriginLink ? dispatch(push(queueOriginLink)) : null;
    return;
  }

  // Toggle show keyboard shortcut help modal
  if (keyCode === LETTER_H && !metaKey && !ctrlKey && !shiftKey) {
    event.preventDefault();

    dispatch(shortcutsHelpIsVisible ? hideModal() : showModal('SHORTCUTS_HELP_MODAL'));
  }
}

export default store => nextAction => action => {
  if (action.type === 'KEY_DOWN') {
    handleMediaControlEvent(store, action);
  }

  nextAction(action);
};
