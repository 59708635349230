// @flow
import { identity, flowRight, kebabCase } from 'lodash';
import {
  TrackList,
  RecordingList,
  PlaylistList,
  AlbumList,
  ArtistList,
  LivestreamEventList,
} from '../schema';
import { normalizeMetaList, normalizeMetaItem } from '../schema/normalizeMeta';
import { normalizeRecordingList } from '../schema/normalizeRecording';
import * as cacheValidators from '../middleware/apiCacheValidators';
import { selectEntityIsInCollection } from '../selectors/collection';
import { selectFeatureFlag } from '../selectors/features';

function asString(trackIds): Array<string> {
  return trackIds.map(trackId => trackId.toString());
}

const mappings = {
  GET: {
    track: 'FETCH_TRACK_COLLECTION',
    recording: 'FETCH_RECORDING_COLLECTION',
    album: 'FETCH_ALBUM_COLLECTION',
    playlist: 'FETCH_PLAYLIST_COLLECTION',
    artist: 'FETCH_ARTIST_COLLECTION',
    livestreamEvent: 'FETCH_LIVESTREAM_EVENT_COLLECTION',
  },
  POST: {
    track: 'ADD_TRACK_TO_COLLECTION',
    recording: 'ADD_RECORDING_TO_COLLECTION',
    album: 'ADD_ALBUM_TO_COLLECTION',
    playlist: 'ADD_PLAYLIST_TO_COLLECTION',
    artist: 'ADD_ARTIST_TO_COLLECTION',
    livestreamEvent: 'ADD_LIVESTREAM_EVENT_TO_COLLECTION',
  },
  DELETE: {
    track: 'REMOVE_TRACK_FROM_COLLECTION',
    recording: 'REMOVE_RECORDING_FROM_COLLECTION',
    album: 'REMOVE_ALBUM_FROM_COLLECTION',
    playlist: 'REMOVE_PLAYLIST_FROM_COLLECTION',
    artist: 'REMOVE_ARTIST_FROM_COLLECTION',
    livestreamEvent: 'REMOVE_LIVESTREAM_EVENT_FROM_COLLECTION',
  },
};

import type { Request, ThunkAction } from './types';

type LoadCollectionActionTypes = $Values<typeof mappings.GET>;
type AddToCollectionActionTypes = $Values<typeof mappings.POST>;
type RemoveFromCollectionActionTypes = $Values<typeof mappings.DELETE>;

type CollectionActionTypes =
  | LoadCollectionActionTypes
  | AddToCollectionActionTypes
  | RemoveFromCollectionActionTypes;

export type LoadCollectionIdsAction = {
  type: 'FETCH_COLLECTION_IDS',
} & Request;
type LoadTrackCollectionAction = { type: typeof mappings.GET.track } & Request;
type LoadRecordingCollectionAction = {
  type: typeof mappings.GET.recording,
} & Request;
type LoadAlbumCollectionAction = { type: typeof mappings.GET.album } & Request;
type LoadPlaylistCollectionAction = {
  type: typeof mappings.GET.playlist,
} & Request;
type LoadArtistCollectionAction = {
  type: typeof mappings.GET.artist,
} & Request;
type LoadLivestreamEventCollectionAction = {
  type: typeof mappings.GET.livestreamEvent,
} & Request;

type CollectionType = $Keys<typeof mappings.GET>;

type LoadCollectionAction = { type: LoadCollectionActionTypes } & Request;
export type ModifyCollectionAction = {
  type: AddToCollectionActionTypes | RemoveFromCollectionActionTypes,
  ids: Array<string>,
  collectionType: CollectionType,
} & Request;

export type CollectionAction =
  | LoadCollectionAction
  | ModifyCollectionAction
  | LoadCollectionIdsAction;

type Method = $Keys<typeof mappings>;

function getActionTypeByMethodAndCollectionType(
  method: Method,
  collectionType: CollectionType
): CollectionActionTypes {
  if (!mappings[method] || !mappings[method][collectionType]) {
    throw new Error('Unsupported method + collectionType combination');
  }

  return mappings[method][collectionType];
}

function loadCollection(
  collectionType: CollectionType,
  schema,
  cursor,
  limit = 20,
  normalizeCollectionItems = identity
): LoadCollectionAction {
  const request = {
    type: 'API_REQUEST',
    method: 'GET',
    endpoint: `/v2.0/me/favorites/${kebabCase(collectionType)}s`,
    params: {},
  };

  if (cursor) {
    request.params.cursor = cursor;
  }

  if (limit) {
    request.params.limit = limit;
  }

  return {
    type: getActionTypeByMethodAndCollectionType('GET', collectionType),
    IDAGIO_REQUEST: request,
    meta: {
      restricted: true,
      normalize: flowRight(normalizeCollectionItems, normalizeMetaList),
      schema,
    },
    cache: {
      fetch: state => state.lists.collection[`${collectionType}s`],
      validate: cacheValidators.loadedCursor(cursor),
    },
  };
}

export function loadTrackCollection(cursor: string, limit: number): LoadTrackCollectionAction {
  return loadCollection('track', TrackList, cursor, limit);
}

export function loadRecordingCollection(
  cursor: string,
  limit: number
): LoadRecordingCollectionAction {
  return loadCollection('recording', RecordingList, cursor, limit, normalizeRecordingList);
}

export function loadPlaylistCollection(
  cursor?: string,
  limit: number
): LoadPlaylistCollectionAction {
  return loadCollection('playlist', PlaylistList, cursor, limit);
}

export function loadAlbumCollection(cursor?: string, limit: number): LoadAlbumCollectionAction {
  return loadCollection('album', AlbumList, cursor, limit);
}

export function loadArtistCollection(cursor?: string, limit?: number): LoadArtistCollectionAction {
  return loadCollection('artist', ArtistList, cursor, limit);
}

export function loadLivestreamEventCollection(
  cursor?: string,
  limit?: number
): LoadLivestreamEventCollectionAction {
  return loadCollection('livestreamEvent', LivestreamEventList, cursor, limit);
}

function modifyCollection(
  ids: Array<string>,
  method: Method,
  collectionType: CollectionType,
  trackingSource: string,
  capacitorContextEntityType?: string,
  capacitorContextEntityId?: string
): ModifyCollectionAction {
  return {
    type: getActionTypeByMethodAndCollectionType(method, collectionType),
    collectionType,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method,
      endpoint: `/v2.0/me/favorites/${kebabCase(collectionType)}s`,
      body: ids,
    },
    meta: {
      restricted: true,
    },
    ids,
    trackingSource,
    capacitorContextEntityType,
    capacitorContextEntityId,
  };
}

export function loadCollectionIds(): LoadCollectionIdsAction {
  return {
    type: 'FETCH_COLLECTION_IDS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/me/favorites/ids',
    },
    meta: {
      restricted: true,
      normalize: normalizeMetaItem,
    },
  };
}

export function addToCollection(
  collectionType: CollectionType,
  ids: Array<any>,
  trackingSource: string,
  capacitorContextEntityType?: string,
  capacitorContextEntityId?: string
): ModifyCollectionAction {
  return modifyCollection(
    asString(ids),
    'POST',
    collectionType,
    trackingSource,
    capacitorContextEntityType,
    capacitorContextEntityId
  );
}

export function removeFromCollection(
  collectionType: CollectionType,
  ids: Array<any>,
  trackingSource: string,
  capacitorContextEntityType?: string,
  capacitorContextEntityId?: string
): ModifyCollectionAction {
  return modifyCollection(
    asString(ids),
    'DELETE',
    collectionType,
    trackingSource,
    capacitorContextEntityType,
    capacitorContextEntityId
  );
}

export function toggleInCollection(
  collectionType: CollectionType,
  id: string,
  trackingSource: string,
  capacitorContextEntityType?: string,
  capacitorContextEntityId?: string
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const collectionIdsState = state.lists.collection.ids;

    try {
      // We need to dispatch loadCollectionIds in case of an error because the collection.ids reducer also
      // adds and removes items eagerly.
      if (selectEntityIsInCollection(collectionType, collectionIdsState, id)) {
        await dispatch(
          removeFromCollection(
            collectionType,
            [id],
            trackingSource,
            capacitorContextEntityType,
            capacitorContextEntityId
          )
        );
      } else {
        await dispatch(
          addToCollection(
            collectionType,
            [id],
            trackingSource,
            capacitorContextEntityType,
            capacitorContextEntityId
          )
        );
      }
    } catch (e) {
      dispatch(loadCollectionIds());
    }
  };
}

export function toggleTrackInCollection(id: string, trackingSource: string): ThunkAction {
  return toggleInCollection('track', id, trackingSource);
}

/**
  Why do we need this? We normally avoid loading the collections before render to improve
  response times. However, for the FreeCollectionStatusModal, we need the full collection
  items from the get go unless we want to show a loading state in the modal. Since we're
  only loading a few items (and only for free users), it shouldn't increase the response
  times too much.
 */
export function loadLimitedCollection(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const collectionAccessLimit = selectFeatureFlag(state, 'collections').access_limit;
    if (collectionAccessLimit) {
      await Promise.all([
        dispatch(loadAlbumCollection(undefined, collectionAccessLimit)),
        dispatch(loadPlaylistCollection(undefined, collectionAccessLimit)),
      ]);
    }
  };
}
