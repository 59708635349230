import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as uiActions from '../actions/ui';
import { selectBaseUrl } from '../selectors/client';

import { snakeCase } from 'lodash';
import { MiscActionsPlugin } from '../capacitor-connector';
import {
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_ARTIST,
  ENTITY_TYPE_DISCOVER_GROUPS,
  ENTITY_TYPE_PERSONAL_PLAYLIST,
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_RECORDING,
} from '../constants';

const contentTypeToCapacitorShareContentType = {
  [ENTITY_TYPE_ALBUM]: 'Album',
  [ENTITY_TYPE_PLAYLIST]: 'Playlist',
  [ENTITY_TYPE_PERSONAL_PLAYLIST]: 'Playlist',
  [ENTITY_TYPE_RECORDING]: 'Recording',
  [ENTITY_TYPE_DISCOVER_GROUPS]: 'DiscoverGroupList',
  [ENTITY_TYPE_ARTIST]: 'Profile',
};

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export const shareableEntityPropTypes = {
  showModal: PropTypes.func,
  baseUrl: PropTypes.string,
  entityTypeString: PropTypes.string,
};

export default function shareableEntity(entityType, getShareTrackingContext, options = {}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class ShareableEntity extends Component {
      static propTypes = shareableEntityPropTypes;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            ref={withRef ? 'wrappedInstance' : null}
            showShareModal={this.showShareModal}
          />
        );
      }

      filterUtmParams = params => {
        const utmParams = {};
        if (params) {
          Object.keys(params).map(key => {
            if (key.startsWith('utm')) {
              utmParams[key] = params[key];
            }
          });
        }
        return utmParams;
      };

      showShareModal = () => {
        const { entityTypeString } = this.props;
        const sharedUrl = options.getShareUrl
          ? `${this.props.baseUrl}${options.getShareUrl(this.props)}`
          : `${this.props.baseUrl}/${this.props.location.pathname.replace(/^\//, '')}${
              this.props.location.search
            }`;

        if (__CAPACITOR__) {
          MiscActionsPlugin.androidShouldShareUrl({
            contentType:
              entityTypeString && contentTypeToCapacitorShareContentType[entityTypeString]
                ? contentTypeToCapacitorShareContentType[entityTypeString]
                : 'Capacitor',
            url: sharedUrl,
          });
          return;
        }

        let embedCode;
        const embedEntity = this.props.album || this.props.playlist || this.props.collection;

        if (embedEntity) {
          const embedId = `${snakeCase(entityTypeString)}_id=${embedEntity.id}`;
          const title = this.props.title || embedEntity.title;
          const embedTitle = `${snakeCase(entityTypeString)}_title=${encodeURIComponent(title)}`;

          embedCode = `<iframe
             src='${this.props.baseUrl}/player?${embedId}&${embedTitle}'
             width="500"
             height="400"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>`
            .replace(/(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/gm, '$1$3')
            .split('\n')
            .join(' ');
        }

        const sharedUrlParams = this.filterUtmParams(options.sharedUrlParams);
        this.props.showModal('SHARE_MODAL', getShareTrackingContext(this.props), {
          sharedUrl,
          sharedUrlParams,
          embedCode,
          entityType: entityTypeString,
        });
      };
    }

    ShareableEntity.displayName = `ShareableEntity(${getDisplayName(WrappedComponent)}`;

    ShareableEntity.WrappedComponent = WrappedComponent;

    function mapStateToProps(state, ownProps) {
      const entityTypeIsFunction = typeof entityType === 'function';
      const entityTypeString = entityTypeIsFunction ? entityType(ownProps) : entityType;
      return {
        baseUrl: selectBaseUrl(state),
        entityTypeString,
      };
    }

    return compose(
      connect(mapStateToProps, {
        showModal: uiActions.showModal,
      })
    )(ShareableEntity);
  };
}
