// @flow
import { showModal } from '../actions/ui';
import { selectUserIsAuthenticated, selectUserIsPatron } from '../selectors/user';
import { addNotification } from '../actions/notifications';
import { USER_NOT_ELIGIBLE } from '../lib/notifications';
import type { State } from '../reducers/index';

type Query = {
  userNotEligible: string | number,
  showSubscribeModal: string | number,
  showPreviewsModal: string | number,
  showLoginModal: string | number,
  showVoucherModal: string | number,
  showSignupModal: string | number,
};

export function showQueryParamModal({ state, query }: { state: State, query: Query }) {
  if (!query) {
    return false;
  }
  const {
    userNotEligible,
    showSubscribeModal,
    showPreviewsModal,
    showLoginModal,
    showVoucherModal,
    showSignupModal,
  } = query;
  const authenticated = selectUserIsAuthenticated(state);
  const patron = selectUserIsPatron(state);

  if (userNotEligible) {
    return addNotification(USER_NOT_ELIGIBLE);
  }

  // Open a modal, maybe
  const trackingContext = { trigger: 'queryParam' };

  if (showVoucherModal) {
    return showModal('VOUCHER_MODAL', trackingContext);
  }

  if (showSubscribeModal && !patron) {
    return showModal('SUBSCRIBE_MODAL', trackingContext);
  }

  if (showPreviewsModal && !patron) {
    return showModal('PREVIEWS_MODAL', trackingContext);
  }

  if (showLoginModal && !authenticated) {
    return showModal('LOGIN_MODAL', trackingContext);
  }

  if (showSignupModal && !authenticated) {
    return showModal('SIGNUP_MODAL', trackingContext);
  }

  return false;
}
