import * as Sentry from '@sentry/react';
import * as playerActions from '../actions/player';
import { playRecommendations } from '../actions/recommendations';
import { FINISHED_TRACK } from '../constants';
import { networkError } from '../actions/connection';
import { isSentryInstalled } from './Sentry';
import { addNotification } from '../actions/notifications';
import {
  endTimeToPlayTracking,
  track as analyticsTrack,
  trackRestartedTrack,
  trackResumedPlaying,
  startTimeBufferingToPlayTracking,
} from '../actions/analytics';
import { setIsLoadingAudio } from '../actions/client';
import { invalidateTrackUrl } from '../actions/trackUrls';
import { PLAYBACK_ERROR } from '../lib/notifications';
import {
  selectPlayerCurrentQueueItem,
  selectPlayerIsPlayingAd,
  selectPlayerState,
  selectRepeatAll,
  selectRepeatOne,
} from '../selectors/player';
import { selectAdById } from '../selectors/ads';

import AdsPlayer from '../client/players/AdsPlayer';

import { adPlaybackProgress, endedAdPlayback, startedAdPlayback } from '../actions/ads';
import { selectAutoplay } from '../selectors/client';

export function attachStoreToLocalPlayer(store, player, Player, adsPlayer) {
  // AdsPlayer events
  adsPlayer.on(AdsPlayer.ENDED, (isLast, event) => {
    const { adId } = event;
    if (isLast) {
      store.dispatch(endedAdPlayback(adId));
      // Since we account for starting playback with an ad, we need to also account
      // for the setting of the queue for the next track somewhere else when triggered
      // the the shouldAdvance functionality
      player.playCurrentQueuedItem();
    }
  });

  adsPlayer.on(AdsPlayer.STARTED_PLAYING, event => {
    const { adId } = event;
    store.dispatch(playerActions.enableControls());
    const ad = selectAdById(store.getState(), adId);
    store.dispatch(startedAdPlayback(adId, ad));
  });

  adsPlayer.on(AdsPlayer.PROGRESS, event => {
    const { adId, currentTime, duration } = event;
    store.dispatch(adPlaybackProgress(currentTime, duration, adId));
  });

  adsPlayer.on(AdsPlayer.PLAYBACK_TIME_OUT, (event, timeoutTime) => {
    const { adId, isLast } = event;
    store.dispatch(endedAdPlayback(adId));
    store.dispatch(analyticsTrack('Ad Playback Start Timeout Error', event));

    if (isLast) {
      store.dispatch(playerActions.enableControls());
      player.playCurrentQueuedItem();
    } else {
      adsPlayer.playNextItem();
    }

    if (isSentryInstalled()) {
      Sentry.captureException(new Error('PLAYBACK_TIME_OUT'), {
        tags: {
          component: 'AdPlayback',
          errorType: 'AdPlaybackTimeOutError',
        },
        extra: {
          ad: adId,
          timeoutTime,
        },
      });
    } else {
      console.error(`Timeout playing track: ${event.trackId}`); // eslint-disable-line no-console
    }
  });

  // Player events
  player.on(Player.RESTARTED_TRACK, () => {
    store.dispatch(trackRestartedTrack());
  });

  player.on(Player.ENDED, isLast => {
    const state = store.getState();
    const repeatAll = selectRepeatAll(state);
    const repeatOne = selectRepeatOne(state);

    if (repeatOne) {
      player.restart();
      return;
    }

    if (isLast) {
      const autoplay = selectAutoplay(state);
      if (autoplay && !repeatAll) {
        store.dispatch(playRecommendations());
      } else {
        store.dispatch(playerActions.resetQueue(repeatAll));
        if (repeatAll) {
          store.dispatch(playerActions.play());
        }
      }
    } else {
      store.dispatch(playerActions.nextThunk(FINISHED_TRACK));
    }
  });

  player.shouldAdvance = function shouldAdvance() {
    const state = store.getState();
    if (selectPlayerIsPlayingAd(state)) {
      // state already got set by PLAYER_NEXT -> SET_AD_QUEUE_AND_PLAY
      return false;
    }

    return !selectRepeatOne(store.getState());
  };

  player.on(Player.PROGRESS, event => {
    const { currentTime, audioQuality, trackPosition, duration } = event;
    let { trackId } = event;
    const state = store.getState();
    const playerState = selectPlayerState(state);
    const source = state.client.isEmbed ? 'Embedded Player' : 'Webapp';
    const { repeatAll, repeatOne, shuffleRecordings, shuffleTracks } = playerState;

    let repeat = 'none';

    if (repeatAll || repeatOne) {
      repeat = repeatAll ? 'all' : 'one';
    }

    let shuffle = 'none';

    if (shuffleRecordings || shuffleTracks) {
      shuffle = shuffleRecordings ? 'recordings' : 'tracks';
    }

    trackId = trackId || selectPlayerCurrentQueueItem(state).track;

    store.dispatch(
      playerActions.progress(
        currentTime,
        duration,
        trackId,
        audioQuality,
        source,
        trackPosition,
        repeat,
        shuffle
      )
    );
  });

  player.on(Player.CONTROLS_DISABLED, () => {
    store.dispatch(playerActions.disableControls());
  });

  player.on(Player.CONTROLS_ENABLED, () => {
    store.dispatch(playerActions.enableControls());
  });

  player.on(Player.RESOLVE_URL_RETRY, event => {
    const { track, quality } = event;
    store.dispatch(invalidateTrackUrl(track, quality));
  });

  player.on(Player.PLAYBACK_TIME_OUT, (params, timeoutTime) => {
    store.dispatch(addNotification(PLAYBACK_ERROR));
    store.dispatch(playerActions.enableControls());
    store.dispatch(setIsLoadingAudio(false));
    store.dispatch(playerActions.pause());
    store.dispatch(analyticsTrack('Playback Start Timeout Error', params));

    if (isSentryInstalled()) {
      Sentry.captureException(new Error('PLAYBACK_TIME_OUT'), {
        tags: {
          component: 'Playback',
          errorType: 'PlaybackTimeOutError',
        },
        extra: {
          track: params.trackId,
          timeoutTime,
        },
      });
    } else {
      console.error(`Timeout playing track: ${params.trackId}`); // eslint-disable-line no-console
    }
  });

  player.on(Player.RESOLVE_URL_ERROR, (error, params) => {
    // Geoblocking: server-side is handled by middleware, but if the
    // user loads the app, and then goes to the US, then playback won't work;
    //
    // Note: This logic is duplicated in api.js
    if (error.status === 451 || (error.status === 403 && error.response.body.errorCode === 45100)) {
      window.location = '/geoblocked';
      return;
    }

    store.dispatch(networkError('playback'));
    store.dispatch(playerActions.enableControls());
    store.dispatch(setIsLoadingAudio(false));
    store.dispatch(playerActions.pause());
    store.dispatch(analyticsTrack('Resolve Url Error', params));

    if (isSentryInstalled()) {
      Sentry.captureException(error, {
        tags: {
          component: 'Playback',
          errorType: 'TrackURLFailure',
        },
        extra: {
          track: params.trackId,
        },
      });
    } else {
      console.error(`Error loading track: ${params.trackId}`, error); // eslint-disable-line no-console
    }
  });

  player.on(Player.PLAYBACK_ERROR, (error, params, src) => {
    const { trackId, audioQuality } = params;
    store.dispatch(invalidateTrackUrl(trackId, audioQuality));
    store.dispatch(addNotification(PLAYBACK_ERROR));
    store.dispatch(playerActions.enableControls());
    store.dispatch(setIsLoadingAudio(false));
    store.dispatch(playerActions.pause());
    store.dispatch(analyticsTrack('Playback Start Error', params));

    if (isSentryInstalled()) {
      Sentry.captureException(new Error(error.message), {
        // new Error because sentry doesn't understand MediaErrors
        tags: {
          component: 'Playback',
          errorType: 'PlaybackFailure',
        },
        extra: {
          track: params.trackId,
          src,
        },
      });
    } else {
      console.error(`Error playing track: ${params.trackId}`, error); // eslint-disable-line no-console
    }
  });

  player.on(Player.STARTED_PLAYING, (event, wasPreloaded) => {
    store.dispatch(endTimeToPlayTracking(event.timestamp, event.trackId, wasPreloaded));
    store.dispatch(setIsLoadingAudio(false));
  });

  player.on(Player.RESUMED_PLAYING, () => {
    store.dispatch(trackResumedPlaying());
  });

  player.on(Player.STARTED_BUFFERING, timestamp => {
    store.dispatch(startTimeBufferingToPlayTracking(timestamp));
  });
}
