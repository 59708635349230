// @flow
import React from 'react';
import { Link, withRouter } from 'react-router';

import SignupOrLogin from '../../components/messages/SignupOrLogin';

type OwnProps = {
  location: Object,
  onClick?: Function,
  text?: string,
};

type Props = OwnProps;

const LoginLink = ({ location, onClick, text }: Props) => {
  return (
    <Link
      to={`/login${location ? location.search : ''}`}
      onClick={onClick}
      className="c-text-link--is-visible"
    >
      {text || <SignupOrLogin />}
    </Link>
  );
};

export default withRouter(LoginLink);
