import { applyMiddleware, compose, createStore } from 'redux';
import { autoRehydrate } from 'redux-persist';
import reducers from '../reducers';

const devToolsShouldLoad =
  typeof window === 'object' &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' &&
  process.env.ENV !== 'production';

export default function createApplicationStore(initialData = {}, middleware) {
  const createStoreWithMiddleware = compose(
    autoRehydrate(),
    applyMiddleware(...middleware),
    devToolsShouldLoad ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )(createStore);

  const store = createStoreWithMiddleware(reducers, initialData);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
