// @flow

import { handleListRequest } from '../phasedActionHelpers';

import type { LoadGenresAction } from '../../actions/genre';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
|};

const initialState = {
  loading: false,
  loaded: false,
};

export default function genres(state: State = initialState, action: LoadGenresAction) {
  if (action.type === 'FETCH_GENRES') {
    return handleListRequest(state, action, result => {
      return result;
    });
  }

  (action: empty);
  return state;
}
