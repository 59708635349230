// @flow
import update from 'immutability-helper';
import type { SetHttpRequestPostDataAction } from '../../actions/client';

export type State = {
  +method: string,
  +postData: ?{},
};

const initialState = {
  method: 'GET',
  postData: {},
};

export default function httpRequest(
  state: State = initialState,
  action: SetHttpRequestPostDataAction
): State {
  if (action.type === 'SET_HTTP_REQUEST_POST_DATA') {
    return update(state, {
      method: { $set: 'POST' },
      postData: { $set: action.data },
    });
  }

  return state;
}
