import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';

import styles from './PaymentForm.css';
import { CONSTANT_MESSAGES } from '../../constants';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';

const messages = defineMessages({
  innerLink: {
    id: 'account.payment-option.footer-link-inner',
    defaultMessage: 'here',
  },
});

class VoucherFormFooter extends PureComponent {
  static propTypes = {
    intl: intlShape,
    locale: PropTypes.string.isRequired,
  };

  render() {
    const { intl, locale } = this.props;

    const text = intl.formatMessage(messages.innerLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <p className={styles.voucherLinks}>
        <FormattedMessage
          id="account.payment-option.footer"
          defaultMessage="Don't have a voucher?"
        />
        &nbsp;
        <FormattedMessage
          id="account.payment-option.footer-link"
          defaultMessage="Get one {link}."
          values={{
            link: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getWebsiteUrlInLocale('/promotions/gift-card', locale)}
                className="c-text-link--is-visible"
                aria-label={ariaLink}
              >
                {text}
              </a>
            ),
          }}
        />
      </p>
    );
  }
}

export default injectIntl(VoucherFormFooter);
