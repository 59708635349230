import { selectPlaylist } from '../selectors/playlist';
import { selectAlbum } from '../selectors/album';
import { selectPersonalPlaylist } from '../selectors/personalPlaylist';

import { loadPlaylist } from '../actions/playlist';
import { loadAlbum } from '../actions/album';
import { loadPersonalPlaylist } from '../actions/personalPlaylist';
import qs from 'querystring';

import {
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_PERSONAL_PLAYLIST,
} from '../constants';
import { getEntityUrl } from './entities';

export const EMBEDDED_ENTITIES = [
  {
    collectionType: ENTITY_TYPE_ALBUM,
    queryParam: 'album',
    selector: selectAlbum,
  },
  {
    collectionType: ENTITY_TYPE_PLAYLIST,
    queryParam: 'playlist',
    selector: selectPlaylist,
  },
  {
    collectionType: ENTITY_TYPE_PERSONAL_PLAYLIST,
    queryParam: 'personal_playlist',
    selector: selectPersonalPlaylist,
  },
];

function getTitleByType(queryParams, type) {
  const titleParams = ['playlist_title', 'album_title', 'personal_playlist_title'];
  const titlePattern = new RegExp('^' + type);
  return queryParams[titleParams.filter(t => t.match(titlePattern))[0]];
}

export function processQueryParams(queryParams) {
  const idParamPattern = /(playlist|album|personal_playlist)_id$/;
  let type;
  let id;

  Object.keys(queryParams).some(param => {
    const matches = param.match(idParamPattern);
    if (matches && matches.length) {
      type = matches[1];
      id = queryParams[param];
      return true;
    }
    return false;
  });

  const title = getTitleByType(queryParams, type);
  const embedEntity = EMBEDDED_ENTITIES.find(e => e.queryParam === type);

  return {
    collectionType: embedEntity && embedEntity.collectionType,
    collectionId: id,
    collectionTitle: decodeURIComponent(title || ''),
  };
}

export function getCollectionSelector(collectionType) {
  const entity = EMBEDDED_ENTITIES.find(e => e.collectionType === collectionType);
  return entity && entity.selector;
}

const loadActions = {
  [ENTITY_TYPE_PLAYLIST]: loadPlaylist,
  [ENTITY_TYPE_ALBUM]: loadAlbum,
  [ENTITY_TYPE_PERSONAL_PLAYLIST]: loadPersonalPlaylist,
};

export function getLoadAction(collectionType) {
  return loadActions[collectionType];
}

export function getOpenInWebAppLink(collectionType, collection, referrer, isModal = false) {
  const params = {
    utm_medium: 'embedded',
    utm_source: 'empl',
    utm_campaign: collection.title,
    f: `embeddable-player${referrer ? `-${referrer}` : ''}`,
  };

  if (isModal) {
    params.utm_term = 'modal';
  }

  const path = getEntityUrl(collectionType, { slug: collection.slug || collection.id });
  const queryString = qs.stringify(params);

  return `${path}?${queryString}`;
}
