import { includes } from 'lodash';
import { createSelector } from 'reselect';

import { selectNextCursor } from './common';

import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';
import { QUEUE_TYPE_TRACK_COLLECTION, TRACK_COLLECTION_ORIGIN_ID } from '../constants';

import { livestreamEventStatusDecorator } from './livestreamEvents.js';

const selectNonAssembledTrackCollection = state => state.lists.collection.tracks;

const selectNonAssembledRecordingCollection = state => state.lists.collection.recordings;

const selectNonAssembledPlaylistCollection = state => state.lists.collection.playlists;

const selectNonAssembledAlbumCollection = state => state.lists.collection.albums;

const selectNonAssembledArtistCollection = state => state.lists.collection.artists;

const selectNonAssembledLivestreamEventCollection = state =>
  state.lists.collection.livestreamEvents;

export const selectCollectionIds = state => state.lists.collection.ids;

export const selectEntityIsInCollection = (type, collectionIdsState, id) => {
  const { entities, loading, loaded } = collectionIdsState;
  const collectionTypeEntity = entities[`${type}Ids`];

  if (!loaded || loading || !id || !collectionTypeEntity) {
    return false;
  }
  return includes(
    collectionTypeEntity.map(entitiesTrackId => entitiesTrackId.toString()),
    id.toString()
  );
};

export const selectTrackCollection = createSelector(
  [state => state.entities, selectNonAssembledTrackCollection],
  (entities, trackCollection) => {
    if (!trackCollection) {
      return [];
    }

    return assembleEntity(Schema.TrackList, entities, trackCollection.entities);
  }
);

export const selectRecordingCollection = createSelector(
  [state => state.entities, selectNonAssembledRecordingCollection],
  (entities, recordingCollection) => {
    if (!recordingCollection) {
      return [];
    }

    return assembleEntity(Schema.RecordingList, entities, recordingCollection.entities);
  }
);

export const selectPlaylistCollection = createSelector(
  [state => state.entities, selectNonAssembledPlaylistCollection],
  (entities, playlistCollection) => {
    if (!playlistCollection) {
      return [];
    }

    return assembleEntity(Schema.PlaylistList, entities, playlistCollection.entities);
  }
);

export const selectAlbumCollection = createSelector(
  [state => state.entities, selectNonAssembledAlbumCollection],
  (entities, albumCollection) => {
    if (!albumCollection) {
      return [];
    }

    return assembleEntity(Schema.AlbumList, entities, albumCollection.entities);
  }
);

export const selectArtistCollection = createSelector(
  [state => state.entities, selectNonAssembledArtistCollection],
  (entities, artistCollection) => {
    if (!artistCollection) {
      return [];
    }

    return assembleEntity(Schema.ArtistList, entities, artistCollection.entities);
  }
);

export const selectLivestreamEventCollection = createSelector(
  [state => state.entities, selectNonAssembledLivestreamEventCollection],
  (entities, livestreamEventCollection) => {
    if (!livestreamEventCollection) {
      return [];
    }

    return assembleEntity(
      Schema.LivestreamEventList,
      entities,
      livestreamEventCollection.entities
    ).map(livestreamEventStatusDecorator);
  }
);

export const selectNextTrackCollectionCursor = state =>
  selectNextCursor(selectNonAssembledTrackCollection(state));
export const selectNextRecordingCollectionCursor = state =>
  selectNextCursor(selectNonAssembledRecordingCollection(state));
export const selectNextPlaylistCollectionCursor = state =>
  selectNextCursor(selectNonAssembledPlaylistCollection(state));
export const selectNextAlbumCollectionCursor = state =>
  selectNextCursor(selectNonAssembledAlbumCollection(state));
export const selectNextArtistCollectionCursor = state =>
  selectNextCursor(selectNonAssembledArtistCollection(state));
export const selectNextLivestreamEventCollectionCursor = state =>
  selectNextCursor(selectNonAssembledLivestreamEventCollection(state));

export const getTrackCollectionOrigin = () => ({
  type: QUEUE_TYPE_TRACK_COLLECTION,
  id: TRACK_COLLECTION_ORIGIN_ID,
});
