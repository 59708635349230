import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectEntityIsInCollection, selectCollectionIds } from '../selectors/collection';
import * as collectionActions from '../actions/collection';
import * as uiActions from '../actions/ui';
import { isFunction } from 'lodash';
import { selectFeatureFlag } from '../selectors/features';
import { selectUserIsAuthenticated } from '../selectors/user';

import { ENTITY_TYPE_PLAYLIST, ENTITY_TYPE_ALBUM } from '../constants.js';
import { messages, thisMessages } from '../components/messages/AddToCollectionPreviewsModal';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export const collectibleEntityPropTypes = {
  isCollectible: PropTypes.bool.isRequired,
  isInCollection: PropTypes.bool,
  toggleInCollection: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default function collectibleEntity(
  collectibleEntityType,
  getCollectibleEntityDescription,
  options = {}
) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class CollectibleEntity extends Component {
      static propTypes = collectibleEntityPropTypes;

      static contextTypes = {
        router: PropTypes.object,
      };

      getCollectionLength = (type, collectionIds) => {
        switch (type) {
          case ENTITY_TYPE_PLAYLIST:
            return collectionIds.entities.playlistIds.length;
          default:
            return collectionIds.entities.albumIds.length;
        }
      };

      render() {
        const { isInCollection, isCollectible } = this.props;
        return (
          <WrappedComponent
            {...this.props}
            ref={withRef ? 'wrappedInstance' : null}
            isInCollection={isInCollection}
            isCollectible={isCollectible}
            toggleIsInCollection={this.toggleIsInCollection}
          />
        );
      }

      toggleIsInCollection = () => {
        const {
          collectionAccessLimit,
          type,
          collectionIds,
          isInCollection,
          isAuthenticated,
          intl,
        } = this.props;

        if (!isAuthenticated) {
          const collectionItem = this.props[this.props.type];
          this.props.showModal(
            'FREE_EXPERIENCE_PREVIEWS_MODAL',
            { trigger: 'collection' },
            {
              imageUrl: collectionItem ? collectionItem.imageUrl : null,
              trigger: 'collectionPreviewsModal',
              title: (
                <FormattedMessage
                  id="add-to-collection-previews-modal.header"
                  defaultMessage="Want to keep {thisResourceName} for later?"
                  values={{ thisResourceName: intl.formatMessage(thisMessages[this.props.type]) }}
                />
              ),
              subtitle: (
                <FormattedMessage
                  id="add-to-collection-previews-modal.subtitle"
                  defaultMessage="Create a free account to build your own {resourceName} collection and never lose track of your favorites."
                  values={{ resourceName: intl.formatMessage(messages[this.props.type]) }}
                />
              ),
            }
          );
          return;
        }

        const { id, trackingSource, capacitorContextEntityType, capacitorContextEntityId } =
          getCollectibleEntityDescription(this.props);
        const isLimitedEntity = type === ENTITY_TYPE_PLAYLIST || type === ENTITY_TYPE_ALBUM;

        if (!isInCollection && isLimitedEntity && collectionAccessLimit) {
          const currentCollectionLength = this.getCollectionLength(type, collectionIds);

          if (currentCollectionLength < collectionAccessLimit) {
            this.props.toggleInCollection(
              id,
              trackingSource,
              capacitorContextEntityType,
              capacitorContextEntityId
            );
          }
          this.props.showModal(
            'FREE_COLLECTION_STATUS_MODAL',
            {
              trigger:
                type === ENTITY_TYPE_PLAYLIST ? 'addPlaylistToCollection' : 'addAlbumToCollection',
            },
            {
              collectionItem: this.props[type],
              collectionItemType: type,
            }
          );
        } else {
          this.props.toggleInCollection(
            id,
            trackingSource,
            capacitorContextEntityType,
            capacitorContextEntityId
          );
        }
      };
    }

    CollectibleEntity.displayName = `CollectibleEntity(${getDisplayName(WrappedComponent)}`;

    CollectibleEntity.WrappedComponent = WrappedComponent;

    function mapStateToProps(state, ownProps) {
      const type = isFunction(collectibleEntityType)
        ? collectibleEntityType(ownProps)
        : collectibleEntityType;
      const { id, active = true } = getCollectibleEntityDescription(ownProps);
      const collectionIds = selectCollectionIds(state);

      return {
        ...ownProps,
        isInCollection: selectEntityIsInCollection(type, collectionIds, id),
        isCollectible: active,
        collectionAccessLimit: selectFeatureFlag(state, 'collections').access_limit,
        type,
        isAuthenticated: selectUserIsAuthenticated(state),
      };
    }

    function mapDispatchtoProps(dispatch, ownProps) {
      const type = isFunction(collectibleEntityType)
        ? collectibleEntityType(ownProps)
        : collectibleEntityType;
      return {
        toggleInCollection: (
          id,
          trackingSource,
          capacitorContextEntityType,
          capacitorContextEntityId
        ) => {
          dispatch(
            collectionActions.toggleInCollection(
              type,
              id,
              trackingSource,
              capacitorContextEntityType,
              capacitorContextEntityId
            )
          );
        },
        showModal: (modalType, _, data) => {
          dispatch(uiActions.showModal(modalType, _, data));
        },
      };
    }

    return compose(connect(mapStateToProps, mapDispatchtoProps), injectIntl)(CollectibleEntity);
  };
}
