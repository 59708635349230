import qs from 'qs';
import makeError from 'make-error';
import request, { checkStatus } from '../lib/request';
import {
  CLIENT_TYPE_HLS_DEFAULT,
  CLIENT_TYPE_HLS_FIREFOX,
  CLIENT_TYPE_WEB,
  CLIENT_TYPE_WEB_DEFAULT,
  CLIENT_TYPE_WEB_FIREFOX,
} from '../constants';

let API_BASE;
let ENCRYPTION_ENABLED;
if (__CLIENT__ && typeof window !== 'undefined') {
  API_BASE = window.__api_base__;
  ENCRYPTION_ENABLED = window.__encryption_enabled__;
} else {
  API_BASE = process.env.API_BASE;
  ENCRYPTION_ENABLED = process.env.ENCRYPTION_ENABLED;
}

const getClientTypeWebEncrypted = isFirefox => {
  // use non-encrypted version for firefox temporarily
  // until there is a permanent fix for the player in Firefox 107
  return isFirefox ? CLIENT_TYPE_WEB_FIREFOX : CLIENT_TYPE_WEB_DEFAULT;
};

const IS_ENCRYPTION_ENABLED = ENCRYPTION_ENABLED === '1';
const getClientTypeTrackJson = isFirefox =>
  IS_ENCRYPTION_ENABLED ? getClientTypeWebEncrypted(isFirefox) : CLIENT_TYPE_WEB;
const getClientTypeHlsM3U8 = isFirefox =>
  isFirefox ? CLIENT_TYPE_HLS_FIREFOX : CLIENT_TYPE_HLS_DEFAULT;

export const TrackUrlResolveError = makeError('TrackUrlResolveError');

export async function mp3Resolver(
  id,
  quality,
  accessToken,
  clientVersion,
  deviceId,
  sonosIsConnected,
  isFirefox
) {
  const method = 'GET';
  const url = `${API_BASE}/v1.8/content/track/${id}`;
  const headers = {
    'Content-Type': 'application/json',
    'X-Client': __ELECTRON__ ? 'desktop' : 'web',
    'X-Client-Version': clientVersion,
    'X-Device-ID': deviceId,
    'X-Device-Class': 'computer',
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  const query = {
    quality,
    format: 2,
    client_type: sonosIsConnected ? 'sonos-2' : getClientTypeTrackJson(isFirefox),
    client_version: clientVersion,
    device_id: 'web',
  };

  const requestObject = { method, url, query, headers };
  const res = await request(requestObject);

  const checkedRes = checkStatus(res);
  const json = await checkedRes.json();
  if (!json.url) {
    throw new TrackUrlResolveError('Track URL was resolved as empty');
  }

  return json.url;
}

export function m3u8Resolver(id, quality, accessToken, clientVersion, deviceId, isFirefox) {
  const url = `${API_BASE}/v1.8/track/${id}.m3u8`;
  const query = {
    quality,
    client_version: clientVersion,
    client_type: getClientTypeHlsM3U8(isFirefox),
    device_id: 'web',

    /*
      These params overwrite the corresponding x-
      headers in the gateway. Reason is that we can't
      set the headers here since the hls lib sends the request
    */
    h_client: 'web',
    h_client_version: clientVersion,
    h_device_id: deviceId,
    h_device_class: 'computer',
  };

  if (accessToken) {
    query.access_token = accessToken;
  }

  return url + '?' + qs.stringify(query);
}
