// @flow

declare var __CLIENT__: boolean; // TODO move somewhere logical

import { capitalize, omit } from 'lodash';
import { selectUser } from '../selectors/user';
import type { ThunkAction } from './types';

import { selectIsIOS, selectIsAndroid, selectIsInApp } from '../selectors/client';

import type { TrackMoveOperation } from './personalPlaylist';

export type TrackAction = { type: '@@analytics/track', event: string, properties: Object } | null;
type TrackTransitionAction = {
  type: '@@analytics/transition',
  components: Array<Object>,
  location: Object,
};
export type FlushAnalyticsProgressBufferAction = { type: 'FLUSH_ANALYTICS_PROGRESS_BUFFER' };
type StartTimeToPlayTrackingAction = { type: 'START_TIME_TO_PLAY_TRACKING' };
type EndTimeToPlayTrackingAction = {
  type: 'END_TIME_TO_PLAY_TRACKING',
  timestamp: number,
  wasPreloaded: boolean,
  trackId: string,
};
type StartTimeBufferingToPlayTrackingAction = {
  type: 'START_TIME_BUFFERING_TO_PLAY_TRACKING',
  timestamp: number,
};
type TrackAppOpenedAction = { type: 'TRACK_APP_OPENED' };
type TrackDesktopInstalledAction = { type: 'TRACK_DESKTOP_INSTALLED' };
type TrackCancelButtonPressedAction = { type: 'TRACK_CANCEL_BUTTON_PRESSED' };
type TrackResumedPlayingAction = { type: 'TRACK_RESUMED_PLAYING' };
type TrackRestartedTrackAction = { type: 'TRACK_RESTARTED_TRACK' };
type IdentifyAction = ThunkAction | null;

type ContextType =
  | 'album'
  | 'category'
  | 'continuousPlaybackWork'
  | 'favouriteAlbums'
  | 'favouritePlaylists'
  | 'favouriteRecordings'
  | 'favouriteTracks'
  | 'mix'
  | 'mood'
  | 'playlist'
  | 'profile'
  | 'recording'
  | 'unknown'
  | 'work';

type ContextFacet =
  | 'Artist'
  | 'Instrument'
  | 'Genre'
  | 'Epoch'
  | 'Work'
  | 'Piece'
  | 'RecordingType';

export type TrackingContext = {
  contextType?: ContextType,
  contextFacet?: ContextFacet,
  contextFilters?: string,
  contextId?: string,
  contextCategory?: string,
  originalContextType?: string,
  originalContextId?: string,
};

export type TrackPlayPausePressedAction = {|
  type: 'TRACK_PLAY_PAUSE_PRESSED',
  context?: TrackingContext,
  playing: boolean,
|};

export type SetPlaybackTrackingContextAction = {|
  type: 'SET_PLAYBACK_TRACKING_CONTEXT',
  context?: TrackingContext,
|};

type User = {
  old_id: number,
  id: string,
};

type TrackFilterType = 'recordings' | 'works';

type TrackFilterProperties = {
  selectedId: string,
  facet: ContextFacet,
  filters: string,
};

type IdentifyUserAction = {
  type: '@@analytics/identify',
  userId: string | number,
  traits: User,
} | null;

export type AnalyticsAction =
  | TrackAction
  | TrackTransitionAction
  | FlushAnalyticsProgressBufferAction
  | StartTimeToPlayTrackingAction
  | EndTimeToPlayTrackingAction
  | TrackResumedPlayingAction
  | TrackAppOpenedAction
  | TrackDesktopInstalledAction
  | TrackCancelButtonPressedAction
  | IdentifyAction
  | IdentifyUserAction
  | SetPlaybackTrackingContextAction;

export function track(event: string, properties: Object = {}): TrackAction {
  if (__CLIENT__) {
    return {
      type: '@@analytics/track',
      event,
      properties,
    };
  }

  return null;
}

export type Track = typeof track;

export function identifyUser(user: User): IdentifyUserAction {
  if (__CLIENT__) {
    const traitsWithoutEmail = omit(user, ['email']);
    return {
      type: '@@analytics/identify',
      userId: user.old_id || user.id,
      traits: traitsWithoutEmail,
    };
  }

  return null;
}

export function identify(): IdentifyAction {
  if (__CLIENT__) {
    return (dispatch, getState) => {
      const state = getState();

      const { auth, me } = state;
      if (auth.isAuthenticated && me.loaded) {
        const user = selectUser(state);
        dispatch(identifyUser(user));
      }
    };
  }

  return null;
}

export function trackTransition(
  components: Array<Object>,
  location: Object
): TrackTransitionAction {
  return {
    type: '@@analytics/transition',
    components,
    location,
  };
}

export function flushAnalyticsProgressBuffer(): FlushAnalyticsProgressBufferAction {
  return {
    type: 'FLUSH_ANALYTICS_PROGRESS_BUFFER',
  };
}

export function startTimeToPlayTracking(): StartTimeToPlayTrackingAction {
  return {
    type: 'START_TIME_TO_PLAY_TRACKING',
  };
}

export function endTimeToPlayTracking(
  timestamp: number,
  trackId: string,
  wasPreloaded: boolean = false
): EndTimeToPlayTrackingAction {
  return {
    type: 'END_TIME_TO_PLAY_TRACKING',
    timestamp,
    wasPreloaded,
    trackId,
  };
}

export function startTimeBufferingToPlayTracking(
  timestamp: number
): StartTimeBufferingToPlayTrackingAction {
  return {
    type: 'START_TIME_BUFFERING_TO_PLAY_TRACKING',
    timestamp,
  };
}

export function trackAppOpened(): TrackAppOpenedAction {
  return {
    type: 'TRACK_APP_OPENED',
  };
}

export function trackDesktopInstalled(): TrackDesktopInstalledAction {
  return {
    type: 'TRACK_DESKTOP_INSTALLED',
  };
}

export function trackPlayPausePressed(
  context: TrackingContext,
  playing: boolean
): TrackPlayPausePressedAction {
  return {
    type: 'TRACK_PLAY_PAUSE_PRESSED',
    context,
    playing,
  };
}

export function setPlaybackTrackingContext(
  context: TrackingContext
): SetPlaybackTrackingContextAction {
  return {
    type: 'SET_PLAYBACK_TRACKING_CONTEXT',
    context,
  };
}

export function trackCancelButtonPressed(): TrackCancelButtonPressedAction {
  return {
    type: 'TRACK_CANCEL_BUTTON_PRESSED',
  };
}

export function trackFilterReset(filterType: TrackFilterType): TrackAction {
  return track(`Reset ${capitalize(filterType)} Filter`);
}

export function trackFilterSelected(
  filterType: TrackFilterType,
  properties: TrackFilterProperties
): TrackAction {
  return track(`Selected ${capitalize(filterType)} Filter`, properties);
}

export function trackResumedPlaying(): TrackResumedPlayingAction {
  return {
    type: 'TRACK_RESUMED_PLAYING',
  };
}

export function trackRestartedTrack(): TrackRestartedTrackAction {
  return {
    type: 'TRACK_RESTARTED_TRACK', // Track is Homograph; 2 meanings, 1 spelling
  };
}

export function trackCreatedPersonalPlaylist(trackingContext: Object): TrackAction {
  return track('Created Personal Playlist', trackingContext);
}

export function trackDeletedPersonalPlaylist(playlistId: string): TrackAction {
  return track('Deleted Personal Playlist', {
    playlistId,
  });
}

export function trackAddedItemToPersonalPlaylist(trackingContext: Object): TrackAction {
  return track('Added To Personal Playlist', trackingContext);
}

export function trackRemovedTrackFromPersonalPlaylist(
  playlistId: string,
  trackId: string
): TrackAction {
  return track('Removed Track From Personal Playlist', {
    playlistId,
    trackId,
  });
}

export function trackMovedTrackInPersonalPlaylist(
  playlistId: string,
  action: TrackMoveOperation
): TrackAction {
  const { trackId, from, to } = action;
  return track('Moved Track On Personal Playlist', {
    trackId,
    from,
    to,
    playlistId,
  });
}

export function trackIdagioLive(event: string, data: Object): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    const isIos = selectIsIOS(state);
    const isAndroid = selectIsAndroid(state);
    const isInApp = selectIsInApp(state);

    dispatch(
      track(event, {
        isIos,
        isAndroid,
        isInApp,
        ...data,
      })
    );
  };
}

export type TrackIdagioLive = typeof trackIdagioLive;
