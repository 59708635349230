// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadTopFacetsAction } from '../../actions/facet';
import type { State } from './topAlbumFacetsByFilter';

const initialState = {};

export default function topWorkFacetsByFilter(
  state: State = initialState,
  action: LoadTopFacetsAction
): State {
  if (action.type === 'FETCH_TOP_WORK_FACETS' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
