// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IconLabel from '../util/IconLabel';
import styles from './PasswordInput.css';
import classNames from 'classnames';

type OwnProps = {
  'id': string,
  'title'?: React$Element<typeof FormattedMessage>,
  'inputError'?: boolean,
  'inputErrorText'?: React$Element<typeof FormattedMessage>,
  'data-test'?: string,
  'className'?: string,
};

type Props = OwnProps;

const PasswordInput = ({
  id,
  title,
  inputError,
  inputErrorText,
  'data-test': dataTest,
  className,
  ...inputProps
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const onClickIcon = event => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={classNames(className, 'c-form-field', {
        'c-form-field-error': inputError,
      })}
    >
      {title && (
        <label htmlFor={id} className="c-form-field__label">
          {title}
        </label>
      )}
      <div className={styles.component}>
        <input
          {...inputProps}
          type={showPassword ? 'text' : 'password'}
          id={id}
          name={id}
          className={classNames('c-form-field__input', styles.input, {
            'c-form-field__input-error': inputError,
          })}
          data-test={dataTest ? `${dataTest}-input` : null}
        />
        <IconLabel
          name="show-password"
          onClick={onClickIcon}
          className={classNames(styles.icon, {
            [styles.inactive]: !showPassword,
          })}
          tabIndex="-1"
          type="button"
        />
      </div>
      <label
        htmlFor={id}
        className={classNames('c-form-field__label-error', {
          visible: inputError,
        })}
        data-test={dataTest ? `${dataTest}-error-label` : null}
      >
        {inputErrorText}
      </label>
    </div>
  );
};

export default PasswordInput;
