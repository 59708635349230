import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

import styles from './LoadingIndicator.css';

export default class LoadingIndicator extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const { isLoading, className } = this.props;
    const classNames = classnames(className, styles.component, {
      [styles.loading]: isLoading,
    });
    return (
      <div className={classNames}>
        <div className={styles.dot1} />
        <div className={styles.dot2} />
        <div className={styles.dot3} />
      </div>
    );
  }
}
