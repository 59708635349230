// @flow

import update from 'immutability-helper';

import type { NotificationAction } from '../../actions/notifications';
import type { Notification } from '../../lib/notifications';

export type State = {
  +lastId: number,
  +list: Array<Notification>,
};

const initialState = {
  lastId: 0,
  list: [],
};

const injectId = (notification, lastId): Notification =>
  Object.assign(notification, { id: lastId + 1 });

export default function notifications(state: State = initialState, action: NotificationAction) {
  if (action.type === 'NOTIFICATION_ADD') {
    const newNotification = injectId(action.notification, state.lastId);
    let list = state.list;
    if (newNotification.topic) {
      list = list.filter(({ topic }) => topic !== newNotification.topic);
    }
    list = [newNotification, ...list];
    return update(state, {
      lastId: { $set: newNotification.id },
      list: { $set: list },
    });
  }

  if (action.type === 'NOTIFICATION_DISMISS') {
    let newList;
    const { id: actionId, topic: actionTopic } = action;
    if (actionId) {
      newList = state.list.filter(({ id }) => id !== actionId);
    } else {
      newList = state.list.filter(({ topic }) => topic !== actionTopic);
    }

    return update(state, {
      list: { $set: newList },
    });
  }

  (action: empty);
  return state;
}
