import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as Shapes from '../../shapes';

import Separated from '../util/Separated';
import SoloistList from './SoloistList';
import Person from './Person';
import EnsembleList from './EnsembleList';

export default class InterpreterList extends PureComponent {
  static propTypes = {
    recording: Shapes.Recording.isRequired,
    multiline: PropTypes.bool,
    hyperlinks: PropTypes.bool,
    linkClassNames: PropTypes.string,
  };

  static defaultProps = {
    multiline: false,
    hyperlinks: false,
    linkClassNames: '',
  };

  renderMultiLine() {
    const { hyperlinks, linkClassNames } = this.props;
    const { summary, conductor, ensembles, soloists } = this.props.recording;
    const soloistsWithPerson = soloists.filter(({ person }) => person);
    if (!!conductor && soloistsWithPerson.length && ensembles.length) {
      return (
        <span>
          <Separated>
            <SoloistList
              soloists={soloistsWithPerson}
              hyperlinks={hyperlinks}
              linkClassNames={linkClassNames}
            />
            <Person person={conductor} hyperlinks={hyperlinks} linkClassNames={linkClassNames} />
          </Separated>
          <br />
          <EnsembleList
            ensembles={ensembles}
            hyperlinks={hyperlinks}
            linkClassNames={linkClassNames}
          />
        </span>
      );
    }

    if (ensembles.length && soloistsWithPerson.length) {
      return (
        <span>
          <EnsembleList
            ensembles={ensembles}
            hyperlinks={hyperlinks}
            linkClassNames={linkClassNames}
          />
          <br />
          <SoloistList
            soloists={soloistsWithPerson}
            hyperlinks={hyperlinks}
            linkClassNames={linkClassNames}
          />
        </span>
      );
    }

    if (!!conductor && ensembles.length) {
      return (
        <Separated>
          <Person person={conductor} hyperlinks={hyperlinks} linkClassNames={linkClassNames} />
          <EnsembleList
            ensembles={ensembles}
            hyperlinks={hyperlinks}
            linkClassNames={linkClassNames}
          />
        </Separated>
      );
    }

    if (ensembles.length) {
      return (
        <EnsembleList
          ensembles={ensembles}
          hyperlinks={hyperlinks}
          linkClassNames={linkClassNames}
        />
      );
    }

    if (soloistsWithPerson.length) {
      return (
        <SoloistList
          soloists={soloistsWithPerson}
          hyperlinks={hyperlinks}
          linkClassNames={linkClassNames}
        />
      );
    }

    return <span>{summary}</span>;
  }

  renderSingleLine() {
    const { hyperlinks, linkClassNames } = this.props;
    const { summary, soloists, conductor, ensembles } = this.props.recording;
    const soloistsWithPerson = soloists.filter(({ person }) => person);
    if (!conductor && !soloistsWithPerson.length && !ensembles.length) {
      return <span>{summary}</span>;
    }

    return (
      <Separated>
        {!!conductor ? (
          <Person person={conductor} hyperlinks={hyperlinks} linkClassNames={linkClassNames} />
        ) : null}
        {ensembles.length ? (
          <EnsembleList
            ensembles={ensembles}
            hyperlinks={hyperlinks}
            linkClassNames={linkClassNames}
          />
        ) : null}
        {soloists.length ? (
          <SoloistList
            soloists={soloists}
            hyperlinks={hyperlinks}
            linkClassNames={linkClassNames}
          />
        ) : null}
      </Separated>
    );
  }

  render() {
    if (this.props.multiline) {
      return this.renderMultiLine();
    }
    return this.renderSingleLine();
  }
}
