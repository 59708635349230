// @flow
import createCollectionReducer from './createCollectionReducer';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<number>,
|};

const initialState: State = {
  loading: false,
  loaded: false,
  loadedCursors: {},
  entities: [],
};

const trackCollectionActions = {
  add: 'ADD_TRACK_TO_COLLECTION',
  remove: 'REMOVE_TRACK_FROM_COLLECTION',
  fetch: 'FETCH_TRACK_COLLECTION',
};

export default createCollectionReducer(initialState, trackCollectionActions);
