import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import ArtistAvatar from './../common/ArtistAvatar';
import classnames from 'classnames';

import styles from './SearchResults.css';
import { SEARCH_IMAGE_DIMS } from '../../constants';

export default class SearchArtistItem extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { path, name, id, onClick } = this.props;
    const classesArtist = classnames(styles.result, 'search-result--artists');

    return (
      <Link to={path} className={classesArtist} onClick={onClick}>
        <ArtistAvatar profileId={id} className={styles.thumb} size={SEARCH_IMAGE_DIMS.w} />
        <span>{name}</span>
      </Link>
    );
  }
}
