import { createSelector } from 'reselect';
import { SEARCH_RESULT_SECTION_FILTER_MAP, SEARCH_RESULT_FILTER_ENTRY_KINDS } from '../constants';

function orderAndFilterResults(order, activeFilter, unordered) {
  return order.map(orderKey => {
    let results = unordered[orderKey];

    if (activeFilter) {
      if (orderKey === 'top') {
        results = unordered[orderKey].filter(result =>
          SEARCH_RESULT_FILTER_ENTRY_KINDS[activeFilter].includes(result.type)
        );
      } else if (SEARCH_RESULT_SECTION_FILTER_MAP[orderKey] !== activeFilter) {
        results = [];
      }
    }

    return { id: orderKey, results };
  });
}

function getResultsLength(results) {
  return results.reduce((acc, section) => acc + section.results.length, 0);
}

export function selectSearchOverlayIsVisible(state) {
  return state.client.ui.searchOverlayVisible;
}

export function selectRecentSearches(state) {
  return state.client.recentSearches.results;
}

export function selectPopularSearches(state) {
  return state.popularSearches.results;
}

const selectRecentSearchesSection = createSelector([selectRecentSearches], searches => ({
  id: 'recent',
  results: searches,
}));

const selectPopularSearchesSection = createSelector([selectPopularSearches], searches => ({
  id: 'popular',
  results: searches,
}));

/* Quick Search */
export function selectQuickSearchQuery(state) {
  return state.quickSearch.query;
}

export const selectQuickSearchFilters = state => state.quickSearch.filters;
export const selectQuickSearchActiveFilter = state => state.quickSearch.activeFilter;

const selectQuickSearchOrder = state => state.quickSearch.order;
const selectQuickSearchRawResults = state => state.quickSearch.results;

export const selectQuickSearchResults = createSelector(
  [selectQuickSearchOrder, selectQuickSearchActiveFilter, selectQuickSearchRawResults],
  orderAndFilterResults
);

export function selectQuickSearchIsLoaded(state) {
  return state.quickSearch.loaded;
}

export const selectQuickSearchResultsLength = createSelector(
  [selectQuickSearchResults],
  getResultsLength
);

export const selectQuickSearchIdleResults = createSelector(
  [selectRecentSearchesSection, selectPopularSearchesSection],
  (recentSearchesSection, popularSearchesSection) => [recentSearchesSection, popularSearchesSection]
);

/* Full Search */
export function selectSearchQuery(state) {
  return state.search.query;
}

const selectSearchOrder = state => state.search.order;
const selectSearchRawResults = state => state.search.results;

export const selectSearchFilters = state => state.search.filters;
export const selectSearchActiveFilter = state => state.search.activeFilter;

export const selectSearchResults = createSelector(
  [selectSearchOrder, selectSearchActiveFilter, selectSearchRawResults],
  orderAndFilterResults
);

export function selectSearchIsLoaded(state) {
  return state.search.loaded;
}
export const selectSearchResultsLength = createSelector([selectSearchResults], getResultsLength);
