import { defineMessages } from 'react-intl';

let StripeErrorMessages = defineMessages({
  default: {
    id: 'stripe-errors.default',
    defaultMessage: 'An error occurred while processing your card.',
  },
  cannotBeUsed: {
    id: 'stripe-errors.pickup_card',
    defaultMessage:
      'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
  },
  customerExceeded: {
    id: 'stripe-errors.customer_exceeded',
    defaultMessage:
      'The customer has exceeded the balance or credit limit available on their card.',
  },
  incorrectNumber: {
    id: 'stripe-errors.incorrect_number',
    defaultMessage: 'The card number is incorrect.',
  },
  unknownReason: {
    id: 'stripe-errors.declined-unknown-reason',
    defaultMessage: 'The card has been declined for an unknown reason.',
  },
  approve_with_id: {
    id: 'stripe-errors.approve_with_id',
    defaultMessage: 'The payment cannot be authorized.',
  },
  card_not_supported: {
    id: 'stripe-errors.card_not_supported',
    defaultMessage: 'The card does not support this type of purchase.',
  },
  currency_not_supported: {
    id: 'stripe-errors.currency_not_supported',
    defaultMessage: 'The card does not support the specified currency.',
  },
  duplicate_transaction: {
    id: 'stripe-errors.duplicate_transaction',
    defaultMessage:
      'A transaction with identical amount and credit card information was submitted very recently.',
  },
  expired_card: {
    id: 'stripe-errors.expired_card',
    defaultMessage: 'The card has expired.',
  },
  incorrect_cvc: {
    id: 'stripe-errors.incorrect_cvc',
    defaultMessage: 'The CVC number is incorrect.',
  },
  incorrect_pin: {
    id: 'stripe-errors.incorrect_pin',
    defaultMessage:
      'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
  },
  incorrect_zip: {
    id: 'stripe-errors.incorrect_zip',
    defaultMessage: 'The ZIP/postal code is incorrect.',
  },
  insufficient_funds: {
    id: 'stripe-errors.insufficient_funds',
    defaultMessage: 'The card has insufficient funds to complete the purchase.',
  },
  invalid_account: {
    id: 'stripe-errors.invalid_account',
    defaultMessage: 'The card, or account the card is connected to, is invalid.',
  },
  invalid_amount: {
    id: 'stripe-errors.invalid_amount',
    defaultMessage: 'The payment amount is invalid, or exceeds the amount that is allowed.',
  },
  invalid_cvc: {
    id: 'stripe-errors.invalid_cvc',
    defaultMessage: 'The CVC number is incorrect.',
  },
  invalid_expiry_month: {
    id: 'stripe-errors.invalid_expiry_month',
    defaultMessage: 'The expiration month is invalid.',
  },
  invalid_expiry_year: {
    id: 'stripe-errors.invalid_expiry_year',
    defaultMessage: 'The expiration year is invalid.',
  },
  invalid_pin: {
    id: 'stripe-errors.invalid_pin',
    defaultMessage:
      'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
  },
  issuer_not_available: {
    id: 'stripe-errors.issuer_not_available',
    defaultMessage: 'The card issuer could not be reached, so the payment could not be authorized.',
  },
  merchant_blacklist: {
    id: 'stripe-errors.merchant_blacklist',
    defaultMessage: 'The payment has been declined.',
  },
  new_account_information_available: {
    id: 'stripe-errors.new_account_information_available',
    defaultMessage: 'The card, or account the card is connected to, is invalid.',
  },
  not_permitted: {
    id: 'stripe-errors.not_permitted',
    defaultMessage: 'The payment is not permitted.',
  },
  pin_try_exceeded: {
    id: 'stripe-errors.pin_try_exceeded',
    defaultMessage: 'The allowable number of PIN tries has been exceeded.',
  },
  processing_error: {
    id: 'stripe-errors.processing_error',
    defaultMessage: 'An error occurred while processing the card.',
  },
  reenter_transaction: {
    id: 'stripe-errors.reenter_transaction',
    defaultMessage: 'The payment could not be processed by the issuer for an unknown reason.',
  },
  testmode_decline: {
    id: 'stripe-errors.testmode_decline',
    defaultMessage: 'A test card number was used.',
  },
});

const { unknownReason, customerExceeded, incorrectNumber, cannotBeUsed } = StripeErrorMessages;

StripeErrorMessages = {
  call_issuer: unknownReason,
  card_velocity_exceeded: customerExceeded,
  do_not_honor: unknownReason,
  do_not_try_again: unknownReason,
  fraudulent: unknownReason,
  generic_decline: unknownReason,
  incorrect_number: incorrectNumber,
  invalid_number: incorrectNumber,
  lost_card: unknownReason,
  no_action_taken: unknownReason,
  pickup_card: cannotBeUsed,
  restricted_card: cannotBeUsed,
  revocation_of_all_authorizations: unknownReason,
  revocation_of_authorization: unknownReason,
  security_violation: unknownReason,
  service_not_allowed: unknownReason,
  stolen_card: unknownReason,
  stop_payment_order: unknownReason,
  transaction_not_allowed: unknownReason,
  try_again_later: unknownReason,
  withdrawal_count_limit_exceeded: customerExceeded,
  ...StripeErrorMessages,
};

export default StripeErrorMessages;
