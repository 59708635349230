// @flow
import * as Sentry from '@sentry/react';

import { loadMe } from './me';
import { isSentryInstalled } from '../client/Sentry';

import { selectSubscriptionRenewalDate } from '../selectors/subscription';

import { selectLastStripeValidation, selectUsedStripeTokens } from '../selectors/stripe';

import { selectUserRequestedAt, selectUserId, selectStripeStatus } from '../selectors/user';

import { addNotification, dismissNotification } from '../actions/notifications';

import {
  STRIPE_REVALIDATION_REQUIRED_INITIAL,
  STRIPE_REVALIDATION_REQUIRED_URGENT,
  STRIPE_REVALIDATION_SUCCESS,
  STRIPE_REVALIDATION_ERROR,
} from '../lib/notifications';

import type { ThunkAction, Request, Dispatch } from './types';

export type LoadStripePaymentIntentTokenAction = {
  type: 'FETCH_STRIPE_PAYMENT_INTENT_TOKEN',
} & Request;
export type UserRevalidatedStripeAction = { type: 'USER_REVALIDATED_STRIPE', timestamp: number };
type SaveUsedStripeTokenAction = { type: 'SAVE_USED_STRIPE_TOKEN', token: string };

export type StripeAction =
  | SaveUsedStripeTokenAction
  | LoadStripePaymentIntentTokenAction
  | UserRevalidatedStripeAction;

export function loadStripePaymentIntentToken(
  captchaType: string,
  captchaToken: string
): ThunkAction {
  return async dispatch => {
    const response = await dispatch({
      type: 'FETCH_STRIPE_PAYMENT_INTENT_TOKEN',
      IDAGIO_REQUEST: {
        type: 'API_REQUEST',
        method: 'GET',
        endpoint: '/subscription/stripe/prepare.v3',
        params: {
          captchaToken,
          captchaType,
        },
      },
      meta: {
        normalize: res => {
          return {
            token: res.client_secret,
          };
        },
      },
    });
    return response.normalized.token;
  };
}

function userRevalidatedStripe(): UserRevalidatedStripeAction {
  return {
    type: 'USER_REVALIDATED_STRIPE',
    timestamp: Date.now(),
  };
}

function saveUsedStripeToken(token: string): SaveUsedStripeTokenAction {
  return {
    type: 'SAVE_USED_STRIPE_TOKEN',
    token,
  };
}

type StripeResult = {
  error?: Object,
  setupIntent?: Object,
};
type StripeCardAction = (token: string) => Promise<StripeResult>;

export function handleStripeCardAction(
  stripeCardAction: StripeCardAction,
  token: string
): ThunkAction {
  return async function handleCardThunk(dispatch) {
    const tokenWithoutTimestamp = token.split(' ')[0];
    const result = await stripeCardAction(tokenWithoutTimestamp);
    dispatch(saveUsedStripeToken(token));
    await dispatch(loadMe());
    return result;
  };
}

/*
  The user stripe status may be of two types, payment intent or setup intent. The type determines
   which stripe method should be called. Currently, the only way to find out this type is to check
  the prefix of the status client secret.
*/
function getStripeRequestForSecretType(secretType, userId) {
  if (secretType === 'pi') {
    // payment intent secret
    return window.stripe.handleCardPayment;
  } else if (secretType === 'seti') {
    // setup intent secret
    return window.stripe.handleCardSetup;
  }
  const error = new Error(
    `Unsupported value in user.stripe_status.client_secret for user:  ${userId}`
  );
  if (isSentryInstalled) {
    Sentry.captureException(error);
  }
  throw error;
}

function createStripeRevalidationTrigger(
  dispatch: Dispatch,
  stripeStatusSecret: string,
  userId: string
) {
  return async function onRevalidateNotificationClick() {
    dispatch(dismissNotification('stripe'));
    const secretType = stripeStatusSecret.split('_')[0];
    const stripeRequest = getStripeRequestForSecretType(secretType, userId);
    const result = await dispatch(handleStripeCardAction(stripeRequest, stripeStatusSecret));
    if (result.error) {
      dispatch(addNotification(STRIPE_REVALIDATION_ERROR));
    } else {
      dispatch(addNotification(STRIPE_REVALIDATION_SUCCESS));
      dispatch(userRevalidatedStripe());
    }
  };
}

export function checkStripeValidationValidity(): ThunkAction {
  return async function stripeValidationCheckThunk(dispatch, getState) {
    const now = Date.now();
    const state = getState();
    const lastStripeValidation = selectLastStripeValidation(state);
    const lastUserFetch = parseInt(selectUserRequestedAt(state), 10);

    // Validation in our backend might not update at once after 3D secure, so hold of for 15min
    // lastStripeValidation === null either means that the user is unsubscribed or that it's a revalidation
    if (lastStripeValidation && now - lastStripeValidation < 9e5) {
      return;
    }

    // Recheck once every hour
    if (now - lastUserFetch > 36e5) {
      await dispatch(loadMe());
      const newState = getState();
      const userId = selectUserId(newState);
      const usedStripeTokens = selectUsedStripeTokens(newState);
      const stripeStatus = selectStripeStatus(newState);
      const stripeStatusSecret = stripeStatus && stripeStatus.client_secret;
      const clientSecretIsStale = usedStripeTokens.indexOf(stripeStatusSecret) > -1;

      if (clientSecretIsStale) {
        return;
      }

      const renewalDate = selectSubscriptionRenewalDate(newState);
      const daysToRenewal = Math.floor((renewalDate - Date.now()) / 86400000);
      const stripeRevalidationTrigger = createStripeRevalidationTrigger(
        dispatch,
        stripeStatusSecret,
        userId
      );
      if (daysToRenewal < 7) {
        dispatch(
          addNotification({
            ...STRIPE_REVALIDATION_REQUIRED_URGENT,
            onClick: stripeRevalidationTrigger,
            values: { days: daysToRenewal },
          })
        );
      } else {
        dispatch(
          addNotification({
            ...STRIPE_REVALIDATION_REQUIRED_INITIAL,
            onClick: stripeRevalidationTrigger,
            values: { renewalDate },
          })
        );
      }
    }
  };
}
