import { compact, findLast, flatten } from 'lodash';

import { hideModal, setChrome } from './actions/ui';
import { trackTransition } from './actions/analytics';
import { selectModalOpen } from './selectors/modals';
import { DEFAULT_CHROME } from './reducers/client/ui';

function asArray(data) {
  return Array.isArray(data) ? data : compact([data]);
}

/**
 * This method enables certain promises to be resolved in
 * series rather than all in parallel. Hence requests that
 * are dependant on other requests return data can be
 * postponed.
 *
 * tasks = [f1, f2, f3, f4, ..., fn]
 * where fn is a function that returns an array
 */
function runSerial(tasks) {
  return tasks.reduce(
    (acc, task) => acc.then(() => Promise.all(asArray(task()))),
    Promise.resolve()
  );
}

function getFetchers(component, acc) {
  const functions = [...acc];

  if (component.fetchData) {
    if (!component.WrappedComponent || !component.WrappedComponent.fetchData) {
      functions.push(component.fetchData);
    }
  }

  if (component.onDataFetch) {
    if (!component.WrappedComponent || !component.WrappedComponent.onDataFetch) {
      functions.push(component.onDataFetch);
    }
  }

  if (component.WrappedComponent) {
    return getFetchers(component.WrappedComponent, functions);
  }
  return functions;
}

function getDeepestChrome(component) {
  if (!component.chrome && component.WrappedComponent) {
    return getDeepestChrome(component.WrappedComponent);
  }

  return component.chrome;
}

function getDeepestAccessFailureHandler(component) {
  if (!component.onAccessFailure && component.WrappedComponent) {
    return getDeepestAccessFailureHandler(component.WrappedComponent);
  }

  return component.onAccessFailure;
}

export function getInnerMostComponent(component) {
  if (component.WrappedComponent) {
    return getInnerMostComponent(component.WrappedComponent);
  }
  return component;
}

function getTrackedViewHOC(component) {
  if (component.isTrackedViewComponent) {
    return component;
  }

  if (component.WrappedComponent) {
    return getTrackedViewHOC(component.WrappedComponent);
  }

  return null;
}

let isInitialPageLoad = true;

export default function routeTransitionHook(store, components, location, params) {
  const fetchers = compact(flatten(components.map(component => getFetchers(component, []))));
  const promiseGenerators = fetchers.map(fetcher => () => fetcher(store, params, location));

  // ProtectedComponent unauthorized access handling
  promiseGenerators.unshift(() => {
    const unauthorizedHandler = findLast(components.map(getDeepestAccessFailureHandler));
    if (!unauthorizedHandler) {
      return null;
    }
    return unauthorizedHandler(store, params, location);
  });

  // Hide all modals if not initial page load
  if (!isInitialPageLoad) {
    promiseGenerators.push(() => {
      if (selectModalOpen(store.getState())) {
        return store.dispatch(hideModal({ ignore: ['WELCOME_MODAL'] }));
      }

      return Promise.resolve();
    });
  }

  // Setting chrome
  promiseGenerators.push(() => {
    const chrome = findLast(components.map(getDeepestChrome));
    if (!chrome) {
      return store.dispatch(setChrome(DEFAULT_CHROME));
    }

    /*
      isInitialPageLoad should be set to false here but only on the client.
      This code is also run on the server but there it causes a bug where it
      incorrectly hides modals before the first render. Server is by default
      always the initial page load.
    */
    if (__CLIENT__) {
      isInitialPageLoad = false;
    }

    return store.dispatch(setChrome(chrome.type));
  });

  // Detailed location change event for tracking
  promiseGenerators.push(() => {
    const componentsTrackingInfo = components.map(component => {
      let target;
      let trackingProps = {};

      // Strategy 1: Explicit higher order component declaration
      const hocTarget = getTrackedViewHOC(component);
      if (hocTarget) {
        target = hocTarget.explicitTarget;

        if (target.trackingProps) {
          trackingProps = target.trackingProps;
          if (trackingProps instanceof Function) {
            trackingProps = trackingProps(store.getState(), params, location);
          }
        }
      } else {
        // Strategy 2, fallback: find the deepest component's and use its name
        target = getInnerMostComponent(component);
      }

      return {
        name: target.name,
        displayName: target.displayName,
        trackingProps,
      };
    });

    return store.dispatch(trackTransition(componentsTrackingInfo, location));
  });

  return runSerial(promiseGenerators);
}
