// @flow

import type { Dispatch, ThunkAction } from './types';
import type { ModalType } from '../components/modals/ModalContainer';
import type { PlayerPlayAction } from './player';
import type { Album, Playlist, CourseResource } from '../shapes/types.js';

import { updatePreferences } from './me';

import {
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_PLAYLIST,
  // Payment Types
  CARD_PAYMENT,
  SEPA_PAYMENT,
  VOUCHER_PAYMENT,
  PLAN_ID_PPC_12M,
  PLAN_ID_PPC_1M,
  PLAN_ID_PREMIUM,
  PLAN_ID_PREMIUM_PLUS,
  PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER,
} from '../constants.js';

type ShowShareModalTrackingContext = {|
  sharedContentType:
    | 'Artist'
    | 'Ensemble'
    | 'Recording'
    | 'Playlist'
    | 'Album'
    | 'Track'
    | 'PersonalPlaylist'
    | 'Livestream Event'
    | 'Genre'
    | 'Epoch'
    | 'Instrument'
    | 'Work',
  sharedContentId: string | number,
  contextType:
    | 'Profile'
    | 'Mood'
    | 'Recording'
    | 'Playlist'
    | 'Album'
    | 'Livestream'
    | 'Category'
    | 'DiscoverCategory'
    | 'Work'
    | 'favouriteRecordings'
    | 'favouritePlaylists'
    | 'favouriteAlbums'
    | 'favouriteTracks'
    | 'favouriteLivestreamEvents',
  contentId: string | number,
|};

type PersonalPlaylistModalOrigin = PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER;

type Trigger =
  | 'audioQuality'
  | 'deviceSelector'
  | 'settings'
  | 'collection'
  | 'collectionPreviewsModal'
  | 'loginModal'
  | 'signupModal'
  | 'subscribeModal'
  | 'universalLinkFromAd'
  | 'notAuthenticated'
  | 'actionNotAllowed'
  | 'invalidVoucher'
  | 'pendingVoucher'
  | 'queryParam'
  | 'recentlyPlayedAccessFailure'
  | 'appheader'
  | 'ticketPurchaseDisabled'
  | 'player'
  | 'booklet'
  | 'bookletPreviewsModal'
  | 'previewsModal'
  | 'livestreamEvent'
  | 'addToPlaylist'
  | 'addToPlaylistPreviewsModal';

export type ShowSubscribeModalTrackingContext = {|
  trigger: Trigger,
  plan?: string,
  payment_gateway?: string,
|};

type ShowModalTrackingContext = {|
  trigger: Trigger,
|};

type ModalTrackingContext =
  | ShowShareModalTrackingContext
  | ShowSubscribeModalTrackingContext
  | ShowModalTrackingContext;

export type Theme = 'light' | 'dark' | 'automatic';

type SetChromeAction = { type: 'SET_CHROME', chrome: string };
type PlayerbarShowAction = { type: 'PLAYERBAR_SHOW' };
type PlayerbarHideAction = { type: 'PLAYERBAR_HIDE' };
type PlayerbarInfoShowAction = { type: 'PLAYERBAR_INFO_SHOW' };
type PlayerbarInfoHideAction = { type: 'PLAYERBAR_INFO_HIDE' };
type SearchOverlayShowAction = { type: 'SEARCH_OVERLAY_SHOW' };
type SearchOverlayHideAction = { type: 'SEARCH_OVERLAY_HIDE' };
type ShowVolumeAction = { type: 'SHOW_VOLUME' };
type HideVolumeAction = { type: 'HIDE_VOLUME' };
type ShowShortcutsHelpAction = { type: 'SHOW_SHORTCUTS_HELP' };
type HideShortcutsHelpAction = { type: 'HIDE_SHORTCUTS_HELP' };
type ShowFacetGroupAllFacetsModalAction = {
  type: 'SHOW_FACET_GROUP_ALL_FACETS_MODAL',
  facetType: string,
  params: Object,
}; // TODO properly type params
type HideFacetGroupAllFacetsModalAction = { type: 'HIDE_FACET_GROUP_ALL_FACETS_MODAL' };
type SetAddToPlaylistModalStateAction = {
  type: 'SET_ADD_TO_PLAYLIST_MODAL_STATE',
  trackIds: Array<string>,
  trackingContext: Object,
  origin?: PersonalPlaylistModalOrigin | null,
};
type SetEditPlaylistModalStateAction = { type: 'SET_EDIT_PLAYLIST_MODAL_STATE', playlist: Object };
type SetDeletePlaylistModalStateAction = {
  type: 'SET_DELETE_PLAYLIST_MODAL_STATE',
  playlist: Object,
};
type ClearPersonalPlaylistModalStatesAction = { type: 'CLEAR_PERSONAL_PLAYLIST_MODAL_STATES' };

type ModalActionData = {
  sharedUrl?: string,
  sharedUrlParams?: Object,
  embedCode?: string,
  entityType?: string,
  collectionItem: Album | Playlist,
  collectionItemType: ENTITY_TYPE_ALBUM | ENTITY_TYPE_PLAYLIST,
  defaultPaymentType: VOUCHER_PAYMENT | CARD_PAYMENT | SEPA_PAYMENT,
  courseResource?: CourseResource,
  bookletUrl?: string,
  plan?: PLAN_ID_PPC_12M | PLAN_ID_PPC_1M | PLAN_ID_PREMIUM | PLAN_ID_PREMIUM_PLUS,
};

type ShowModalAction = {
  type: 'SHOW_MODAL',
  modalType: ModalType,
  trackingContext?: ModalTrackingContext,
  data?: ModalActionData,
};
type SetThemeAction = { type: 'SET_THEME', theme: Theme };
type SetAutoplayAction = { type: 'SET_AUTOPLAY', autoplay: boolean };

type HideModalOptions = { ignore: Array<ModalType> };
type HideModalAction = {
  type: 'HIDE_MODAL',
  options: ?HideModalOptions,
};

type OpenPlayerAudioQualityDialogAction = {
  type: 'OPEN_PLAYER_AUDIO_QUALITY_DIALOG',
};
type ClosePlayerAudioQualityDialogAction = {
  type: 'CLOSE_PLAYER_AUDIO_QUALITY_DIALOG',
};

type OpenPlayerExternalDeviceDialogAction = {
  type: 'OPEN_PLAYER_EXTERNAL_DEVICE_DIALOG',
};
type ClosePlayerExternalDeviceDialogAction = {
  type: 'CLOSE_PLAYER_EXTERNAL_DEVICE_DIALOG',
};

export type PageTrackingContext = {
  contextId: ?string,
  contextType: 'Discover' | 'Profile' | 'Album' | 'Playlist' | 'Recording' | null,
  contextSlug: ?string,
};

type SetPageTrackingContextAction = {
  type: 'SET_PAGE_TRACKING_CONTEXT',
  context: PageTrackingContext,
};

export type UIAction =
  | SetChromeAction
  | PlayerbarShowAction
  | PlayerbarHideAction
  | PlayerbarInfoShowAction
  | PlayerbarInfoHideAction
  | SearchOverlayShowAction
  | SearchOverlayHideAction
  | ShowVolumeAction
  | HideVolumeAction
  | ShowShortcutsHelpAction
  | HideShortcutsHelpAction
  | ShowFacetGroupAllFacetsModalAction
  | HideFacetGroupAllFacetsModalAction
  | SetAddToPlaylistModalStateAction
  | SetEditPlaylistModalStateAction
  | SetDeletePlaylistModalStateAction
  | ClearPersonalPlaylistModalStatesAction
  | PlayerPlayAction
  | SetAutoplayAction
  | SetThemeAction
  | ShowModalAction
  | HideModalAction
  | OpenPlayerAudioQualityDialogAction
  | ClosePlayerAudioQualityDialogAction
  | OpenPlayerExternalDeviceDialogAction
  | ClosePlayerExternalDeviceDialogAction
  | SetPageTrackingContextAction;

export function setChrome(chrome: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    if (state.client.ui.chrome !== chrome) {
      return dispatch({
        type: 'SET_CHROME',
        chrome,
      });
    }

    return null;
  };
}

export function setUITheme(theme: Theme): SetThemeAction {
  return {
    type: 'SET_THEME',
    theme,
  };
}

export function setTheme(theme: Theme): ThunkAction {
  return dispatch => {
    dispatch(
      updatePreferences({
        web_theme: theme,
      })
    );
    dispatch(setUITheme(theme));
  };
}

export function setAutoplay(autoplay: boolean): SetAutoplayAction {
  return {
    type: 'SET_AUTOPLAY',
    autoplay,
  };
}

export function updateAutoplayPreference(autoplay: boolean): ThunkAction {
  return dispatch => {
    dispatch(
      updatePreferences({
        web_autoplay: autoplay,
      })
    );
    dispatch(setAutoplay(autoplay));
  };
}

export function hidePlayerbar(): PlayerbarHideAction {
  return {
    type: 'PLAYERBAR_HIDE',
  };
}

export function showPlayerbar(): PlayerbarShowAction {
  return {
    type: 'PLAYERBAR_SHOW',
  };
}

export function hidePlayerInfo(): PlayerbarInfoHideAction {
  return {
    type: 'PLAYERBAR_INFO_HIDE',
  };
}

export function showPlayerInfo(): PlayerbarInfoShowAction {
  return {
    type: 'PLAYERBAR_INFO_SHOW',
  };
}

export function showSearchOverlay(): SearchOverlayShowAction {
  return {
    type: 'SEARCH_OVERLAY_SHOW',
  };
}

export function hideSearchOverlay(): SearchOverlayHideAction {
  return {
    type: 'SEARCH_OVERLAY_HIDE',
  };
}

export function showVolume(): ShowVolumeAction {
  return {
    type: 'SHOW_VOLUME',
  };
}

export function hideVolume(): HideVolumeAction {
  return {
    type: 'HIDE_VOLUME',
  };
}

export function showFacetGroupAllFacetsModal(
  facetType: string,
  params: Object
): ShowFacetGroupAllFacetsModalAction {
  // TODO properly type params
  return {
    type: 'SHOW_FACET_GROUP_ALL_FACETS_MODAL',
    facetType,
    params,
  };
}

export function hideFacetGroupAllFacetsModal(): HideFacetGroupAllFacetsModalAction {
  return {
    type: 'HIDE_FACET_GROUP_ALL_FACETS_MODAL',
  };
}

export function showModal(
  modalType: ModalType,
  trackingContext?: ModalTrackingContext,
  data: ?ModalActionData
): ShowModalAction {
  const action: ShowModalAction = {
    type: 'SHOW_MODAL',
    modalType,
  };

  if (data) {
    action.data = data;
  }

  if (trackingContext) {
    action.trackingContext = trackingContext;
  }

  return action;
}

function setAddToPlaylistModalState(
  trackIds: Array<string>,
  trackingContext: Object,
  origin?: PersonalPlaylistModalOrigin | null
): SetAddToPlaylistModalStateAction {
  return {
    type: 'SET_ADD_TO_PLAYLIST_MODAL_STATE',
    trackIds,
    trackingContext,
    origin,
  };
}

function setEditPlaylistModalState(playlist: Object): SetEditPlaylistModalStateAction {
  return {
    type: 'SET_EDIT_PLAYLIST_MODAL_STATE',
    playlist,
  };
}

export function showEditPlaylistModal(playlist: Object): ThunkAction {
  return (dispatch: Dispatch) => {
    dispatch(setEditPlaylistModalState(playlist));
    dispatch(showModal('EDIT_PLAYLIST_MODAL'));
  };
}

function setDeletePlaylistModalState(playlist: Object): SetDeletePlaylistModalStateAction {
  return {
    type: 'SET_DELETE_PLAYLIST_MODAL_STATE',
    playlist,
  };
}

export function showDeletePlaylistModal(playlist: Object): ThunkAction {
  return (dispatch: Dispatch) => {
    dispatch(setDeletePlaylistModalState(playlist));
    dispatch(showModal('DELETE_PLAYLIST_MODAL'));
  };
}

function clearPersonalPlaylistModalStates(): ClearPersonalPlaylistModalStatesAction {
  return { type: 'CLEAR_PERSONAL_PLAYLIST_MODAL_STATES' };
}

export function showAddToPlaylistModal(
  trackIds: Array<string>,
  trackingContext: Object,
  origin?: PersonalPlaylistModalOrigin | null
): ThunkAction {
  return (dispatch: Dispatch) => {
    dispatch(setAddToPlaylistModalState(trackIds, trackingContext, origin));
    dispatch(showModal('ADD_TO_PLAYLIST_MODAL'));
  };
}

export function hideModal(options: ?HideModalOptions): HideModalAction {
  return {
    type: 'HIDE_MODAL',
    options,
  };
}

export function hidePersonalPlaylistModal() {
  return (dispatch: Dispatch) => {
    dispatch(hideModal());
    dispatch(clearPersonalPlaylistModalStates());
  };
}

export function openPlayerAudioQualityDialog(): OpenPlayerAudioQualityDialogAction {
  return {
    type: 'OPEN_PLAYER_AUDIO_QUALITY_DIALOG',
  };
}

export function closePlayerAudioQualityDialog(): ClosePlayerAudioQualityDialogAction {
  return {
    type: 'CLOSE_PLAYER_AUDIO_QUALITY_DIALOG',
  };
}

export function openPlayerExternalDeviceDialog(): OpenPlayerExternalDeviceDialogAction {
  return {
    type: 'OPEN_PLAYER_EXTERNAL_DEVICE_DIALOG',
  };
}

export function closePlayerExternalDeviceDialog(): ClosePlayerExternalDeviceDialogAction {
  return {
    type: 'CLOSE_PLAYER_EXTERNAL_DEVICE_DIALOG',
  };
}

export function setPageTrackingContext(context: PageTrackingContext): SetPageTrackingContextAction {
  return {
    type: 'SET_PAGE_TRACKING_CONTEXT',
    context,
  };
}
