// @flow

import { loaded } from '../middleware/apiCacheValidators';
import { Mix } from '../schema';
import { normalizeMetaItem } from '../schema/normalizeMeta';

import type { Request } from './types';

export type LoadMixAction = { type: 'FETCH_MIX' } & Request;

export type MixAction = LoadMixAction;

export function loadMix(slug: string): LoadMixAction {
  return {
    type: 'FETCH_MIX',
    cacheId: slug,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/me/mix/{slug}',
      params: {
        slug,
      },
    },
    meta: {
      schema: Mix,
      normalize: normalizeMetaItem,
      restricted: true,
    },
    cache: {
      fetch: state => state.maps.mixes[slug],
      validate: loaded,
    },
  };
}
