import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { showSearchOverlay as showSearchOverlayActionCreator } from '../../actions/ui';
import IconLabel from './../util/IconLabel';
import CapacitorBackButton from './BackButton';
import CollectionButton from './CollectionButton';
import CapacitorRipple from './Ripple';
import QuickSearch from '../search/QuickSearch';
import { selectBaseUrl } from '../../selectors/client';
import { selectSearchOverlayIsVisible } from '../../selectors/search';
import styles from './HeaderBar.css';
import ShareButton from '../common/ShareButton';

const CapacitorHeaderBar = props => {
  const { onlyShowTitleOnScroll } = props;
  const scrollThreshold = React.useRef(null);
  const [isTitleShown, setIsTitleShown] = React.useState(!onlyShowTitleOnScroll);

  React.useEffect(() => {
    if (onlyShowTitleOnScroll) {
      const observer = new IntersectionObserver(intersects => {
        if (intersects[0].boundingClientRect.y < 0) {
          setIsTitleShown(true);
        } else {
          setIsTitleShown(false);
        }
      }, {});
      const scrollThresholdRef = scrollThreshold.current;
      observer.observe(scrollThresholdRef);
      return () => observer.unobserve(scrollThresholdRef);
    }
    return () => {};
  }, [onlyShowTitleOnScroll]);

  return (
    <React.Fragment>
      <div className={styles.spacer} />
      <div ref={scrollThreshold} className={styles.scrollThreshold} />
      <section
        className={classnames(styles.component, {
          [styles.componentFilled]: !props.contentOverflow || isTitleShown,
        })}
      >
        <div className={styles.header}>
          <div className={styles.leftHeaderGroup}>
            {!props.hideBackButton && <CapacitorBackButton />}
            {props.title && (
              <h1
                className={classnames('fz--beta c-text--is-truncated', styles.title, {
                  [styles.invisible]: !isTitleShown,
                })}
              >
                {props.title}
              </h1>
            )}
          </div>
          <div className={styles.rightHeaderGroup}>
            {props.showSearch && (
              <button onClick={props.showSearchOverlay} className={styles.searchButton}>
                <CapacitorRipple version="center" />
                <IconLabel name="search" title="Search" size="medium" />
              </button>
            )}
            {props.toggleIsInCollection && (
              <CollectionButton
                active={props.isInCollection}
                onClick={props.toggleIsInCollection}
              />
            )}
            {props.showShareModal && <ShareButton onClick={props.showShareModal} />}
          </div>
        </div>
        {props.subNavigation}
      </section>
      {props.searchIsVisible && <QuickSearch />}
    </React.Fragment>
  );
};

CapacitorHeaderBar.propTypes = {
  subNavigation: PropTypes.element,
  hideBackButton: PropTypes.bool,
  showShareModal: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  toggleIsInCollection: PropTypes.func,
  isInCollection: PropTypes.bool,
  onlyShowTitleOnScroll: PropTypes.bool,

  showSearch: PropTypes.bool,
  showSearchOverlay: PropTypes.func.isRequired,
  searchIsVisible: PropTypes.bool.isRequired,
  contentOverflow: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    baseUrl: selectBaseUrl(state),
    searchIsVisible: selectSearchOverlayIsVisible(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    showSearchOverlay: showSearchOverlayActionCreator,
  })
)(CapacitorHeaderBar);
