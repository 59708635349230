import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import SearchSection from './SearchSection';
import styles from './SearchSections.css';

export default class SearchSections extends PureComponent {
  static propTypes = {
    sections: PropTypes.array.isRequired,
    onAccessed: PropTypes.func,
    hideHeading: PropTypes.bool,
  };

  onAccessed = (el, section) => {
    if (this.props.onAccessed) {
      this.props.onAccessed(el, section);
    }
  };

  render() {
    const { sections, hideHeading } = this.props;
    return (
      <div className={styles.sections}>
        {sections.map(section => (
          <SearchSection
            key={section.id}
            id={section.id}
            section={section.results}
            onAccessed={this.onAccessed}
            hideHeading={hideHeading}
          />
        ))}
      </div>
    );
  }
}
