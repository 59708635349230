// @flow
import React from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import classnames from 'classnames';

import styles from './IconLabel.css';

type Name = {
  name: string,
  className: string,
};

type OwnProps = {
  'name': string | Array<Name>,
  'title'?: string,
  'onClick'?: Function,
  'labelVisible'?: boolean,
  'size'?: string,
  'data-test'?: string,
  'className'?: string,
};

const IconLabel = ({
  name,
  title,
  onClick,
  labelVisible,
  size,
  'data-test': dataTest,
  className,
  ...props
}: OwnProps) => {
  const LinkHref = () => {
    if (Array.isArray(name)) {
      return (
        <React.Fragment>
          {name.map(icon => {
            return (
              <use xlinkHref={`#icon-${icon.name}`} className={icon.className} key={icon.name} />
            );
          })}
        </React.Fragment>
      );
    }

    return <use xlinkHref={`#icon-${name}`} />;
  };

  const svgSize =
    size && ['default', 'small', 'medium'].includes(size) ? `var(--icon-size-${size})` : size;
  const sizeStyles = size ? { height: svgSize, width: svgSize } : null;

  const WrappingTag = onClick ? 'button' : 'div';

  return (
    <WrappingTag
      {...props}
      className={classnames(styles.component, className)}
      data-test={dataTest}
      onClick={onClick}
    >
      {onClick && <CapacitorRipple version="center" />}
      <svg style={sizeStyles} className={styles.svg}>
        <LinkHref />
      </svg>
      <span className={classnames({ [styles.label]: labelVisible })}>{title}</span>
    </WrappingTag>
  );
};

export default IconLabel;
