import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './ToggleSwitch.css';
import classNames from 'classnames';

export default class ToggleSwitch extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    descriptionLeft: PropTypes.object,
    descriptionRight: PropTypes.object,
    className: PropTypes.string,
    lightsAlwaysOn: PropTypes.bool,
  };

  render() {
    const {
      disabled,
      checked,
      id,
      onChange,
      name,
      descriptionLeft,
      descriptionRight,
      className,
      lightsAlwaysOn,
    } = this.props;

    return (
      <div className={classNames(styles.toggleSwitch, className)}>
        <label htmlFor={id} className={styles.descriptionLeft}>
          {descriptionLeft}
        </label>
        <label
          className={styles.component}
          data-test="toggle-switch.btn"
          data-test-active={checked}
        >
          <input
            className={styles.input}
            disabled={disabled}
            type="checkbox"
            checked={checked}
            id={id}
            onChange={onChange}
            name={name}
          />
          <span
            className={classNames(styles.slider, {
              [styles.sliderLightOn]: lightsAlwaysOn,
            })}
          />
        </label>
        <label htmlFor={id} className={styles.descriptionRight}>
          {descriptionRight}
        </label>
      </div>
    );
  }
}
