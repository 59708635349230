import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';

import styles from '../modals/ShareModal.css';

class ShareIcons extends Component {
  static propTypes = {
    trackShareButtonClick: PropTypes.func,
    getUrlWithParams: PropTypes.func,
  };

  render() {
    const { getUrlWithParams } = this.props;
    const iconSize = 32;
    const facebookUrl = getUrlWithParams({ utm_source: 'fb' });
    const twitterUrl = getUrlWithParams({ utm_source: 'tw' });
    const linkedInUrl = getUrlWithParams({ utm_source: 'li' });
    return (
      <React.Fragment>
        <FacebookShareButton
          url={facebookUrl}
          beforeOnClick={this.trackFacebook}
          className={styles.socialShareButton}
        >
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={twitterUrl}
          beforeOnClick={this.trackTwitter}
          className={styles.socialShareButton}
        >
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>
        <LinkedinShareButton
          url={linkedInUrl}
          beforeOnClick={this.trackLinkedin}
          className={styles.socialShareButton}
        >
          <LinkedinIcon size={iconSize} round />
        </LinkedinShareButton>
      </React.Fragment>
    );
  }

  trackFacebook = () => {
    this.props.trackShareButtonClick('Facebook');
  };

  trackTwitter = () => {
    this.props.trackShareButtonClick('Twitter');
  };

  trackLinkedin = () => {
    this.props.trackShareButtonClick('Linkedin');
  };
}

export default ShareIcons;
