import React, { Component } from 'react';
import Qs from 'qs';
import RedirectInstruction from '../lib/routing/RedirectInstruction';
import { selectUserIsAuthenticated } from '../selectors/user';
import { connect } from 'react-redux';
import { replace as replaceActionCreator, go as goActionCreator } from 'react-router-redux';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function protectedComponent(options = {}, redirectToSignup = false, fromId = null) {
  const { withRef = false, authenticatedOnly, anonymousOnly } = options;

  if (!authenticatedOnly && !anonymousOnly) {
    throw new Error('You have to pass in access control options to ProtectedComponent!');
  }

  if (!!authenticatedOnly && !!anonymousOnly) {
    throw new Error(
      `Invalid options passed to ProtectedComponent! authenticatedOnly: ${authenticatedOnly}, anonymousOnly: ${anonymousOnly}`
    );
  }

  function checkIsAllowed(isAuthenticated, location) {
    if (isAuthenticated && anonymousOnly) {
      const param = Qs.parse(location.search.slice(1));
      if (param.reloadTo) {
        return Promise.reject(
          new RedirectInstruction(param.reloadTo + location.hash, undefined, true)
        );
      }

      return Promise.reject(new RedirectInstruction((param.to || '/discover') + location.hash));
    }

    if (!isAuthenticated && authenticatedOnly) {
      const to = `/${location.pathname}${location.search}`;
      const path = redirectToSignup ? '/join' : '/login';
      let redirectUrl = `${path}?to=${encodeURIComponent(to)}`;
      if (fromId) {
        redirectUrl += `&fromId=${fromId}`;
      }

      return Promise.reject(new RedirectInstruction(redirectUrl));
    }

    // Should return
    return null;
  }

  return WrappedComponent => {
    class ProtectedComponent extends Component {
      componentDidUpdate = async prevProps => {
        if (this.props.isAuth !== prevProps.isAuth) {
          try {
            await checkIsAllowed(this.props.isAuth, this.props.location);
          } catch (redirectInstruction) {
            const redirect = redirectInstruction.reload ? this.props.go : this.props.replace;
            redirect(redirectInstruction.to);
          }
        }
      };

      render() {
        return <WrappedComponent {...this.props} ref={withRef ? 'wrappedInstance' : null} />;
      }
    }

    ProtectedComponent.displayName = `ProtectedComponent(${getDisplayName(WrappedComponent)}`;

    ProtectedComponent.WrappedComponent = WrappedComponent;

    ProtectedComponent.onAccessFailure = function handleAccessFailure(store, params, location) {
      const isAuthenticated = selectUserIsAuthenticated(store.getState());
      return checkIsAllowed(isAuthenticated, location);
    };

    return connect(
      (state, ownProps) => {
        return {
          isAuth: selectUserIsAuthenticated(state),
          location: ownProps.location,
        };
      },
      {
        replace: replaceActionCreator,
        go: goActionCreator,
      }
    )(ProtectedComponent);
  };
}
