// @flow
// $FlowFixMe
import React, { useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BodyClassName from 'react-body-classname';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';

import IconLabel from './../util/IconLabel';
import {
  completeQuickSearch as completeQuickSearchActionCreator,
  loadQuickSearch as loadQuickSearchActionCreator,
  setQuickSearchQuery as setQuickSearchQueryActionCreator,
  setQuickSearchActiveFilter as setQuickSearchActiveFilterActionCreator,
  loadPopularSearches as loadPopularSearchesActionCreator,
} from '../../actions/search';
import {
  hideSearchOverlay as hideSearchOverlayActionCreator,
  showSearchOverlay as showSearchOverlayActionCreator,
} from '../../actions/ui';

import { MIN_SEARCH_QUERY_LENGTH, SEARCH_CHANGE_DEBOUNCE_INTERVAL } from '../../constants';

import * as KEYS from '../../lib/key-codes';

import {
  selectQuickSearchFilters,
  selectQuickSearchIdleResults,
  selectQuickSearchIsLoaded,
  selectQuickSearchQuery,
  selectQuickSearchResults,
  selectSearchOverlayIsVisible,
  selectQuickSearchActiveFilter,
} from '../../selectors/search';
import { activeElementIsTextField } from '../../utils/dom';
import SearchBox from '../common/SearchBox';
import SearchFilters from './SearchFilters';

import stylesQuickSearch from './QuickSearch.css';
import SearchResults from './SearchResults';
import stylesSearchResults from './SearchResults.css';
import { push as pushActionCreator } from 'react-router-redux';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

declare var __CAPACITOR__;

const messages = defineMessages({
  placeholder: {
    id: 'search.placeholder',
    defaultMessage: 'Search composers, performers, works, albums and playlists',
  },
  titleSearch: {
    id: 'title-search-box',
    defaultMessage: 'Quick search',
  },
});

type OwnProps = {
  className?: string,
};

type MapStateToProps = {
  idleResults: Array<any>,
  loaded: boolean,
  query: string,
  results: Array<any>,
  visible: boolean,
  filters: Array<string>,
  activeFilter: string | null,
};

type DispatchProps = {
  completeQuickSearch: Function,
  loadQuickSearch: Function,
  setQuickSearchQuery: Function,
  showSearchOverlay: Function,
  hideSearchOverlay: Function,
  loadPopularSearches: Function,
  push: Function,
  setQuickSearchActiveFilter: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const QuickSearch = ({
  className,
  idleResults,
  loaded,
  query,
  results,
  visible,
  filters,
  activeFilter,
  completeQuickSearch,
  loadQuickSearch,
  setQuickSearchQuery,
  showSearchOverlay,
  hideSearchOverlay,
  loadPopularSearches,
  setQuickSearchActiveFilter,
  push,
  intl,
}: Props) => {
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const hideSearchOverlayIfVisible = useCallback(() => {
    if (visible) {
      hideSearchOverlay();
    }
  }, [visible, hideSearchOverlay]);

  const onFocusIn = useCallback(
    e => {
      if (!containerRef.current.contains(e.target)) {
        hideSearchOverlayIfVisible();
      }
    },
    [hideSearchOverlayIfVisible]
  );

  const onGlobalKeyDown = useCallback(
    event => {
      const activeElement = document.activeElement;
      const activeElementIsInput = inputRef.current === activeElement;

      if (event.keyCode === KEYS.SLASH) {
        if (!activeElementIsInput) {
          if (activeElementIsTextField()) {
            return;
          }
          event.preventDefault();
          inputRef.current.focus();
        }
      }

      if (!visible) {
        return;
      }

      const allResults = [...document.getElementsByClassName(stylesSearchResults.result)];
      const activeElementIndex = allResults.indexOf(activeElement);

      switch (event.keyCode) {
        case KEYS.ESC:
          if (!query) {
            hideSearchOverlay();
            inputRef.current.blur();
          }
          return;
        case KEYS.DOWN: {
          event.preventDefault();
          if (allResults.length) {
            if (activeElementIsInput) {
              allResults[0].focus();
              return;
            }

            if (activeElementIndex + 1 < allResults.length) {
              allResults[activeElementIndex + 1].focus();
            } else {
              inputRef.current.focus();
            }
          }

          break;
        }
        case KEYS.UP:
          event.preventDefault();
          if (allResults.length) {
            if (activeElementIndex === 0) {
              inputRef.current.focus();
              return;
            }

            if (activeElementIsInput) {
              allResults[allResults.length - 1].focus();
            } else {
              allResults[activeElementIndex - 1].focus();
            }
          }
          break;
        default:
      }
    },
    [visible, hideSearchOverlay, query]
  );

  useEffect(() => {
    document.addEventListener('focusin', onFocusIn);
    document.addEventListener('keydown', onGlobalKeyDown);

    return () => {
      document.removeEventListener('focusin', onFocusIn);
      document.removeEventListener('keydown', onGlobalKeyDown);
    };
  }, [onFocusIn, onGlobalKeyDown, showSearchOverlay]);

  useEffect(() => {
    loadPopularSearches();
  }, [loadPopularSearches]);

  // don't search immediately after typing
  // eslint-disable-next-line
  const debouncedLoadSearch = useCallback(
    debounce(searchQuery => {
      loadQuickSearch(searchQuery);
    }, SEARCH_CHANGE_DEBOUNCE_INTERVAL),
    []
  );

  const onChange = searchQuery => {
    setQuickSearchQuery(searchQuery);
  };

  useEffect(() => {
    if (query.length >= MIN_SEARCH_QUERY_LENGTH) {
      debouncedLoadSearch(query);
    }
  }, [query, debouncedLoadSearch]);

  const onSubmit = searchQuery => {
    const target = `/search?query=${searchQuery}`;
    push(target);
  };

  const handleClickOutside = () => {
    if (document.activeElement !== inputRef.current) {
      hideSearchOverlayIfVisible();
    }
  };

  const isValidQuery = !!query && query.length >= MIN_SEARCH_QUERY_LENGTH;

  const bodyClassName = visible ? stylesQuickSearch.componentVisible : '';
  return (
    <BodyClassName className={bodyClassName}>
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <div
          className={classnames({
            [stylesQuickSearch.isFullscreen]: visible, // eslint-disable-line
          })}
        >
          <div className={classnames(stylesQuickSearch.container, className)} ref={containerRef}>
            {/* eslint-disable-next-line */}
            <div className={stylesQuickSearch.boxContainer}>
              <SearchBox
                query={query}
                onChange={onChange}
                onFocus={showSearchOverlay}
                onSubmit={onSubmit}
                ref={inputRef}
                titleSearch={intl.formatMessage(messages.titleSearch)}
                placeholder={intl.formatMessage(messages.placeholder)}
                id="idagio-search-box-input"
                isFullscreen={visible}
                hasShadow={__CAPACITOR__}
              />
              {__CAPACITOR__ && visible && (
                <button
                  onClick={hideSearchOverlayIfVisible}
                  className={stylesQuickSearch.closeButton}
                >
                  <IconLabel name="close" size="default" />
                </button>
              )}
            </div>
            <div className={stylesQuickSearch.dropdown}>
              <SearchFilters
                filters={filters}
                onFilterSelect={setQuickSearchActiveFilter}
                activeFilter={activeFilter}
                isValidQuery={isValidQuery}
                loaded={loaded}
              />
              <SearchResults
                isValidQuery={isValidQuery}
                loaded={loaded}
                results={results}
                idleResults={idleResults}
                onAccessed={completeQuickSearch}
                hideHeading={!!activeFilter}
              />
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </BodyClassName>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    idleResults: selectQuickSearchIdleResults(state),
    loaded: selectQuickSearchIsLoaded(state),
    query: selectQuickSearchQuery(state),
    results: selectQuickSearchResults(state),
    visible: selectSearchOverlayIsVisible(state),
    filters: selectQuickSearchFilters(state),
    activeFilter: selectQuickSearchActiveFilter(state),
  };
}

const dispatchProps: DispatchProps = {
  completeQuickSearch: completeQuickSearchActionCreator,
  loadQuickSearch: loadQuickSearchActionCreator,
  setQuickSearchQuery: setQuickSearchQueryActionCreator,
  showSearchOverlay: showSearchOverlayActionCreator,
  hideSearchOverlay: hideSearchOverlayActionCreator,
  push: pushActionCreator,
  loadPopularSearches: loadPopularSearchesActionCreator,
  setQuickSearchActiveFilter: setQuickSearchActiveFilterActionCreator,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(QuickSearch);
