// @flow

import update from 'immutability-helper';
import type { PlayerProgressAction } from '../actions/player';
import type {
  EndedAdPlayback,
  ResetAdQueueAction,
  SetAdQueueAndPlayAction,
  StartedAdPlayback,
} from '../actions/ads';
import type { Ad } from '../shapes/types';

const initialState = {
  secondsSinceLastAd: 0,
  adsPlayedInSession: 0,
  currentlyPlayingAdId: null,
  currentlyPlayingAd: null,
  currentAdQueue: null,
};

export type State = {|
  +secondsSinceLastAd: number,
  +adsPlayedInSession: number,
  +currentlyPlayingAdId: ?string,
  +currentlyPlayingAd: ?Ad,
  +currentAdQueue: ?Array<Ad>,
|};

type Action =
  | PlayerProgressAction
  | EndedAdPlayback
  | StartedAdPlayback
  | ResetAdQueueAction
  | SetAdQueueAndPlayAction;

export default function ads(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'PLAYER_PROGRESS': {
      return update(state, {
        secondsSinceLastAd: { $set: state.secondsSinceLastAd + 1 },
      });
    }
    case 'STARTED_AD_PLAYBACK':
      return update(state, {
        currentlyPlayingAdId: { $set: action.adId },
        currentlyPlayingAd: { $set: action.ad },
      });
    case 'ENDED_AD_PLAYBACK':
      return update(state, {
        secondsSinceLastAd: { $set: 0 },
        adsPlayedInSession: { $set: state.adsPlayedInSession + 1 },
        currentlyPlayingAdId: { $set: null },
        currentlyPlayingAd: { $set: null },
      });
    case 'SET_AD_QUEUE_AND_PLAY':
      return update(state, {
        currentAdQueue: { $set: action.ads },
      });
    case 'RESET_AD_QUEUE':
      return update(state, {
        currentlyPlayingAdId: { $set: null },
        currentlyPlayingAd: { $set: null },
        currentAdQueue: { $set: null },
      });
    case 'LOGOUT':
      return initialState;
    default:
      (action: empty);
      return state;
  }
}
