// @flow
declare var __CAPACITOR__: boolean;
import { defineMessages } from 'react-intl';
import { getWebsiteUrlInLocale } from './locale-utils';
import type { Hotlink } from '../shapes/types';

export const messages = defineMessages({
  magazine: {
    id: 'hotlinks.magazine',
    defaultMessage: 'Magazine',
  },
  recentlyPlayed: {
    id: 'hotlinks.recently-played',
    defaultMessage: 'Recently Played',
  },
  spotlight: {
    id: 'hotlinks.spotlight-main',
    defaultMessage: 'Curated by Artists',
  },
  weeklyMixes: {
    id: 'hotlinks.weekly-mixes',
    defaultMessage: 'Weekly Mixes',
  },
  idagioExclusives: {
    id: 'hotlinks.idagio-exclusives',
    defaultMessage: 'IDAGIO Exclusives',
  },
  playlistsForYou: {
    id: 'hotlinks.playlists-for-you',
    defaultMessage: 'Playlists for You',
  },
});

export const magazine = (locale: string): Hotlink => {
  return {
    id: 'magazine',
    title: messages.magazine,
    url: `${getWebsiteUrlInLocale('/magazine', locale)}${
      __CAPACITOR__ ? '&ctc=inapp-webview' : ''
    }`,
    trackingUrl: 'magazine',
  };
};

export const recentlyPlayed: Hotlink = {
  id: 'recentlyPlayed',
  title: messages.recentlyPlayed,
  url: '/recently-played',
  trackingUrl: 'recently-played',
};

export const spotlight: Hotlink = {
  id: 'spotlight',
  title: messages.spotlight,
  url: '/discover/spotlight',
  trackingUrl: 'discover/spotlight',
};

export const weeklyMixes: Hotlink = {
  id: 'weeklyMixes',
  title: messages.weeklyMixes,
  url: '/discover#mixes-main',
  trackingUrl: 'discover#mixes-main',
};

export const idagioExclusives: Hotlink = {
  id: 'idagioExclusives',
  title: messages.idagioExclusives,
  url: '/discover/curated-album-group/idagio-exclusives',
  trackingUrl: 'discover/curated-album-group/idagio-exclusives',
};

export const playlistsForYou: Hotlink = {
  id: 'playlistsForYou',
  title: messages.playlistsForYou,
  url: '/discover/playlists',
  trackingUrl: 'discover/playlists',
};
