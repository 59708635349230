// @flow

import { getCursorName } from '../reducers/phasedActionHelpers';

type Resource = {
  loaded: boolean,
};

type CachedObject = {
  loadedCursors: Object,
  loaded: boolean,
  expireAt: number,
};

export function loaded(resource: Resource): boolean {
  return resource && resource.loaded;
}

export function loadedCursor(cursor: string = ''): (cached: CachedObject) => boolean {
  return function isLoadedCursorFunction(cached: CachedObject): boolean {
    return !!cached && cached.loadedCursors[getCursorName(cursor)];
  };
}

export function presence(resource: CachedObject): boolean {
  return !!resource;
}

export function notExpired(cached: CachedObject) {
  return cached && Boolean(cached.expireAt) && cached.expireAt > Date.now();
}
