import { QUEUE_TYPE_MIX } from '../constants';
import { createSelector } from 'reselect';
import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

import { selectPlayerIsPlaying } from './player';
import { selectIsQueueOriginSetAs } from './queue';

const selectMixes = state => {
  return state.entities.mixes;
};

export function selectMixIdFromSlug(state, slug) {
  return state.maps.mixes[slug] ? state.maps.mixes[slug].entities : null;
}

export function selectMixIsLoading(state, slug) {
  return state.maps.mixes[slug] ? state.maps.mixes[slug].loading : false;
}

export function selectMixIsLoaded(state, slug) {
  return state.maps.mixes[slug] ? state.maps.mixes[slug].loaded : false;
}

export function getMixQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_MIX,
    id: originId,
  };
}

export function selectMixIsQueued(state, slug) {
  const queueOrigin = getMixQueueOrigin(slug);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectMixIsPlaying(state, slug) {
  const isQueued = selectMixIsQueued(state, slug);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

export const selectMix = createSelector(
  [state => state.entities, selectMixIdFromSlug, selectMixes],
  (entities, id, mixes) => {
    if (!id || !mixes[id]) {
      return null;
    }

    return assembleEntity(Schema.Mix, entities, id);
  }
);

export const selectMixImageAnnotation = (state, slug) => {
  const annotationId = `mix-${slug}-main.jpg`;
  return state.client.annotations.image ? state.client.annotations.image[annotationId] : null;
};
