// @flow
import React from 'react';
import { Link } from 'react-router';
import SubscribeNow from '../messages/SubscribeNow';
import * as uiActions from '../../actions/ui';
import * as analyticsActions from '../../actions/analytics';
import { selectIsInApp, selectPageTrackingContext } from '../../selectors/client';
import { connect } from 'react-redux';
import { NavigationPlugin } from '../../capacitor-connector';

type OwnProps = {
  'trackingContext': Object,
  'className'?: string,
  'data-test'?: string,
  'onClick'?: Function,
  'targetPlan'?: string,
};

type MapStateToProps = {
  isInApp: boolean,
  pageTrackingContext: Object,
};

type DispatchProps = {
  showModal: Function,
  track: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

declare var __CAPACITOR__: boolean;
declare var __ELECTRON__: boolean;

const SubscribeNowButton = ({
  className,
  'data-test': dataTest,
  onClick,
  targetPlan,
  showModal,
  isInApp,
  track,
  trackingContext,
  pageTrackingContext,
}: Props) => {
  const isElectronOrIOS = __ELECTRON__ || (isInApp && !__CAPACITOR__);

  const onSubscribeClick = () => {
    if (onClick) {
      onClick();
    }

    if (isElectronOrIOS) {
      track('Get Premium Modal Triggered', { ...trackingContext, ...pageTrackingContext });
      return;
    }

    if (__CAPACITOR__) {
      NavigationPlugin.androidShouldOpenSubscriptionPage();
      return;
    }

    showModal('SUBSCRIBE_MODAL', trackingContext, targetPlan ? { plan: targetPlan } : {});
  };

  if (isElectronOrIOS) {
    return (
      <Link
        className={className}
        to={`/subscribe${targetPlan ? `?plan=${targetPlan}` : ''}`}
        data-test={dataTest}
        onClick={onSubscribeClick}
      >
        <SubscribeNow />
      </Link>
    );
  }

  return (
    <button className={className} onClick={onSubscribeClick} data-test={dataTest}>
      <SubscribeNow />
    </button>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isInApp: selectIsInApp(state),
    pageTrackingContext: selectPageTrackingContext(state),
  };
}

const dispatchProps: DispatchProps = {
  showModal: uiActions.showModal,
  track: analyticsActions.track,
};

export default connect(mapStateToProps, dispatchProps)(SubscribeNowButton);
