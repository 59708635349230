import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class TitleWithPopularTitle extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    popularTitle: PropTypes.string,
  };

  render() {
    const { title, popularTitle } = this.props;
    return (
      <span title={title}>
        <span>{title}</span>
        {popularTitle ? <span>{` “${popularTitle}”`}</span> : null}
      </span>
    );
  }
}
