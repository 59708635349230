// @flow
import { normalizeRecommendations } from '../schema/normalizeRecommendations';
import * as playerActions from './player';
import { getQueueOrigin, selectRecommendations } from '../selectors/recommendations';
import { selectPlayerCurrentQueueItem, selectTrackingData } from '../selectors/player';
import { selectTrack } from '../selectors/track';
import { selectRecording } from '../selectors/recording';
import { setPlaybackTrackingContext } from './analytics';
import type { Request, ThunkAction } from './types';
import type { TrackingContext } from './analytics';

export type LoadRecommendationsAction = { type: 'FETCH_RECOMMENDATIONS' } & Request;

export type RecommendationsAction = LoadRecommendationsAction;

export function loadRecommendations(workId: string): LoadRecommendationsAction {
  return {
    type: 'FETCH_RECOMMENDATIONS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: `/recommendations/tracks.v1?work_id=${workId}`,
    },
    meta: {
      normalize: normalizeRecommendations,
    },
  };
}

function getTrackingContext(workId: string, trackingData: TrackingContext): TrackingContext {
  return {
    contextType: 'continuousPlaybackWork',
    contextId: workId.toString(),
    originalContextType: trackingData.contextType,
    originalContextId: trackingData.contextId,
  };
}

export function playRecommendations(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();

    const currentQueueItem = selectPlayerCurrentQueueItem(state);
    const recordingId = selectTrack(state, currentQueueItem.track).recording;
    const workId = selectRecording(state, recordingId, false).work;

    await dispatch(loadRecommendations(workId));

    const { trackIds } = selectRecommendations(getState());

    dispatch(
      playerActions.setQueueAndPlay(getQueueOrigin(workId), trackIds.map(playerActions.createItem))
    );

    dispatch(setPlaybackTrackingContext(getTrackingContext(workId, selectTrackingData(state))));
  };
}
