// @flow

import React from 'react';

import AvailabilityText from './AvailabilityText';
import UpcomingText from './UpcomingText';
import LiveText from './LiveText';

type OwnProps = {
  event: Object,
  showAvailabilityText?: boolean,
  showUpcomingTime?: boolean,
};

const AvailabilityInfo = ({ event, showAvailabilityText, showUpcomingTime }: OwnProps) => {
  const { startDate, isUpcoming, isLive, isAvailable, availabilityEnd } = event;
  const showAvailability = showAvailabilityText && !isUpcoming && !isLive;

  return (
    <React.Fragment>
      {startDate && isUpcoming && (
        <UpcomingText startDate={startDate} showTime={showUpcomingTime} />
      )}
      {isLive && <LiveText />}
      {showAvailability && (
        <AvailabilityText isAvailable={isAvailable} availabilityEnd={availabilityEnd} />
      )}
    </React.Fragment>
  );
};

export default AvailabilityInfo;
