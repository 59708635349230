// @flow

import type { Request } from './types';
import { LivestreamEvent, MyEventsList } from '../schema';
import { normalizeMetaItem, normalizeMetaList } from '../schema/normalizeMeta';
import { loaded } from '../middleware/apiCacheValidators';

export type LoadLivestreamEventAction = { type: 'FETCH_LIVESTREAM_EVENT' } & Request;
export type ReloadLivestreamEventAction = { type: 'RE_FETCH_LIVESTREAM_EVENT' } & Request;
export type LoadEventJWTAction = { type: 'LOAD_EVENT_JWT' } & Request;
export type LoadZoomJWTAction = { type: 'LOAD_ZOOM_JWT' } & Request;
export type LoadMyEventsAction = { type: 'FETCH_MY_EVENTS' } & Request;

type VideoProviders = 'vimeo';

export type LiveStreamPlaybackProgressAction = {
  type: 'LIVESTREAM_PLAYBACK_PROGRESS',
  progress: number,
  eventSlug: string,
  eventType: string,
  currentTime: number,
  videoProvider: VideoProviders,
  isLive: boolean,
};

export function loadLivestreamEvent(slug: string): LoadLivestreamEventAction {
  return {
    type: 'FETCH_LIVESTREAM_EVENT',
    cacheId: slug,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/livestream-event.v2/{slug}',
      params: {
        slug,
        includeCourses: true,
      },
    },
    meta: {
      schema: LivestreamEvent,
      normalize: normalizeMetaItem,
    },
    cache: {
      fetch: state => state.maps.livestreamEvents[slug],
      validate: loaded,
    },
  };
}

export function reloadLivestreamEvent(slug: string): ReloadLivestreamEventAction {
  return {
    type: 'RE_FETCH_LIVESTREAM_EVENT',
    cacheId: slug,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/livestream-event.v2/{slug}',
      params: {
        slug,
        includeCourses: true,
      },
    },
    meta: {
      schema: LivestreamEvent,
      normalize: normalizeMetaItem,
    },
  };
}

export function liveStreamPlaybackProgress(
  progress: number,
  eventSlug: string,
  eventType: string,
  currentTime: number,
  videoProvider: VideoProviders,
  isLive: boolean
): LiveStreamPlaybackProgressAction {
  return {
    type: 'LIVESTREAM_PLAYBACK_PROGRESS',
    progress,
    eventSlug,
    eventType,
    currentTime,
    videoProvider,
    isLive,
  };
}

export function loadEventJWT(slug: string) {
  return {
    type: 'LOAD_EVENT_JWT',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'GET',
      endpoint: '/event-jwt/{slug}',
      params: { slug },
    },
  };
}

export function loadZoomJWT(slug: string, sessionId: string) {
  return {
    type: 'LOAD_ZOOM_JWT',
    IDAGIO_REQUEST: {
      type: 'AUTH_REQUEST',
      method: 'GET',
      endpoint: '/zoom-jwt/{slug}/{sessionId}',
      params: {
        slug,
        sessionId,
      },
    },
  };
}

export function loadMyEvents(cursor?: string, limit: number = 10): LoadMyEventsAction {
  let params = { limit };

  if (cursor) {
    params = {
      ...params,
      cursor,
    };
  }

  return {
    type: 'FETCH_MY_EVENTS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.1/user/events',
      params,
    },
    meta: {
      schema: MyEventsList,
      normalize: normalizeMetaList,
      restricted: true,
    },
  };
}
