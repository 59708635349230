import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';

export default class Person extends PureComponent {
  static propTypes = {
    person: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
    }),
    hyperlinks: PropTypes.bool,
    linkClassNames: PropTypes.string,
    tabIndex: PropTypes.number,
    omitForename: PropTypes.bool,
  };

  static defaultProps = {
    linkClassNames: '',
    hyperlinks: false,
    omitForename: false,
  };

  renderName() {
    const { person, omitForename } = this.props;
    const name = omitForename ? person.surname : person.name;
    return <span>{name}</span>;
  }

  render() {
    const { person, hyperlinks, linkClassNames, tabIndex } = this.props;

    if (!person) {
      return null;
    }

    if (hyperlinks) {
      return (
        <Link to={`/profiles/${person.id}`} className={linkClassNames} tabIndex={tabIndex}>
          {this.renderName()}
        </Link>
      );
    }

    return this.renderName();
  }
}
