import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getEntityUrl } from '../../lib/entities';
import { Link } from 'react-router';
import Image from '../util/Image';
import AlbumOneLineParticipantSummary from '../common/AlbumOneLineParticipantSummary';
import stylesSearchResult from './SearchResults.css';
import stylesSearchCollectionItem from './SearchCollectionItem.css';
import { SEARCH_IMAGE_DIMS } from '../../constants';

class SearchCollectionItem extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    curatorName: PropTypes.string,
    participants: PropTypes.array,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { title, type, slug, imageUrl, onClick } = this.props;
    const { curatorName, participants } = this.props;
    const path = getEntityUrl(type, { slug });

    return (
      <Link to={path} className={stylesSearchResult.result} onClick={onClick}>
        <Image
          src={imageUrl}
          className={stylesSearchResult.thumb}
          width={SEARCH_IMAGE_DIMS.w}
          height={SEARCH_IMAGE_DIMS.h}
          noBase
          alt=""
        />
        <div className={stylesSearchCollectionItem.wrapper}>
          <span className={stylesSearchCollectionItem.title}>{title}</span>
          {curatorName && <div>{curatorName}</div>}
          {participants && (
            <AlbumOneLineParticipantSummary
              className={stylesSearchCollectionItem.participants}
              participants={participants}
            />
          )}
        </div>
      </Link>
    );
  }
}

export default SearchCollectionItem;
