import React from 'react';

const Skeleton = () => (
  <div className="global-skeleton global-skeleton-list">
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '100%' }} />
    <div
      className="global-skeleton-item"
      style={{ marginBottom: '2rem', height: '2rem', width: '80%' }}
    />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
  </div>
);

export default Skeleton;
