// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadPeriodAction } from '../../actions/period';

export type State = {
  +[key: string]: {|
    +loading: boolean,
    +loaded: boolean,
    +entities: string,
  |},
  loading: boolean,
};

const initialState = {
  loading: false,
};

export default function epochs(state: State = initialState, action: LoadPeriodAction): State {
  if (action.type === 'FETCH_EPOCH' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
