import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default class AnimatedList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    className: PropTypes.string,
    transitionName: PropTypes.string,
    transitionEnterTimeout: PropTypes.number,
    transitionLeaveTimeout: PropTypes.number,
  };

  static defaultProps = {
    transitionName: 'list__transition',
    transitionEnterTimeout: 300,
    transitionLeaveTimeout: 300,
  };

  render() {
    const { className, renderItem, items } = this.props;
    return (
      <TransitionGroup className={className} component="ul">
        {items.map(renderItem).map(this.wrapInTransition)}
      </TransitionGroup>
    );
  }

  wrapInTransition = (item, i) => {
    const { transitionName, transitionEnterTimeout, transitionLeaveTimeout } = this.props;

    return (
      <CSSTransition
        key={i + transitionName}
        classNames={transitionName}
        timeout={{
          enter: transitionEnterTimeout,
          exit: transitionLeaveTimeout,
        }}
      >
        {item}
      </CSSTransition>
    );
  };
}
