import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import * as livestreamActions from '../../actions/livestreamEvents';

import CapacitorRipple from '../capacitor/Ripple';
import LivestreamEventImage from '../livestream-event/LivestreamEventImage';
import AvailabilityInfo from './AvailabilityInfo';
import CoursePeriod from './CoursePeriod';
import ParticipantList from './ParticipantList';

import styles from './LivestreamEventInfo.css';
import CollectionButton from '../common/CollectionButton';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';

const LivestreamEventInfo = ({
  event,
  url,
  onClickLink,
  isInverted,
  isInCollection,
  toggleIsInCollection,
  shouldShowDetails,
  loadLivestreamEvent,
  isSliderItem,
}) => {
  const { title, slug, location, isLive, isAvailable, isCourse, participants, isUpcoming } = event;
  const wrapperClasses = classnames(styles.wrapper, {
    [styles.inverted]: isInverted,
  });

  const toggleInCollection = () => {
    // load event entity in the background and save in the state
    loadLivestreamEvent(slug);
    toggleIsInCollection();
  };

  return (
    <div className={wrapperClasses} data-test="livestream-event-info.container">
      <LivestreamEventImage event={event} isSliderItem={isSliderItem} />
      <div className={styles.infoSection}>
        <div className={styles.availability}>
          {isCourse ? (
            <CoursePeriod event={event} />
          ) : (
            <AvailabilityInfo event={event} showAvailabilityText />
          )}
          <CollectionButton
            active={isInCollection}
            onClick={toggleInCollection}
            disabled={!isLive && !isAvailable && !isUpcoming}
            className={styles.collectionBtn}
          />
        </div>
        <Link
          // Disable HTML5 drag in the slider
          draggable={false}
          onDragStart={e => e.preventDefault()}
          to={url}
          onClick={onClickLink}
          onlyActiveOnIndex={false}
          className={classnames('c-link--expand-hitbox', styles.link)}
          data-test="livestream-event-info.link"
        >
          <CapacitorRipple />
          <p className={`${styles.title} fz--gamma`} data-test="livestream-event-info.title">
            {title}
          </p>
        </Link>
        {shouldShowDetails && (
          <React.Fragment>
            <div className={styles.location}>{location}</div>
            <div className={styles.participants}>
              <ParticipantList items={participants} />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

function getCollectibleEntityDescription(props) {
  return {
    id: props.event.id,
    trackingSource: 'Livestream Event',
  };
}

LivestreamEventInfo.propTypes = {
  event: PropTypes.object.isRequired,
  url: PropTypes.string,
  onClickLink: PropTypes.func,
  isInverted: PropTypes.bool,
  shouldShowDetails: PropTypes.bool,
  loadLivestreamEvent: PropTypes.func.isRequired,
  isSliderItem: PropTypes.bool,

  ...collectibleEntityPropTypes,
};

export default compose(
  connect(null, {
    loadLivestreamEvent: livestreamActions.loadLivestreamEvent,
  }),
  collectibleEntity(ENTITY_TYPE_LIVESTREAM_EVENT, getCollectibleEntityDescription)
)(LivestreamEventInfo);
