import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Modal from './Modal';

import stylesLosslessSupportModal from './LosslessSupportModal.css';
import * as uiActions from '../../actions/ui';

import classnames from 'classnames';
import DownloadAppButton from '../util/DownloadAppButton';
import { selectOpenModalTypeIs } from '../../selectors/modals';

class LosslessSupportModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  render() {
    const { hideModal, isOpen } = this.props;

    const downloadButtonClasses = classnames(
      'c-btn',
      'c-btn--is-purple',
      stylesLosslessSupportModal.downloadLink
    );

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={hideModal}
        onClose={hideModal}
        contentLabel="lossless-support-modal"
      >
        <section className={stylesLosslessSupportModal.modal}>
          <p>
            <FormattedMessage
              id="account.audio-quality.browser-support-notice"
              defaultMessage="Unfortunately, your browser doesn't support our lossless playback mode. For a more complete IDAGIO experience, download our app."
            />
          </p>
          <DownloadAppButton page="LosslessSupportModal" className={downloadButtonClasses} />
          <p>
            <button className="c-text-link--is-visible" onClick={hideModal}>
              <FormattedMessage
                id="account.audio-quality.browser-support-notice.maybeLater"
                defaultMessage="Maybe later"
              />
            </button>
          </p>
        </section>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'LOSSLESS_SUPPORT_MODAL'),
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
})(LosslessSupportModal);
