import { setAudioPlaybackSupport, setAudioQuality } from '../actions/client';
import { getHighestAllowedQuality } from '../client/playback-support';
import * as analyticsActions from '../actions/analytics';
import { identifyAnonymousUser } from '../actions/auth';
import connectPusher from '../client/pusher';
import pageVisibility from '../client/pageVisibility';
import { loadTracks } from '../actions/tracks';
import { QUEUE_TYPE_ALBUM, QUEUE_TYPE_PLAYLIST } from '../constants';
import { loadPlaylist } from '../actions/playlist';
import { loadAlbum } from '../actions/album';
import { selectPlayerQueueItems, selectPlayerQueueOrigin } from '../selectors/player';

export default function bootup(store) {
  store.dispatch(setAudioPlaybackSupport());
  const highestQuality = getHighestAllowedQuality(store.getState());
  store.dispatch(setAudioQuality(highestQuality));

  store.dispatch(analyticsActions.identify());
  store.dispatch(analyticsActions.trackAppOpened());
  if (analytics.user) {
    store.dispatch(identifyAnonymousUser(analytics.user().anonymousId()));
  }

  if (!__CAPACITOR__) {
    connectPusher(store);
  }
  pageVisibility.connectToStore(store);
}

export async function fetchCurrentQueueData(store) {
  try {
    const state = store.getState();
    const queueOrigin = selectPlayerQueueOrigin(state);

    if (queueOrigin && queueOrigin.type) {
      const queueItems = selectPlayerQueueItems(state);
      const trackIds = queueItems.map(({ track }) => track.toString());

      // Load tracks
      const requestActions = [loadTracks(trackIds)];

      // Load queue specific info
      if (queueOrigin.type === QUEUE_TYPE_PLAYLIST) {
        requestActions.push(loadPlaylist(queueOrigin.id));
      }

      if (queueOrigin.type === QUEUE_TYPE_ALBUM) {
        requestActions.push(loadAlbum(queueOrigin.id));
      }

      await Promise.all(requestActions.map(store.dispatch));

      console.log('Queue information fetched'); // eslint-disable-line no-console
      return true;
    }

    console.log('Queue information fetch not needed'); // eslint-disable-line no-console
    return false;
  } catch (e) {
    console.log('Could not refetch saved queue', e); // eslint-disable-line no-console
    return false;
  }
}
