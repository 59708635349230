import { createSelector } from 'reselect';
import {
  selectUser,
  selectUserCanSubscribe,
  selectUserIsAllowedOptOutTrial,
  selectUserIsAuthenticated,
  selectUserIsConcertTier,
  selectUserIsPatron,
  selectUserIsTrialOptIn,
  selectUserPlan,
  selectUserPlanStartDate,
  selectUserPlanSubscriptionType,
  selectUserStripeDiscount,
} from './user';

import {
  SUBSCRIPTION_PLAN_FREE,
  SUBSCRIPTION_PLAN_PREMIUM,
  SUBSCRIPTION_PLAN_TRIAL_OPT_IN,
  SUBSCRIPTION_PLAN_TRIAL_OPT_OUT,
  VOUCHER_STATUS_PENDING,
} from '../constants';
import { selectSubscriptionTrialDurationDays } from './subscriptionOffer';

export function selectSubscriptionIsCanceled(state) {
  const user = selectUser(state);
  if (!user) {
    return false;
  }
  return user.plan_canceled_at !== null;
}

export function selectSubscriptionIsLoaded(state) {
  return state.subscription.loaded;
}

export function selectSubscriptionIsLoading(state) {
  return state.subscription.loading;
}

export function selectSubscription(state) {
  if (!selectSubscriptionIsLoaded(state)) {
    return null;
  }

  return state.subscription.entities;
}

export const selectSubscriptionTypeIsApple = createSelector(
  selectUserPlanSubscriptionType,
  subscriptionType => subscriptionType === 'apple'
);

export const selectSubscriptionTypeIsAndroid = createSelector(
  selectUserPlanSubscriptionType,
  subscriptionType => subscriptionType === 'android'
);

export const selectSubscriptionTypeIsStripe = createSelector(
  selectUserPlanSubscriptionType,
  subscriptionType => subscriptionType === 'stripe'
);

export const selectUserHasStripeDiscount = createSelector(selectUserStripeDiscount, discount =>
  Boolean(discount)
);

function selectUserPlanProductId(state) {
  const user = selectUser(state);
  return user.plan_product_id;
}

export function selectUserCanApplyVoucher(state) {
  const user = selectUser(state);
  if (!user) {
    // we don't show this to users without an account
    return false;
  }

  // users on P+C plan product ids can't use vouchers
  // https://idagio.slack.com/archives/C01LBRBKL4W/p1612808045025000
  const planProductId = selectUserPlanProductId(state);
  if (
    typeof planProductId === 'string' &&
    (planProductId.startsWith('ppc-6m') || planProductId.startsWith('ppc-12m'))
  ) {
    return false;
  }

  const isStripeSubscription = selectSubscriptionTypeIsStripe(state);
  const userHasNoStripeDiscount = !selectUserHasStripeDiscount(state);
  const plan = selectUserPlan(state);

  const planIsFreeOrTrialOptIn =
    plan === SUBSCRIPTION_PLAN_FREE || plan === SUBSCRIPTION_PLAN_TRIAL_OPT_IN;

  const planIsPremiumOrTrialOptOut =
    plan === SUBSCRIPTION_PLAN_PREMIUM || plan === SUBSCRIPTION_PLAN_TRIAL_OPT_OUT;

  return (
    planIsFreeOrTrialOptIn ||
    (planIsPremiumOrTrialOptOut && isStripeSubscription && userHasNoStripeDiscount)
  );
}

export const selectStripeDiscountCouponId = createSelector(
  [selectUserHasStripeDiscount, selectUserStripeDiscount],
  (discountActive, stripeDiscount) => {
    if (discountActive && stripeDiscount && stripeDiscount.coupon_id) {
      return stripeDiscount.coupon_id;
    }

    return null;
  }
);

export const selectStripeDiscountPercentOff = createSelector(
  [selectUserHasStripeDiscount, selectUserStripeDiscount],
  (discountActive, credit) => {
    if (discountActive) {
      return credit.percent_off;
    }

    return null;
  }
);

export function selectUserCanCancelSubscription(state) {
  // Only users who are subscribed, are not on a trial that expires and doesn't
  // have a 100% coupon active can cancel
  const userIsPatron = selectUserIsPatron(state);
  const userIsTrialOptIn = selectUserIsTrialOptIn(state);
  const userHasFullPercentOff = selectStripeDiscountPercentOff(state) === 100;

  return userIsPatron && !userIsTrialOptIn && !userHasFullPercentOff;
}

export function selectUserCanManageSubscription(state) {
  const isStripe = selectSubscriptionTypeIsStripe(state);
  const userIsTrialOptIn = selectUserIsTrialOptIn(state);
  const userHasStripeDiscount = selectUserHasStripeDiscount(state);

  return isStripe && !userHasStripeDiscount && !userIsTrialOptIn;
}

export function selectUserCanUpgrade(state) {
  const userCanManageSubscription = selectUserCanManageSubscription(state);
  const isAppleSubscription = selectSubscriptionTypeIsApple(state);
  const isAndroidSubscription = selectSubscriptionTypeIsAndroid(state);
  const isConcertTier = selectUserIsConcertTier(state);
  return (
    !isConcertTier && (userCanManageSubscription || isAppleSubscription || isAndroidSubscription)
  );
}

function selectUserHasSubscribedForAMonth(state) {
  const planStart = selectUserPlanStartDate(state);

  if (!planStart) {
    return false;
  }

  const present = new Date();
  return present - planStart > 1000 * 60 * 60 * 24 * 30; // 1 month
}

export function selectUserCanUpgradeAfterAMonth(state) {
  return selectUserCanUpgrade(state) && selectUserHasSubscribedForAMonth(state);
}

export function selectUserCanSubscribeOrUpgrade(state) {
  return selectUserCanSubscribe(state) || selectUserCanUpgrade(state);
}

export function selectUserCanAccessSubscribeOrUpgradeView(state) {
  return !selectUserIsAuthenticated(state) || selectUserCanSubscribeOrUpgrade(state);
}

export function selectUserCanAccessSubscribeView(state) {
  return !selectUserIsAuthenticated(state) || selectUserCanSubscribe(state);
}

export const selectStripeDiscountEndDate = createSelector(
  [selectUserHasStripeDiscount, selectUserStripeDiscount],
  (discountActive, credit) => {
    if (discountActive && credit.end) {
      return new Date(credit.end * 1000);
    }

    return null;
  }
);

export function selectSubscriptionEnds(state) {
  if (!selectUserIsPatron(state)) {
    return false;
  }

  // user exists because selectUserIsPatron checks for it
  const user = selectUser(state);

  const { plan, plan_canceled_at: planCanceledAt } = user;
  return plan === SUBSCRIPTION_PLAN_TRIAL_OPT_IN || planCanceledAt !== null;
}

export const selectSubscriptionEndDate = createSelector(
  [selectSubscriptionEnds, selectUser],
  (subscriptionEnds, user) => {
    if (subscriptionEnds !== true) {
      return null;
    }

    if (!user || !user.plan_ends_at) {
      return null;
    }

    return new Date(user.plan_ends_at * 1000);
  }
);

export function selectSubscriptionRenews(state) {
  const plan = selectUserPlan(state);
  const stripePercentOff = selectStripeDiscountPercentOff(state);
  if (!plan) {
    return false;
  }

  if (plan === SUBSCRIPTION_PLAN_FREE) {
    return false;
  }

  if (stripePercentOff === 100) {
    return false;
  }

  const subscriptionEnds = selectSubscriptionEnds(state);
  if (subscriptionEnds === null) {
    // no user or no subscription
    return false;
  }

  return !subscriptionEnds;
}

export const selectSubscriptionRenewalDate = createSelector(
  [selectSubscriptionRenews, selectUser],
  (subscriptionRenews, user) => {
    if (subscriptionRenews !== true) {
      return null;
    }

    if (!user || !user.plan_renews_at) {
      return null;
    }

    return new Date(user.plan_renews_at * 1000);
  }
);

export const selectSubscriptionPlanName = createSelector(selectUser, user =>
  user ? user.plan_display_name : null
);

export function selectIncomingVoucher(state) {
  return state.incomingVoucher;
}

export function selectShouldApplyVoucher(state) {
  const voucher = selectIncomingVoucher(state);
  return voucher && voucher.status === VOUCHER_STATUS_PENDING;
}

export function selectUserIsTrial(state) {
  const plan = selectUserPlan(state);
  if (!plan) {
    return false;
  }

  return plan === SUBSCRIPTION_PLAN_TRIAL_OPT_IN || plan === SUBSCRIPTION_PLAN_TRIAL_OPT_OUT;
}

export const selectUserTrialEndDate = createSelector(
  [selectUserIsTrial, selectUser],
  (isTrial, user) => {
    if (!isTrial) {
      return null;
    }

    if (!user || !user.plan_ends_at) {
      return null;
    }

    return new Date(user.plan_ends_at * 1000);
  }
);

export function selectIsTrialAvailable(state) {
  return selectUserIsAllowedOptOutTrial(state) && selectSubscriptionTrialDurationDays(state) > 0;
}
