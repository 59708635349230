// @flow
import { APP_SCROLLER_CLASS } from '../constants';

declare var __CAPACITOR__: boolean;

export function getScrollableSelector() {
  if (__CAPACITOR__) {
    return window;
  }

  return document.querySelector(`.${APP_SCROLLER_CLASS}`);
}

export function getScrollPosition() {
  const scrollEl = getScrollableSelector();
  if (scrollEl === window) {
    return window.scrollY;
  }
  return scrollEl ? scrollEl.scrollTop : 0;
}
