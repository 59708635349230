import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as Shapes from '../../shapes';

import { Link } from 'react-router';
import Separated from '../util/Separated';

export default class EnsembleList extends PureComponent {
  static propTypes = {
    ensembles: PropTypes.arrayOf(Shapes.Ensemble),
    hyperlinks: PropTypes.bool,
    linkClassNames: PropTypes.string,
  };

  static defaultProps = {
    hyperlinks: false,
  };

  renderEnsemble(ensembles, hyperlinks, linkClassNames) {
    return ensembles.map(({ id, name }) => {
      if (hyperlinks) {
        return (
          <Link key={id} to={`profiles/${id}`} className={linkClassNames}>
            {name}
          </Link>
        );
      }

      return name;
    });
  }

  render() {
    const { ensembles, hyperlinks, linkClassNames } = this.props;

    if (!ensembles.length) {
      return null;
    }

    return <Separated>{this.renderEnsemble(ensembles, hyperlinks, linkClassNames)}</Separated>;
  }
}
