import {
  DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP,
  HIDDEN_DISCOVER_GROUP_TYPES_IN_CAPACITOR,
  SUPPORTED_DISCOVER_GROUP_ITEM_TYPES,
  SUPPORTED_DISCOVER_GROUP_TYPES,
} from '../constants';
import { getItemType } from '../selectors/discover';

function filterSupportedGroupItems(group) {
  return group.items.filter(item => {
    const itemType = getItemType(item);
    return SUPPORTED_DISCOVER_GROUP_ITEM_TYPES.includes(itemType);
  });
}

const isSupportedGroupType = ({ type }) => {
  if (!SUPPORTED_DISCOVER_GROUP_TYPES.includes(type)) {
    return false;
  }

  if (__CAPACITOR__ && HIDDEN_DISCOVER_GROUP_TYPES_IN_CAPACITOR.includes(type)) {
    return false;
  }

  return true;
};

const filterSupportedDiscoverGroups = groups => {
  return groups.reduce((validGroups, group) => {
    const supportedItems = filterSupportedGroupItems(group);
    if (isSupportedGroupType(group) && supportedItems.length > 0) {
      validGroups.push({
        ...group,
        items: supportedItems,
      });
    }
    return validGroups;
  }, []);
};

export function normalizeDiscoverLivestreamEvent({ content, title, imageUrl, subtitle }) {
  return {
    type: content.type,
    title,
    id: content.id,
    imageUrl,
    location: subtitle,
    slug: content.slug,
    isUpcoming: true,
    isAvailable: true,
    startDate: content.dateStart || content.start,
    end: content.dateEnd || content.end,
    availabilityEnd: content.dateAvailabilityEnd || content.availabilityEnd,
    participants: content.participants,
    priceTemplate: content.priceTemplate,
    ticketPriceEuro: content.ticketPriceEuro,
    ticketPriceUsd: content.ticketPriceUsd,
    ticketPriceGbp: content.ticketPriceGbp,
    disableTicketPurchase: content.disableTicketPurchase,
  };
}

export function normalizeDiscoverGroup(result) {
  const {
    can_view_all: canViewAll,
    has_sub_discover_page: hasSubDiscoverPage,
    sub_discover_page: subDiscoverPage,
    ...restGroup
  } = result;

  const normalizedGroup = {
    ...restGroup,
    canViewAll,
    hasSubDiscoverPage,
    subDiscoverPage,
  };

  if (restGroup.type === DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP) {
    const { items } = restGroup;
    items.map(item => {
      item.content = normalizeDiscoverLivestreamEvent(item);
    });
  }

  return normalizedGroup;
}

export function normalizeDiscoverGroups({ result }) {
  const normalizedGroups = result.groups.map(normalizeDiscoverGroup);
  const normalizedResult = {
    ...result,
    groups: filterSupportedDiscoverGroups(normalizedGroups),
  };
  return { result: normalizedResult };
}
