// @flow

import type { SetOfferDetailsAction } from '../actions/subscriptionOffer';
import {
  DEFAULT_PLAN_ID,
  FROM_ID_CAMPAIGNS,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_TRIAL_DURATION_DAYS,
} from '../constants';

type State = {|
  +fromId: ?string,
  +price: number,
  +relativeDiscount: ?number,
  +reducedPriceDuration: ?number,
  +trialDurationInDays: ?number,
  +displayNName: ?string,
  +trackingName: ?string,
|};

const initialState = {
  fromId: FROM_ID_CAMPAIGNS.default.fromId,
  price: SUBSCRIPTION_PLANS[DEFAULT_PLAN_ID].monthlyPrice,
  relativeDiscount: null,
  reducedPriceDuration: null,
  trialDurationInDays: SUBSCRIPTION_TRIAL_DURATION_DAYS,
  displayNName: null,
  trackingName: null,
};

export default function subscriptionOffer(
  state: State = initialState,
  action: SetOfferDetailsAction
) {
  if (action.type === 'SET_OFFER_DETAILS') {
    return action.offer;
  }

  if (action.type === 'SET_OFFER_FROM_ID') {
    return {
      ...state,
      fromId: action.fromId,
    };
  }
  return state;
}
