import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import Modal from './Modal';

import * as uiActions from '../../actions/ui';
import stylesShortcutsHelpModal from './ShortcutsHelpModal.css';
import { selectOpenModalTypeIs } from '../../selectors/modals';

class ShortcutsHelpModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  render() {
    const { hideModal, isOpen } = this.props;
    const classesKeys = classnames(stylesShortcutsHelpModal.key, stylesShortcutsHelpModal.keySpace);

    return (
      <Modal
        isOpen={isOpen}
        onClose={hideModal}
        onRequestClose={hideModal}
        contentLabel="shortcuts-help-modal"
      >
        <div className={stylesShortcutsHelpModal.modal}>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={classesKeys}>
                <FormattedMessage id="shortcuts.keys.space" defaultMessage="space" />
              </kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage id="shortcuts.def.play-pause" defaultMessage="Play or pause" />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&rarr;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.seek-forward"
                defaultMessage="Seek forwards (5 seconds)"
              />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&larr;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.seek-backward"
                defaultMessage="Seek backwards (5 seconds)"
              />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&#x21E7;</kbd>
              <kbd className={stylesShortcutsHelpModal.key}>&#x2192;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.skip-next"
                defaultMessage="Skip to next track in queue"
              />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&#x21E7;</kbd>
              <kbd className={stylesShortcutsHelpModal.key}>&#x2190;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.skip-prev"
                defaultMessage="Skip to previous track in queue"
              />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&#x21E7;</kbd>
              <kbd className={stylesShortcutsHelpModal.key}>&#x2191;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage id="shortcuts.def.volume-up" defaultMessage="Increase volume" />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&#x21E7;</kbd>
              <kbd className={stylesShortcutsHelpModal.key}>&#x2193;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage id="shortcuts.def.volume-down" defaultMessage="Decrease volume" />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>F</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.fav-add"
                defaultMessage="Add current track to collection"
              />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>R</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage id="shortcuts.def.repeat" defaultMessage="Toggle repeat" />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>&#x21E7;</kbd>
              <kbd className={stylesShortcutsHelpModal.key}>&#x232B;</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.nav-to-queue"
                defaultMessage="Navigate to playing queue"
              />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>/</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage id="shortcuts.def.start-search" defaultMessage="Start search" />
            </dd>
          </dl>
          <dl className={stylesShortcutsHelpModal.item}>
            <dt className={stylesShortcutsHelpModal.term}>
              <kbd className={stylesShortcutsHelpModal.key}>H</kbd>
            </dt>
            <dd className={stylesShortcutsHelpModal.def}>
              <FormattedMessage
                id="shortcuts.def.show-hide-shortcuts"
                defaultMessage="Show/hide keyboard shortcuts"
              />
            </dd>
          </dl>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'SHORTCUTS_HELP_MODAL'),
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
})(ShortcutsHelpModal);
