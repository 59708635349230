import { QUEUE_TYPE_LIVE_COMPARE } from '../constants';

const PRECISION = 0.1;
const COEFFICIENT = 100;
export const DEFAULT_LOOKAHEAD = 10000;

export const normalizeTimestamp = timestampInSeconds => {
  return (Math.floor(timestampInSeconds / PRECISION) * COEFFICIENT).toString();
};

/**
 * @param currentTimestamp timestamp in S
 * @param interpolation
 * @returns {*|number} timestamp in S
 */
export const getInterpolatedTimestamp = (currentTimestamp, interpolation) => {
  const normalized = normalizeTimestamp(currentTimestamp);

  return parseInt(interpolation[normalized] || interpolation.last || 0, 10) / 1000;
};

/**
 * creates a live compare queue origin. the id is the piece id
 * @param pieceId
 * @param trackId
 * @returns {{id: string, type: string, pieceId: string, trackId: string}}
 */
export function getQueueOrigin(pieceId, trackId) {
  return {
    type: QUEUE_TYPE_LIVE_COMPARE,
    id: pieceId,
    pieceId,
    trackId,
  };
}

export const LIVE_COMPARE_ERROR__TIMEOUT = 'TimeoutError';

export function createTimeoutError() {
  const error = new Error('TimeoutError');

  error.type = LIVE_COMPARE_ERROR__TIMEOUT;

  return error;
}

/**
 * creates a wrapper promise that will be rejected, unless the wrapped promise resolves before the timeout.
 */
export function awaitWithTimeout(promise, timeout) {
  return new Promise((resolve, reject) => {
    promise.then(resolve);
    setTimeout(() => reject(createTimeoutError()), timeout);
  });
}
