// @flow
// $FlowFixMe
import React, { useState, useEffect } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import classnames from 'classnames';

import Notifications from '../notifications/Notifications';

import styles from './CardForm.css';

export const getStripeElementsStyle = (): Object => {
  let lookup = variableName => `var(${variableName})`;
  if (typeof document !== 'undefined') {
    lookup = variableName =>
      getComputedStyle(document.documentElement).getPropertyValue(variableName);
  }

  return {
    base: {
      'color': lookup('--color-text-1'),
      'fontFamily': 'Eesti Display',
      'fontSize': '14px',
      'fontSmoothing': 'antialiased',
      'fontWeight': '400',
      'letterSpacing': '1px',
      'lineHeight': '16px',
      '::placeholder': {
        color: lookup('--color-text-1'),
        fontSize: '10px',
      },
    },
  };
};

type OwnProps = {
  isInModal: boolean,
  setCardFormIsReadyForSubmission: Function,
};

type ErrorLabelProps = {
  id: string,
  errorEvent: {
    message: string,
  } | null,
};

type Props = OwnProps;

const CardForm = ({ isInModal, setCardFormIsReadyForSubmission }: Props) => {
  const initState = {
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  };

  const [activeElement, setActiveElement] = useState(null);
  const [stripeErrorEvents, setStripeErrorEvents] = useState(initState);
  const [stripeCompleteEvents, setStripeCompleteEvents] = useState(initState);
  const [stripeElements, setStripeElements] = useState(initState);

  useEffect(() => {
    const cardFormIsReadyForSubmission = Object.values(stripeCompleteEvents).every(Boolean);
    setCardFormIsReadyForSubmission(cardFormIsReadyForSubmission);
  }, [stripeCompleteEvents, setCardFormIsReadyForSubmission]);

  const focusNext = () => {
    const { cardNumber, cardExpiry, cardCvc } = stripeElements;
    switch (activeElement) {
      case cardNumber:
        cardExpiry.focus();
        break;
      case cardExpiry:
        cardCvc.focus();
        break;
      default:
    }
  };

  const onStripeEvent = event => {
    setStripeErrorEvents(prevState => {
      return {
        ...prevState,
        [event.elementType]: event.error,
      };
    });
    setStripeCompleteEvents(prevState => {
      return {
        ...prevState,
        [event.elementType]: event.complete,
      };
    });

    if (event.complete) {
      focusNext();
    }
  };

  const onStripeReady = el => {
    setStripeElements(prevState => {
      return {
        ...prevState,
        [el._componentName]: el,
      };
    });
  };

  const cardNumberInputId = uniqueId();
  const cardExpiryInputId = uniqueId();
  const cardCvcInputId = uniqueId();

  const ErrorLabel = ({ id, errorEvent }: ErrorLabelProps) => {
    return (
      <React.Fragment>
        {errorEvent && (
          <div className={styles.singleErrorLabel}>
            <label
              htmlFor={id}
              className={classnames(styles.stripeErrorLabel, { [styles.hasError]: !!errorEvent })}
            >
              {errorEvent.message}
            </label>
          </div>
        )}
      </React.Fragment>
    );
  };

  const stripeFormFieldStyles = getStripeElementsStyle();

  return (
    <div className={styles.component}>
      {isInModal && <Notifications />}
      <div data-test="card-form.card-number">
        <label
          className={classnames('c-form-field__label', styles.label)}
          htmlFor={cardNumberInputId}
        >
          <FormattedMessage id="account.payment.card-number-label" defaultMessage="Card Number" />
          <span className={styles.iconCards} />
        </label>
        <CardNumberElement
          className={classnames('c-form-field__input', styles.stripeInput, {
            [styles.hasError]: !!stripeErrorEvents.cardNumber,
          })}
          placeholder="XXXX XXXX XXXX XXXX"
          onChange={onStripeEvent}
          onReady={onStripeReady}
          onFocus={() => setActiveElement(stripeElements.cardNumber)}
          style={stripeFormFieldStyles}
          id={cardNumberInputId}
        />
        <ErrorLabel id={cardNumberInputId} errorEvent={stripeErrorEvents.cardNumber} />
      </div>

      <div className={styles.cardDetails}>
        <div className={styles.expDate} data-test="card-form.card-expiry">
          <label
            className={classnames('c-form-field__label', styles.label)}
            htmlFor={cardExpiryInputId}
          >
            <FormattedMessage
              id="account.payment.expiration-date-label"
              defaultMessage="Expiration Date"
            />
          </label>
          <CardExpiryElement
            className={classnames('c-form-field__input', styles.stripeInput, {
              [styles.hasError]: !!stripeErrorEvents.cardExpiry,
            })}
            onChange={onStripeEvent}
            onReady={onStripeReady}
            onFocus={() => setActiveElement(stripeElements.cardExpiry)}
            style={stripeFormFieldStyles}
            id={cardExpiryInputId}
          />
        </div>
        <div className={styles.cvc} data-test="card-form.card-cvc">
          <label
            className={classnames('c-form-field__label', styles.label)}
            htmlFor={cardCvcInputId}
          >
            <FormattedMessage id="account.payment.cvc-label" defaultMessage="CVC" />
          </label>
          <CardCvcElement
            className={classnames('c-form-field__input', styles.stripeInput, {
              [styles.hasError]: !!stripeErrorEvents.cardCvc,
            })}
            onChange={onStripeEvent}
            onReady={onStripeReady}
            onFocus={() => setActiveElement(stripeElements.cardCvc)}
            style={stripeFormFieldStyles}
            id={cardCvcInputId}
          />
        </div>
      </div>
      <ErrorLabel id={cardExpiryInputId} errorEvent={stripeErrorEvents.cardExpiry} />
      <ErrorLabel id={cardCvcInputId} errorEvent={stripeErrorEvents.cardCvc} />
    </div>
  );
};

export default CardForm;
