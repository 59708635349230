import React, { Component } from 'react';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import Separated from '../util/Separated';
import { PIANO_INSTRUMENT_ID } from '../../constants';

const HIGH_PRIORITY_ENSEMBLE_FUNCTIONS = ['piano-trio', 'string-trio', 'string-quartet'];

function copyParticipantInformation(participant, index) {
  return { ...participant, originalIndex: index };
}

function getTypeOrderValue(participant) {
  const type = participant.type;
  if (type === 'ensemble') {
    const ensembleFunction = participant.ensemble.function;
    if (HIGH_PRIORITY_ENSEMBLE_FUNCTIONS.includes(ensembleFunction)) {
      return 0;
    }
  }

  switch (type) {
    case 'soloist':
      if (participant.person.instrumentID === PIANO_INSTRUMENT_ID) {
        return 2;
      }
      return 1;
    case 'conductor':
      return 3;
    case 'ensemble':
      return 4;
    default:
      return 5;
  }
}

/*
  The sorting is primarily on number of occurences. If two items have the same number of occurences then we
  try to order them by the performer type. If that is also the same between the two items, then we fall back to
  the original sorting provided by the backend which is sorted by popularity.
 */
function sortParticipants(participants) {
  return values(participants).sort((a, b) => {
    return (
      b.numOccurrences - a.numOccurrences ||
      getTypeOrderValue(a) - getTypeOrderValue(b) ||
      a.originalIndex - b.originalIndex
    );
  });
}

/*
  We create an intermediate representation of all the participants from the server because some performers appear
  multiple times in the list if they are present in different roles.
 */
function mergeParticipants(participants) {
  return participants.reduce((acc, participant, currentIndex) => {
    const existingParticipant = acc[participant.id];

    if (!existingParticipant) {
      acc[participant.id] = copyParticipantInformation(participant, currentIndex);

      return acc;
    }

    existingParticipant.numOccurrences += participant.numOccurrences;

    if (getTypeOrderValue(participant) < getTypeOrderValue(existingParticipant)) {
      existingParticipant.type = participant.type;
    }

    acc[existingParticipant.id] = existingParticipant;

    return acc;
  }, {});
}

export default class AlbumOneLineParticipantSummary extends Component {
  static propTypes = {
    className: PropTypes.string,
    participants: PropTypes.array.isRequired,
  };

  render() {
    return (
      <span className={this.props.className}>
        <Separated>{this.orderParticipants()}</Separated>
      </span>
    );
  }

  orderParticipants() {
    const { participants } = this.props;

    return sortParticipants(mergeParticipants(participants)).map(participant => {
      let visibleName = participant.name;
      if (participant.person) {
        visibleName = participant.person.surname
          ? participant.person.surname
          : participant.person.forename;
      }

      return visibleName;
    });
  }
}
