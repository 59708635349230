// @flow

import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadPersonalPlaylistAction } from '../../actions/personalPlaylist';

export type State = {
  +[key: string]: {|
    +loading: boolean,
    +loaded: boolean,
    +entities: string,
  |},
};

const initialState = {};

export default function personalPlaylists(
  state: State = initialState,
  action: LoadPersonalPlaylistAction
): State {
  if (action.type === 'FETCH_PERSONAL_PLAYLIST' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }

  return state;
}
