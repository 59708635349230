import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import * as Shapes from '../../shapes';

class CoursePeriod extends Component {
  static propTypes = {
    event: Shapes.LivestreamEvent,
  };

  render() {
    const { event } = this.props;
    const { startDate, endDate } = event;

    const start = <FormattedDate year="numeric" month="short" day="numeric" value={startDate} />;
    const end = <FormattedDate year="numeric" month="short" day="numeric" value={endDate} />;

    if (startDate.toDateString() === endDate.toDateString()) {
      return (
        <span data-test="livestream-event-hero.course-timestamp" data-test-timestamp={startDate}>
          {start}
        </span>
      );
    }

    return (
      <span
        data-test="livestream-event-hero.course-period"
        data-test-start-timestamp={startDate}
        data-test-end-timestamp={endDate}
      >
        {start} - {end}
      </span>
    );
  }
}

export default CoursePeriod;
