// @flow

import { handlePaginatedMapRequest } from '../phasedActionHelpers';

import type { LoadRecordingsByFilterAction } from '../../actions/recording';

export type State = {
  +[key: string]: {|
    +entities: Array<number>,
    +loaded: boolean,
    +loading: boolean,
    +loadedCursors: { +[key: string]: boolean },
    +meta: {
      +count: number,
      +cursor: { +next: ?string, +prev: ?string },
    },
  |},
};

const initialState = {};

export default function recordingsByFilter(
  state: State = initialState,
  action: LoadRecordingsByFilterAction
): State {
  if (action.type === 'FETCH_RECORDINGS_BY_FILTER' && action.cacheId) {
    return handlePaginatedMapRequest(state, action, action.cacheId);
  }

  return state;
}
