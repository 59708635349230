// @flow

import update from 'immutability-helper';

export type State = {|
  +list: Array<string>,
|};

const initState = {
  list: [],
};

export default function lastActions(state: State = initState, action: { type: string }): State {
  const { list } = state;
  list.unshift(action.type);
  return update(state, {
    list: {
      $set: list.slice(0, 15),
    },
  });
}
