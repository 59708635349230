export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const removeAtIndex = (list, index) => {
  return [
    ...list.slice(0, index),
    ...list.slice(index + 1), // eslint-disable-line comma-dangle
  ];
};
