// @flow
import { handleListRequest } from '../phasedActionHelpers';
import type { LoadAdsAction } from '../../actions/ads';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +entities: Array<string>,
|};

const initialState = {
  loading: false,
  loaded: false,
  entities: [],
};

export default function ads(state: State = initialState, action: LoadAdsAction) {
  if (action.type === 'FETCH_ADS') {
    return handleListRequest(state, action, result => {
      return result;
    });
  }
  (action: empty);
  return state;
}
