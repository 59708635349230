// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Price from '../common/Price';

import styles from './PlanPrice.css';
import classNames from 'classnames';

type OwnProps = {
  price: number,
  relativeDiscount?: number,
  interval: 'monthly' | 'yearly',
  className?: string,
  priceTag?: React$Element<any>,
};

type Props = OwnProps;

const getIntervalMessage = (interval: 'monthly' | 'yearly') => {
  if (interval === 'monthly') {
    return (
      <FormattedMessage
        id="subscribe-modal.plans-options.monthly-price-interval"
        defaultMessage="/month"
      />
    );
  }
  return (
    <FormattedMessage
      id="subscribe-modal.plans-options.yearly-price-interval"
      defaultMessage="/year"
    />
  );
};

const PlanPrice = ({ price, relativeDiscount, interval, className, priceTag }: Props) => {
  return (
    <div className={classNames(styles.priceContainer, className)}>
      <div className={styles.price}>
        <span data-test="plan-price.original-price">
          <Price price={price} className={relativeDiscount ? styles.discountedPrice : ''} />
        </span>
        {relativeDiscount && (
          <span data-test="plan-price.discounted-price">
            &nbsp;
            <Price price={price} relativeDiscount={relativeDiscount} />
          </span>
        )}
        <span className={styles.interval}>{getIntervalMessage(interval)}</span>
      </div>
      {priceTag && <div className={styles.priceTag}>{priceTag}</div>}
    </div>
  );
};

export default PlanPrice;
