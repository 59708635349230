// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { validateEmail } from '../../utils/validateInput';
import LoadingIndicator from '../common/LoadingIndicator';
import AcceptTerms from '../common/AcceptTerms';

import * as authActions from '../../actions/auth';

import AccountEmailLabel from '../messages/AccountEmailLabel';
import AccountPasswordLabel from '../messages/AccountPasswordLabel';

import { CAPTCHA_ACTION_SIGN_UP, DEFAULT_SIGNUP_SOURCE } from '../../constants';

import styles from './SignupForm.css';
import { selectCaptchaType } from '../../selectors/client';
import CaptchaForm from './CaptchaForm';
import PasswordInput from './PasswordInput';
import AccountFirstNameLabel from '../messages/AccountFirstNameLabel';
import AccountPasswordConfirmationLabel from '../messages/AccountPasswordConfirmationLabel';
import AccountJoin from '../messages/AccountJoin';
import { isValidPassword } from '../../utils/password';
import InvalidPassword from '../messages/InvalidPassword';
import PasswordMismatch from '../messages/PasswordMismatch';

type OwnProps = {
  fromId: string,
  signupSource: 'Sonos' | 'Webtop',
  promotionName?: string,
  onAuthComplete?: Function,
};

type MapStateToProps = {
  captchaType: string,
};

type DispatchProps = {
  signup: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const SignupForm = ({
  fromId,
  signupSource = DEFAULT_SIGNUP_SOURCE,
  promotionName,
  onAuthComplete,
  captchaType,
  signup,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const onSubmit = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
  };

  const onEmailChange = e => {
    setEmail(e.target.value);
    if (e.target.value && validateEmail(e.target.value)) {
      setInvalidEmail(false);
    }
  };

  const onEmailBlur = () => {
    const emailError = email && !validateEmail(email);
    setInvalidEmail(emailError);
  };

  const onPasswordChange = e => {
    setPassword(e.target.value);
    if (e.target.value && isValidPassword(e.target.value)) {
      setInvalidPassword(false);
    }

    if (e.target.value && passwordConfirmation && e.target.value === passwordConfirmation) {
      setPasswordMismatch(false);
    }
  };

  const onPasswordBlur = () => {
    const passwordError = password && !isValidPassword(password);
    setInvalidPassword(passwordError);
    const mismatch = password && passwordConfirmation && password !== passwordConfirmation;
    setPasswordMismatch(mismatch);
  };

  const onPasswordConfirmationChange = e => {
    setPasswordConfirmation(e.target.value);
    if (e.target.value && password === e.target.value) {
      setPasswordMismatch(false);
    }
  };

  const submitSignup = async captchaToken => {
    const marketingOptIn = false;

    try {
      await signup({
        firstName,
        email,
        password,
        fromId,
        marketingOptIn,
        signupSource,
        promotionName,
        captchaType,
        captchaToken,
      });
      if (onAuthComplete) {
        await onAuthComplete();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const className = classnames(styles.component);
  const buttonClassName = classnames(styles.btn, 'c-btn', 'c-btn--is-red');

  const isButtonDisabled =
    isLoading ||
    !email ||
    !password ||
    !passwordConfirmation ||
    invalidEmail ||
    passwordMismatch ||
    invalidPassword;

  return (
    <CaptchaForm
      captchaAction={CAPTCHA_ACTION_SIGN_UP}
      className={className}
      onClose={onClose}
      onSubmit={onSubmit}
      submit={submitSignup}
    >
      <div>
        <div className="c-form-field">
          <label htmlFor="firstname" className="c-form-field__label">
            <AccountFirstNameLabel />
          </label>
          <input
            autoFocus
            type="text"
            id="firstname"
            name="firstname"
            disabled={isLoading}
            className="c-form-field__input"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            data-test="signup-form.firstname-input"
          />
        </div>
        <div
          className={classnames('c-form-field', {
            'c-form-field-error': invalidEmail,
          })}
        >
          <label htmlFor="email" className="c-form-field__label">
            <AccountEmailLabel />
          </label>
          <input
            type="email"
            id="email"
            name="email"
            disabled={isLoading}
            className={classnames('c-form-field__input', {
              'c-form-field__input-error': invalidEmail,
            })}
            value={email}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
            data-test="signup-form.email-input"
          />
          <label
            htmlFor="email"
            className={classnames('c-form-field__label-error', {
              visible: invalidEmail,
            })}
            data-test="signup-form.email-not-valid-label"
          >
            <FormattedMessage
              id="account.signup.email-not-valid"
              defaultMessage="Please enter a valid email"
            />
          </label>
        </div>
        <PasswordInput
          id="password"
          value={password}
          onChange={onPasswordChange}
          onBlur={onPasswordBlur}
          disabled={isLoading}
          title={<AccountPasswordLabel />}
          inputError={invalidPassword}
          inputErrorText={<InvalidPassword />}
          data-test="signup-form.password"
        />
        <PasswordInput
          id="password-confirmation"
          value={passwordConfirmation}
          onChange={onPasswordConfirmationChange}
          onBlur={onPasswordBlur}
          disabled={isLoading}
          title={<AccountPasswordConfirmationLabel />}
          inputError={passwordMismatch}
          inputErrorText={<PasswordMismatch />}
          data-test="signup-form.password-confirmation"
        />
        <AcceptTerms />
        <button
          className={buttonClassName}
          disabled={isButtonDisabled}
          data-test="signup-form.submit-btn"
          type="submit"
        >
          <LoadingIndicator isLoading={isLoading} className="hideText" />
          <AccountJoin />
        </button>
      </div>
    </CaptchaForm>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    captchaType: selectCaptchaType(state),
  };
}

const dispatchProps: DispatchProps = {
  signup: authActions.signup,
};

export default connect(mapStateToProps, dispatchProps)(SignupForm);
