// @flow

import { handlePaginatedListRequest } from '../phasedActionHelpers';
import update from 'immutability-helper';
import { REQUEST, SUCCESS } from '../../middleware/api';

import type {
  LoadPersonalPlaylistsAction,
  ReloadPersonalPlaylistsAction,
  CreatePersonalPlaylistAction,
  DeletePersonalPlaylistAction,
} from '../../actions/personalPlaylist';

type Action =
  | LoadPersonalPlaylistsAction
  | ReloadPersonalPlaylistsAction
  | CreatePersonalPlaylistAction
  | DeletePersonalPlaylistAction;

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<number>,
|};

const initialState = {
  loading: false,
  loaded: true,
  loadedCursors: {},
  entities: [],
};

export default function personalPlaylists(state: State = initialState, action: Action) {
  const { phase } = action;
  switch (action.type) {
    case 'FETCH_PERSONAL_PLAYLISTS':
      return handlePaginatedListRequest(state, action);
    case 'RELOAD_PERSONAL_PLAYLISTS':
      switch (phase) {
        case REQUEST:
          return update(state, {
            loading: { $set: true },
            loaded: { $set: false },
            loadedCursors: { $set: {} },
            entities: { $set: [] },
          });
        default:
          return handlePaginatedListRequest(state, action);
      }
    case 'DELETE_PERSONAL_PLAYLIST':
    case 'CREATE_PERSONAL_PLAYLIST':
      switch (action.phase) {
        case REQUEST:
          return update(state, {
            loading: { $set: true },
            loaded: { $set: false },
          });
        case SUCCESS:
          return initialState;
        default:
          return state;
      }
    default:
      (action: empty);
      return state;
  }
}
