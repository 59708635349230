// @flow
// $FlowFixMe
import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';

import LoadingIndicator from '../common/LoadingIndicator';

import styles from './PaymentFormSubmitButton.css';
import Subscribe from '../messages/Subscribe';

type OwnProps = {
  isLoading: boolean,
  formIsReadyForSubmission: boolean,
};

type Props = OwnProps;

const PaymentFormSubmitButton = ({ isLoading, formIsReadyForSubmission }: Props) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (formIsReadyForSubmission) {
      buttonRef.current.focus();
    }
  }, [formIsReadyForSubmission]);

  const buttonClassName = classnames('c-btn c-btn--is-inverted', styles.button, {
    [styles.isLoading]: isLoading,
  });

  return (
    <React.Fragment>
      <button
        className={buttonClassName}
        disabled={!formIsReadyForSubmission || isLoading}
        ref={buttonRef}
        data-test="payment-form.submit-btn"
        type="submit"
      >
        <LoadingIndicator isLoading={isLoading} className={styles.loader} />

        <span className="payment-form__button-text">
          <Subscribe />
        </span>
      </button>
    </React.Fragment>
  );
};

export default PaymentFormSubmitButton;
