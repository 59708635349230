// @flow
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

type OwnProps = {
  startDate: Date,
  showTime?: boolean,
};

const UpcomingText = ({ startDate, showTime }: OwnProps) => {
  const upcomingDate = (
    <FormattedDate weekday="short" month="short" day="numeric" value={startDate} />
  );

  const upcomingTime = <FormattedTime timeZoneName="short" value={startDate} />;

  return (
    <span data-test="livestream-event-hero.timestamp" data-test-timestamp={startDate}>
      <FormattedMessage
        id="livestream-events.event-preview.upcoming-info"
        defaultMessage="Upcoming on {upcomingDate}"
        values={{ upcomingDate }}
      />
      {showTime && (
        <FormattedMessage
          id="livestream-events.event-preview.upcoming-info-time"
          defaultMessage="\u00a0at {upcomingTime}"
          values={{ upcomingTime }}
        />
      )}
    </span>
  );
};

export default UpcomingText;
