import request from '../lib/request';
import { connected, disconnected } from '../actions/connection';
import { addNotification, dismissNotification } from '../actions/notifications';
import { selectAuthApiBase } from '../selectors/client';
import { API_ERROR, OFFLINE } from '../lib/notifications';

const checkConnection = base => request({ url: base + '/test-connectivity', method: 'HEAD' });

const pollFrequency = 1e3;
async function poll(onConnection, base) {
  try {
    await checkConnection(base);
    onConnection();
  } catch (err) {
    setTimeout(() => {
      poll(onConnection, base);
    }, pollFrequency);
  }
}

export default store => next => async action => {
  const prevState = store.getState();
  const base = selectAuthApiBase(prevState);

  next(action);

  switch (action.type) {
    case 'NETWORK_ERROR':
      try {
        await checkConnection(base);
      } catch (err) {
        if (prevState.client.connected) {
          store.dispatch(disconnected());
        }
        break;
      }
      store.dispatch(addNotification(API_ERROR));
      break;
    case 'DISCONNECTED':
      poll(() => store.dispatch(connected()), base);
      store.dispatch(addNotification(OFFLINE));
      break;
    case 'CONNECTED':
      store.dispatch(dismissNotification(OFFLINE.topic));
      break;

    default:
  }
};
