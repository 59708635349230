import { createSelector } from 'reselect';

import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

import {
  AUDIO_QUALITY_LOW,
  AUDIO_QUALITY_PREVIEW,
  CREDIT_STRIPE_COUPONS,
  FROM_ID_CAMPAIGNS,
  SUBSCRIPTION_PLAN_FREE,
  SUBSCRIPTION_PLAN_PREMIUM,
  SUBSCRIPTION_PLAN_TRIAL_OPT_IN,
  SUBSCRIPTION_PLAN_TRIAL_OPT_OUT,
  DEFAULT_LOCALE,
  DEFAULT_AUTOPLAY,
  DEFAULT_THEME,
} from '../constants';
import { selectLocale, selectIsSupportedLocale } from './client';
import { splitLocale } from '../lib/locale-utils';
import { selectFeatureFlag } from './features';
import { find } from 'lodash';

export const selectUserIsAuthenticated = createSelector(
  [state => state.auth.isAuthenticated, state => state.me.loaded],
  (isAuthenticated, userDataIsLoaded) => isAuthenticated && userDataIsLoaded
);

export const selectRawUser = createSelector(
  [state => state.me.loaded, state => state.entities, state => state.me.entities],
  (loaded, allEntities, meEntities) =>
    loaded ? assembleEntity(Schema.User, allEntities, meEntities) : false
);

export const selectUser = createSelector(
  [selectUserIsAuthenticated, selectRawUser],
  (userIsAuthenticated, user) => (userIsAuthenticated && user ? user : null)
);

export const selectUserPlanStartDate = createSelector(selectUser, user =>
  user && user.plan_begins_at ? new Date(user.plan_begins_at * 1000 /* in ms */) : null
);

export const selectUserPlan = createSelector(selectUser, user => (user ? user.plan : null));

export const selectUserId = createSelector(selectUser, user => (user ? user.id : null));

export const selectUserPlanSubscriptionType = createSelector(selectUser, user =>
  user ? user.plan_subscription_type : null
);

export const selectUserStripeDiscount = createSelector(selectUser, user =>
  user ? user.stripe_discount : null
);

export const selectUserIsFree = createSelector(
  selectUserPlan,
  plan => plan === SUBSCRIPTION_PLAN_FREE
);

export const selectUserIsConcertTier = state =>
  selectFeatureFlag(state, 'gch').allow_concert_playback;

export const selectUserIsPremium = createSelector(
  selectUserPlan,
  plan => plan === SUBSCRIPTION_PLAN_PREMIUM
);

export const selectUserIsTrialOptOut = createSelector(
  selectUserPlan,
  plan => plan === SUBSCRIPTION_PLAN_TRIAL_OPT_OUT
);

export const selectUserIsTrialOptIn = createSelector(
  selectUserPlan,
  plan => plan === SUBSCRIPTION_PLAN_TRIAL_OPT_IN
);

export const selectUserIsExpiredOptOutTrial = createSelector(
  [selectUserIsAuthenticated, selectUserIsFree, selectUser],
  (isAuth, isFree, user) => isAuth && isFree && user.had_trial_opt_out
);

export const selectUserIsAllowedOptOutTrial = createSelector(
  [
    selectUserIsAuthenticated,
    selectUserIsFree,
    selectUserIsTrialOptIn,
    selectUserIsExpiredOptOutTrial,
  ],
  (isAuth, isFree, isTrialOptIn, wasOptOut) => isAuth && (isFree || isTrialOptIn) && !wasOptOut
);

export const selectUserIsPatron = createSelector(
  selectUserIsAuthenticated,
  selectUserIsFree,
  (isAuth, isFree) => isAuth && !isFree
);

export const selectUserCanSubscribe = createSelector(
  selectUserIsAuthenticated,
  selectUserIsFree,
  selectUserIsTrialOptIn,
  (isAuth, isFree, isTrialOptIn) => isAuth && (isFree || isTrialOptIn)
);

export const selectFacebookIsLinked = createSelector(selectUser, user =>
  user && Array.isArray(user.login_methods) ? user.login_methods.includes('facebook') : false
);

export function selectLocalePreference(state) {
  const user = selectUser(state);
  if (!user || !user.preferences) {
    return false;
  }

  return selectIsSupportedLocale(state, user.preferences.locale)
    ? user.preferences.locale
    : DEFAULT_LOCALE;
}

export function selectThemePreferences(state) {
  const user = selectUser(state);
  if (user) {
    return user.preferences.web_theme;
  }

  return DEFAULT_THEME;
}

export function selectAutoplayPreferences(state) {
  const user = selectUser(state);
  if (user) {
    return user.preferences.web_autoplay;
  }

  return DEFAULT_AUTOPLAY;
}

export function selectAudioQualityPreference(state) {
  if (selectUserIsAuthenticated(state)) {
    const user = selectUser(state);
    if (!user.preferences) {
      return AUDIO_QUALITY_LOW; // default
    }

    // The actual state read...
    const storedQuality = user.preferences.web_audio_quality;

    if (!storedQuality) {
      return AUDIO_QUALITY_LOW; // no quality set
    }

    return storedQuality;
  }

  return AUDIO_QUALITY_PREVIEW;
}

export const selectUserCredits = createSelector(selectUser, user =>
  user && user.credits ? user.credits : []
);

export function selectUserCreditById(state, creditId) {
  const credits = selectUserCredits(state);
  const credit = credits.find(item => item.id === creditId);

  return credit ? credit : null;
}

export function selectUserIsEligibleForOffer(state, offerId) {
  if (!selectUserIsAuthenticated(state)) {
    // can't check user that is not auth'd
    return false;
  }

  if (selectUserIsPatron(state)) {
    return false;
  }

  const credit = selectUserCreditById(state, offerId);

  if (!credit) {
    return false;
  }

  if (credit.redeemed_at) {
    // it was already redeemed
    return false;
  }

  if (credit.eligible_until) {
    const endDate = new Date(credit.eligible_until * 1000);
    const currentDate = new Date();
    if (endDate < currentDate) {
      // endDate is in the past
      return false;
    }
  }

  return true;
}

export const selectUserEligibleCredits = state =>
  selectUserCredits(state).filter(item => selectUserIsEligibleForOffer(state, item.id));

export const selectUserHasEligibleCredits = state => selectUserEligibleCredits(state).length > 0;

// This actually selects the first one, should be only one
export const selectUserActiveCredit = state => {
  const credits = selectUserEligibleCredits(state);
  return credits[0];
};

export function selectUserActiveCreditEffectOfType(state, type) {
  const activeCredit = selectUserActiveCredit(state);

  /*
    Hard coded since coupon credits currently don't
    contain any effects.
  */
  if (!!activeCredit) {
    const credit = find(CREDIT_STRIPE_COUPONS, { id: activeCredit.id });
    if (!!credit && type === 'StripeCoupon') {
      return credit;
    }
  }

  return activeCredit.effects.find(item => item.type === type) || false;
}

export function selectIsStudentStripeCoupon(state) {
  const credit = selectUserActiveCredit(state);
  return !!credit && credit.id === CREDIT_STRIPE_COUPONS.studentDiscounts.id;
}

export const selectUserIPCountry = createSelector(selectUser, user =>
  user ? user.last_ip_country : null
);

export const selectUserFromId = createSelector(selectUser, user => (user ? user.from_id : ''));

export const selectIsSonosFromId = createSelector(
  selectUserFromId,
  fromId => fromId === FROM_ID_CAMPAIGNS.sonos.fromId
);

export function selectUserLanguageIsClientLanguage(state) {
  if (!selectUserIsAuthenticated(state)) {
    return true;
  }

  const userLocale = selectLocalePreference(state);
  const clientLocale = selectLocale(state);
  const userLanguage = splitLocale(userLocale).language;
  const clientLanguage = splitLocale(clientLocale).language;
  return userLanguage === clientLanguage;
}

export const selectStripeStatus = createSelector(selectUser, user => user && user.stripe_status);

export function selectUserRequestedAt(state) {
  return state.me.requestedAt;
}
