import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from './Modal';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import { times, find } from 'lodash';
import { Link } from 'react-router';
import classnames from 'classnames';

import * as uiActions from '../../actions/ui';

import { selectOpenModalTypeIs, selectFreeCollectionModal } from '../../selectors/modals';
import { selectFeatureFlag } from '../../selectors/features';
import {
  selectAlbumCollection,
  selectPlaylistCollection,
  selectCollectionIds,
} from '../../selectors/collection';

import { createGetLimitedCollection } from '../collection/PlaylistCollection';

import Image from '../util/Image';
import List from '../util/List';

import styles from './FreeCollectionStatusModal.css';

import { ENTITY_TYPE_ALBUM, ENTITY_TYPE_PLAYLIST, IMGIX_PARAMS } from '../../constants';
import { compose } from 'redux';
import SubscribeNowButton from '../common/SubscribeNowButton';

const pluralMessages = defineMessages({
  [ENTITY_TYPE_ALBUM]: {
    id: 'collection-modal.albums',
    defaultMessage: 'albums',
  },
  [ENTITY_TYPE_PLAYLIST]: {
    id: 'collection-modal.curated-playlists',
    defaultMessage: 'curated playlists',
  },
});

const singularMessages = defineMessages({
  [ENTITY_TYPE_ALBUM]: {
    id: 'collection-modal.album',
    defaultMessage: 'album',
  },
  [ENTITY_TYPE_PLAYLIST]: {
    id: 'collection-modal.playlist',
    defaultMessage: 'playlist',
  },
});

class FreeCollectionStatusModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,

    collectionAccessLimit: PropTypes.number.isRequired,
    collectionLength: PropTypes.number.isRequired,
    collectionItemType: PropTypes.string.isRequired,
    limitedCollection: PropTypes.array.isRequired,
    collectionItem: PropTypes.object.isRequired,

    intl: intlShape.isRequired,
  };

  onSubscribeClick = () => {
    this.props.hideModal();
  };

  renderCollectionItem = (collectionItem, i) => {
    return (
      <li
        key={collectionItem ? collectionItem.id : i}
        className={styles.collectionItem}
        data-test={
          collectionItem ? 'free-collection-modal-item' : 'free-collection-modal-item-placeholder'
        }
      >
        <Image
          noBase={!!collectionItem}
          src={collectionItem ? collectionItem.imageUrl : ''}
          width={48}
          height={48}
          auto={IMGIX_PARAMS}
          className={styles.bg}
          alt=""
        />
      </li>
    );
  };

  renderHeader() {
    const {
      collectionAccessLimit,
      collectionLength,
      collectionItem,
      collectionItemType,
      limitedCollection,
      intl,
    } = this.props;

    if (collectionLength === 1) {
      return (
        <span data-test="collection-modal.first-collection-addition-success-message">
          <FormattedMessage
            id="collection-modal.header.first-item"
            defaultMessage="“{title}” was saved to your Collection."
            values={{ title: collectionItem.title }}
          />
        </span>
      );
    }

    if (collectionLength < collectionAccessLimit) {
      return (
        <span data-test="collection-modal.num-text" data-test-length={collectionLength}>
          <FormattedMessage
            id="collection-modal.header.subsequent"
            defaultMessage="Your free collection has {count} {resourceName}."
            values={{
              count: collectionLength,
              resourceName: intl.formatMessage(pluralMessages[collectionItemType]),
            }}
          />
        </span>
      );
    }

    const itemWasAdded = find(limitedCollection, { id: collectionItem.id });

    if (itemWasAdded) {
      return (
        <span data-test="collection-modal.limit-reached-message">
          <FormattedMessage
            id="collection-modal.header.collection-full"
            defaultMessage="Your free {resourceName} Collection is full."
            values={{
              resourceName: intl.formatMessage(singularMessages[collectionItemType]),
            }}
          />
        </span>
      );
    }

    return (
      <FormattedMessage
        id="collection-modal.header.subscribe-to-save"
        defaultMessage="Subscribe to save “{title}”."
        values={{ title: collectionItem.title }}
      />
    );
  }

  renderSubtitle() {
    const { collectionAccessLimit, collectionLength, collectionItemType, intl } = this.props;
    const resourceName = intl.formatMessage(pluralMessages[collectionItemType]);

    if (collectionLength < collectionAccessLimit) {
      return (
        <FormattedMessage
          id="collection-modal.subtitle.collection-limit-message"
          defaultMessage="You can save up to {limit} {resourceName} for free. Subscribe for an unlimited collection."
          values={{
            limit: collectionAccessLimit,
            resourceName,
          }}
        />
      );
    }
    return (
      <span data-test="collection-modal.collection-size-exceeded-message">
        <FormattedMessage
          id="collection-modal.subtitle.collection-size-exceeded-message"
          defaultMessage="When you use IDAGIO for free, you can save up to {limit} {resourceName}. Subscribe for an unlimited collection."
          values={{
            limit: collectionAccessLimit,
            resourceName,
          }}
        />
      </span>
    );
  }

  renderViewCollectionButton(isFull) {
    const { intl, collectionItemType } = this.props;

    const classNames = classnames(styles.collectionButton, 'c-btn', {
      'c-btn--is-purple': !isFull,
      'c-btn--has-outline': isFull,
    });

    return (
      <Link
        className={classNames}
        to={`/collection/${collectionItemType === ENTITY_TYPE_PLAYLIST ? 'playlists' : 'albums'}`}
        onClick={this.props.hideModal}
        data-test="collection-modal.view-collection-btn"
      >
        <FormattedMessage
          id="collection-modal.button.view"
          defaultMessage="View {resourceName} collection"
          values={{
            resourceName: intl.formatMessage(singularMessages[collectionItemType]),
          }}
        />
      </Link>
    );
  }

  render() {
    const { isOpen, hideModal, collectionLength, collectionAccessLimit } = this.props;

    const isFull = collectionLength >= collectionAccessLimit;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={hideModal}
        onClose={hideModal}
        contentLabel="free-collection-status-modal"
      >
        <section className={styles.component} data-test="free-collection-modal">
          <List
            renderItem={this.renderCollectionItem}
            items={this.props.limitedCollection}
            className={styles.collectionItems}
          />
          <h1 className="fz--gamma">{this.renderHeader()}</h1>
          <p className={styles.subTitle}>{this.renderSubtitle()}</p>
          {this.subscribeNowButton(isFull)}
          {this.renderViewCollectionButton(isFull)}
        </section>
      </Modal>
    );
  }

  subscribeNowButton(isFull) {
    if (isFull) {
      return (
        <SubscribeNowButton
          className={classnames(styles.subNowButton, 'c-btn', 'c-btn--is-purple')}
          onClick={this.onSubscribeClick}
          trackingContext={{ trigger: 'freeCollectionModal' }}
          data-test="collection-modal.sub-now-btn"
        />
      );
    }
    return null;
  }
}

const getLimitedCollection = createGetLimitedCollection();

const getCollectionSelector = collectionItemType => {
  switch (collectionItemType) {
    case ENTITY_TYPE_PLAYLIST:
      return selectPlaylistCollection;
    default:
      return selectAlbumCollection;
  }
};

const getCollectionLength = (collectionItemType, collectionIds) => {
  switch (collectionItemType) {
    case ENTITY_TYPE_PLAYLIST:
      return collectionIds.entities.playlistIds.length;
    default:
      return collectionIds.entities.albumIds.length;
  }
};

function mapStateToProps(state) {
  const collectionAccessLimit = selectFeatureFlag(state, 'collections').access_limit;
  const { collectionItem, collectionItemType } = selectFreeCollectionModal(state);
  const selectCollection = getCollectionSelector(collectionItemType);

  const collection = selectCollection(state);
  const limitedCollection = getLimitedCollection(collection, collectionAccessLimit);
  const limitedCollectionIsFull = limitedCollection.length === collectionAccessLimit;

  const collectionIds = selectCollectionIds(state);
  const collectionLength = getCollectionLength(collectionItemType, collectionIds);

  // Fill out collection limit with placeholders
  const emptySpots = collectionAccessLimit - limitedCollection.length;
  times(emptySpots, () => limitedCollection.push(null));

  return {
    isOpen: selectOpenModalTypeIs(state, 'FREE_COLLECTION_STATUS_MODAL'),
    collectionAccessLimit: selectFeatureFlag(state, 'collections').access_limit,
    freeCollectionModal: selectFreeCollectionModal(state),
    limitedCollection,
    collectionLength,
    limitedCollectionIsFull,
    collectionItem, // item to be added
    collectionItemType,
  };
}

export default compose(
  connect(mapStateToProps, {
    hideModal: uiActions.hidePersonalPlaylistModal,
  }),
  injectIntl
)(FreeCollectionStatusModal);
