import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import IconLabel from './../util/IconLabel';
import CapacitorRipple from '../capacitor/Ripple';
import LoadingIndicator from './LoadingIndicator';

import styles from './PlayButton.css';
import radioStyles from './PlayRadioButton.css';

export default class PlayButton extends PureComponent {
  static propTypes = {
    'onClick': PropTypes.func,
    'playing': PropTypes.bool,
    'loading': PropTypes.bool,
    'className': PropTypes.string,
    'iconClassName': PropTypes.string,
    'pausedTitle': PropTypes.string,
    'playingTitle': PropTypes.string,
    'moduleVariant': PropTypes.string,
    'labelVisible': PropTypes.bool,
    'size': PropTypes.string,
    'tabIndex': PropTypes.number,
    'isRadio': PropTypes.bool,
    'data-test': PropTypes.string,
  };

  static defaultProps = {
    pausedTitle: 'Play All',
    playingTitle: 'Pause',
  };

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.playing);
    }
  };

  getIconName = () => {
    const { isRadio, playing } = this.props;

    if (isRadio && playing) {
      return [
        {
          name: 'radio-pause-1',
          className: radioStyles.iconPause1,
        },
        {
          name: 'radio-pause-2',
          className: radioStyles.iconPause2,
        },
        {
          name: 'radio-pause-3',
          className: radioStyles.iconPause3,
        },
      ];
    }

    return `${isRadio ? 'radio-' : ''}${playing ? 'pause' : 'play'}`;
  };

  render() {
    const {
      playing,
      loading,
      className,
      pausedTitle,
      playingTitle,
      moduleVariant,
      labelVisible,
      size,
      iconClassName,
      tabIndex,
      'data-test': dataTest,
    } = this.props;

    const buttonClassNames = classnames(styles.component, className, {
      'is-playing': playing,
      'is-loading': loading,
    });

    const title = playing ? playingTitle : pausedTitle;

    const iconClassNames = classnames(styles.icon, iconClassName);

    return (
      <button
        className={buttonClassNames}
        onClick={loading ? null : this.onClick}
        data-module-variant={moduleVariant}
        tabIndex={tabIndex}
        data-test={dataTest}
      >
        {__CAPACITOR__ && (
          <div className={styles.rippleContainer}>
            <CapacitorRipple version="center" />
          </div>
        )}
        {loading ? (
          <LoadingIndicator isLoading={loading} />
        ) : (
          <IconLabel
            name={this.getIconName()}
            className={iconClassNames}
            title={title}
            labelVisible={labelVisible}
            size={size}
          />
        )}
      </button>
    );
  }
}
