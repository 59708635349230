// @flow
import { selectTrack } from '../selectors/track';
import { mp3Resolver, m3u8Resolver } from '../client/trackUrlResolver';
import {
  AUDIO_QUALITY_LOSSLESS,
  QUEUE_TYPE_PLAYLIST,
  QUEUE_TYPE_ALBUM,
  IMAGES_BASE,
} from '../constants';

import type { SonosGroupsArray } from '../components/player/SonosGroups';
import type { SonosGroup } from '../components/player/SonosGroup';
import type { Request, ThunkAction } from './types';
import type { Track, SonosTrack } from '../shapes/types';

import { selectAccessToken } from '../selectors/auth';
import { selectElectronClientVersion, selectDeviceId, selectIsFirefox } from '../selectors/client';
import { selectSonosIsConnected } from '../selectors/sonos';
import { selectPlayerQueueOrigin } from '../selectors/player';
import { selectPlaylist } from '../selectors/playlist';
import { selectAlbum } from '../selectors/album';
import { selectQueueOriginCompoundId } from '../selectors/queue';

import { SonosQueue } from '../schema/index';

type ReplaceSonosGroupsAction = {
  type: 'REPLACE_SONOS_GROUPS',
  groups: SonosGroupsArray,
};

type PickSonosGroupAction = {
  type: 'PICK_SONOS_GROUP',
  group: SonosGroup,
};

export type SetConnectedSonosGroupAction = {
  type: 'SET_CONNECTED_SONOS_GROUP',
  groupId: string,
};

type SetSonosSessionIdAction = {
  type: 'SET_SONOS_SESSION_ID',
  sessionId: string,
};

export type SetSonosQueueAction = {
  type: 'SET_SONOS_QUEUE',
  tracks: Array<SonosTrack>,
} & Request;

type SetSonosQueueLoadedAction = {
  type: 'SET_SONOS_QUEUE_LOADED',
};

type UpdateSonosGroupNameAction = {
  type: 'UPDATE_SONOS_GROUP_NAME',
  groupName: string,
};

export type SonosAction =
  | ReplaceSonosGroupsAction
  | PickSonosGroupAction
  | SetConnectedSonosGroupAction
  | SetSonosQueueAction
  | SetSonosSessionIdAction
  | SetSonosQueueLoadedAction
  | UpdateSonosGroupNameAction;

export function replaceSonosGroups(groups: SonosGroupsArray): ReplaceSonosGroupsAction {
  return {
    type: 'REPLACE_SONOS_GROUPS',
    groups,
  };
}

export function pickSonosGroup(group: SonosGroup): PickSonosGroupAction {
  return {
    type: 'PICK_SONOS_GROUP',
    group,
  };
}

export type PickSonosGroup = typeof pickSonosGroup;

export function setConnectedSonosGroup(groupId: string): SetConnectedSonosGroupAction {
  return {
    type: 'SET_CONNECTED_SONOS_GROUP',
    groupId,
  };
}

const createTrackToSonosTrack = (state: Object, quality: number) => {
  const trackResolver = quality === AUDIO_QUALITY_LOSSLESS ? mp3Resolver : m3u8Resolver;
  const contentType = quality === AUDIO_QUALITY_LOSSLESS ? 'audio/flac' : 'application/x-mpegURL';
  const accessToken = selectAccessToken(state);
  const clientVersion = selectElectronClientVersion(state);
  const deviceId = selectDeviceId(state);
  const sonosIsConnected = selectSonosIsConnected(state);
  const isFirefox = selectIsFirefox(state);

  return async (trackId: string | number): Promise<SonosTrack> => {
    const stringId = trackId.toString();
    const track: Track = selectTrack(state, stringId, true);

    const queueOrigin = selectPlayerQueueOrigin(state);

    let imageUrl = `${IMAGES_BASE}/artists/${track.recording.work.composer.id}/main.jpg?dpr=1&w=384&h=384&fit=crop&crop=faces`;
    if (queueOrigin.type === QUEUE_TYPE_PLAYLIST) {
      const playlist = selectPlaylist(state, queueOrigin.id);
      imageUrl = `${playlist.imageUrl}?auto=format&dpr=1&fit=crop&w=384&h=384`;
    }

    if (queueOrigin.type === QUEUE_TYPE_ALBUM) {
      const album = selectAlbum(state, queueOrigin.id);
      imageUrl = `${album.imageUrl}?auto=format&dpr=1&fit=crop&w=384&h=384`;
    }

    const trackUrl = await trackResolver(
      stringId,
      quality,
      accessToken,
      clientVersion,
      deviceId,
      sonosIsConnected,
      isFirefox
    );
    return {
      id: stringId,
      deleted: false,
      track: {
        name: track.piece.title,
        album: {
          name: track.recording.work.title,
        },
        artist: {
          name: track.recording.work.composer.name,
        },
        mediaUrl: trackUrl,
        contentType,
        durationMillis: track.duration * 1000,
        imageUrl,
        type: 'track',
      },
    };
  };
};

export function setSonosQueue(
  tracks: Array<string | number>,
  quality: number = AUDIO_QUALITY_LOSSLESS
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const unResolvedTracks = tracks.map(createTrackToSonosTrack(state, quality));
    const sonosTracks = await Promise.all(unResolvedTracks);
    const cacheId = selectQueueOriginCompoundId(state);
    return dispatch({
      type: 'SET_SONOS_QUEUE',
      tracks: sonosTracks,
      IDAGIO_REQUEST: {
        type: 'API_REQUEST',
        method: 'POST',
        endpoint: '/v2/sonos/cloud_queue',
        body: {
          tracks: sonosTracks,
        },
      },
      meta: {
        restricted: true,
        schema: SonosQueue,
        normalize: result => {
          return {
            id: cacheId,
            cloudQueueId: result.id,
            tracks: sonosTracks,
          };
        },
      },
      cacheId,
    });
  };
}

export function setSonosSessionId(sessionId: string): SetSonosSessionIdAction {
  return {
    type: 'SET_SONOS_SESSION_ID',
    sessionId,
  };
}

export function updateSonosGroupName(groupName: string): UpdateSonosGroupNameAction {
  return {
    type: 'UPDATE_SONOS_GROUP_NAME',
    groupName,
  };
}
