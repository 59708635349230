// @flow
import React from 'react';
import IconLabel from '../util/IconLabel';
import styles from './PlanBenefit.css';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  'title': React$Element<typeof FormattedMessage>,
  'description': React$Element<typeof FormattedMessage>,
  'data-test'?: string,
  'data-test-value'?: string | number,
};

const PlanBenefit = ({
  title,
  description,
  'data-test': dataTest,
  'data-test-value': dataTestValue,
}: OwnProps) => (
  <div className={styles.container} data-test={dataTest} data-test-value={dataTestValue}>
    <div className={styles.heading}>
      <IconLabel name="check" className={styles.icon} size="small" />
      <span className={styles.title}>{title}</span>
    </div>
    <p className={styles.description}>{description}</p>
  </div>
);

export default PlanBenefit;
