// @flow
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as uiActions from '../../actions/ui';

import SignupStep from '../premium/SignupStep';

type OwnProps = {
  fromId: string,
  onComplete?: Function,
};

type DispatchProps = {
  showModal: Function,
};

type Props = OwnProps & DispatchProps;

const PreviewsModalRightPane = ({ fromId, onComplete, showModal }: Props) => {
  const onClickLoginLink = event => {
    event.preventDefault();
    showModal('LOGIN_MODAL', { trigger: 'previewsModal' });
  };

  const onSignupSuccess = () => {
    if (onComplete) {
      onComplete();
    }
  };

  return (
    <SignupStep
      expanded
      fromId={fromId}
      onSignupSuccess={onSignupSuccess}
      onClickLoginLink={onClickLoginLink}
      subheader={
        <FormattedMessage
          id="previews-modal.signup.subheader"
          defaultMessage="Create an account for free to get the most out of IDAGIO."
        />
      }
    />
  );
};

const dispatchProps: DispatchProps = {
  showModal: uiActions.showModal,
};

export default connect(null, dispatchProps)(PreviewsModalRightPane);
