// @flow
import { handleListRequest } from '../phasedActionHelpers';

import type { LoadInstrumentsAction } from '../../actions/instrument';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
|};

const initialState = {
  loading: false,
  loaded: false,
};

export default function instruments(state: State = initialState, action: LoadInstrumentsAction) {
  if (action.type === 'FETCH_INSTRUMENTS') {
    return handleListRequest(state, action, result => {
      return result;
    });
  }
  (action: empty);
  return state;
}
