import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import stylesCollection from '../../views/Collection.css';

const CollectionArchiveHeader = ({ children }) => {
  return (
    <div className={stylesCollection.archiveInfo}>
      <h1 className="fz--gamma">{children}</h1>
      <p>
        <FormattedMessage
          id="collection.access.subscribe"
          defaultMessage="Subscribe to access your entire Collection."
        />
      </p>
    </div>
  );
};

CollectionArchiveHeader.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CollectionArchiveHeader;
