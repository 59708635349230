// @flow

import React from 'react';
import Image from '../util/Image';
import classnames from 'classnames';

import styles from './ArtistAvatar.css';
import { IMAGES_BASE, IMGIX_PARAMS } from '../../constants';
import ContentfulImage from '../util/ContentfulImage';

type SharedProps = {
  className: string,
  size: number,
};

type ImageSrcProps = {
  src?: string, // contentful images
};

type ProfileIdProps = {
  profileId?: number, // idagio service images
};

type OwnProps = SharedProps & ImageSrcProps & ProfileIdProps;

type Props = OwnProps;

const ArtistAvatar = ({ profileId, src, className, size = 160 }: Props) => {
  const classNames = classnames(className, styles.avatar);

  if (profileId) {
    return (
      <Image
        src={`/artists/${profileId}/main.jpg`}
        auto={IMGIX_PARAMS}
        width={size}
        height={size}
        className={classNames}
        alt=""
      />
    );
  }

  if (src && !src.startsWith(IMAGES_BASE)) {
    return (
      <ContentfulImage
        src={src}
        className={classNames}
        options={{
          fit: 'thumb',
          f: 'face',
          h: size,
          w: size,
        }}
      />
    );
  }

  return null;
};

export default ArtistAvatar;
