// @flow
// $FlowFixMe
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './ContentfulImage.css';
import Qs from 'qs';

type OwnProps = {
  src: string,
  className?: string,
  srcSet?: string,
  options?: Object,
};

const ContentfulImage = ({ src, className, options, ...props }: OwnProps) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  const imageUrl = options ? `${src}?${Qs.stringify(options)}` : src;

  const onLoad = () => setLoaded(true);

  useEffect(() => {
    const imgElement = imgRef.current;
    if (!imgElement) {
      return null;
    }

    if (imgElement.complete) {
      onLoad();
    } else {
      imgElement.addEventListener('load', onLoad);
      imgElement.addEventListener('error', onLoad);
    }

    return () => {
      imgElement.removeEventListener('load', onLoad);
      imgElement.removeEventListener('error', onLoad);
    };
  }, []);

  return (
    <img
      {...props}
      ref={imgRef}
      src={imageUrl}
      role="img"
      className={classNames(className, styles.image, {
        [styles.loaded]: loaded,
      })}
    />
  );
};

export default ContentfulImage;
