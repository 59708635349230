// @flow
import { selectNAdsToPlayQueue } from '../selectors/ads';
import type { Request, ThunkAction } from './types';
import type { Ad } from '../shapes/types';
import { AdsList } from '../schema/index';
import { loaded } from '../middleware/apiCacheValidators';
import { normalizeMetaList } from '../schema/normalizeMeta';

export type LoadAdsAction = { type: 'FETCH_ADS' } & Request;

export type EndedAdPlayback = { type: 'ENDED_AD_PLAYBACK', adId: string };
export type StartedAdPlayback = {
  +type: 'STARTED_AD_PLAYBACK',
  +adId: string,
  +ad: Ad,
};
export type AdPlaybackProgress = {
  +type: 'AD_PLAYBACK_PROGRESS',
  +value: number,
  +duration: number,
  +adId: string,
};

export type SetAdQueueAndPlayAction = {
  type: 'SET_AD_QUEUE_AND_PLAY',
  ads: Array<Ad>,
};

export type PlaybackAdClicked = {
  type: 'PLAYBACK_AD_CLICKED',
  ad: Ad,
};

export type ResetAdQueueAction = {
  type: 'RESET_AD_QUEUE',
};

export function loadAds(): LoadAdsAction {
  return {
    type: 'FETCH_ADS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/interruptions.v1',
    },
    meta: {
      schema: AdsList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.lists.ads,
      validate: loaded,
    },
  };
}

export function endedAdPlayback(adId: string): EndedAdPlayback {
  return {
    type: 'ENDED_AD_PLAYBACK',
    adId,
  };
}

export function startedAdPlayback(adId: string, ad: Ad): StartedAdPlayback {
  return {
    type: 'STARTED_AD_PLAYBACK',
    adId,
    ad,
  };
}

export function adPlaybackProgress(
  value: number,
  duration: number,
  adId: string
): AdPlaybackProgress {
  return {
    type: 'AD_PLAYBACK_PROGRESS',
    duration,
    value,
    adId,
  };
}

export function setAdQueueAndPlay(ads: Array<Ad>): SetAdQueueAndPlayAction {
  return {
    type: 'SET_AD_QUEUE_AND_PLAY',
    ads,
  };
}

export function playAdsNext(count: Number): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const ads: Array<Ad> = selectNAdsToPlayQueue(state, count);
    dispatch(setAdQueueAndPlay(ads));
  };
}

export function clickedAd(ad: Ad): PlaybackAdClicked {
  return {
    type: 'PLAYBACK_AD_CLICKED',
    ad,
  };
}

export function resetAdQueue(): ResetAdQueueAction {
  return {
    type: 'RESET_AD_QUEUE',
  };
}
