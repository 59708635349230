// @flow

import { handlePaginatedListRequest } from '../phasedActionHelpers';

import type { LoadSoloistsAction } from '../../actions/soloist';

export type State = {|
  +loading: boolean,
  +loaded: boolean,
  +loadedCursors: { [key: string]: boolean },
  +entities: Array<number>,
|};

const initialState = {
  loading: false,
  loaded: false,
  loadedCursors: {},
  entities: [],
};

export default function soloists(state: State = initialState, action: LoadSoloistsAction) {
  if (action.type === 'FETCH_SOLOISTS') {
    return handlePaginatedListRequest(state, action);
  }

  (action: empty);
  return state;
}
