import React from 'react';
import IconLabel from '../util/IconLabel';
import CapacitorRipple from './Ripple';
import styles from './BackButton.css';

const CapacitorBackButton = () => {
  const onClick = () => {
    window.EventChannelPlugin.simulateAndroidBackPress();
  };

  return (
    <button onClick={onClick} className={styles.button}>
      <CapacitorRipple version="center" />
      <IconLabel name="arrow-left" className={styles.icon} title="Back" size="medium" />
    </button>
  );
};

CapacitorBackButton.propTypes = {};

export default CapacitorBackButton;
