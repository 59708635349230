/* global Stripe */

// @flow
// $FlowFixMe
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { defineMessages } from 'react-intl';

import { injectStripe } from 'react-stripe-elements';

import SEPAForm from './SEPAForm';
import SEPANotice from './SEPANotice';
import CardForm from './CardForm';
import PaymentFormSubmitButton from './PaymentFormSubmitButton';
import PaymentMethods from './PaymentMethods';

import * as notificationsActions from '../../actions/notifications';
import * as subscriptionActions from '../../actions/subscription';
import * as stripeAction from '../../actions/stripe';
import * as analyticsActions from '../../actions/analytics';

import { STRIPE_REVALIDATION_ERROR } from '../../lib/notifications';

import {
  SUBSCRIPTION_TRIAL_DURATION_DAYS,
  SEPA_PAYMENT,
  CARD_PAYMENT,
  CAPTCHA_ACTION_PAYMENT,
} from '../../constants';

import { selectSepaIsSupported, selectCurrency, selectCaptchaType } from '../../selectors/client';

import StripeErrorMessages from '../../lib/stripe-error-messages';

import styles from './PaymentForm.css';
import CaptchaForm from '../common/CaptchaForm';
import classNames from 'classnames';
import { selectIsTrialAvailable } from '../../selectors/subscription';

const PAYMENT_TYPE_TO_EVENT = {
  SEPA_PAYMENT: 'Selected SEPA Payment Method',
  CARD_PAYMENT: 'Selected Card Payment Method',
};

const messages = defineMessages({
  emptyError: {
    id: 'account.payment.empty-error',
    defaultMessage: 'Please fill out all fields before you proceed',
  },
  generalError: {
    id: 'stripe-errors.general',
    defaultMessage: 'Sorry, something went wrong.',
  },
});

const SUPPORTED_PAYMENT_METHODS = [CARD_PAYMENT];

function getStripePlanId(plan, currency) {
  // Stripe id is the plan id + currency suffix e.g 'ppc-6m-cad'
  if (currency === 'CAD') {
    return `${plan}-cad`;
  }

  if (currency === 'USD') {
    return `${plan}-usd`;
  }

  return `${plan}${plan !== 'premium' ? '-eur' : ''}`;
}

type OwnProps = {
  isTrialOptOut: boolean,
  trialDuration: number,
  onSuccess?: Function,
  onPaymentTypeChange: Function,
  isInModal: boolean,
  initialPaymentType?: string,
  selectedPlan: string,
  userIsAllowedOptOutTrial: boolean,
  stripe: {
    handleCardSetup: Function,
    createSource: Function,
  },
};

type MapStateToProps = {
  sepaIsSupported: boolean,
  currency: string,
  captchaType: string,
};

type DispatchProps = {
  subscribeToPlan: Function,
  addNotification: Function,
  dismissNotification: Function,
  handleStripeCardAction: Function,
  loadStripePaymentIntentToken: Function,
  track: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const PaymentForm = ({
  subscribeToPlan,
  addNotification,
  dismissNotification,
  isTrialOptOut = true,
  trialDuration = SUBSCRIPTION_TRIAL_DURATION_DAYS,
  onSuccess,
  userIsAllowedOptOutTrial,
  onPaymentTypeChange = noop,
  isInModal,
  sepaIsSupported,
  initialPaymentType,
  selectedPlan,
  stripe,
  currency,
  captchaType,
  handleStripeCardAction,
  loadStripePaymentIntentToken,
  track,
}: Props) => {
  const getInitialPaymentTypeState = () => {
    if (initialPaymentType) {
      return initialPaymentType;
    }

    if (sepaIsSupported) {
      return SEPA_PAYMENT;
    }

    return CARD_PAYMENT;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(getInitialPaymentTypeState());
  const [SEPAOwnerName, setSEPAOwnerName] = useState('');
  const [cardFormIsReadyForSubmission, setCardFormIsReadyForSubmission] = useState(false);

  const showError = useCallback(
    message => {
      addNotification({
        message,
        severity: 'error',
        persistent: true,
        topic: 'subscription',
      });
    },
    [addNotification]
  );

  useEffect(() => {
    const isSEPAPayment = paymentType === SEPA_PAYMENT;

    if (isLoading && isSEPAPayment && !SEPAOwnerName) {
      setIsLoading(false);
      showError(messages.emptyError);
      return;
    }
  }, [isLoading, SEPAOwnerName, paymentType, showError]);

  const onSubmit = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
  };

  const onPaymentMethodChange = newPaymentType => {
    track(PAYMENT_TYPE_TO_EVENT[newPaymentType]);
    if (isLoading) {
      return;
    }

    onPaymentTypeChange(newPaymentType);
    setPaymentType(newPaymentType);
  };

  const shouldOpenCaptchaOnSubmit = () => {
    const isSEPAPayment = paymentType === SEPA_PAYMENT;
    if (isSEPAPayment && !SEPAOwnerName) {
      return false;
    }

    return cardFormIsReadyForSubmission;
  };

  const showSubscriptionError = errorKey => {
    const errorMessage = StripeErrorMessages.hasOwnProperty(errorKey)
      ? StripeErrorMessages[errorKey]
      : messages.generalError;

    showError(errorMessage);
  };

  const handleApiErrors = error => {
    let errorKey = null;
    if (error && error.context && error.context.decline_code) {
      errorKey = error.context.decline_code;
    }
    showSubscriptionError(errorKey);
  };

  const doSubscribeToPlan = async (token, isSEPAPayment) => {
    const days = userIsAllowedOptOutTrial && isTrialOptOut ? trialDuration : undefined;
    const stripePlanId = getStripePlanId(selectedPlan, currency);

    try {
      await subscribeToPlan(token, days, isSEPAPayment, stripePlanId, isInModal);
      if (onSuccess) {
        onSuccess(selectedPlan, stripePlanId, currency);
      }
    } catch (err) {
      handleApiErrors(err.body);
      setIsLoading(false);
    }
  };

  const submitPaymentRequest = async captchaToken => {
    dismissNotification('stripe');

    const isSEPAPayment = paymentType === SEPA_PAYMENT;

    if (isSEPAPayment) {
      const stripeResponse = await stripe.createSource({
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: SEPAOwnerName,
        },
      });
      if (stripeResponse.error) {
        setIsLoading(false);
      } else {
        doSubscribeToPlan(stripeResponse.source.id, isSEPAPayment);
      }
      return;
    }

    const token = await loadStripePaymentIntentToken(captchaType, captchaToken);

    // Stripe element data is automatically inferred by HOC
    const stripeResponse = await handleStripeCardAction(stripe.handleCardSetup, token);
    if (stripeResponse.error) {
      setIsLoading(false);
      addNotification({
        ...STRIPE_REVALIDATION_ERROR,
        onClick: submitPaymentRequest,
      });
    } else {
      const paymentMethod = stripeResponse.setupIntent.payment_method;
      doSubscribeToPlan(paymentMethod, isSEPAPayment);
    }
  };

  const PaymentBody = () => {
    if (paymentType === SEPA_PAYMENT) {
      return (
        <SEPAForm isInModal={isInModal} onChangeName={e => setSEPAOwnerName(e.target.value)} />
      );
    }

    return (
      <CardForm
        isInModal={isInModal}
        setCardFormIsReadyForSubmission={setCardFormIsReadyForSubmission}
      />
    );
  };

  const MemoizedPaymentBody = useMemo(() => <PaymentBody />, []);

  const isSEPAPayment = paymentType === SEPA_PAYMENT;

  return (
    <CaptchaForm
      captchaAction={CAPTCHA_ACTION_PAYMENT}
      shouldOpenCaptchaOnSubmit={shouldOpenCaptchaOnSubmit}
      onSubmit={onSubmit}
      onClose={onClose}
      submit={submitPaymentRequest}
      className={classNames(styles.body)}
    >
      <div className={styles.paymentContainer}>
        {SUPPORTED_PAYMENT_METHODS.length > 1 && (
          <PaymentMethods
            sepaIsSupported={sepaIsSupported}
            current={paymentType}
            onMethodChange={onPaymentMethodChange}
          />
        )}
        <div className={styles.paymentBody}>{MemoizedPaymentBody}</div>
        {isSEPAPayment && <SEPANotice />}
        <PaymentFormSubmitButton
          isLoading={isLoading}
          trialDuration={trialDuration}
          formIsReadyForSubmission={cardFormIsReadyForSubmission}
          userIsAllowedOptOutTrial={userIsAllowedOptOutTrial}
        />
      </div>
    </CaptchaForm>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isTrialAvailable: selectIsTrialAvailable(state),
    sepaIsSupported: selectSepaIsSupported(state),
    currency: selectCurrency(state),
    captchaType: selectCaptchaType(state),
  };
}

const dispatchProps: DispatchProps = {
  subscribeToPlan: subscriptionActions.subscribeToPlan,
  addNotification: notificationsActions.addNotification,
  dismissNotification: notificationsActions.dismissNotification,
  handleStripeCardAction: stripeAction.handleStripeCardAction,
  loadStripePaymentIntentToken: stripeAction.loadStripePaymentIntentToken,
  track: analyticsActions.track,
};

export { PaymentForm as PurePaymentForm };

export default compose(connect(mapStateToProps, dispatchProps), injectStripe)(PaymentForm);
