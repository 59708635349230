// @flow
import { handleMapRequest } from '../phasedActionHelpers';

import type { LoadAllFacetsAction } from '../../actions/facet';

export type State = {
  +[key: string]: {|
    +entities: Array<Object>,
    +loaded: boolean,
    +loading: boolean,
    +meta: { +count: number },
  |},
};

const initialState = {};

export default function albumFacets(
  state: State = initialState,
  action: LoadAllFacetsAction
): State {
  if (action.type === 'FETCH_ALL_ALBUM_FACETS' && action.cacheId) {
    return handleMapRequest(state, action, action.cacheId);
  }
  return state;
}
