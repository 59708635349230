import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { MiscActionsPlugin } from '../../capacitor-connector';

import IconLabel from '../util/IconLabel';

import styles from './Notification.css';

const dismissTimeout = 2e3;

class Notification extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    message: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    severity: PropTypes.string.isRequired,
    persistent: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
    values: PropTypes.object,
    intl: intlShape,
    learnMoreAction: PropTypes.func,
    onClick: PropTypes.func,
  };

  componentDidMount() {
    if (__CAPACITOR__) {
      // Obviously it would be cleaner to directly send the message to android
      // in the capacitor middleware. Problem is, we can't access the intl
      // object in a middleware, see
      // https://github.com/formatjs/formatjs/issues/416
      // So instead, the best way is probably to immediately send the
      // translated message to android as soon as the component mounts, then
      // immediately unmount it again, and also don't render anything in the
      // meantime.
      MiscActionsPlugin.androidShouldDisplayNativeMessage({
        message: this.getMessageValues(),
        // Android only knows error and info types
        type: this.props.severity === 'error' ? 'error' : 'info',
      });
      this.dismiss();
      return;
    }
    if (!this.props.persistent) {
      setTimeout(this.dismiss, dismissTimeout);
    }
  }

  getMessageValues = () => {
    const { message, intl, values } = this.props;
    return message.id ? intl.formatMessage(message, values) : '';
  };

  renderMessage = () => {
    const { id, message, onClick, learnMoreAction } = this.props;

    return (
      <div className={classnames('message', styles.container)}>
        {onClick && (
          <button onClick={onClick} className="dummy-btn" aria-label={this.getMessageValues()} />
        )}
        {message.id && <span data-test={message.id}>{this.getMessageValues()}</span>}
        {learnMoreAction && (
          <span>
            &nbsp;
            <button
              className={`c-text-link--is-visible ${styles.learnMoreBtn}`}
              onClick={() => learnMoreAction(id)}
            >
              <FormattedMessage id="notifications.learn-more" defaultMessage="Learn more" />
            </button>
          </span>
        )}
      </div>
    );
  };

  renderDismissBtn(dismiss) {
    return (
      <IconLabel
        name="close"
        size="default"
        onClick={dismiss}
        className={styles.btn}
        data-test="notification.close-btn"
      />
    );
  }

  render() {
    if (__CAPACITOR__) {
      return null;
    }
    const { severity, persistent } = this.props;
    const className = classnames(styles.component, severity, { persistent });
    return (
      <li className={className}>
        {this.renderMessage()}
        {persistent && this.renderDismissBtn(this.dismiss)}
      </li>
    );
  }

  dismiss = () => {
    this.props.dismiss(this.props.id);
  };
}

export default injectIntl(Notification);
