import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import classnames from 'classnames';

import * as uiActions from '../../actions/ui';

import { selectModalType } from '../../selectors/modals';
import stylesAddToPlaylistModal from './AddToPlaylistModal.css';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import LoadingIndicator from '../common/LoadingIndicator';
import { selectPersonalPlaylistsLoading } from '../../selectors/personalPlaylist';
import { deletePlaylist } from '../../actions/personalPlaylist';
import CancelButton from '../messages/CancelButton';
import CapacitorRipple from '../capacitor/Ripple';
import * as Shapes from '../../shapes';
import { selectPersonalPlaylistToDelete } from '../../selectors/client';

class DeletePlaylistModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    deletePlaylist: PropTypes.func.isRequired,
    playlist: Shapes.PersonalPlaylist,
  };

  onDeletePlaylistClick = () => {
    const { playlist } = this.props;

    this.props.deletePlaylist(playlist);
  };

  render() {
    const { isOpen, hideModal, isLoading } = this.props;
    const deleteButtonClassNames = classnames('c-btn', stylesAddToPlaylistModal.deleteButton);
    const cancelButtonClassNames = classnames(
      'c-btn c-btn--has-outline',
      stylesAddToPlaylistModal.cancelButton
    );

    return (
      <Modal
        isOpen={isOpen}
        onClose={hideModal}
        onRequestClose={hideModal}
        contentLabel="add-to-playlist-modal"
      >
        <section className={stylesAddToPlaylistModal.modalDeletePlaylist}>
          <div className={stylesAddToPlaylistModal.createPlaylist}>
            <div className={stylesAddToPlaylistModal.body}>
              <h1 className="fz--beta">
                <FormattedMessage
                  id="playlist.edit-modal.delete-playlist.header"
                  defaultMessage="Delete Playlist"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="playlist.edit-modal.delete-playlist.text"
                  defaultMessage="Do you really want to delete this playlist?"
                />
              </p>
            </div>
            <div className={stylesAddToPlaylistModal.buttonWrapper}>
              <button
                className={cancelButtonClassNames}
                onClick={hideModal}
                data-test="delete-playlist-modal.cancel-btn"
              >
                <CapacitorRipple />
                <LoadingIndicator isLoading={isLoading} className="hideText" />
                <CancelButton />
              </button>
              <button
                className={deleteButtonClassNames}
                onClick={this.onDeletePlaylistClick}
                data-test="delete-playlist-modal.delete-btn"
              >
                <CapacitorRipple />
                <LoadingIndicator isLoading={isLoading} className="hideText" />
                <FormattedMessage id="playlist.edit-modal.delete.button" defaultMessage="Delete" />
              </button>
            </div>
          </div>
        </section>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectModalType(state) === 'DELETE_PLAYLIST_MODAL',
    isLoading: selectPersonalPlaylistsLoading(state),
    playlist: selectPersonalPlaylistToDelete(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    hideModal: uiActions.hidePersonalPlaylistModal,
    deletePlaylist: deletePlaylist,
  })
)(DeletePlaylistModal);
