import { defineMessages } from 'react-intl';
import {
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_LIVESTREAM_EVENT,
  ENTITY_TYPE_TRACK,
  ENTITY_TYPE_MIX,
  ENTITY_TYPE_ARTIST,
  ENTITY_TYPE_RECORDING,
} from '../../constants';

export const thisMessages = defineMessages({
  [ENTITY_TYPE_ALBUM]: {
    id: 'collection-previews-modal.this-album',
    defaultMessage: 'this album',
  },
  [ENTITY_TYPE_PLAYLIST]: {
    id: 'collection-previews-modal.this-playlist',
    defaultMessage: 'this playlist',
  },
  [ENTITY_TYPE_LIVESTREAM_EVENT]: {
    id: 'collection-previews-modal.this-livestream-event',
    defaultMessage: 'this event',
  },
  [ENTITY_TYPE_TRACK]: {
    id: 'collection-previews-modal.this-track',
    defaultMessage: 'this track',
  },
  [ENTITY_TYPE_MIX]: {
    id: 'collection-previews-modal.this-mix',
    defaultMessage: 'this mix',
  },
  [ENTITY_TYPE_ARTIST]: {
    id: 'collection-previews-modal.this-artist',
    defaultMessage: 'this artist',
  },
  [ENTITY_TYPE_RECORDING]: {
    id: 'collection-previews-modal.this-recording',
    defaultMessage: 'this recording',
  },
});

export const messages = defineMessages({
  [ENTITY_TYPE_ALBUM]: {
    id: 'collection-previews-modal.album',
    defaultMessage: 'album',
  },
  [ENTITY_TYPE_PLAYLIST]: {
    id: 'collection-previews-modal.playlist',
    defaultMessage: 'playlist',
  },
  [ENTITY_TYPE_LIVESTREAM_EVENT]: {
    id: 'collection-previews-modal.livestream-event',
    defaultMessage: 'event',
  },
  [ENTITY_TYPE_TRACK]: {
    id: 'collection-previews-modal.track',
    defaultMessage: 'track',
  },
  [ENTITY_TYPE_MIX]: {
    id: 'collection-previews-modal.mix',
    defaultMessage: 'mix',
  },
  [ENTITY_TYPE_ARTIST]: {
    id: 'collection-previews-modal.artist',
    defaultMessage: 'artist',
  },
  [ENTITY_TYPE_RECORDING]: {
    id: 'collection-previews-modal.recording',
    defaultMessage: 'recording',
  },
});
