// @flow

import { TrackList } from '../schema';
import { normalizeMetaList } from '../schema/normalizeMeta';

import type { Request } from './types';

type LoadTracksAction = { type: 'FETCH_TRACKS' } & Request;

export type TracksAction = LoadTracksAction;

export function loadTracks(trackIds: Array<string>): LoadTracksAction {
  return {
    type: 'FETCH_TRACKS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/metadata/tracks/bulk',
      body: {
        ids: trackIds,
      },
    },
    meta: {
      schema: TrackList,
      normalize: normalizeMetaList,
    },
  };
}
