export function normalizeMetaItem(object) {
  return object.result;
}

export function normalizeMetaList(object) {
  return object.results;
}

export function normalizeMetaListToItem(object) {
  return { result: normalizeMetaList(object) };
}
