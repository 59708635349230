/**
 * Reason why this is separate from locale is that locale does node-specific
 * loading of json files (aka translation files) which breaks clients importing it
 * Also easier to test
 */
import { DEFAULT_LOCALE, WEBSITE_DOMAIN } from '../constants';

import urlJoin from 'url-join';

export function splitLocale(locale) {
  const [, language, region] = locale.match(/([a-z]{2})(?:-([a-z]{2}))?/i);

  const result = { language: language.toLowerCase() };

  if (region) {
    result.region = region.toUpperCase();
  }

  return result;
}

export function getBaseNameFromLocale(locale) {
  if (splitLocale(locale).language === splitLocale(DEFAULT_LOCALE).language) {
    return '/';
  }
  const split = splitLocale(locale);
  return `/${split.language}/`;
}

export function getURLWithoutLocale(url, locale) {
  if (splitLocale(locale).language === splitLocale(DEFAULT_LOCALE).language) {
    return url;
  }
  const split = splitLocale(locale);
  const prefixRegEx = new RegExp(`^(/${split.language})(/.*)`, 'i');
  const matches = url.match(prefixRegEx);
  if (matches && matches.length >= 2) {
    return matches[2]; // eg: ['/de/discover', '/de', '/discover']
  }

  return url;
}

export function switchUrlLanguage(url, fromLanguage, toLanguage) {
  let path = url;
  const defaultLanguage = splitLocale(DEFAULT_LOCALE).language;
  if (fromLanguage !== defaultLanguage) {
    const prefixRegEx = new RegExp('^/([a-z]{2})(/.*)', 'i');
    const matches = url.match(prefixRegEx);
    if (matches) {
      path = matches[2];
    }
    // if no matches, means url is not namespaced under a locale
  }
  if (toLanguage === defaultLanguage) {
    return path;
  }

  return '/' + toLanguage + path;
}

export function getUrlInLocale(url, locale) {
  const targetLocaleLanguage = splitLocale(locale).language;
  const defaultLocaleLanguage = splitLocale(DEFAULT_LOCALE).language;
  if (targetLocaleLanguage === defaultLocaleLanguage) {
    return url;
  }

  return urlJoin('/', splitLocale(locale).language, url);
}

export function getWebsiteUrlInLocale(url, locale) {
  return urlJoin(WEBSITE_DOMAIN, url, `?locale=${splitLocale(locale).language}`);
}
