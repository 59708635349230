export function normalizePlaylist({ result }) {
  const playlist = result;
  const { tracks } = playlist;
  return {
    ...playlist,
    tracks: tracks
      ? tracks.map(track => ({
          ...track,
          id: track.id.toString(),
        }))
      : [],
    trackIds: tracks ? tracks.map(track => track.id.toString()) : [],
  };
}
