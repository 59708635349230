/* @flow */

import React from 'react';
import type { ChildrenArray, Element } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingIndicator from './LoadingIndicator';

import styles from './SocialLoginButtons.css';

type Props = {
  onClick: Function,
  isWorking: boolean,
  disabled?: boolean,
  children: ChildrenArray<Element<typeof FormattedMessage>>,
};

const FacebookButton = ({ onClick, isWorking, disabled, children }: Props) => {
  return (
    <button onClick={onClick} className={styles.FacebookLoginButton} disabled={disabled}>
      <svg viewBox="0 0 24 24" className={styles.icon}>
        <path d="M8 8.192v3.76h2.042V23h3.927V11.906h2.74L17 8.192h-3.031V6.077c0-.876.156-1.222.906-1.222H17V1h-2.719c-2.922 0-4.239 1.451-4.239 4.23v2.962H8z" />
      </svg>
      <LoadingIndicator isLoading={isWorking} className="hideText" />
      <span className={styles.text}>{children}</span>
    </button>
  );
};

export default FacebookButton;

export type FBButton = React$Element<typeof FacebookButton>;
