import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { ConsentManagerSelectionOption } from './ConsentManagerSelectionOption';
import { groupBy } from 'lodash';
import { cookies } from './data/cookies';
import { messages } from './data/translations';
import styles from './ConsentManagerSelectionTable.css';

function renderWithWBRForArray(value, arr = []) {
  const splitter = '~';
  let output = value;
  arr.forEach(char => {
    output = output.replace(char, splitter + char);
  });

  return output.split(splitter).map((text, index) => (
    <React.Fragment key={index}>
      <wbr />
      {text}
      <wbr />
    </React.Fragment>
  ));
}

class ConsentManagerSelectionTable extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    selection: PropTypes.shape({
      necessary: PropTypes.bool.isRequired,
      marketingAndAnalytics: PropTypes.bool.isRequired,
      functional: PropTypes.bool.isRequired,
      advertising: PropTypes.bool.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    checkboxes: {
      necessary: {
        key: 'necessary',
        disabled: true,
        checked: this.props.selection.necessary,
        label: messages.necessary,
      },
      marketingAndAnalytics: {
        key: 'marketingAndAnalytics',
        disabled: false,
        checked: this.props.selection.marketingAndAnalytics,
        label: messages.marketingAndAnalytics,
      },
      functional: {
        key: 'functional',
        disabled: false,
        checked: this.props.selection.functional,
        label: messages.functional,
      },
      advertising: {
        key: 'advertising',
        disabled: false,
        checked: this.props.selection.advertising,
        label: messages.advertising,
      },
    },
  };

  onStateUpdate = () => {
    this.props.onChange(this.getSelection(this.state.checkboxes));
  };

  onCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(
      prevState => ({
        checkboxes: {
          ...prevState.checkboxes,
          [name]: {
            ...prevState.checkboxes[name],
            checked: !prevState.checkboxes[name].checked,
          },
        },
      }),
      this.onStateUpdate
    );
  };

  getSelection = checkboxes => {
    return Object.keys(checkboxes)
      .map(key => checkboxes[key]) // turn to array
      .reduce((acc, { key, checked }) => {
        return {
          ...acc,
          [key]: checked,
        };
      }, {});
  };

  renderCategory = key => {
    const { disabled, checked, label } = this.state.checkboxes[key];
    const groupedCookies = groupBy(cookies, 'category');
    const cookiesData = groupedCookies[key];
    return (
      <div key={key}>
        <p className="fz--delta">
          <ConsentManagerSelectionOption
            key={key}
            name={key}
            disabled={disabled}
            checked={checked}
            onChange={this.onCheckboxChange}
            label={this.props.intl.formatMessage(label)}
          />
        </p>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Provider</th>
              <th>Name</th>
              <th>Purpose</th>
              <th>Duration</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {cookiesData.map(data => (
              <tr key={data.name}>
                <td>{data.owner}</td>
                <td>{this.renderCookieName(data.name)}</td>
                <td>{this.props.intl.formatMessage(data.message)}</td>
                <td>{data.duration}</td>
                <td>{data.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  renderCookieName(cookieName) {
    return renderWithWBRForArray(cookieName, ['{', '}', '_', '-', '.']);
  }

  render() {
    return Object.keys(this.state.checkboxes).map(this.renderCategory);
  }
}

export default injectIntl(ConsentManagerSelectionTable);
