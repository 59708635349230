import * as playerActions from '../actions/player';
import { REHYDRATE } from 'redux-persist/constants';
import { find, findIndex } from 'lodash';
import {
  AUDIO_QUALITY_LOSSLESS,
  USER_CLICKED_NEXT,
  SHOW_PREVIEWS_MODAL_PLAYBACK_DURATION_MS,
} from '../constants';
import { startTimeToPlayTracking, track as analyticsTrack } from '../actions/analytics';
import { setIsLoadingAudio } from '../actions/client';

import { setConnectedSonosGroup } from '../actions/sonos';
import {
  selectAudioQuality,
  selectPlayerCurrentQueueItem,
  selectPlayerIsPlaying,
  selectPlayerIsPlayingAd,
  selectPlayerMuted,
  selectPlayerQueueItems,
  selectPlayerVolume,
  selectProgress,
  selectRepeatAll,
  selectRepeatOne,
} from '../selectors/player';
import {
  selectConnectedSonosGroup,
  selectSonosGroups,
  selectSonosIsConnected,
  selectSonosSessionId,
} from '../selectors/sonos';
import {
  selectDeviceId,
  selectEncryptionEnabled,
  selectSupportsLossless,
  selectUserAgentInfo,
  selectShouldShowPreviewsModal,
} from '../selectors/client';
import { selectUserId } from '../selectors/user';
import {
  clientSupportsPlayback,
  getAdsPlayerInstance,
  getPlayerClass,
  getPlayerInstance,
} from '../client/playback-support';

import {
  createOnConnected,
  createOnConnectionFail,
  createRevertToLocalPlayback,
  disconnectSonosGroup,
} from '../sonos/util';
import { showModal } from '../actions/ui';

import AdsPlayer from '../client/players/AdsPlayer';
import {
  selectAdById,
  selectAdUrlFromAdId,
  selectCurrentlyPlayingAdIndex,
  selectCurrentlyPlayingAdQueue,
  selectShouldPlayAdsForUser,
  selectNumberOfAdsToPlayNow,
} from '../selectors/ads';
import { playAdsNext, resetAdQueue } from '../actions/ads';

import { attachStoreToLocalPlayer } from '../client/attachStoreToLocalPlayer';
import { getStateResolver } from '../actions/trackUrls';
import {
  getInterpolatedTimestamp,
  getQueueOrigin,
  LIVE_COMPARE_ERROR__TIMEOUT,
  normalizeTimestamp,
} from '../utils/liveCompare';
import { addNotification } from '../actions/notifications';
import { PLAYBACK_ERROR } from '../lib/notifications';
import { fetchInterpolationAction } from '../actions/liveCompare/api';
import {
  selectLiveCompareActiveRecordingIndex,
  selectLiveCompareCurrentTrackId,
  selectLiveComparePiece,
} from '../selectors/liveCompare';
import {
  setLiveCompareIsSwitching,
  setLiveComparePlayingRecordingIndex,
} from '../actions/liveCompare';
import {
  LIVE_COMPARE_METHOD_CROSSFADE,
  LIVE_COMPARE_METHOD_PLAIN,
  LIVE_COMPARE_PHASE_BEGIN,
  LIVE_COMPARE_PHASE_END,
  liveCompareError,
  trackLiveCompareSwitch,
} from '../actions/liveCompare/tracking';

// dynamically require these so that we can run it in web as well
let ProxyPlayer;
let SonosPlayer;
let sonosConnector;
if (__ELECTRON__) {
  ProxyPlayer = require('../client/players/ProxyPlayer');
  const SonosConnectionObjects = require('../client/players/SonosPlayer');
  SonosPlayer = SonosConnectionObjects.default;
  sonosConnector = SonosConnectionObjects.sonosConnector;
}

let progressTrackingFlushTimer;
let currentPlaybackPromise;
let liveCompareCurrentSwitchPromise;
let liveCompareCurrentSwitchPromiseHasNext = false;

export default store => {
  const initialState = store.getState();
  const useragentInfo = selectUserAgentInfo(initialState);
  const encryptionEnabled = selectEncryptionEnabled(initialState);

  if (!clientSupportsPlayback(useragentInfo, encryptionEnabled)) {
    // Skip it!
    return next => next;
  }

  let player;
  let sonosPlayer;
  const localPlayerClass = getPlayerClass(useragentInfo, encryptionEnabled);
  const stateResolver = getStateResolver(store.dispatch);
  const localPlayer = getPlayerInstance(useragentInfo, encryptionEnabled, stateResolver);

  const adPlayerUrlResolver = id => selectAdUrlFromAdId(store.getState(), id);
  const adsPlayer = getAdsPlayerInstance(useragentInfo, adPlayerUrlResolver);
  /*
    Proxies are not supported in all browsers and can't be polyfilled
    but we only need this functionality (sonos) in desktop anyway.
  */
  if (__ELECTRON__) {
    sonosPlayer = new SonosPlayer(store);
    player = new ProxyPlayer(store, localPlayer, sonosPlayer).proxy;
  } else {
    player = localPlayer;
  }

  if (__CLIENT__ && typeof window !== 'undefined') {
    window.player = player;
  }

  attachStoreToLocalPlayer(store, player, localPlayerClass, adsPlayer, AdsPlayer);

  const revertToLocalPlayback = createRevertToLocalPlayback(
    store,
    localPlayer,
    localPlayerClass,
    adsPlayer,
    AdsPlayer
  );

  return next => async action => {
    const prevState = store.getState();
    const sonosWasConnected = selectSonosIsConnected(prevState);

    // Check support for lossless
    if (action.type === 'SET_AUDIO_QUALITY') {
      const supportsLossless = selectSupportsLossless(prevState);
      const valueIsLossless = action.quality === AUDIO_QUALITY_LOSSLESS;
      if (valueIsLossless && !supportsLossless) {
        store.dispatch(showModal('LOSSLESS_SUPPORT_MODAL'));
        return;
      }
    }

    next(action);
    const state = store.getState();
    const sessionId = selectSonosSessionId(state);
    const sonosIsConnected = selectSonosIsConnected(state);

    if (!sonosWasConnected && sonosIsConnected) {
      localPlayer.stop();
      localPlayer.removeAllListeners();
      sonosPlayer.setupProgressPollInterval();
    }

    if (sonosWasConnected && !sonosIsConnected) {
      sonosPlayer.clearProgressPollInterval();
      await revertToLocalPlayback();
    }

    switch (action.type) {
      case REHYDRATE: {
        const vol = selectPlayerVolume(state) / 100;
        const muted = selectPlayerMuted(state);

        // If sonos reconnect, use sonos state
        if (!sonosIsConnected) {
          player.setVolume(vol);
          player.setMuted(muted);

          adsPlayer.setVolume(vol);
          adsPlayer.setMuted(muted);
        }

        return;
      }
      case 'SET_AUDIO_QUALITY':
        if (selectPlayerIsPlaying(state)) {
          store.dispatch(startTimeToPlayTracking());
          store.dispatch(setIsLoadingAudio(true));
        }
        if (sonosIsConnected && selectAudioQuality(state) !== selectAudioQuality(prevState)) {
          const queueItems = selectPlayerQueueItems(state);
          const playing = selectPlayerIsPlaying(state);
          const position = selectProgress(state);
          const trackIds = queueItems.map(({ track }) => track);

          await player.setQueue(trackIds, true, 'internal');
          if (playing) {
            const currentQueueItem = selectPlayerCurrentQueueItem(state);
            player.playQueueItem(currentQueueItem.track, position, 'internal');
          }
        } else {
          player.setQuality(action.quality, 'internal');
        }

        return;

      case 'PLAYER_QUEUE_SET': {
        const resumePosition = !action.restartPlayback;
        const queue = action.tracks.map(item => item.track);
        const currentQueueItem = selectPlayerCurrentQueueItem(state);
        const currentIndex =
          currentQueueItem && resumePosition ? findIndex(action.tracks, currentQueueItem) : 0;

        if (action.affectPlayback) {
          await player.setQueue(
            queue,
            resumePosition,
            sonosIsConnected ? action.source : currentIndex
          );
        } else {
          await player.updateQueue(queue, sonosIsConnected ? action.source : currentIndex);
        }
        return;
      }

      case 'LIVE_COMPARE_PLAYER_SWITCH': {
        /**
         * action.toTrackId: which track id to play next
         */

        /*
         * Load local queue
         * When loaded:
         * 	stop player
         * 	set queue to the player
         * 	start player
         */

        if (liveCompareCurrentSwitchPromise && liveCompareCurrentSwitchPromiseHasNext) {
          // back off if there's already a request for the next switch
          return;
        } else if (liveCompareCurrentSwitchPromise) {
          liveCompareCurrentSwitchPromiseHasNext = true;
          await liveCompareCurrentSwitchPromise;
        }

        liveCompareCurrentSwitchPromise = new Promise(async resolve => {
          const recentState = store.getState();

          store.dispatch(setLiveCompareIsSwitching(true));

          const toTrackId = selectLiveCompareCurrentTrackId(recentState);
          const toRecordingIndex = selectLiveCompareActiveRecordingIndex(recentState);
          const liveComparePiece = selectLiveComparePiece(recentState);
          const currentTrackId = selectPlayerCurrentQueueItem(recentState).track;

          const queueOrigin = getQueueOrigin(liveComparePiece.id, toTrackId);

          if (currentTrackId === toTrackId.toString()) {
            resolve({ toRecordingIndex, hasSwitched: false });
            return;
          }

          const interpolationResponse = await store.dispatch(
            fetchInterpolationAction({
              fromTrackId: currentTrackId,
              toTrackId: toTrackId,
              at: normalizeTimestamp(await player.getCurrentTime(action.source)),
            })
          );

          const interpolation = interpolationResponse.normalized.result;

          const getInterpolatedTimestampAttachedToAudio = async () => {
            return getInterpolatedTimestamp(
              await player.getCurrentTime(action.source),
              interpolation
            );
          };

          try {
            if (
              !selectSonosIsConnected(store.getState()) &&
              selectPlayerIsPlaying(store.getState())
            ) {
              await store.dispatch(playerActions.disableControls());
              store.dispatch(
                trackLiveCompareSwitch(
                  LIVE_COMPARE_METHOD_CROSSFADE,
                  LIVE_COMPARE_PHASE_BEGIN,
                  currentTrackId,
                  toTrackId
                )
              );
              await player.liveCompareSwitch(
                toTrackId,
                getInterpolatedTimestampAttachedToAudio,
                queueOrigin,
                interpolation,
                action.source
              );
              await store.dispatch(
                playerActions.setCurrentQueueItem(
                  {
                    track: toTrackId.toString(),
                  },
                  'internal',
                  false
                )
              );
              await store.dispatch(
                playerActions.setQueue(
                  queueOrigin,
                  [toTrackId].map(playerActions.createItem),
                  false,
                  'internal',
                  false
                )
              );
            } else {
              store.dispatch(
                trackLiveCompareSwitch(
                  LIVE_COMPARE_METHOD_PLAIN,
                  LIVE_COMPARE_PHASE_BEGIN,
                  currentTrackId,
                  toTrackId
                )
              );

              const tracks = [toTrackId].map(playerActions.createItem);
              // Promise.resolve not needed, can just await
              await store.dispatch(
                playerActions.setQueueAndPlay(
                  queueOrigin,
                  tracks,
                  playerActions.getItemById(toTrackId, tracks),
                  true,
                  action.source,
                  {
                    progress: await getInterpolatedTimestampAttachedToAudio(),
                  }
                )
              );
            }
          } catch (e) {
            if (e.type === LIVE_COMPARE_ERROR__TIMEOUT) {
              store.dispatch(liveCompareError('timeout', e.message, currentTrackId, toTrackId));
              const tracks = [toTrackId].map(playerActions.createItem);
              await store.dispatch(
                playerActions.setQueueAndPlay(
                  queueOrigin,
                  tracks,
                  playerActions.getItemById(toTrackId, tracks),
                  true,
                  action.source,
                  {
                    progress: await getInterpolatedTimestampAttachedToAudio(),
                  }
                )
              );
            } else {
              store.dispatch(liveCompareError('unknown', e.message, currentTrackId, toTrackId));
              store.dispatch(playerActions.pause());
              store.dispatch(addNotification(PLAYBACK_ERROR));
            }
          } finally {
            resolve({ toRecordingIndex, hasSwitched: true, currentTrackId, toTrackId });
          }
        }).then(async ({ toRecordingIndex, hasSwitched, currentTrackId, toTrackId }) => {
          if (hasSwitched) {
            store.dispatch(
              trackLiveCompareSwitch(null, LIVE_COMPARE_PHASE_END, currentTrackId, toTrackId)
            );
          }
          store.dispatch(setLiveComparePlayingRecordingIndex(toRecordingIndex));
          store.dispatch(setLiveCompareIsSwitching(false));
          store.dispatch(playerActions.enableControls());
          liveCompareCurrentSwitchPromiseHasNext = false;
          liveCompareCurrentSwitchPromise = null;
        });
        break;
      }

      case 'PLAYER_SET_CURRENT_QUEUE_ITEM': {
        if (action.startPlayback) {
          const queueItems = selectPlayerQueueItems(state);
          const index = action.queueItem ? findIndex(queueItems, action.queueItem) || 0 : 0;
          currentPlaybackPromise = player.playQueueItemAtIndex(index, false, action.source); // don't start to play
        }
        return;
      }

      case 'PLAYER_SET_CURRENT_QUEUE_ITEM_INDEX': {
        player._setCurrentIndex(action.index);
        return;
      }

      case 'PLAYER_RECONSTRUCT_LOCAL_QUEUE': {
        const progress = selectProgress(state);
        const queueItems = selectPlayerQueueItems(state);
        const queueItem = selectPlayerCurrentQueueItem(state);
        const trackIds = queueItems.map(({ track }) => track);
        const index = queueItem ? findIndex(queueItems, queueItem) : 0;

        localPlayer.setQueue(trackIds, null, index);
        if (trackIds.length) {
          await localPlayer.playQueueItemAtIndex(index, false); // don't start to play
          localPlayer.seek(selectProgress(store.getState())); // in case of shared track link, the progress would have been reset to 0 in the meanwhile, so we need the very recent state
        }

        if (selectPlayerIsPlayingAd(state)) {
          const ads = selectCurrentlyPlayingAdQueue(state).filter(({ id }) =>
            selectAdById(state, id)
          );

          if (ads && ads.length) {
            adsPlayer.setQueue(ads.map(({ id }) => id));
            adsPlayer.setDurations(ads.map(({ duration }) => duration));

            const adIndex = selectCurrentlyPlayingAdIndex(state);
            await adsPlayer.playQueueItemAtIndex(adIndex, false);
            adsPlayer.seek(progress);
          } else {
            // eslint-disable-next-line no-console
            console.log(
              'Player should be playing ads, but the ads in the queue were not loaded...'
            );
            store.dispatch(resetAdQueue());
          }
        }
        return;
      }

      case 'PLAYER_PAUSE':
        if (selectPlayerIsPlayingAd(state)) {
          adsPlayer.pause();
          return;
        }

        clearInterval(progressTrackingFlushTimer);
        player.pause(action.source);

        if (selectShouldShowPreviewsModal(state)) {
          store.dispatch(playerActions.showPreviewsModal());
        }

        return;

      case 'PLAYER_RESUME': {
        if (selectPlayerIsPlayingAd(state)) {
          adsPlayer.resume();
          return;
        }

        if (progressTrackingFlushTimer) {
          clearInterval(progressTrackingFlushTimer);
        }

        player.resume(action.source);

        const userId = selectUserId(state);
        if (userId) {
          store.dispatch(playerActions.notifyPlay(selectDeviceId(state), userId));
        }

        if (selectShouldShowPreviewsModal(state)) {
          setTimeout(() => {
            store.dispatch(playerActions.showPreviewsModal());
          }, SHOW_PREVIEWS_MODAL_PLAYBACK_DURATION_MS);
        }

        return;
      }
      case 'PLAYER_PLAY': {
        // start progress tracking flush timer
        if (progressTrackingFlushTimer) {
          clearInterval(progressTrackingFlushTimer);
        }

        if (currentPlaybackPromise) {
          await currentPlaybackPromise;
          currentPlaybackPromise = null;
        }

        if (typeof action.progress === 'number') {
          player.seek(action.progress, action.source);
        }

        player.resume(action.source);

        const userId = selectUserId(state);
        if (userId) {
          store.dispatch(playerActions.notifyPlay(selectDeviceId(state), userId));
        }

        if (selectShouldShowPreviewsModal(state)) {
          setTimeout(() => {
            store.dispatch(playerActions.showPreviewsModal());
          }, SHOW_PREVIEWS_MODAL_PLAYBACK_DURATION_MS);
        }

        return;
      }

      case 'PLAYER_SEEK_FINISH':
        if (selectPlayerIsPlayingAd(state)) {
          return;
        }

        player.seek(action.newPosition, action.source);
        return;

      case 'PLAYER_VOLUME':
        player.setVolume(action.value / 100, action.source);
        adsPlayer.setVolume(action.value / 100, action.source);
        return;

      case 'PLAYER_TOGGLE_MUTE':
        player.toggleMute(selectPlayerMuted(state), action.source);
        adsPlayer.toggleMute(selectPlayerMuted(state), action.source);
        return;

      case 'PLAYER_QUEUE_LOAD':
        if (selectPlayerIsPlayingAd(state)) {
          // don't track time / set flag if currently playing ad
          return;
        }
        store.dispatch(startTimeToPlayTracking());
        store.dispatch(setIsLoadingAudio(true));
        return;

      case 'PLAYER_QUEUE_LOAD_ABORT':
        store.dispatch(setIsLoadingAudio(false));
        return;

      case 'PLAYER_PREVIOUS': {
        if (selectPlayerIsPlayingAd(state) || selectRepeatOne(state)) {
          return;
        }

        store.dispatch(startTimeToPlayTracking());
        store.dispatch(setIsLoadingAudio(true));

        if (selectShouldShowPreviewsModal(state)) {
          store.dispatch(playerActions.showPreviewsModal());
        }

        const queueItems = selectPlayerQueueItems(state);
        const queueLength = queueItems.length;
        const lastIndex = queueLength - 1;

        const wasOnFirstTrack =
          findIndex(queueItems, selectPlayerCurrentQueueItem(state)) === lastIndex;
        // ^ This code runs after the action has updated the store
        if (selectRepeatAll(state) && wasOnFirstTrack) {
          player.playQueueItemAtIndex(lastIndex, selectPlayerIsPlaying(state));
        } else {
          player.playPreviousItem(selectPlayerIsPlaying(state));
        }

        return;
      }

      case 'PLAYER_NEXT': {
        if (selectShouldPlayAdsForUser(state)) {
          const adCount = selectNumberOfAdsToPlayNow(state);
          if (adCount) {
            player.stop();
            player.queueNextItem();
            store.dispatch(playAdsNext(adCount));
            return;
          }
        }

        store.dispatch(startTimeToPlayTracking());
        store.dispatch(setIsLoadingAudio(true));

        if (action.reason === USER_CLICKED_NEXT) {
          if (selectShouldShowPreviewsModal(state)) {
            store.dispatch(playerActions.showPreviewsModal());
          }

          const wasOnLastTrack =
            findIndex(selectPlayerQueueItems(state), selectPlayerCurrentQueueItem(state)) === 0;
          // ^ This code runs after the action has updated the store
          if (selectRepeatAll(state) && wasOnLastTrack) {
            player.playQueueItemAtIndex(0, selectPlayerIsPlaying(state));
          } else {
            // player will advance on it's own when a track ends
            if (selectPlayerIsPlaying(state)) {
              player.playNextItem();
            } else {
              player.queueNextItem();
            }
          }
        }
        return;
      }
      case 'PLAYER_QUEUE_RESET':
        player.resetQueue(action.restartPlayback, action.source);
        return;

      case 'PLAYER_REWIND':
        if (selectPlayerIsPlayingAd(state)) {
          return;
        }

        if (selectShouldShowPreviewsModal(state)) {
          store.dispatch(playerActions.showPreviewsModal());
        }

        player.seek(0, action.source);
        return;

      case 'PICK_SONOS_GROUP':
        if (action.group.groupId !== 'this-device') {
          sonosConnector.sessionId = sessionId;
          const onConnected = createOnConnected(
            store,
            prevState,
            player,
            localPlayerClass,
            revertToLocalPlayback,
            action.group.groupId
          );
          const onConnectionFail = createOnConnectionFail(store, revertToLocalPlayback);

          if (sonosWasConnected) {
            await disconnectSonosGroup(store, false);
          }

          sonosConnector.connect(action.group, onConnected, onConnectionFail);
        } else {
          if (sonosWasConnected) {
            const prevConnectedGroupId = selectConnectedSonosGroup(prevState);
            const prevGroup = find(
              selectSonosGroups(prevState),
              ({ groupId }) => groupId === prevConnectedGroupId
            );
            if (prevGroup) {
              // prevGroup is undefined if the group disconnected while the app was closed
              store.dispatch(
                analyticsTrack('Purged Sonos Session', {
                  deviceName: prevGroup.groupName,
                  deviceId: prevConnectedGroupId,
                  sessionId: selectSonosSessionId(state),
                })
              );
            }
            store.dispatch(setConnectedSonosGroup(action.group.groupId));
          }
        }
        break;
      case 'SET_AD_QUEUE_AND_PLAY':
        adsPlayer.setQueue(action.ads.map(({ id }) => id));
        adsPlayer.setDurations(action.ads.map(({ duration }) => duration));
        store.dispatch(playerActions.disableControls());
        // play the ad at position 0
        await adsPlayer.playQueueItemAtIndex(0);
        break;
      default:
    }
  };
};
