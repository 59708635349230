import { QUEUE_TYPE_ALBUM, VALID_ALBUM_FILTER_PARAMS } from '../constants';
import { createSelector } from 'reselect';
import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';

import { selectPlayerIsPlaying, selectPlayerQueueOrigin } from './player';
import filterAndSortParams from '../utils/filterAndSortParams';
import getSerializedFilterId from '../utils/getSerializedFilterId';
import { flatten, map, some } from 'lodash';
import { selectNextCursor, selectPreviousCursor } from './common';
import { selectIsLoadingAudio } from './client';
import { selectQueueIsLoading } from './queue';

export function selectAlbumIsLoading(state, slug) {
  return state.maps.albums[slug] ? state.maps.albums[slug].loading : false;
}

export function selectAlbumIsLoaded(state, slug) {
  return state.maps.albums[slug] ? state.maps.albums[slug].loaded : false;
}

export const selectAlbumIdFromSlug = (state, slug) => {
  return state.maps.albums[slug] ? state.maps.albums[slug].entities : null;
};

const selectAlbums = state => {
  return state.entities.albums;
};

export function selectAlbumById(state, id) {
  const albums = selectAlbums(state);
  const entities = state.entities;

  if (!id || !albums[id]) {
    return null;
  }

  return assembleEntity(Schema.Album, entities, id);
}

export function selectAlbumIsQueued(state, slug) {
  const queueOrigin = selectPlayerQueueOrigin(state);
  return Boolean(queueOrigin) && queueOrigin.type === QUEUE_TYPE_ALBUM && queueOrigin.id === slug;
}

export function selectAlbumIsPlaying(state, slug) {
  const isQueued = selectAlbumIsQueued(state, slug);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

export const selectAlbum = createSelector(
  [state => state.entities, selectAlbumIdFromSlug, selectAlbums],
  (entities, id, albums) => {
    if (!id || !albums[id]) {
      return null;
    }

    return assembleEntity(Schema.Album, entities, id);
  }
);

export const selectAlbumImageAnnotation = (state, slug) => {
  const annotationId = `album-${slug}-main.jpg`;
  return state.client.annotations.image ? state.client.annotations.image[annotationId] : null;
};

export const selectFilteredAndSortedAlbums = createSelector(
  [
    state => state.entities,
    state => state.maps.albumsByFilter,
    (state, filterParams) => filterParams,
  ],
  (entities, albumsByFilter, filterParams) => {
    const validAndSortedParams = filterAndSortParams(filterParams, VALID_ALBUM_FILTER_PARAMS);
    return assembleEntity(
      Schema.AlbumList,
      entities,
      albumsByFilter[getSerializedFilterId(validAndSortedParams, VALID_ALBUM_FILTER_PARAMS)]
        .entities
    );
  }
);

export const selectFilteredAndSortedAlbumsLoading = state => {
  const loadingStates = map(state.maps.albumsByFilter, entry => entry && entry.loading);
  return some(loadingStates, isLoading => isLoading);
};

export const selectAlbumsAreLoadingForRecording = (state, recordingId) => {
  return (
    Boolean(state.maps.albumsByRecording[recordingId]) &&
    Boolean(state.maps.albumsByRecording[recordingId].loading)
  );
};

export const selectAlbumsByRecording = createSelector(
  [
    state => state.entities,
    state => state.maps.albumsByRecording,
    (state, recordingId) => recordingId,
  ],
  (entities, albumsByRecording, recordingId) => {
    if (!albumsByRecording[recordingId]) {
      return [];
    }
    return assembleEntity(Schema.AlbumList, entities, albumsByRecording[recordingId].entities);
  }
);

export function selectNextFilteredAlbumsCursor(state, filterParams) {
  if (selectFilteredAndSortedAlbumsLoading(state)) {
    return '';
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_ALBUM_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_ALBUM_FILTER_PARAMS);
  return selectNextCursor(state.maps.albumsByFilter[cacheId]);
}

export function selectPreviousFilteredAlbumsCursor(state, filterParams) {
  if (selectFilteredAndSortedAlbumsLoading(state)) {
    return '';
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_ALBUM_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_ALBUM_FILTER_PARAMS);
  return selectPreviousCursor(state.maps.albumsByFilter[cacheId]);
}

export const selectTracksFromAlbums = createSelector([albums => albums], albums =>
  flatten(map(albums, album => album.tracks))
);

export const selectTrackIdsFromAlbums = createSelector([albums => albums], albums =>
  flatten(map(albums, ({ trackIds }) => trackIds))
);

export function selectAlbumIsLoadingToPlay(state, slug) {
  const isLoadingAudio = selectIsLoadingAudio(state);
  const itemIsQueued = selectAlbumIsQueued(state, slug);
  const queueIsLoading = selectQueueIsLoading(state);

  return itemIsQueued && (isLoadingAudio || queueIsLoading);
}

export const selectCreateQueueOriginFromAlbums = createSelector([albums => albums], albums => {
  return albums.map(({ id, tracks, trackIds }) => ({
    originId: id,
    tracks,
    trackIds,
  }));
});
