// @flow
declare var __CAPACITOR__;

import type { Request, ThunkAction } from './types';
import {
  PersonalPlaylist as PersonalPlaylistSchema,
  PersonalPlaylistList as PersonalPlaylistListSchema,
} from '../schema';
import {
  normalizePersonalPlaylist,
  normalizePersonalPlaylistList,
} from '../schema/normalizePersonalPlaylist';
import * as cacheValidators from '../middleware/apiCacheValidators';
import { addNotification } from './notifications';
import {
  ADDED_TO_PLAYLIST,
  PLAYLIST_CREATED,
  PLAYLIST_DELETED,
  PLAYLIST_EXCEEDS_MAX_TRACKS,
  PLAYLIST_SAVED,
  REMOVED_FROM_PLAYLIST,
} from '../lib/notifications';
import { hidePersonalPlaylistModal } from './ui';
import {
  trackAddedItemToPersonalPlaylist,
  trackCreatedPersonalPlaylist,
  trackDeletedPersonalPlaylist,
  trackMovedTrackInPersonalPlaylist,
  trackRemovedTrackFromPersonalPlaylist,
} from './analytics';

import { replace } from 'react-router-redux';
import { selectPersonalPlaylistToAddState } from '../selectors/client';
import {
  getPersonalPlaylistQueueOrigin,
  selectPersonalPlaylist,
} from '../selectors/personalPlaylist';
import { MAX_PERSONAL_PLAYLISTS_TRACKS } from '../constants';
import { selectIsQueueOriginSetAs } from '../selectors/queue';
import {
  playerAppendItemsToCurrentQueueAndShuffle,
  playerRemoveItemFromCurrentQueue,
  playerMoveItemInCurrentQueueAndShuffle,
} from './player';

type PersonalPlaylistTrack = {| id: string |};
type PersonalPlaylist = {|
  +id: string,
  +tracks: ?Array<PersonalPlaylistTrack>,
|};

type PersonalPlaylistUpdate = {|
  title: string,
|};

export type TrackRemoveOperation = {|
  type: 'remove',
  trackId: string,
  index: number,
  trackIds: Array<string>,
|};

export type TrackMoveOperation = {|
  type: 'move',
  from: number,
  to: number,
  trackId: string,
  trackIds: Array<string>,
|};

// Below: plural, singular
export type LoadPersonalPlaylistsAction = {
  type: 'FETCH_PERSONAL_PLAYLISTS',
} & Request;
export type ReloadPersonalPlaylistsAction = { type: 'RELOAD_PERSONAL_PLAYLISTS' } & Request;
export type LoadPersonalPlaylistAction = { type: 'FETCH_PERSONAL_PLAYLIST' } & Request;
export type CreatePersonalPlaylistAction = {
  type: 'CREATE_PERSONAL_PLAYLIST',
} & Request;
export type DeletePersonalPlaylistAction = {
  type: 'DELETE_PERSONAL_PLAYLIST',
} & Request;
type ModifyPlaylistAction = { type: 'MODIFY_PERSONAL_PLAYLIST' } & Request;
export type PersonalPlaylistAction =
  | LoadPersonalPlaylistsAction
  | LoadPersonalPlaylistAction
  | ModifyPlaylistAction
  | CreatePersonalPlaylistAction;

export function loadPersonalPlaylists(
  cursor?: string,
  limit?: number = 20
): LoadPersonalPlaylistsAction {
  const request = {
    type: 'API_REQUEST',
    method: 'GET',
    endpoint: '/personal-playlists.v2',
    params: {},
  };

  if (cursor) {
    request.params.cursor = cursor;
  }

  if (limit) {
    request.params.limit = limit;
  }

  return {
    type: 'FETCH_PERSONAL_PLAYLISTS',
    IDAGIO_REQUEST: request,
    meta: {
      restricted: true,
      schema: PersonalPlaylistListSchema,
      normalize: normalizePersonalPlaylistList,
    },
    cache: {
      fetch: state => state.lists.personalPlaylists,
      validate: cacheValidators.loadedCursor(cursor),
    },
  };
}

export function reloadPersonalPlaylists() {
  const request = {
    type: 'API_REQUEST',
    method: 'GET',
    endpoint: '/personal-playlists.v2',
    params: {},
  };

  return {
    type: 'RELOAD_PERSONAL_PLAYLISTS',
    IDAGIO_REQUEST: request,
    meta: {
      restricted: true,
      schema: PersonalPlaylistListSchema,
      normalize: normalizePersonalPlaylistList,
    },
  };
}

export function loadPersonalPlaylist(id: string): LoadPersonalPlaylistAction {
  return {
    type: 'FETCH_PERSONAL_PLAYLIST',
    cacheId: id,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v1.0/personal-playlists/{id}',
      params: {
        id,
      },
    },
    meta: {
      schema: PersonalPlaylistSchema,
      normalize: normalizePersonalPlaylist,
    },
    cache: {
      // checking `state.map` below for fully hydrated items
      fetch: state => state.maps.personalPlaylists[id],
      validate: cacheValidators.notExpired,
      expiryPeriodInMs: 1000 * 60 * 60, // 1 hour
    },
  };
}

const wrapTrackIdsForBody = trackIds => trackIds.map(id => ({ id: id.toString() })); //            ^.toString is important, backend will not accept integers

function modifyPlaylist(
  playlistId: string,
  update: ?PersonalPlaylistUpdate,
  trackIds: ?Array<string>
): ModifyPlaylistAction {
  let body: Object = {};
  let optimisticUpdate = {};

  if (update) {
    body = {
      ...update,
    };
  }

  if (trackIds) {
    optimisticUpdate = {
      tracks: trackIds,
      trackIds: trackIds,
    };
    body.tracks = wrapTrackIdsForBody(trackIds);
  }
  return {
    type: 'MODIFY_PERSONAL_PLAYLIST',
    mergeStrategy: {
      type: 'REPLACE_ENTITY',
      entityKey: 'personalPlaylists',
      entityId: playlistId,
      optimisticUpdate,
    },
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'PUT',
      endpoint: `/v1.0/personal-playlists/${playlistId}`,
      body,
    },
    meta: {
      schema: PersonalPlaylistSchema,
      normalize: normalizePersonalPlaylist,
    },
  };
}

function shouldSyncQueue(playlistId: string, state): Boolean {
  const queueOrigin = getPersonalPlaylistQueueOrigin(playlistId);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function addTracksToPlaylist(id: string): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(loadPersonalPlaylist(id));

    const state = getState();
    const target = selectPersonalPlaylist(state, id);
    const toAdd = selectPersonalPlaylistToAddState(state);

    const playlistTrackIds = target.trackIds;
    const { trackIds: trackIdsToAdd, trackingContext } = toAdd;

    const updatedTrackIds = [...playlistTrackIds, ...trackIdsToAdd];

    if (updatedTrackIds.length > MAX_PERSONAL_PLAYLISTS_TRACKS) {
      dispatch(addNotification(PLAYLIST_EXCEEDS_MAX_TRACKS));
      return;
    }

    if (shouldSyncQueue(id, state)) {
      dispatch(playerAppendItemsToCurrentQueueAndShuffle(updatedTrackIds));
    }

    await dispatch(modifyPlaylist(id, null, updatedTrackIds));

    const trackingContextWithId = {
      ...trackingContext,
      playlistId: id,
    };

    dispatch(trackAddedItemToPersonalPlaylist(trackingContextWithId));
    dispatch(addNotification(ADDED_TO_PLAYLIST));
    dispatch(hidePersonalPlaylistModal());
  };
}

export function updatePlaylist(id: string, update: PersonalPlaylistUpdate): ThunkAction {
  return async dispatch => {
    await dispatch(modifyPlaylist(id, update));
    dispatch(addNotification(PLAYLIST_SAVED));
    dispatch(hidePersonalPlaylistModal());
  };
}

export function deleteTrackFromPlaylist(
  playlist: PersonalPlaylist,
  op: TrackRemoveOperation
): ThunkAction {
  const playlistId = playlist.id;
  const { trackId, trackIds } = op;

  return async (dispatch, getState) => {
    if (shouldSyncQueue(playlistId, getState())) {
      dispatch(playerRemoveItemFromCurrentQueue(op));
    }

    await dispatch(modifyPlaylist(playlistId, null, trackIds));
    dispatch(trackRemovedTrackFromPersonalPlaylist(playlistId, trackId));
    dispatch(addNotification(REMOVED_FROM_PLAYLIST));
  };
}

export function reorderTrackIdInPlaylist(
  playlist: PersonalPlaylist,
  op: TrackMoveOperation
): ThunkAction {
  const playlistId = playlist.id;
  const { trackIds } = op;
  return async (dispatch, getState) => {
    if (shouldSyncQueue(playlistId, getState())) {
      dispatch(playerMoveItemInCurrentQueueAndShuffle(op));
    }
    await dispatch(modifyPlaylist(playlistId, null, trackIds));
    dispatch(trackMovedTrackInPersonalPlaylist(playlistId, op));
  };
}

export function deletePlaylist(playlist: PersonalPlaylist): ThunkAction {
  return async dispatch => {
    await dispatch({
      type: 'DELETE_PERSONAL_PLAYLIST',
      IDAGIO_REQUEST: {
        type: 'API_REQUEST',
        method: 'DELETE',
        endpoint: `/v1.0/personal-playlists/${playlist.id}`,
        params: __CAPACITOR__
          ? {
              // Even though the endpoint doesn't need the id in the params, we
              // pass it so we can read it in the capacitor middleware.
              id: playlist.id,
            }
          : {},
      },
    });

    dispatch(trackDeletedPersonalPlaylist(playlist.id));
    dispatch(replace('/collection/playlists/personal')); // redirect
    dispatch(hidePersonalPlaylistModal());
    dispatch(addNotification(PLAYLIST_DELETED));
    // do not reload pps because it will conflict with the redirect above
  };
}

export function createPersonalPlaylist(title: string): ThunkAction {
  return async (dispatch, getState) => {
    const personalPlaylistToAdd = selectPersonalPlaylistToAddState(getState());
    const { trackIds, trackingContext } = personalPlaylistToAdd;

    const response = await dispatch({
      type: 'CREATE_PERSONAL_PLAYLIST',
      IDAGIO_REQUEST: {
        type: 'API_REQUEST',
        method: 'POST',
        endpoint: '/v1.0/personal-playlists',
        body: {
          title,
          tracks: wrapTrackIdsForBody(trackIds),
        },
      },
      meta: {
        schema: PersonalPlaylistSchema,
        normalize: normalizePersonalPlaylist,
      },
    });

    const playlistId = response.normalized.result;
    const trackingContextWithId = {
      ...trackingContext,
      playlistId,
    };

    dispatch(trackCreatedPersonalPlaylist(trackingContextWithId));

    dispatch(addNotification(PLAYLIST_CREATED));
    if (trackIds.length) {
      dispatch(addNotification(ADDED_TO_PLAYLIST));
    }
    dispatch(hidePersonalPlaylistModal());
    dispatch(loadPersonalPlaylists());
  };
}
