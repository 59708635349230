import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import classnames from 'classnames';

import * as uiActions from '../../actions/ui';
import * as subscriptionActions from '../../actions/subscription';
import * as notificationsActions from '../../actions/notifications';
import * as meActions from '../../actions/me';
import * as clientActions from '../../actions/client';
import * as analyticsActions from '../../actions/analytics';

import { selectOpenModalTypeIs } from '../../selectors/modals';
import { selectIncomingVoucher } from '../../selectors/subscription';

import Modal from './Modal';
import VoucherForm from '../premium/VoucherForm';
import VoucherFooter from '../premium/VoucherFormFooter';
import LoadingIndicator from '../common/LoadingIndicator';

import styles from './VoucherModal.css';

import { APPLYING_COUPON, COUPON_SUCCESS } from '../../lib/notifications';
import { selectLocale } from '../../selectors/client';

const messages = defineMessages({
  emptyError: {
    id: 'voucher-modal.empty-error',
    defaultMessage: 'Please fill in the voucher code before you proceed',
  },
});

class VoucherModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    incomingVoucher: PropTypes.object,
    applyVoucherCode: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    loadSubscription: PropTypes.func.isRequired,
    loadMe: PropTypes.func.isRequired,
    loadFeatureFlags: PropTypes.func.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    voucherCode: this.props.incomingVoucher && this.props.incomingVoucher.code,
  };

  render() {
    const { hideModal, isOpen, locale } = this.props;
    const { loading, voucherCode } = this.state;

    if (!isOpen) {
      return null;
    }

    const buttonClassName = classnames('c-btn c-btn--is-purple', styles.button, {
      [styles.isLoading]: loading,
    });

    return (
      <Modal
        isOpen={isOpen}
        onClose={hideModal}
        onRequestClose={hideModal}
        contentLabel="voucher-success-modal"
      >
        <section className={styles.modal} data-test="voucher.modal">
          <h1>
            <FormattedMessage id="voucher-modal.title" defaultMessage="Redeem your voucher code" />
          </h1>
          <VoucherForm code={voucherCode} onChange={this.setVoucherData} />
          <button
            className={buttonClassName}
            onClick={this.submitForm}
            disabled={loading}
            ref={this.setButtonRef}
            data-test="voucher-modal.submit-btn"
          >
            <LoadingIndicator isLoading={loading} className={styles.loader} />
            <div className={styles.texts}>
              <span className="payment-form__button-text">
                <FormattedMessage
                  id="subscribe.modal.payment-details.voucher"
                  defaultMessage="Redeem Voucher"
                />
              </span>
            </div>
          </button>
          <VoucherFooter locale={locale} />
        </section>
      </Modal>
    );
  }

  setVoucherData = voucherCode => {
    if (this.state.loading) {
      return;
    }

    this.setState({ voucherCode });
  };

  applyVoucherCode = async code => {
    const {
      addNotification,
      applyVoucherCode,
      loadMe,
      loadSubscription,
      loadFeatureFlags,
      hideModal,
      showModal,
    } = this.props;

    addNotification(APPLYING_COUPON);
    try {
      await applyVoucherCode(code);
      await Promise.all([loadMe(), loadFeatureFlags(), loadSubscription()]);
      addNotification(COUPON_SUCCESS);
      this.trackSuccessfulRedemption();
      hideModal();
      showModal('VOUCHER_SUCCESS_MODAL');
    } catch (error) {
      subscriptionActions.handleCouponError(error, addNotification);
      this.setState({ loading: false });
    }
  };

  submitForm = event => {
    event.preventDefault();

    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true }, () => {
      const { voucherCode } = this.state;

      if (!voucherCode) {
        this.setState({ loading: false });
        this.showError(messages.emptyError);
        return;
      }

      this.applyVoucherCode(voucherCode);
    });
  };

  showError(message) {
    this.props.addNotification({
      message,
      severity: 'error',
      persistent: true,
      topic: 'subscription',
    });
  }

  trackSuccessfulRedemption = () => {
    this.props.analyticsTrack('Upgraded To Premium');
  };
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'VOUCHER_MODAL'),
    incomingVoucher: selectIncomingVoucher(state),
    locale: selectLocale(state),
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
  showModal: uiActions.showModal,
  applyVoucherCode: subscriptionActions.applyVoucherCode,
  addNotification: notificationsActions.addNotification,
  loadMe: meActions.loadMe,
  loadSubscription: subscriptionActions.loadSubscription,
  loadFeatureFlags: clientActions.loadFeatureFlags,
  analyticsTrack: analyticsActions.track,
})(VoucherModal);
