// @flow

import { SUCCESS } from '../../middleware/api';
import { DEFAULT_FEATURE_FLAGS } from '../../constants';
import type { LoadFeatureFlagsAction } from '../../actions/client';

export type FeatureFlags = {|
  +full_catalogue: Boolean,
  +streaming_to_all_external_players: Boolean,
  +audio_quality: Array<Number>,
  +interval_between_intermission: Number,
  +collections: {|
    access_limit: number,
  |},
  +user_playlists: Boolean,
  +gch: {|
    +allow_concert_playback: Boolean,
  |},
|};

export type State = FeatureFlags;

// These are defensive fallback defaults but are not necessarily the same as the
// defaults coming from the feature flag endpoint for anonymous users.
const initialState = DEFAULT_FEATURE_FLAGS;

export default function features(state: State = initialState, action: LoadFeatureFlagsAction) {
  switch (action.type) {
    case 'LOAD_FEATURE_FLAGS':
      if (action.phase === SUCCESS) {
        return action.response ? action.response.normalized : state;
      }
      return state;
    default:
      (action: empty);
      return state;
  }
}
