// @flow

import update from 'immutability-helper';
import { SUCCESS, REQUEST } from '../middleware/api';

import type {
  LoadQuickSearchAction,
  ResetQuickSearchAction,
  SetQuickSearchQueryAction,
  SetQuickSearchActiveFilterAction,
} from '../actions/search';

type Action =
  | LoadQuickSearchAction
  | ResetQuickSearchAction
  | SetQuickSearchQueryAction
  | SetQuickSearchActiveFilterAction;

export type State = {|
  +query: string,
  +loading: boolean,
  +loaded: boolean,
  +order: Array<string>,
  +activeFilter: string | null,
  +filters: Array<string>,
  +results: {
    +top: [],
    +artists: [],
    +recordings: [],
    +compositions: [],
    +playlists: [],
    +albums: [],
    +events: [],
  },
|};

const initialState = {
  query: '',
  loading: false,
  loaded: false,
  order: ['top', 'events', 'artists', 'recordings', 'compositions', 'playlists', 'albums'],
  activeFilter: null,
  filters: [],
  results: {
    top: [],
    artists: [],
    recordings: [],
    compositions: [],
    playlists: [],
    albums: [],
    events: [],
  },
};

export default function quickSearch(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'SET_QUICK_SEARCH_QUERY':
      return update(state, {
        query: { $set: action.query },
        loading: { $set: true },
        loaded: { $set: false },
      });
    case 'SET_QUICK_SEARCH_ACTIVE_FILTER':
      return update(state, {
        activeFilter: { $set: action.filter },
      });
    case 'FETCH_QUICK_SEARCH_RESULTS':
      switch (action.phase) {
        case REQUEST:
          return update(state, {
            loading: { $set: true },
            loaded: { $set: false },
          });
        case SUCCESS: {
          const response = action.response || {};
          const { results, order, filters } = response.normalized;
          return update(state, {
            loading: { $set: false },
            loaded: { $set: true },
            order: { $set: order },
            filters: { $set: filters },
            results: {
              top: { $set: results.top },
              artists: { $set: results.artists },
              recordings: { $set: results.recordings },
              compositions: { $set: results.compositions },
              playlists: { $set: results.playlists },
              albums: { $set: results.albums },
              events: { $set: results.events },
            },
          });
        }
        default:
          return state;
      }
    case 'RESET_QUICK_SEARCH':
      return initialState;
    default:
      (action: empty);
      return state;
  }
}
