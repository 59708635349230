// @flow
// $FlowFixMe
import React, { useState } from 'react';

import { SUBSCRIPTION_TRIAL_DURATION_DAYS } from '../../constants';

import StripePaymentForm from './StripePaymentForm';
import PaymentSummary from './PaymentSummary';

import styles from './PaymentStep.css';
import stylesConversionForm from './ConversionFormVisual.css';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  isInModal: boolean,
  selectedPlan: string | null,
  isTrialAvailable: boolean,
  trialDurationDays?: number,
  price: number,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  onSuccess?: Function,
  defaultPaymentType?: string,
  userIsAllowedOptOutTrial: boolean,
  goBack: Function,
};

type Props = OwnProps;

const PaymentStep = ({
  isInModal,
  selectedPlan,
  isTrialAvailable,
  trialDurationDays = SUBSCRIPTION_TRIAL_DURATION_DAYS,
  price,
  relativeDiscount,
  reducedPriceDuration,
  onSuccess,
  defaultPaymentType,
  userIsAllowedOptOutTrial,
  goBack,
}: Props) => {
  const [activePaymentType, setActivePaymentType] = useState(defaultPaymentType);

  return (
    <div className={stylesConversionForm.paymentGrid} data-test="conversion-form.payment-step-view">
      <PaymentSummary
        isTrialAvailable={isTrialAvailable}
        trialDurationDays={trialDurationDays}
        selectedPlan={selectedPlan}
        price={price}
        relativeDiscount={relativeDiscount}
        goBack={goBack}
        reducedPriceDuration={reducedPriceDuration}
      />
      <div className={styles.formContainer}>
        <h1>
          <FormattedMessage
            id="premium.payment-step.title"
            defaultMessage="Complete Your Purchase"
          />
        </h1>
        <p className={styles.description}>
          <FormattedMessage
            id="premium.payment-step.description.1"
            defaultMessage="Enter your payment details to start your journey."
          />
          {isTrialAvailable && (
            <span>
              &nbsp;
              <FormattedMessage
                id="premium.payment-step.description.free-trial"
                defaultMessage="Your subscription will begin after the free trial."
              />
            </span>
          )}
        </p>
        <StripePaymentForm
          isInModal={isInModal}
          onSuccess={onSuccess}
          isTrialAvailable={isTrialAvailable}
          trialDuration={trialDurationDays}
          price={price}
          relativeDiscount={relativeDiscount}
          reducedPriceDuration={reducedPriceDuration}
          onPaymentTypeChange={setActivePaymentType}
          selectedPlan={selectedPlan}
          initialPaymentType={activePaymentType}
          userIsAllowedOptOutTrial={userIsAllowedOptOutTrial}
        />
      </div>
    </div>
  );
};

export default PaymentStep;
