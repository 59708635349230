// @flow

import { normalizeDiscoverGroup, normalizeDiscoverGroups } from '../schema/normalizeDiscover';
import { loaded } from '../middleware/apiCacheValidators';

import type { Request } from './types';
import type { EntityAction } from '../lib/entities';
import { LIVESTREAM_EVENT_DEFAULT_FILTER } from '../constants';

export type LoadDiscoverPageAction = { type: 'FETCH_DISCOVER_PAGE' } & Request;
export type LoadDiscoverGroupAction = {
  type: 'FETCH_DISCOVER_GROUP',
} & Request;

export type DiscoverAction = LoadDiscoverPageAction | LoadDiscoverGroupAction | EntityAction;

type LIVESTREAM_EVENT_FILTER = 'upcoming' | 'onDemandOrLive' | 'all' | 'allAvailable';

export function loadDiscoverPage(
  page: string,
  filter?: LIVESTREAM_EVENT_FILTER = LIVESTREAM_EVENT_DEFAULT_FILTER
): LoadDiscoverPageAction {
  return {
    type: 'FETCH_DISCOVER_PAGE',
    cacheId: page,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: `/discover/${page}.v5`,
      params: {
        filter,
        includeStories: true,
        includeCourses: true,
      },
    },
    meta: {
      normalize: normalizeDiscoverGroups,
    },
    cache: {
      fetch: state => state.maps.discoverPages[page],
      validate: loaded,
    },
  };
}

export function loadDiscoverGroup(
  type: string,
  slug: string,
  filter?: LIVESTREAM_EVENT_FILTER = LIVESTREAM_EVENT_DEFAULT_FILTER
): LoadDiscoverGroupAction {
  const compoundKey = [type, slug, filter].filter(i => i).join('--');

  return {
    type: 'FETCH_DISCOVER_GROUP',
    cacheId: compoundKey,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: `/discover/${type}/${slug}.v3`,
      params: {
        filter,
        includeStories: true,
        includeCourses: true,
      },
    },
    meta: {
      normalize: response => {
        return {
          result: normalizeDiscoverGroup(response.result),
        };
      },
    },
    cache: {
      fetch: state => state.maps.discoverGroups[compoundKey],
      validate: loaded,
    },
  };
}
