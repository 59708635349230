// @flow

import update from 'immutability-helper';

import type {
  SetFontLoadingAction,
  SetFontLoadedAction,
  SetUrlToFontsAction,
} from '../../actions/client';

type FontAction = SetFontLoadingAction | SetFontLoadedAction | SetUrlToFontsAction;

export type State = { +[key: string]: {| name: string, className: string |}, url: string };

export const FONTS = {
  EESTI_DISPLAY: 'Eesti Display',
};

const initialState = {
  [FONTS.EESTI_DISPLAY]: {
    name: 'Eesti Display',
    className: 'eesti-display--loaded',
  },
  url: '',
};

export default function fonts(state: State = initialState, action: FontAction) {
  if (action.type === 'SET_FONT_LOADING') {
    return update(state, {
      [action.font]: {
        $set: {
          loading: true,
          loaded: false,
        },
      },
    });
  }

  if (action.type === 'SET_FONT_LOADED') {
    return update(state, {
      [action.font]: {
        $set: {
          loading: false,
          loaded: true,
        },
      },
    });
  }

  if (action.type === 'SET_URL_TO_FONTS') {
    return {
      ...state,
      url: action.url,
    };
  }

  (action: empty);
  return state;
}
