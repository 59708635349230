// @flow
import React from 'react';

type OwnProps = {
  items: Array<any>,
  renderItem: Function,
  className?: string,
  placeholder?: React$Element<any>,
};

const List = ({ items, renderItem, className, placeholder }: OwnProps) => {
  return (
    <ul
      className={className}
      data-test="list.container"
      data-test-length={items ? items.length : 0}
    >
      {items && items.map(renderItem)}
      {placeholder}
    </ul>
  );
};

export default List;
