// @flow

import { combineReducers } from 'redux';

import moods from './moods';
import composers from './composers';
import instruments from './instruments';
import genres from './genres';
import epochs from './epochs';
import ensembles from './ensembles';
import collection from './collection';
import soloists from './soloists';
import conductors from './conductors';
import personalPlaylists from './personalPlaylists';
import ads from './ads';
import myEvents from './myEvents';
import radios from './radios';
import recommendations from './recommendations';

import type { State as MoodsState } from './moods';
import type { State as ComposersState } from './composers';
import type { State as InstrumentsState } from './instruments';
import type { State as GenresState } from './genres';
import type { State as EpochsState } from './epochs';
import type { State as EnsemblesState } from './ensembles';
import type { State as CollectionState } from './collection';
import type { State as SoloistsState } from './soloists';
import type { State as ConductorsState } from './conductors';
import type { State as PersonalPlaylistsState } from './personalPlaylists';
import type { State as AdsState } from './ads';
import type { State as MyEventsState } from './myEvents';
import type { State as RadiosState } from './radios';
import type { State as RecommendationsState } from './recommendations';

import type { CollectionAction } from '../../actions/collection';

export type State = {
  +moods: MoodsState,
  +composers: ComposersState,
  +instruments: InstrumentsState,
  +genres: GenresState,
  +epochs: EpochsState,
  +ensembles: EnsemblesState,
  +collection: CollectionState,
  +soloists: SoloistsState,
  +conductors: ConductorsState,
  +personalPlaylists: PersonalPlaylistsState,
  +ads: AdsState,
  +myEvents: MyEventsState,
  +radios: RadiosState,
  +recommendations: RecommendationsState,
};

const combinedReducer: (state: State, action: CollectionAction) => State = combineReducers({
  moods,
  composers,
  instruments,
  genres,
  epochs,
  ensembles,
  collection,
  soloists,
  conductors,
  personalPlaylists,
  ads,
  myEvents,
  radios,
  recommendations,
});

export default combinedReducer;
