// @flow
import React from 'react';
import { connect } from 'react-redux';

import { selectOpenModalTypeIs } from '../../selectors/modals';

import Modal from './Modal';

import styles from '../../views/Subscribe.css';

import * as uiActions from '../../actions/ui';
import * as analyticsActions from '../../actions/analytics';

import UpgradeForm from '../premium/UpgradeForm';

type OwnProps = {};

type MapStateToProps = {
  isOpen: boolean,
};

type DispatchProps = {
  closeModal: Function,
  analyticsTrack: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const UpgradeModal = ({ isOpen, closeModal, analyticsTrack }: Props) => {
  const onComplete = () => {
    closeModal();
  };

  const onClose = () => {
    closeModal();
    analyticsTrack('Closed Subscription Modal', { step: 'PaymentStep' });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel="upgrade-modal"
      shouldCloseOnOverlayClick={false}
    >
      <div data-test="upgrade-modal" className={styles.form}>
        <UpgradeForm onComplete={onComplete} />
      </div>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isOpen: selectOpenModalTypeIs(state, 'UPGRADE_MODAL'),
  };
}

const dispatchProps: DispatchProps = {
  closeModal: uiActions.hideModal,
  analyticsTrack: analyticsActions.track,
};

export default connect(mapStateToProps, dispatchProps)(UpgradeModal);
