// @flow
// $FlowFixMe
import React, { useState, useLayoutEffect } from 'react';
import Experiment from '../common/Experiment';
import { Variant, emitter } from '@marvelapp/react-ab-test';
import {
  PLAN_ID_PREMIUM,
  PLAN_ID_PREMIUM_PLUS,
  PLAN_ID_PPC_12M,
  PLAN_ID_PPC_1M,
  PLANS_OVERVIEW_STEP_EXPERIMENT,
  IDAGIO_AB_COOKIE,
} from '../../constants';
import PlansOverviewSubscribeWithAddOn from './PlansOverviewSubscribeWithAddOn';
import PlansOverviewSubscribeAllPlans from './PlansOverviewSubscribeAllPlans';
import styles from './ConversionFormVisual.css';
import PlansOverviewSummary from './PlansOverviewSummary';
import ProceedToPayment from './ProceedToPayment';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import LoadingIndicator from '../common/LoadingIndicator';

const experiment = PLANS_OVERVIEW_STEP_EXPERIMENT;
emitter.defineVariants(experiment.name, Object.values(experiment.variant));

type OwnProps = {
  selectedPlan: PLAN_ID_PREMIUM | PLAN_ID_PREMIUM_PLUS | PLAN_ID_PPC_12M | PLAN_ID_PPC_1M,
  price: number,
  isStudentStripeCoupon: boolean,
  isTrialAvailable: boolean,
  relativeDiscount?: number,
  setSelectedPlan: Function,
  trialDurationDays: number,
  proceedToPayment: Function,
  premiumPlusPlan: string,
  setPremiumPlusPlan: Function,
};

type Props = OwnProps & { location: Object };

const PlansOverviewStep = ({
  selectedPlan,
  price,
  isStudentStripeCoupon,
  isTrialAvailable,
  relativeDiscount,
  setSelectedPlan,
  trialDurationDays,
  proceedToPayment,
  premiumPlusPlan,
  setPremiumPlusPlan,
  location,
}: Props) => {
  const [preselectedVariant, setPreselectedVariant] = useState(null);
  const [shouldRenderExperiment, setShouldRenderExperiment] = useState(false);

  useLayoutEffect(() => {
    const variantFromQuery = location.query && location.query.variant;
    const variantFromCookie =
      Cookies.get(IDAGIO_AB_COOKIE) && JSON.parse(Cookies.get(IDAGIO_AB_COOKIE))[experiment.id];

    if (variantFromQuery && Object.keys(experiment.variant).indexOf(variantFromQuery) > -1) {
      setPreselectedVariant(experiment.variant[variantFromQuery]);
    } else if (
      variantFromCookie &&
      Object.keys(experiment.variant).indexOf(variantFromCookie) > -1
    ) {
      setPreselectedVariant(experiment.variant[variantFromCookie]);
    }

    setShouldRenderExperiment(true);
  }, [location.query]);

  if (!shouldRenderExperiment) {
    return <LoadingIndicator isLoading />;
  }

  return (
    <Experiment name={experiment.name} preselectedVariant={preselectedVariant}>
      <Variant name={experiment.variant.plansWithAddOn}>
        <div
          className={classNames(
            styles.plansOverviewGrid,
            styles[`${experiment.variant.plansWithAddOn}Variant`]
          )}
          data-test="conversion-form.plans-overview-step-view"
        >
          <PlansOverviewSummary
            isTrialAvailable={isTrialAvailable}
            trialDurationDays={trialDurationDays}
          />
          <PlansOverviewSubscribeWithAddOn
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
            relativeDiscount={relativeDiscount}
            premiumPlusPlan={premiumPlusPlan}
            setPremiumPlusPlan={setPremiumPlusPlan}
          />
          <ProceedToPayment
            proceedToPayment={proceedToPayment}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            relativeDiscount={relativeDiscount}
            price={price}
            isStudentStripeCoupon={isStudentStripeCoupon}
          />
        </div>
      </Variant>
      <Variant name={experiment.variant.allPlans}>
        <div
          className={classNames(
            styles.plansOverviewGrid,
            styles[`${experiment.variant.allPlans}Variant`]
          )}
          data-test="conversion-form.plans-overview-step-view"
        >
          <PlansOverviewSummary
            isTrialAvailable={isTrialAvailable}
            trialDurationDays={trialDurationDays}
          />
          <PlansOverviewSubscribeAllPlans
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
            relativeDiscount={relativeDiscount}
          />
          <ProceedToPayment
            proceedToPayment={proceedToPayment}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            relativeDiscount={relativeDiscount}
            price={price}
            isStudentStripeCoupon={isStudentStripeCoupon}
          />
        </div>
      </Variant>
    </Experiment>
  );
};

export default PlansOverviewStep;
