// @flow

type MessageId =
  | 'fromDownloadToggle'
  | 'fromTrackSelection'
  | 'fromSkipTrack'
  | 'fromExternalPlayback';

type ShowUpsellViewAction = {
  type: 'SHOW_UPSELL_VIEW',
  messageId: MessageId,
};

type SetStartRouteAction = {
  type: 'SET_START_ROUTE',
  route: string,
};

export type CapacitorAction = ShowUpsellViewAction | SetStartRouteAction;

export function showUpsellView(messageId: MessageId): ShowUpsellViewAction {
  return {
    type: 'SHOW_UPSELL_VIEW',
    messageId,
  };
}

export function setStartRoute(route: string): SetStartRouteAction {
  return {
    type: 'SET_START_ROUTE',
    route,
  };
}
