// @flow
import React, { PureComponent } from 'react';
import type { AuthorGroup } from '../../shapes/types';

import Separated from '../util/Separated';
import Person from '../common/Person';

import styles from './ComposerList.css';

type Props = {|
  authors: Array<AuthorGroup>,
  hyperlinks?: boolean,
  tabIndex?: number | null,
  linkClassNames?: string,
  className?: string,
  omitForename?: boolean,
|};

export default class ComposerList extends PureComponent<Props> {
  defaultProps: {
    authors: [],
    omitForename: false,
  };

  renderAsPerson(authorGroup: AuthorGroup) {
    return authorGroup.persons.map(person => (
      <span key={person.id}>
        <span className={styles.personDefault}>
          <Person {...this.props} person={person} />
        </span>
        <span className={styles.personMobile}>
          <Person {...this.props} omitForename person={person} />
        </span>
      </span>
    ));
  }

  render() {
    const authors = this.props.authors;
    const composers = authors.filter(({ key }) => key === 'composer')[0];

    if (!composers) {
      return null;
    }

    const composersOriginalWork = authors.filter(({ key }) => key === 'composer_original_work')[0];
    const moreThanOneComposer = composers.persons.length > 1 || composersOriginalWork;

    if (moreThanOneComposer) {
      return (
        <div className={this.props.className}>
          <Separated separator=" / ">
            {composers && <Separated>{this.renderAsPerson(composers)}</Separated>}
            {composersOriginalWork && (
              <Separated>{this.renderAsPerson(composersOriginalWork)}</Separated>
            )}
          </Separated>
        </div>
      );
    }

    return (
      <div className={this.props.className}>
        <Person {...this.props} person={composers.persons[0]} />
      </div>
    );
  }
}
