// @flow
import React from 'react';

import AuthOptions from './AuthOptions';
import SignupForm from './SignupForm';

import { SIGNUP_INTENT } from '../../constants';
import styles from './AuthOptions.css';
import SignupHeader from '../../components/messages/SignupHeader';
import { FormattedMessage } from 'react-intl';
import LoginLink from './LoginLink';

type OwnProps = {
  fromId: string,
  signupSource?: string,
  promotionName?: string,
  onAuthComplete?: Function,
  onClickLoginLink?: Function,
  subheader?: React$Element<typeof FormattedMessage>,
  hideSocialOptions?: boolean,
};

type Props = OwnProps;

const Signup = ({
  fromId,
  onAuthComplete,
  onClickLoginLink,
  subheader,
  signupSource,
  promotionName,
  hideSocialOptions,
}: Props) => {
  const Header = () => {
    return (
      <div className={styles.header}>
        <h1 className="fz--beta">
          <SignupHeader />
        </h1>
        <p className={styles.subheader}>{subheader}</p>
        <LoginLink onClick={onClickLoginLink} />
      </div>
    );
  };

  const DefaultForm = () => {
    return (
      <SignupForm
        onAuthComplete={onAuthComplete}
        fromId={fromId}
        signupSource={signupSource}
        promotionName={promotionName}
      />
    );
  };

  return (
    <AuthOptions
      onAuthComplete={onAuthComplete}
      defaultForm={<DefaultForm />}
      header={<Header />}
      intent={SIGNUP_INTENT}
      fromId={fromId}
      hideSocialOptions={hideSocialOptions}
    />
  );
};

export default Signup;
