// @flow
import update from 'immutability-helper';

import type { SetLocalListSearchQueryAction } from '../actions/localListSearch';

export type State = {
  +[key: string]: string,
};

const initialState = {};

export default function localListSearch(
  state: State = initialState,
  action: SetLocalListSearchQueryAction
): State {
  if (action.type === 'SET_LOCAL_LIST_SEARCH_QUERY' && action.id) {
    return update(state, {
      [action.id]: { $set: action.query },
    });
  }
  return state;
}
