/* @flow */
// $FlowFixMe
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { LOGIN_INTENT } from '../../constants';

import * as analyticsActions from '../../actions/analytics';
import { selectAuthIsWorking } from '../../selectors/auth';

import FacebookLogin from './FacebookLogin';
import AppleButton from './AppleButton';

import styles from './AuthOptions.css';

declare var __ELECTRON__: boolean;

type OwnProps = {
  onAuthComplete: Function,
  header?: React$Element<any>,
  defaultForm: React$Element<any>,
  intent: string,
  fromId?: string,
  hideSocialOptions?: boolean,
  showFacebookLogin?: boolean,
};

type MapStateToProps = {
  authIsWorking: boolean,
};

type DispatchProps = {
  track: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const AuthOptions = ({
  intent,
  defaultForm,
  header,
  onAuthComplete,
  fromId,
  authIsWorking,
  track,
  hideSocialOptions = false,
  showFacebookLogin = false,
}: Props) => {
  const trackSelectedFacebook = () => {
    const trackingEvent =
      intent === LOGIN_INTENT ? 'Selected Signin Method' : 'Selected Signup Method';
    track(trackingEvent, { authMethod: 'facebook' });
  };

  const trackSelectedAppleSignin = () => {
    const trackingEvent =
      intent === LOGIN_INTENT ? 'Selected Signin Method' : 'Selected Signup Method';
    track(trackingEvent, { authMethod: 'apple' });
  };

  const onAppleSignin = () => {
    if (typeof window !== 'undefined' && window.AppleID) {
      window.AppleID.auth.signIn();
      trackSelectedAppleSignin();
    }
  };

  const showAppleLogin = !__ELECTRON__;
  const showSocialOptions = !hideSocialOptions && (showFacebookLogin || showAppleLogin);

  return (
    <div className={styles.formContainer}>
      {header}
      <div className={styles.defaultForm}>{defaultForm}</div>
      {showSocialOptions && (
        <React.Fragment>
          <p className={styles.or}>
            <FormattedMessage id="auth-options.or" defaultMessage="or" />
          </p>
          <div className={styles.socialButtonsContainer}>
            {showFacebookLogin && (
              <FacebookLogin
                trackSelectedFacebook={trackSelectedFacebook}
                onAuthComplete={onAuthComplete}
                passwordConfirmCopy={
                  <FormattedMessage
                    id="login.options.password-confirm"
                    defaultMessage="This email address has already been used to create an IDAGIO account. To continue, please enter your IDAGIO password."
                  />
                }
                fromId={fromId}
              />
            )}
            {showAppleLogin && <AppleButton disabled={authIsWorking} onClick={onAppleSignin} />}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    authIsWorking: selectAuthIsWorking(state),
  };
}

const dispatchProps: DispatchProps = {
  track: analyticsActions.track,
};

export default memo(connect(mapStateToProps, dispatchProps)(AuthOptions), () => true);
