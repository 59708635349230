// @flow
import update from 'immutability-helper';
import { values } from 'lodash';

import { handlePaginatedListRequest } from '../../phasedActionHelpers';

import { FAILURE, REQUEST, SUCCESS } from '../../../middleware/api';

import type { Request } from '../../../actions/types';

type ModifyActions = {
  add: any,
  remove: any,
  fetch: any,
};

type Action = { type: any, ids?: [], collectionType?: string } & Request;

function getNormalizedIds(action: Action) {
  if (action.ids) {
    // track and artist ids being stored as numbers in the state,
    // in the action though we get them as strings
    return action.collectionType === 'track' || action.collectionType === 'artist'
      ? action.ids.map(Number)
      : action.ids;
  }
  return [];
}

function collectionSpliceUpdate<S: Object>(state: S, action: Action): Object {
  const spliceActions = getNormalizedIds(action).reduce((result, trackIdToRemove) => {
    const indexToRemove = state.entities.indexOf(trackIdToRemove);
    if (indexToRemove !== -1) {
      result.push([indexToRemove, 1]);
    }
    return result;
  }, []);

  return {
    previousEntities: { $set: state.entities },
    entities: { $splice: spliceActions },
  };
}

function collectionUnshiftUpdate<S: Object>(state: S, action: Action): Object {
  return {
    previousEntities: { $set: state.entities },
    entities: { $unshift: getNormalizedIds(action) },
  };
}

export default function createCollectionReducer<S: Object>(
  initialState: S,
  actions: ModifyActions
): (s: S, a: Action) => S {
  const { add, remove, fetch } = actions;
  return function reducer(state: S = initialState, action: Action): S {
    switch (action.type) {
      case add:
        if (!state.loaded) {
          return state;
        }

        if (action.phase === REQUEST) {
          return update(state, {
            loading: { $set: true },
            ...collectionUnshiftUpdate(state, action),
          });
        }

        if (action.phase === FAILURE) {
          return update(state, {
            loading: { $set: false },
            entities: { $set: state.previousEntities },
          });
        }

        if (action.phase === SUCCESS) {
          return update(state, {
            loading: { $set: false },
          });
        }
        return state;
      case remove:
        if (!state.loaded) {
          return state;
        }

        if (action.phase === REQUEST) {
          return update(state, {
            loading: { $set: true },
            ...collectionSpliceUpdate(state, action),
          });
        }

        if (action.phase === FAILURE) {
          return update(state, {
            loading: { $set: false },
            entities: { $set: state.previousEntities },
          });
        }

        if (action.phase === SUCCESS) {
          return update(state, {
            loading: { $set: false },
          });
        }
        return state;
      case fetch:
        return handlePaginatedListRequest(state, action, result => {
          return values(result);
        });
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
}
