// @flow
import type { FetchLiveCompareAction } from '../../reducers/liveCompare';

export type SetLiveCompareActiveRecordingAction = {
  type: 'SET_ACTIVE_RECORDING_INDEX',
  payload: number,
};

export type SetCurrentyOnAction = {
  type: 'SET_LIVE_COMPARE_CURRENTLY_ON',
  payload: boolean,
};

export type SetLiveCompareIsSwitchingAction = {
  type: 'SET_LIVE_COMPARE_IS_SWITCHING',
  payload: boolean,
};

export type SetLiveComparePlayingRecordingIndex = {
  type: 'SET_LIVE_COMPARE_PLAYING_RECORDING_INDEX',
  payload: number,
};

export type SetLiveCompareDismissedInactivityTooltip = {
  type: 'SET_LIVE_COMPARE_DISMISSED_INACTIVITY_TOOLTIP',
};

export type LiveCompareAction =
  | FetchLiveCompareAction
  | SetLiveCompareActiveRecordingAction
  | SetCurrentyOnAction
  | SetLiveCompareIsSwitchingAction
  | SetLiveComparePlayingRecordingIndex
  | SetLiveCompareDismissedInactivityTooltip;

export function setLiveCompareCurrentlyOn(flag: boolean): SetCurrentyOnAction {
  return {
    type: 'SET_LIVE_COMPARE_CURRENTLY_ON',
    payload: flag,
  };
}

export function setLiveCompareIsSwitching(flag: boolean): SetLiveCompareIsSwitchingAction {
  return {
    type: 'SET_LIVE_COMPARE_IS_SWITCHING',
    payload: flag,
  };
}

export function setLiveComparePlayingRecordingIndex(
  index: number
): SetLiveComparePlayingRecordingIndex {
  return {
    type: 'SET_LIVE_COMPARE_PLAYING_RECORDING_INDEX',
    payload: index,
  };
}

export function dismissLiveCompareInactivityTooltip(): SetLiveCompareDismissedInactivityTooltip {
  return {
    type: 'SET_LIVE_COMPARE_DISMISSED_INACTIVITY_TOOLTIP',
  };
}
