import * as Sentry from '@sentry/react';
import { EventEmitter } from 'events';
import { TrackUrlResolveError } from '../trackUrlResolver';
import { isSentryInstalled } from '../Sentry';

import { awaitWithTimeout } from '../../utils/liveCompare';

const MAX_RESOLVE_URL_RETRY_COUNT = 1;

export default class BasePlayer extends EventEmitter {
  // Event names
  static STARTED_PLAYING = 'STARTED_PLAYING';
  static RESUMED_PLAYING = 'RESUMED_PLAYING';
  static ENDED = 'ENDED';
  static RESTARTED_TRACK = 'RESTARTED_TRACK';
  static PROGRESS = 'PROGRESS';
  static RESOLVING_URL = 'RESOLVING_URL';
  static RESOLVED_URL = 'RESOLVED_URL';
  static PLAYBACK_ERROR = 'PLAYBACK_ERROR';
  static RESOLVE_URL_ERROR = 'RESOLVE_URL_ERROR';
  static STARTED_PRELOAD = 'STARTED_PRELOAD';
  static FINISHED_PRELOAD = 'FINISHED_PRELOAD';
  static ABORTED_PRELOAD = 'ABORTED_PRELOAD';
  static QUEUED_NEXT_TRACK = 'QUEUED_NEXT_TRACK';
  static CONTROLS_DISABLED = 'CONTROLS_DISABLED';
  static CONTROLS_ENABLED = 'CONTROLS_ENABLED';
  static PLAYBACK_TIME_OUT = 'PLAYBACK_TIME_OUT';
  static RESOLVE_URL_RETRY = 'RESOLVE_URL_RETRY';
  static STARTED_BUFFERING = 'STARTED_BUFFERING';

  // Percent of current track at which to preload the next one
  static PRELOAD_PERCENT = 0.9;

  // Debug help
  DEBUG_PREFIX = 'Player';

  // Private variables
  playbackTimeoutTime = 20e3; // 20s
  playbackErrorCount = 0;

  // Debug by logging out the emitted events
  debug = false;
  debugBlackList = [];
  debugProgress = false;

  constructor(urlResolver, audioQuality, createAudioDevice, updateInterval = 1000) {
    super();
    this.urlResolver = urlResolver;
    this.audioQuality = audioQuality;
    this.createAudioDevice = createAudioDevice;
    this.updateInterval = updateInterval;

    // Queue specific
    this.queue = [];
    this.queueId = 0; // to identify queue changes

    // Preload Flags
    this.isPreloading = false;
    this.isPreloaded = false;
    this.preloadingIndex = null;
    this.initialLoad = true;

    // Queue flags
    this.currentIndex = null;

    // Playback flags
    this.startedItemId = null;
    this.shouldBePlaying = false;
    this._progressTimer = null;

    // Playback seek flags
    this.wasSeek = false;
    this.resumePosition = null;

    // Timeout flags
    this.hasTimedOut = false;
    this.timeoutCheckId = null;

    // Audio devices
    this.mainAudio = this.createAudioDevice();
    this.preloadAudio = this.createAudioDevice();

    // Attach event listeners
    this._addMainListeners(this.mainAudio);

    // Progress polling
    this.setupProgressPollInterval();
  }

  // Debug: Intercepted emit function
  emit(event, ...args) {
    if (this.debug) {
      if (!this.debugBlackList.includes(event)) {
        if (event === BasePlayer.PROGRESS) {
          if (this.debugProgress) {
            console.log(this.DEBUG_PREFIX, event, ...args); // eslint-disable-line no-console
          }
        } else {
          console.log(this.DEBUG_PREFIX, event, ...args); // eslint-disable-line no-console
        }
      }
    }

    return super.emit(event, ...args);
  }

  // Can be overwritten by client client code
  shouldAdvance = () => {
    return true;
  };

  getMainAudio = () => this.mainAudio;

  // Private methods
  setupProgressPollInterval() {
    const updateProgress = () => {
      // eliminate the need to re-setup this interval every time we change the audio device.
      const mainAudio = this.getMainAudio();
      const { currentIndex, queue } = this;
      const { currentTime, duration, paused } = mainAudio;
      const src = this._getSrc(mainAudio);
      const trackId = queue[currentIndex];

      if (currentIndex === null || currentIndex === undefined) {
        // ^ especially necessary on Win 10 / IE 11 after resetQueue as it fails
        // to set mainAudio.paused to false after the end of the track
        // This check is more robust since there is no progress without a
        // currentIndex being set
        return;
      }

      if (!src || paused) {
        return;
      }

      if (duration === 0 || Number.isNaN(duration)) {
        // Actually loading something...
        return;
      }

      if (this.resumePosition) {
        // Loading next quality
        return;
      }

      if (this._shouldPreload(currentTime, duration)) {
        this._preloadQueueItemAtIndex(currentIndex + 1);
      }

      if (isSentryInstalled() && !trackId) {
        Sentry.captureException(new Error('TRACK_ID_NOT_DEFINED'), {
          tags: {
            component: 'Player',
            errorType: 'PlayerTracking',
          },
          extra: {
            queue,
            currentIndex,
            trackId,
          },
        });
      }

      const event = this._getEventProperties();
      this.emit(BasePlayer.PROGRESS, {
        currentTime,
        duration,
        ...event,
      });
    };

    clearInterval(this._progressTimer);
    this._progressTimer = setInterval(updateProgress, this.updateInterval);
  }

  // Events
  _onPlaying = () => {
    this._clearTimeoutCheck();
    this.emit(BasePlayer.CONTROLS_ENABLED);

    // We switched quality or interpretation queue
    if (this.resumePosition) {
      this.mainAudio.currentTime = this.resumePosition;
      this.resumePosition = false;
      this.wasSeek = false;
      return;
    }

    if (this.wasSeek) {
      this.wasSeek = false;
      // Always fire started playing event on initial load after seeking
      if (!this.initialLoad) {
        return;
      }
      this.initialLoad = false;
    }

    const { queue, currentIndex, audioQuality } = this;
    const trackId = queue[currentIndex];
    const itemId = [trackId, audioQuality].join('-');
    if (this.startedItemId === itemId) {
      // Only fire started playing event once per item id + quality
      return;
    }
    this.startedItemId = itemId;

    const event = this._getEventProperties();
    this.emit(BasePlayer.STARTED_PLAYING, event, this.wasPreloaded);
    this.wasPreloaded = false;
  };

  _onError = async event => {
    const target = event.target;
    const shouldRetry = this.playbackErrorCount < MAX_RESOLVE_URL_RETRY_COUNT;
    if (shouldRetry) {
      this.playbackErrorCount++;
      this.emit(BasePlayer.RESOLVE_URL_RETRY, this._getEventProperties());
      await this._startCurrentIndexPlayback();
      return;
    }

    this.playbackErrorCount = 0;
    this._clearTimeoutCheck();
    this._resetPlayerState();
    this.emit(
      BasePlayer.PLAYBACK_ERROR,
      target.error,
      this._getEventProperties(),
      this._getSrc(target)
    );
  };

  _onEnded = () => {
    const isLast = !this.hasNext();
    this.emit(BasePlayer.ENDED, isLast, this._getEventProperties());

    if (!isLast && this.shouldAdvance(this._getEventProperties())) {
      this.playNextItem();
    }
  };

  _addMainListeners(audio) {
    audio.addEventListener('error', this._onError);
    audio.addEventListener('ended', this._onEnded);
    audio.addEventListener('playing', this._onPlaying);
  }

  _removeMainListeners(audio) {
    audio.removeEventListener('error', this._onError);
    audio.removeEventListener('ended', this._onEnded);
    audio.removeEventListener('playing', this._onPlaying);
  }

  _shouldResume = trackIndex => {
    return (
      this._getSrc(this.mainAudio) && this.mainAudio.paused && trackIndex === this.currentIndex
    );
  };

  _shouldPreload(currentTime, duration) {
    if (this.isPreloading) {
      // No need to check, already in the process of preloading
      return false;
    }

    if (this.isPreloaded) {
      // No need to load, already loaded
      return false;
    }

    if (!this.hasNext()) {
      // No need to preload, no tracks after
      return false;
    }

    return currentTime > duration * BasePlayer.PRELOAD_PERCENT;
  }

  _resolveItemUrlByTrackId(trackId) {
    return this.urlResolver(trackId, this.audioQuality, this.isPreloading);
  }

  _resolveItemUrlAtIndex(index) {
    return this._resolveItemUrlByTrackId(this.queue[index]);
  }

  _isStillPreloading = index => {
    return this.isPreloading && this.preloadingIndex === index;
  };

  _getEventProperties = (index = this.currentIndex, isPreload = false) => {
    const { queue, queueId, currentIndex, audioQuality } = this;
    const isLast = !this.hasNext();

    return {
      currentIndex,
      queue: queue.slice(),
      queueId,
      trackPosition: currentIndex + 1,
      audioQuality,
      trackId: queue[index],
      isPreload,
      isLast,
      timestamp: Date.now(),
    };
  };

  // Timeout and Error
  _resetPlayerState = () => {
    this.mainAudio.pause();
    this._removeMainListeners(this.mainAudio);
    this.mainAudio = this.createAudioDevice();
    this._addMainListeners(this.mainAudio);
    this.emit(BasePlayer.CONTROLS_ENABLED);
  };

  _triggerTimeout = () => {
    this.hasTimedOut = true;
    const time = this._getTimeoutTime();
    this._resetPlayerState();
    this.emit(BasePlayer.PLAYBACK_TIME_OUT, this._getEventProperties(), time);
  };

  _getTimeoutTime = () => this.playbackTimeoutTime;

  _setTimeoutCheck = () => {
    if (this.timeoutCheckId) {
      clearTimeout(this.timeoutCheckId);
    }

    this.timeoutCheckId = setTimeout(() => this._triggerTimeout(), this._getTimeoutTime());
  };

  _clearTimeoutCheck = () => {
    clearTimeout(this.timeoutCheckId);
    this.timeoutCheckId = null;
    this.hasTimedOut = false;
  };

  // To be implemented by individual players
  _switchToPreloadPlayer = () => {
    throw new Error('Implement: _switchToPreloadPlayer');
  };

  _preloadQueueItemAtIndex = async () => {
    throw new Error('Implement: _preloadQueueItemAtIndex');
  };

  _startPlayback = async () => {
    throw new Error('Implement: _startPlayback');
  };

  _liveCompareSwitch = async () => {
    throw new Error('Implement: _liveCompareSwitch');
  };

  async liveCompareSwitch(toTrackId, getInterpolatedTimestamp) {
    const liveComparePreloadAudioDevice = this.createAudioDevice();

    await awaitWithTimeout(this.preloadAudioDevice(toTrackId, liveComparePreloadAudioDevice), 3500);
    await this._liveCompareSwitch(getInterpolatedTimestamp, liveComparePreloadAudioDevice);
  }

  // Public

  hasNext() {
    return this.currentIndex !== this.queue.length - 1;
  }

  hasPrevious() {
    return this.currentIndex > 0;
  }

  setQuality(audioQuality) {
    this.audioQuality = audioQuality;
    if (typeof this.currentIndex !== 'undefined' && this.queue.length) {
      if (this._getSrc(this.mainAudio)) {
        this.emit(BasePlayer.CONTROLS_DISABLED);
        this.resumePosition = this.mainAudio.currentTime;
        this.playQueueItemAtIndex(this.currentIndex, !this.mainAudio.paused);
      }
    }
  }

  seek(time) {
    this.wasSeek = true;
    this.mainAudio.currentTime = time;
  }

  stop() {
    this.mainAudio.pause();
    this.mainAudio.currentTime = 0;
  }

  resume() {
    this.shouldBePlaying = true;
    const oldSeekPos = this.mainAudio.currentTime;
    this.mainAudio.play();
    // You have to set the time after calling play in safari
    // https://stackoverflow.com/questions/38134149/html5-video-seeking-to-mid-video-on-ended-on-safari
    this.mainAudio.currentTime = oldSeekPos;
    this.emit(BasePlayer.RESUMED_PLAYING, this._getEventProperties());
  }

  restart() {
    this.shouldBePlaying = true;
    this.mainAudio.currentTime = 0;
    this.mainAudio.play();
    this.emit(BasePlayer.RESTARTED_TRACK, this._getEventProperties());
  }

  pause() {
    this.shouldBePlaying = false;
    this._clearTimeoutCheck();
    this.emit(BasePlayer.CONTROLS_ENABLED);
    this.mainAudio.pause();
  }

  setVolume(value) {
    this.mainAudio.volume = value;
  }

  setMuted(flag) {
    this.mainAudio.muted = flag;
  }

  toggleMute() {
    this.mainAudio.muted = !this.mainAudio.muted;
  }

  // Public, Queue Related

  resetQueue(restartPlayback = false) {
    this.playQueueItemAtIndex(0, restartPlayback);
  }

  getCurrentTime() {
    return this.mainAudio.currentTime;
  }

  /**
   * preloads the audio for playback
   * @param trackId
   * @param audioDevice = this.mainAudio the audio device to preload the audio for.
   * @returns {Promise<void>}
   */
  async preloadAudioDevice(trackId, audioDevice) {
    const preloadToAudioDevice = audioDevice || this.preloadAudio;
    const url = await this._resolveItemUrlByTrackId(trackId);

    return this._startPlayback(url, false, preloadToAudioDevice);
  }

  updateQueue(queue, currentIndex) {
    this.queue = queue;
    this.currentIndex = currentIndex;
    this.queueId++;
  }

  // todo: think if there is a better way to name this method as it also kinda controls playback.
  setQueue(queue, resumePosition = false, currentIndex) {
    this.updateQueue(queue, currentIndex);

    // ---------
    if (resumePosition) {
      // Save the position
      this.resumePosition = this.mainAudio.currentTime;
    }

    // Save old mainAudio settings
    const oldMuted = this.mainAudio.muted;
    const oldVolume = this.mainAudio.volume;

    // Clear old Flags
    this.isPreloading = false;
    this.isPreloaded = false;

    // Clear the current queue and pause playback
    this._removeMainListeners(this.mainAudio);
    this.mainAudio.pause();

    // Reset the main audio while keeping volume and muted
    this.mainAudio = this.createAudioDevice();
    this.mainAudio.muted = oldMuted;
    this.mainAudio.volume = oldVolume;
    this._addMainListeners(this.mainAudio);
    // ---------

    // Reset preload audio device
    this.preloadAudio = this.createAudioDevice();
  }

  playPreviousItem(autoPlay) {
    if (!this.hasPrevious()) {
      return;
    }

    // Reset the seek flags when moving to the prev track (Safari had trouble)
    this.resumePosition = null;
    this.wasSeek = false;

    this.currentIndex -= 1;
    this.emit(BasePlayer.QUEUED_NEXT_TRACK, this._getEventProperties());
    this.playQueueItemAtIndex(this.currentIndex, autoPlay);
  }

  queueNextItem() {
    if (!this.hasNext()) {
      return;
    }

    // Reset the seek flags when moving to the next track (Safari had trouble)
    this.resumePosition = null;
    this.wasSeek = false;

    this.currentIndex += 1;
    this.emit(BasePlayer.QUEUED_NEXT_TRACK, this._getEventProperties());

    if (this.isPreloaded) {
      // Set wasPreloaded first
      this.wasPreloaded = true;

      // Switch em'; implemented in individual clients
      this._switchToPreloadPlayer(false);

      // Clear preload flags
      this.preloadingIndex = null;
      this.isPreloading = false;
      this.isPreloaded = false;
    } else {
      this.playQueueItemAtIndex(this.currentIndex, false);
    }
  }

  playNextItem() {
    if (!this.hasNext()) {
      return;
    }

    // Reset the seek flags when moving to the next track (Safari had trouble)
    this.resumePosition = null;
    this.wasSeek = false;

    this.currentIndex += 1;
    this.emit(BasePlayer.QUEUED_NEXT_TRACK, this._getEventProperties());

    if (this.isPreloaded) {
      // Set wasPreloaded first
      this.wasPreloaded = true;

      // Switch em'; implemented in individual clients
      this._switchToPreloadPlayer();

      // Clear preload flags
      this.preloadingIndex = null;
      this.isPreloading = false;
      this.isPreloaded = false;
    } else {
      this.playQueueItemAtIndex(this.currentIndex);
    }
  }

  playCurrentQueuedItem() {
    this.playQueueItemAtIndex(this.currentIndex);
  }

  async playQueueItem(track, progress) {
    const trackIndex = this.queue.indexOf(track);
    if (trackIndex === -1) {
      console.log(this.DEBUG_PREFIX, 'Track is not in queue:', track, this.queue); // eslint-disable-line
      // Track is not in queue at all; exit
      return;
    }

    const shouldResume = this._shouldResume(trackIndex);
    if (shouldResume) {
      this.resume();
      return;
    }

    await this.playQueueItemAtIndex(trackIndex);

    if (typeof progress === 'number') {
      this.seek(progress);
    }
  }

  async playQueueItemAtIndex(index, autoPlay = true) {
    if (!this.queue[index]) {
      throw new Error(`${this.DEBUG_PREFIX} Track index: ${index} is out of queue bounds.`, {
        index,
        queue: this.queue,
      });
    }

    this.emit(BasePlayer.CONTROLS_DISABLED, index);
    // Pause whatever is playing
    this.mainAudio.pause();

    // Set flag for UI interactions while loading
    this.shouldBePlaying = true;

    // Handle preloading
    this.isPreloaded = false;
    if (this.isPreloading) {
      this.preloadAudio = this.createAudioDevice();
      this.isPreloading = false;
      this.preloadingIndex = null;
    }

    // Set current index
    this.currentIndex = index;

    const paramsAtRequestTime = this._getEventProperties();
    this.emit(BasePlayer.RESOLVING_URL, index);
    if (autoPlay) {
      this._setTimeoutCheck();
    }

    this.playbackErrorCount = 0;
    this.paramsAtRequestTime = paramsAtRequestTime;
    this.autoPlay = autoPlay;
    return await this._startCurrentIndexPlayback();
  }

  _startCurrentIndexPlayback = async () => {
    const paramsAtRequestTime = this.paramsAtRequestTime;
    const index = this.currentIndex;
    const autoPlay = this.autoPlay;

    try {
      const url = await this._resolveItemUrlAtIndex(index);
      if (this.hasTimedOut) {
        return;
      }

      if (paramsAtRequestTime.currentIndex !== this.currentIndex) {
        console.log(this.DEBUG_PREFIX, 'TimingError', 'Aborting because of currentIndex change'); // eslint-disable-line no-console
        // Looks like while this url was loading at attempt at another url load was made
        // discarding request
        return;
      }

      if (paramsAtRequestTime.queueId !== this.queueId) {
        console.log(this.DEBUG_PREFIX, 'TimingError', 'Aborting because of queueId change'); // eslint-disable-line no-console
        // Looks like while this url was resolving a new queue was set
        return;
      }

      if (paramsAtRequestTime.audioQuality !== this.audioQuality) {
        console.log(this.DEBUG_PREFIX, 'TimingError', 'Aborting because of quality change'); // eslint-disable-line no-console
        // Looks like while this url was resolving a new quality was set
        return;
      }

      const eventProps = this._getEventProperties();
      this.emit(BasePlayer.RESOLVED_URL, { ...eventProps, autoPlay });

      // Clear old value
      this.startedItemId = null;

      const shouldStartPlayback = this.shouldBePlaying && autoPlay;

      try {
        // Ask implementation to start playback or just set the url
        await this._startPlayback(url, shouldStartPlayback);
      } catch (startPlaybackError) {
        /*
          AbortErrors occurs when things are played/paused in quick succession.
          Playback doesn't actually break so we can safely ignore them here.
        */
        if (startPlaybackError.name === 'AbortError') {
          return;
        }

        this._clearTimeoutCheck();
        this._resetPlayerState();
        this.emit(
          BasePlayer.PLAYBACK_ERROR,
          startPlaybackError,
          paramsAtRequestTime,
          this._getSrc(this.mainAudio)
        );
      }

      // Re-enable controls if playback start is not necessary
      if (!shouldStartPlayback) {
        this.emit(BasePlayer.CONTROLS_ENABLED);
      }
    } catch (err) {
      if (err instanceof TrackUrlResolveError) {
        this.emit(BasePlayer.RESOLVE_URL_ERROR, err, paramsAtRequestTime);
        return;
      }

      this._clearTimeoutCheck();
      this._resetPlayerState();
      this.emit(BasePlayer.PLAYBACK_ERROR, err, paramsAtRequestTime, this._getSrc(this.mainAudio));
    }
  };

  _getSrc = target => target.src;

  _setCurrentIndex = index => {
    this.currentIndex = index;
  };
}
