// @flow

import { DEFAULT_LOCALE } from '../../constants';

import type { SetSupportedLocales } from '../../actions/client';

export type State = Array<string>;

const initialState = [DEFAULT_LOCALE];

export default function supportedLocales(state: State = initialState, action: SetSupportedLocales) {
  if (action.type === 'SET_SUPPORTED_LOCALES') {
    return action.locales;
  }

  (action: empty);
  return state;
}
