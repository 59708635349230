import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Image from '../util/Image';

import { selectPageTrackingContext } from '../../selectors/client';
import { selectAlbum } from '../../selectors/album';
import { selectPlaylist } from '../../selectors/playlist';
import { selectPersonalPlaylist } from '../../selectors/personalPlaylist';
import { selectPlayerQueueOrigin } from '../../selectors/player';

import * as analyticsActions from '../../actions/analytics';
import { togglePlayPlayableItemPreview } from '../../actions/playableItemPreview';

import styles from './PreviewsModal.css';

import {
  PLAYABLE_ITEM_PREVIEW_DIMS,
  IMAGES_BASE,
  IMGIX_PARAMS,
  QUEUE_TYPE_PERSONAL_PLAYLIST,
} from '../../constants';

const defaultImage = `${IMAGES_BASE}/albums/IDG000000069/main.jpg`;

class PreviewsModalLeftPane extends Component {
  static propTypes = {
    imageUrl: PropTypes.string,
  };
  render() {
    // Dimensions and therefore the imgix url will match PlayableItemPreview.
    // This is to reuse already loaded image (and to avoid additional loading).
    const imageDimension = PLAYABLE_ITEM_PREVIEW_DIMS;
    return (
      <div className={styles.albumContainer}>
        <Image
          noBase
          src={this.props.imageUrl}
          width={imageDimension.w}
          height={imageDimension.h}
          auto={IMGIX_PARAMS}
          className={styles.previewImage}
          alt=""
        />
        <p className={styles.explanation}>
          <FormattedMessage
            id="previews-modal.explanation-2"
            defaultMessage="This is just a preview – get the full experience by creating an account for free."
          />
        </p>
      </div>
    );
  }
}

function getQueueOriginImage(state) {
  const queueOrigin = selectPlayerQueueOrigin(state);
  if (queueOrigin) {
    let contentSelector = selectPlaylist;

    if (queueOrigin.type === 'QUEUE_TYPE_PERSONAL_PLAYLIST') {
      contentSelector = selectPersonalPlaylist;
    }

    if (queueOrigin.type === 'QUEUE_TYPE_ALBUM') {
      contentSelector = selectAlbum;
    }

    const content = contentSelector(state, queueOrigin.id);
    return content && content.imageUrl;
  }
  return null;
}

function getContextImage(state) {
  const { contextType, contextSlug, contextId } = selectPageTrackingContext(state);
  let content = null;

  if (contextType === QUEUE_TYPE_PERSONAL_PLAYLIST) {
    content = selectPersonalPlaylist(state, contextId);
  }

  if (contextType && contextSlug) {
    content =
      contextType === 'Album'
        ? selectAlbum(state, contextSlug)
        : selectPlaylist(state, contextSlug);
  }

  return content && content.imageUrl;
}

function getPreviewImage(state) {
  const queueOriginImage = getQueueOriginImage(state);
  if (queueOriginImage) {
    return queueOriginImage;
  }

  const contextImage = getContextImage(state);
  if (contextImage) {
    return contextImage;
  }

  return defaultImage;
}

function mapStateToProps(state) {
  return { imageUrl: getPreviewImage(state) };
}

export default connect(
  mapStateToProps,
  {
    analyticsTrack: analyticsActions.track,
    togglePlay: togglePlayPlayableItemPreview,
  },
  (stateProps, dispatchProps) => ({ ...stateProps, ...dispatchProps })
)(PreviewsModalLeftPane);
